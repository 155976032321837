import React from 'react';
import TagsPanel from './TagsPanel';
import useTitle from '@/hook/useTitle';
import {useEditAuthService} from '@/page/admin/adminService';

export default function TagsPage() {
    useTitle('标签管理 - 系统管理');

    const editable = useEditAuthService(['global:tag:w']);

    return <TagsPanel editable={editable} />;
}
