import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {PageHeader} from 'antd';
import Line from '@ant-design/charts/es/line';
import dayjs from 'dayjs';

import ChartDownloader from '../component/ChartDownloader';
import {useHourlyNewsData} from './newsReportService';

HourlyNewsChart.propTypes = {
    appId: PropTypes.string.isRequired,
};

function HourlyNewsChart({appId}) {
    const chartRef = useRef();
    const {loading, value} = useHourlyNewsData(appId);
    // console.log('loading, value', loading, value);

    if (loading || !value) {
        return null;
    }

    return (
        <div style={{paddingBottom: 48}}>
            <PageHeader
                title="24小时图文活跃分布"
                style={{padding: '16px 0'}}
                extra={<ChartDownloader chartRef={chartRef} name="24小时图文活跃分布.png" />}
            />
            <Line
                data={value}
                chartRef={chartRef}
                padding="auto"
                xField="date"
                yField="value"
                seriesField="category"
                smooth
                point={{shape: 'circle'}}
                tooltip={{
                    title: (title, {dateHour}) => {
                        // console.log(dateHour);
                        return `小时：${dateHour}`;
                    },
                }}
                xAxis={{
                    label: {
                        formatter: (text, item, index) => {
                            // console.log('text, item, index', text, item, index);
                            // console.log('value[index]', index, value[index]);
                            const {id} = item;
                            // 转成字符串，否则 0 不会正常显示
                            return '' + dayjs(id, 'YYYY-MM-DDTHH:mm:ss').hour();
                        },
                    },
                }}
            />
        </div>
    );
}

export default HourlyNewsChart;
