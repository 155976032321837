import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, message} from 'antd';
import {useParams} from 'react-router-dom';
import copy from 'copy-to-clipboard';

import {PlusOutlined} from '@/icons';
import NewsFormModal from '@/page/component/NewsFormModal';
import MediaListView from '../MediaListView';
import MediaType from '../MediaType';
import NewsListModal from './news/NewsListModal';
import NewsViewModal from '@/page/app/MediaPage/pages/news/NewsViewModal';
import {addNewsMedia, getNewsContents, updateNewsMedia} from '@/api/media';
import {getMediaUrl} from '@/page/util/appUrlUtils';

export default function NewsPage({editable = true, onItemChecked}) {
    const newsFormModalRef = useRef();
    const newsListModalRef = useRef();
    const newsViewModalRef = useRef();

    const {appId} = useParams();
    const listRef = useRef();

    let type = MediaType.NEWS;

    function reload() {
        listRef.current.reload();
    }

    function onNameClick(record) {
        // console.log(record);
        newsListModalRef.current.showModal(record);
    }

    function onAddNewsClick() {
        newsFormModalRef.current.showModal();
    }

    function onAddNewsLinkClick() {
        newsFormModalRef.current.showModal({mediaType: 'news_link'});
    }

    function onEditClick(record) {
        // console.log(record);
        const {id, mediaType} = record;

        getNewsContents(id).then(({data}) => {
            // console.log(data);
            newsFormModalRef.current.showModal({businessId: id, mediaType, newsList: data});
        });
    }

    function onCopyLinkClick(record) {
        // console.log('onCopyLinkClick', record);
        const url = getMediaUrl(record.id);

        const result = copy(url);
        if (result) {
            message.success(`链接拷贝到剪贴板`);
        } else {
            message.warn('链接拷贝失败');
        }
    }

    function onNewsItemClick(news) {
        console.log('onNewsItemClick', news);
        const {id, link} = news;
        if (link) {
            window.open(link, '_blank', 'noopener');
        } else {
            newsViewModalRef.current.showModal(id);
        }
    }

    function onFormSuccess(businessId, mediaType, newsList) {
        // console.log('businessId', businessId);
        console.table(newsList);

        const promise = !businessId
            ? addNewsMedia(appId, mediaType, newsList)
            : updateNewsMedia(appId, businessId, mediaType, newsList);
        return promise.then(data => {
            console.log(data);
            reload();
            return true;
        });
    }

    return (
        <>
            <MediaListView
                ref={listRef}
                type={type}
                editable={editable}
                onItemChecked={onItemChecked}
                columnKeys={['avatar', 'title', 'newsNum', 'creatorName', 'createTime']}
                columnOptions={{onNameClick}}
                columnActionProps={{enableDownload: false, onEditClick, onCopyLinkClick}}
                switchVisible
                initialGrid
            >
                {editable && (
                    <>
                        <Button type="primary" icon={<PlusOutlined />} onClick={onAddNewsClick}>
                            添加图文
                        </Button>
                        <Button type="primary" onClick={onAddNewsLinkClick}>
                            链接图文
                        </Button>
                    </>
                )}
            </MediaListView>
            <NewsFormModal ref={newsFormModalRef} itemFrom="media" onFormSuccess={onFormSuccess} />
            <NewsListModal ref={newsListModalRef} onItemClick={onNewsItemClick} />
            <NewsViewModal ref={newsViewModalRef} />
        </>
    );
}

NewsPage.propTypes = {
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
};
