import PropTypes from 'prop-types';

export const roleScopePropTypes = {
    scope: PropTypes.oneOf(['global', 'app']),
    scopeItemId: PropTypes.string,
    fallbackOnApp: PropTypes.bool,
};

export const rolePropTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    meme: PropTypes.string,
    ...roleScopePropTypes,
};

export const roleTypes = PropTypes.shape(rolePropTypes);
