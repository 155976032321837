import React, {useRef} from 'react';
import {Button, PageHeader, Spin, Typography} from 'antd';

import {useAppCustomSettingsDefine} from './appCustomService';
import AppListBySetting from './AppListBySetting';
import AppMultiSelector from '@/page/admin/selector/AppMultiSelector';
import {saveSettings} from '@/api/settings';

import styles from './styles.module.less';
import useTitle from '@/hook/useTitle';

function AppCustomPage() {
    useTitle('应用管理 - 应用功能设置');
    const appMultiSelectorRef = useRef();

    const {value, loading, retry} = useAppCustomSettingsDefine();
    const {appIdMap = {}, settingList = []} = value ?? {};
    console.log('useAppCustomSettingsDefine', appIdMap, settingList, loading);

    const selSettingKey = useRef();

    function onSelAppClick(key, appIds) {
        return () => {
            console.log(appIds);
            selSettingKey.current = key;
            appMultiSelectorRef.current.showModal(appIds, appIdMap);
        };
    }

    function onAppSelect(appIds) {
        console.log('onAppSelect', appIds, selSettingKey);

        return saveSettings({[selSettingKey.current]: appIds.join(',')}).then(data => {
            retry();

            return true;
        });
    }

    return (
        <PageHeader title="应用功能设置" style={{overflowY: 'auto'}}>
            <Spin spinning={loading}>
                {settingList.map(({id, name, settingKey, settingValue}) => {
                    const appIds = settingValue?.split(',').filter(Boolean);
                    console.log('appIds', appIds, id, name, settingKey);

                    return (
                        <div key={id} className={styles.item}>
                            <div className={styles.titleWrap}>
                                <Typography.Title level={5}>{name}</Typography.Title>
                                <Button
                                    type="primary"
                                    size="small"
                                    onClick={onSelAppClick(settingKey, appIds)}
                                >
                                    选择应用
                                </Button>
                            </div>
                            <AppListBySetting appIdMap={appIdMap} appIds={appIds} />
                        </div>
                    );
                })}
            </Spin>

            <AppMultiSelector ref={appMultiSelectorRef} onSelect={onAppSelect} />
        </PageHeader>
    );
}

export default AppCustomPage;
