import React, {useState} from 'react';
import {Card} from 'antd';
import _ from 'lodash';

import RecentReportList from '../component/RecentReportList';
import PropTypes from 'prop-types';

export default function RecentList({appId, alias}) {
    const [userCount, setUserCount] = useState(0);

    function onLoad(data = []) {
        console.log('onload', data);
        if (_.isEmpty(data)) {
            setUserCount(0);
            return;
        }

        const {allowUserCount} = data[0];
        setUserCount(allowUserCount);
    }
    const recentColumns = [
        {
            title: '时间',
            dataIndex: 'title',
        },
        {
            title: '开通应用可见（人）',
            dataIndex: 'subscribeCount',
            align: 'right',
        },
        {
            title: '关闭应用可见（人）',
            dataIndex: 'unsubscribeCount',
            align: 'right',
        },
    ];

    return (
        <div style={{display: 'flex'}}>
            <div style={{flex: 1}}>
                <RecentReportList
                    appId={appId}
                    alias={alias}
                    columns={recentColumns}
                    onLoad={onLoad}
                />
            </div>
            <Card
                title="已关注的可见成员"
                size="small"
                style={{width: 400, marginLeft: 24, display: 'flex', flexDirection: 'column'}}
                headStyle={{textAlign: 'center', backgroundColor: '#fafafa'}}
                bodyStyle={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flex: 1,
                    fontSize: 24,
                }}
            >
                {userCount}
            </Card>
        </div>
    );
}

RecentList.propTypes = {
    appId: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
};
