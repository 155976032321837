import {cleanTokens, setTokens} from '@/util/TokenStore';

const actions = {
    loginSuccess: (draft, {tokens, user, authorities}) => {
        draft.isLogin = true;
        draft.user = user;
        draft.authorities = authorities;

        setTokens(tokens);
    },

    reset: draft => {
        draft.isLogin = false;
        draft.user = null;
        draft.authorities = null;

        cleanTokens();
    },
};

export default actions;
