import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'antd';
import classNames from 'classnames';

import {PushpinTwoTone} from '@/icons';
import styles from './AppPinButton.module.less';

export default function AppPinButton({pined = false, big = false, className, onClick}) {
    const color = pined ? ['#1890ff', '#e6f7ff'] : ['#8c8c8c', '#e6e6e6'];

    return (
        <Tooltip placement="left" title={pined ? '取消置顶' : '置顶'}>
            <Button
                type="default"
                size={big ? 'large' : 'middle'}
                icon={
                    <PushpinTwoTone
                        twoToneColor={color}
                        className={classNames({[styles.pinedIcon]: pined})}
                    />
                }
                className={classNames(styles.button, className)}
                onClick={onClick}
            />
        </Tooltip>
    );
}

AppPinButton.propTypes = {
    pined: PropTypes.bool,
    big: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};
