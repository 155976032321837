import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Checkbox, Divider, Modal} from 'antd';
import {useAsync} from 'react-use';
import _ from 'lodash';

import {isPromise} from '@/util/PromiseUtils';
import {getAppRoles} from '@/api/apps';

import styles from './styles.module.less';

/**
 * 应用内人员（包括应用管理员、消息发送人）中选择。
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AppUserSelector = forwardRef(
    ({appId, empty, showMgr = true, showUser = true, onSelect}, ref) => {
        const [visible, setVisible] = useState(false);
        const [loading, setLoading] = useState(false);

        const [modalTitle, setModalTitle] = useState(null);

        // 用于每次打开 Modal 时重新读取一次人员列表
        const [timestamp, setTimestamp] = useState(0);

        const [checkedIds, setCheckedIds] = useState([]);

        const {value, loading: userLoading} = useAsync(() => {
            return getAppRoles(appId);
        }, [appId, timestamp]);
        console.log('app user selector', value);

        const appMgrs = value?.data?.app_mgr ?? [];
        const appUsers = value?.data?.app_user ?? [];

        const showEmpty = _.isEmpty(appMgrs) && _.isEmpty(appUsers) && !userLoading;
        console.log('showEmpty', showEmpty);

        function showModal({modalTitle, initIds = []}) {
            setVisible(true);
            setTimestamp(Date.now());
            setModalTitle(modalTitle);
            setCheckedIds([...initIds]);
        }

        function closeModal() {
            setVisible(false);
            setLoading(false);
            setModalTitle(null);
            setCheckedIds([]);
        }

        useImperativeHandle(ref, () => ({
            showModal,
            closeModal,
        }));

        function onChange(e) {
            console.log(e);
            const {checked, value} = e.target;
            if (checked) {
                setCheckedIds(prevState => {
                    return [...new Set([...prevState, value])];
                });
            } else {
                setCheckedIds(prevState => {
                    return prevState.filter(id => id !== value);
                });
            }
        }

        function onOk() {
            setLoading(true);

            const result = onSelect(checkedIds);
            if (isPromise(result)) {
                result.then(data => {
                    // console.log(data);
                    if (data === true) {
                        closeModal();
                    } else {
                        setLoading(false);
                    }
                });
            } else {
                if (result) {
                    closeModal();
                } else {
                    setLoading(false);
                }
            }
        }

        return (
            <Modal
                destroyOnClose
                title={modalTitle ?? '选择应用下人员'}
                visible={visible}
                onCancel={closeModal}
                onOk={onOk}
                okButtonProps={{loading}}
            >
                {showEmpty ? (
                    empty || (
                        <Alert
                            type="warning"
                            showIcon
                            message="当前应用尚未配置任何管理员和发送人"
                        />
                    )
                ) : (
                    <>
                        <Divider orientation="left">应用管理员</Divider>
                        {appMgrs.map(({userId, userName}) => (
                            <div key={userId} className={styles.checkboxLine}>
                                <Checkbox
                                    value={userId}
                                    onChange={onChange}
                                    checked={checkedIds.includes(userId)}
                                >
                                    {userName}
                                </Checkbox>
                            </div>
                        ))}
                        <Divider orientation="left">消息发送人</Divider>
                        {appUsers.map(({userId, userName}) => (
                            <div key={userId} className={styles.checkboxLine}>
                                <Checkbox
                                    value={userId}
                                    onChange={onChange}
                                    checked={checkedIds.includes(userId)}
                                >
                                    {userName}
                                </Checkbox>
                            </div>
                        ))}
                    </>
                )}
            </Modal>
        );
    }
);

AppUserSelector.displayName = 'AppUserSelector';

AppUserSelector.propTypes = {
    appId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,

    /**
     * 当人员列表为空时显示的提示信息
     */
    empty: PropTypes.element,

    /**
     * 是否显示应用管理员
     */
    showMgr: PropTypes.bool,

    /**
     * 是否显示消息发送人
     */
    showUser: PropTypes.bool,
};

export default AppUserSelector;
