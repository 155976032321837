import {getJson, postJson, patchJson, putJson, deleteJson} from '@/util/ajaxUtils';

export function getMediaList(appId, type, query, current = 1, size = 10) {
    return getJson(`/media/${appId}`, {params: {type, query, current, size}});
}

export function deleteMedia(mediaId) {
    return deleteJson(`/media/${mediaId}`);
}

export function addCommonMedia(appId, data) {
    return postJson(`/media/${appId}/common`, {data});
}

export function updateCommonMedia(appId, mediaId, data) {
    return patchJson(`/media/${appId}/common/${mediaId}`, {data});
}

export function addNewsMedia(appId, mediaType, newsList) {
    return postJson(`/media/${appId}/news`, {params: {mediaType}, data: newsList});
}

export function updateNewsMedia(appId, mediaId, mediaType, newsList) {
    return putJson(`/media/${appId}/news/${mediaId}`, {params: {mediaType}, data: newsList});
}

/**
 * 返回图文内容列表，不包括内容字段
 *
 * @param mediaId
 * @return {Promise<*>}
 */
export function getSimpleNewsContents(mediaId) {
    return getJson(`/media/${mediaId}/news-content-simple`);
}

/**
 * 返回图文内容列表，包括内容字段
 *
 * @param mediaId
 * @return {Promise<*>}
 */
export function getNewsContents(mediaId) {
    return getJson(`/media/${mediaId}/news-content`);
}
