/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Tooltip} from 'antd';

import {CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined} from '@/icons';
import {deleteMedia} from '@/api/media';
import {getFileMediaDownloadUrl} from '../MediaType';

function onMediaDeleteClick({reload, record, onDeleteClick}) {
    return () => {
        if (onDeleteClick) {
            onDeleteClick(record);
            return;
        }

        Modal.confirm({
            title: '警告',
            content: `确定删除素材「${record.title}」`,
            okButtonProps: {danger: true},
            okText: '删除',
            onOk: () => {
                deleteMedia(record.id).then(() => {
                    reload(true);
                });
            },
        });
    };
}

function getDownloadUrl(enableDownload, record) {
    let downloadUrl;
    if (enableDownload) {
        const {attachId} = record;
        downloadUrl = getFileMediaDownloadUrl(attachId);
    }

    return downloadUrl;
}

export default function ActionButtons({
    record,
    reload,
    editable = false,
    enableDownload = false,
    enableUserEdit = false,
    onEditClick,
    onDeleteClick,
    onCopyLinkClick,
}) {
    console.log('editable, onEditClick', editable, onEditClick);
    return (
        <Button.Group>
            {onCopyLinkClick && (
                <Tooltip title="复制链接">
                    <Button icon={<CopyOutlined />} onClick={() => onCopyLinkClick(record)} />
                </Tooltip>
            )}
            {enableDownload && (
                <Tooltip title="下载">
                    <Button
                        icon={<DownloadOutlined />}
                        href={getDownloadUrl(enableDownload, record)}
                    />
                </Tooltip>
            )}

            {editable && (
                <Tooltip title="修改">
                    <Button icon={<EditOutlined />} onClick={() => onEditClick(record)} />
                </Tooltip>
            )}

            {enableUserEdit && (
                <Tooltip title="删除">
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={onMediaDeleteClick({reload, record, onDeleteClick})}
                    />
                </Tooltip>
            )}
        </Button.Group>
    );
}

ActionButtons.propTypes = {
    record: PropTypes.object,
    reload: PropTypes.func,
    editable: PropTypes.bool,
    enableDownload: PropTypes.bool,
    enableUserEdit: PropTypes.bool,
    onEditClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    onCopyLinkClick: PropTypes.func,
};

export const columnAction = ({
    editable,
    isMgr,
    userId,
    width = 150,
    reload,
    enableDownload = false,
    onCopyLinkClick,
    onEditClick,
    onDeleteClick,
}) => {
    return {
        title: '操作',
        key: 'action',
        width,
        render: (text, record) => {
            const enableUserEdit = isMgr || (!isMgr && userId === record.creatorId);
            // console.log('enableUserEdit', enableUserEdit, isMgr, onDeleteClick);
            return (
                <ActionButtons
                    record={record}
                    reload={reload}
                    editable={editable}
                    enableDownload={enableDownload}
                    enableUserEdit={enableUserEdit}
                    onEditClick={editable ? onEditClick : null}
                    onDeleteClick={enableUserEdit ? onDeleteClick : null}
                    onCopyLinkClick={onCopyLinkClick}
                />
            );
        },
    };
};

export const gridAction = ({
    editable = false,
    isMgr,
    userId,
    reload,
    enableDownload = false,
    onCopyLinkClick,
    onEditClick,
    onDeleteClick,

    record = {},
}) => {
    const enableUserEdit = isMgr || (!isMgr && userId === record.creatorId);
    // console.log('editable', editable, isMgr, enableUserEdit);
    return [
        onCopyLinkClick && (
            <Tooltip title="拷贝链接" key="copy">
                <CopyOutlined onClick={() => onCopyLinkClick(record)} />
            </Tooltip>
        ),

        enableDownload && (
            <Tooltip title="下载" key="download">
                <a href={getDownloadUrl(enableDownload, record)}>
                    <DownloadOutlined />
                </a>
            </Tooltip>
        ),

        editable && (
            <Tooltip title="编辑" key="edit">
                <EditOutlined onClick={() => onEditClick(record)} />
            </Tooltip>
        ),

        editable && enableUserEdit && (
            <Tooltip title="删除" key="delete">
                <DeleteOutlined onClick={onMediaDeleteClick({reload, record, onDeleteClick})} />
            </Tooltip>
        ),
    ].filter(Boolean);
};
