import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal, message} from 'antd';
import {useParams} from 'react-router-dom';

import {addCommonMedia, updateCommonMedia} from '@/api/media';
import {byteLength} from '@/util/stringUtils';

const MAX_CONTENT_BYTE_LENGTH = 2048;

const TextFormModal = forwardRef(({onSaveAfter}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [textData, setTextData] = useState({id: null, content: null});

    const {appId} = useParams();

    function showModal(id, content) {
        setVisible(true);
        setTextData({id, content});
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function isAdd() {
        return !textData.id;
    }

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const {content, ...rest} = values;
        if (byteLength(content) > MAX_CONTENT_BYTE_LENGTH) {
            message.warning(`文本内容长度不能超过 ${MAX_CONTENT_BYTE_LENGTH} 字节`);
            return;
        }

        const textValues = {...rest, content: content.trim()};

        const promise = isAdd()
            ? addCommonMedia(appId, textValues)
            : updateCommonMedia(appId, textValues.id, textValues);

        promise
            .then(data => {
                console.log(data);
                onSaveAfter && onSaveAfter();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd() ? '添加文本素材' : '修改文本素材'}
            onCancel={closeModal}
            onOk={onOk}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={textData}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>
                <Form.Item hidden name="mediaType" initialValue="text">
                    <Input />
                </Form.Item>
                <Form.Item
                    name="content"
                    rules={[
                        {
                            required: true,
                            message: '请输入文本内容',
                        },
                    ]}
                >
                    <Input.TextArea
                        placeholder={`文本内容长度不能超过 ${MAX_CONTENT_BYTE_LENGTH} 字节`}
                        allowClear
                        autoFocus
                        rows={8}
                        // showCount
                        // maxLength={500}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
});

TextFormModal.displayName = 'TextFormModal';

TextFormModal.propTypes = {
    onSaveAfter: PropTypes.func,
};

export default TextFormModal;
