import React from 'react';
import PropTypes from 'prop-types';
import {ContainerQuery} from 'react-container-query';
import classNames from 'classnames';

import ResponsiveContext from './Context';
import query, {isMobileQuery} from './query';

/**
 * 响应式视图容器，内部调用了 ResponsiveContext.Provider。
 *
 * 此容器的作用是判断当前页面是否是移动端展示，并将相应页面参数传入 ResponsiveContext。
 *
 * {
 *     queryClassName: 包裹子页面的容器上定义的 class，也就是上面 query 中定义的 key 值
 *     screenType: 上面 query 中 key 值对应的对象，相应值为 true 或 false
 *     isMobile: 是否为移动端
 * }
 */
const ResponsiveView = ({children}) => (
    <ContainerQuery query={query}>
        {params => {
            const isMobile = isMobileQuery(params);
            const screenCls = `screen-${classNames(params)}`;

            const queryClassName = classNames(screenCls, {
                'screen-mobile': isMobile,
            });
            const values = {
                queryClassName,
                screenType: params,
                isMobile,
            };

            return (
                <div className={queryClassName}>
                    <ResponsiveContext.Provider value={values}>
                        {children}
                    </ResponsiveContext.Provider>
                </div>
            );
        }}
    </ContainerQuery>
);

ResponsiveView.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ResponsiveView;
