import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import filesize from 'filesize';
import {useMount} from 'react-use';

import ArticleTitle from './ArticleTitle';
import ArticleContent from './ArticleContent';
import {format} from '@/util/dateUtils';
import {getFileUrl} from '@/page/util/appUrlUtils';
import WaterMask from './WaterMask';

import styles from './ArticleView.module.less';

/**
 * 根据传入的图文内容对象展示图文。
 *
 * @param news 图文内容
 * @param sendTime 图文发送时间，如果是查看素材图文，此值可能为 null
 * @param app 应用信息，可选
 * @param maskText 水印文本，不提供则无水印
 * @param forbidCopy 禁止拷贝
 * @param showDescription 是否显示描述
 * @return {JSX.Element|null}
 * @constructor
 */
export default function ArticleView({
    news,
    sendTime,
    app,
    maskText,
    forbidCopy = false,
    showDescription = true,
}) {
    useMount(() => {
        const timeout = setTimeout(() => {
            // 丁香园的图文中的视频默认是转换成图片，需要在载入之后将其替换为视频标签
            document.querySelectorAll('.j_video_data').forEach(el => {
                const thumb = el.getAttribute('data-thumb');
                const path = el.getAttribute('data-path');
                // console.log(thumb, path);

                const video = document.createElement('video');
                // video.src = 'http://biwechatworktest.boehringer-ingelheim.cn/wecomweb/' + path;
                // video.poster = 'http://biwechatworktest.boehringer-ingelheim.cn/wecomweb/' + thumb;
                video.src = path;
                video.poster = thumb;
                video.autoplay = false;
                video.controls = true;
                video.style.width = '100%';
                video.style.height = 'auto';

                el.parentNode.insertBefore(video, el);
                el.parentNode.removeChild(el);
            });

            // 显示音频
            document.querySelectorAll('.j_audio_data').forEach(el => {
                const path = el.getAttribute('data-path');
                // console.log(thumb, path);

                const div = document.createElement('div');
                div.style.display = 'flex';
                div.style.height = '40px';

                const audio = document.createElement('audio');
                // audio.src = 'http://biwechatworktest.boehringer-ingelheim.cn/wecomweb/' + path;
                // audio.poster = 'http://biwechatworktest.boehringer-ingelheim.cn/wecomweb/' + thumb;
                audio.src = path;
                audio.autoplay = false;
                audio.controls = true;
                audio.style.width = '100%';
                audio.style.height = 'auto';

                div.append(audio);

                el.parentNode.insertBefore(div, el);
                el.parentNode.removeChild(el);
            });

            // 微信或企业微信打开时支持点击预览图片
            const imgEls = document.querySelectorAll('.typo img');
            const images = [];
            imgEls.forEach(el => {
                images.push(el.src);
            });

            imgEls.forEach(el => {
                el.onclick = () => {
                    if (window.wx && window.wx.previewImage) {
                        window.wx.previewImage({
                            current: el.src,
                            urls: images,
                        });
                    }
                };
            });

            clearTimeout(timeout);
        }, 300);
    });

    if (_.isEmpty(news)) {
        return null;
    }

    // console.log('ArticleView', news);

    const {title = '', description, author, content, createTime, attaches} = news;

    function renderSubTitle() {
        const appName = _.get(app, 'name');
        if (!sendTime && !createTime) {
            return null;
        }

        const time = sendTime || createTime;

        return (
            <>
                {time && <span>{format(time)}</span>}
                {author && <span style={{margin: '0 10px'}}>{author}</span>}
                {appName && <span className={styles.appName}>{appName}</span>}
            </>
        );
    }

    function renderDescription() {
        if (!showDescription || !description) {
            return null;
        }

        return (
            <div className={styles.description}>
                <div className={styles.descriptionLabel}>摘要</div>
                {description}
            </div>
        );
    }

    function renderAttachs() {
        if (_.isEmpty(attaches)) {
            return null;
        }

        return (
            <div className={styles.attachs}>
                <div className={styles.attachCaption}>附件列表（{attaches.length}）：</div>

                <ol>
                    {attaches.map(({id, fileExt, fileName, fileSize}) => {
                        return (
                            <li key={id}>
                                <a href={getFileUrl(id, fileExt)} target="_blank" rel="noreferrer">
                                    {fileName}
                                </a>
                                <div className={styles.attachSize}>{filesize(fileSize)}</div>
                            </li>
                        );
                    })}
                </ol>
            </div>
        );
    }

    return (
        <>
            <ArticleTitle
                title={title}
                subTitle={renderSubTitle()}
                description={renderDescription()}
            />
            <ArticleContent>
                <div dangerouslySetInnerHTML={{__html: content}} />
                {forbidCopy && <div className={styles.forbidCopy} />}
                {maskText && <WaterMask text={maskText} />}
            </ArticleContent>
            {renderAttachs()}
        </>
    );
}

ArticleView.propTypes = {
    news: PropTypes.object,
    sendTime: PropTypes.number,
    app: PropTypes.object,
    maskText: PropTypes.string,
    forbidCopy: PropTypes.bool,
    showDescription: PropTypes.bool,
};
