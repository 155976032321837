import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import _ from 'lodash';

import {TwoColumnLayout} from '@/component';

import {AuditOutlined, EditOutlined} from '@/icons';
import {useRoleAuthorities} from '../useRoleService';
import {rolePropTypes} from '../propTypes';
import RoleAuthoritiesModal from './RoleAuthoritiesModal';
import RoleAuthorityList from './RoleAuthorityList';

export default function RoleAuthorities({role, editable}) {
    const authModalRef = useRef();
    const authState = useRoleAuthorities(role);
    // console.log('==========authState', authState);

    let authorities = [];
    if (authState.value) {
        const {data = []} = authState.value;
        authorities = data.map(({authority}) => authority);
    }

    function onAuthEditClick() {
        authModalRef.current && authModalRef.current.showModal && authModalRef.current.showModal();
    }

    return (
        <>
            <TwoColumnLayout.PanelHeader icon={<AuditOutlined />} title="拥有权限">
                {editable && (
                    <Button type="primary" icon={<EditOutlined />} onClick={onAuthEditClick} />
                )}
            </TwoColumnLayout.PanelHeader>

            <TwoColumnLayout.PanelContent>
                <RoleAuthorityList authorities={_.get(authState.value, 'data')} />
            </TwoColumnLayout.PanelContent>

            <RoleAuthoritiesModal
                role={role}
                authorities={authorities}
                ref={authModalRef}
                reload={authState.retry}
            />
        </>
    );
}

RoleAuthorities.propTypes = {
    role: PropTypes.shape(rolePropTypes),
    editable: PropTypes.bool,
};
