import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Empty, Spin} from 'antd';
import _ from 'lodash';
import AppTree from '@/page/component/AppTree';

import {convertDeparts2TreeFormat} from '@/util/treeUtils';

import styles from './DepartsTree.module.less';

/**
 * 组织结构树，支持拖动、部门编辑（右键菜单实现）等功能。
 *
 * 注意：只支持一个根节点。
 *
 * @param treeState
 * @param onDepartSelect
 * @param onDepartEditClick
 * @return {JSX.Element}
 * @constructor
 */
const DepartsTree = ({treeState, onDepartSelect, onDepartEditClick}) => {
    // 可使用 treeState.retry() 重新载入所有数据
    const [treeData, setTreeData] = useState([]);
    const [selRoot, setSelRoot] = useState(false);
    // console.log('treeState', treeState);

    useEffect(() => {
        if (!treeState.loading) {
            const {status, data} = treeState.value;
            if (status === 200) {
                setTreeData(convertDeparts2TreeFormat(data));
            }

            if (!selRoot) {
                // 初始选中根部门
                const {id, name} = _.isEmpty(data) ? {} : data[0];
                onDepartSelect && onDepartSelect({id, name});
                setSelRoot(true);
            }
        }
    }, [onDepartSelect, selRoot, treeState.loading, treeState.value]);

    function onSelect(value, {node}) {
        // console.log(value, node);
        const {
            depart: {id, name},
        } = node;
        onDepartSelect && onDepartSelect({id, name});
    }

    function renderEmpty() {
        if (!_.isEmpty(treeData)) {
            return null;
        }

        return (
            <Empty
                style={{marginTop: 100}}
                description={
                    <>
                        <Button type="primary" style={{marginTop: 10}} onClick={onDepartEditClick}>
                            编辑部门
                        </Button>
                    </>
                }
            />
        );
    }

    return (
        <>
            <div className={styles.treeWrap}>
                {renderEmpty()}

                {/* 之所以做个空值判断，是因为 defaultExpandAll 不起作用，因为数据载入前树是空的，所以就不展开了 */}
                {!_.isEmpty(treeData) && (
                    <AppTree
                        onSelect={onSelect}
                        defaultSelectedKeys={[treeData[0].key]}
                        treeData={treeData}
                    />
                )}
            </div>

            {treeState.loading && (
                <div className={styles.loading}>
                    <Spin size="large" />
                </div>
            )}
        </>
    );
};

DepartsTree.propTypes = {
    treeState: PropTypes.object,
    onDepartSelect: PropTypes.func,
    onDepartEditClick: PropTypes.func,
};

export default DepartsTree;
