import React from 'react';
import {Alert} from 'antd';
import {useLocation} from 'react-router-dom';
import qs from 'qs';

import useTitle from '@/hook/useTitle';
import LoginContainer from './LoginContainer';
import UserPassword from '@/page/ProfilePage/UserPassword';
import {PASS_EXPIRE_DAYS} from '@/context/auth';

/**
 * 管理员重置密码或者密码已过期，则进入本页面。
 */
export default function ChangePassPage() {
    useTitle('修改密码');
    const location = useLocation();
    const query = qs.parse(location.search, {ignoreQueryPrefix: true});
    const isExpire = 'true' === query['expire'];

    return (
        <LoginContainer title="修改密码">
            {isExpire && (
                <Alert message={`当前密码使用时间已超过 ${PASS_EXPIRE_DAYS} 天`} type="error" />
            )}
            <UserPassword />
        </LoginContainer>
    );
}
