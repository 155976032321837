import React from 'react';
import {Space} from 'antd';
import {useParams} from 'react-router-dom';

import useTitle from '@/hook/useTitle';
import RecentNewsList from './RecentNewsList';
import NewsChart from './NewsChart';
import HourlyNewsChart from './HourlyNewsChart';
import NewsStatList from './NewsStatList';

const alias = 'news';

export default function NewsAnalysisReport() {
    useTitle('图文分析 - 数据统计');
    const {appId} = useParams();

    return (
        <Space direction="vertical" size={48} style={{display: 'flex'}}>
            <RecentNewsList appId={appId} alias={alias} />
            <NewsStatList appId={appId} />
            <NewsChart appId={appId} alias={alias} />
            <HourlyNewsChart appId={appId} />
        </Space>
    );
}
