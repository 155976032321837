import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Typography, Upload} from 'antd';
import filesize from 'filesize';
import _ from 'lodash';

import {PlusOutlined} from '@/icons';
import AppUpload from '@/page/component/AppUpload';
import MediaType from '@/page/app/MediaPage/MediaType';
import {videoDataPropTypes} from './propTypes';
import {getFileUrl} from '@/page/util/appUrlUtils';
import {getVideoThumb} from '@/util/videoUtils';
import {updateImageDataURL} from '@/api/attach';

export default function VideoUpload({category, videoData, onChange}) {
    const [list, setList] = useState(
        !_.isEmpty(videoData)
            ? [
                  {
                      uid: videoData.attachId,
                      name: videoData.title,
                      status: 'done',
                  },
              ]
            : []
    );

    // console.log('VideoUpload=========', list);

    function beforeUpload(file) {
        if (file.size <= 5) {
            message.warn('视频不能小于 5 个字节');
            return Upload.LIST_IGNORE;
        }
        if (file.size > MediaType.VIDEO.maxSize) {
            message.warn(`文件大小不能超过 ${filesize(MediaType.VIDEO.maxSize)}`);
            return Upload.LIST_IGNORE;
        }

        return true;
    }

    function onUploadChange({file, fileList}) {
        setList(fileList);

        if (file.status === 'done') {
            const {
                response: {data},
            } = file;
            const {id, fileName, fileExt, fileSize} = data[0];

            console.log(file, data);
            // onChange &&
            //     onChange({attachId: id, attachExt: fileExt, title: fileName, attachSize: fileSize});

            getVideoThumb(getFileUrl(id, fileExt))
                .then(({dataURL, width, height}) => {
                    // console.log(dataURL);

                    updateImageDataURL('media', null, width, height, dataURL).then(json => {
                        console.log(json);
                        onChange &&
                            onChange({
                                title: fileName,
                                attachId: id,
                                attachExt: fileExt,
                                attachSize: fileSize,
                                thumbAttachId: json?.data?.id,
                                thumbAttachExt: json?.data?.fileExt,
                                thumbAttachSize: json?.data?.fileSize ?? 0,
                            });
                    });
                })
                .catch(e => {
                    console.log(e);
                    onChange &&
                        onChange({
                            attachId: id,
                            attachExt: fileExt,
                            title: fileName,
                            attachSize: fileSize,
                        });
                });
        }
    }

    function onRemove() {
        onChange && onChange({attachId: null, attachExt: null, title: null, attachSize: null});
        return true;
    }

    function isImageUrl() {
        return false;
    }

    return (
        <AppUpload
            category={category}
            maxCount={1}
            accept={MediaType.VIDEO.accept}
            listType="picture"
            fileList={list}
            isImageUrl={isImageUrl}
            beforeUpload={beforeUpload}
            onChange={onUploadChange}
            onRemove={onRemove}
        >
            <Button type="primary" icon={<PlusOutlined />}>
                上传视频
            </Button>
            <Typography.Text type="secondary" style={{marginLeft: 24}}>
                不超过 {filesize(MediaType.VIDEO.maxSize)}, 文件格式：{MediaType.VIDEO.accept}
            </Typography.Text>
        </AppUpload>
    );
}

VideoUpload.propTypes = {
    category: PropTypes.oneOf(['media', 'message']),
    videoData: videoDataPropTypes,

    onChange: PropTypes.func,
};
