import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import {useParams} from 'react-router-dom';
import _ from 'lodash';

import {CloudUploadOutlined} from '@/icons';
import UploadWallModal from '@/page/component/UploadWallModal';
import {addCommonMedia} from '@/api/media';
import {fileMediaTypeProps} from '../../propTypes';

export default function AddButton({type, reload}) {
    const wallModal = useRef();
    const {appId} = useParams();

    function onAddClick() {
        wallModal.current.showModal();
    }

    function afterUpload(attachs) {
        if (_.isEmpty(attachs)) {
            return;
        }

        attachs.forEach(attach => {
            addCommonMedia(appId, {
                mediaType: type.key,
                title: attach.fileName,
                attachId: attach.id,
                attachExt: attach.fileExt,
                attachSize: attach.fileSize,
            }).then(data => {
                console.log('upload after: ', data);
            });
        });
    }

    function afterCloseModal() {
        reload && reload();
    }

    return (
        <>
            <Button icon={<CloudUploadOutlined />} type="primary" onClick={onAddClick}>
                上传{type.label}
            </Button>
            <UploadWallModal
                ref={wallModal}
                type={type}
                category="media"
                modalTitle={`上传${type.label}素材`}
                afterUpload={afterUpload}
                afterCloseModal={afterCloseModal}
            />
        </>
    );
}

AddButton.propTypes = {
    ...fileMediaTypeProps,
    reload: PropTypes.func,
};
