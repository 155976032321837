import React from 'react';
import {Typography} from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import {videoDataPropTypes} from './propTypes';
import {getFileMediaUrl} from '@/page/app/MediaPage/MediaType';

import styles from '@/page/component/NewsFormModal/NewsPreview.module.less';

export default function VideoPreview({videoData}) {
    // console.log('videoData', videoData);

    function get(key, defaultValue) {
        const value = _.get(videoData, key);
        if (!value || !value.trim()) {
            return defaultValue;
        }

        return value;
    }

    function renderCover() {
        const attachId = get('attachId');
        const attachExt = get('attachExt');
        if (!attachId) {
            return null;
        }

        const url = getFileMediaUrl(attachId, attachExt);
        return <ReactPlayer controls playing={false} url={url} width="100%" height="100%" />;
    }

    return (
        <div className={classNames(styles.preview, styles.selected)} onClick={() => {}}>
            <div className={styles.previewCover}>{renderCover()}</div>
            <div className={styles.detail}>
                <Typography.Title level={5} ellipsis={{rows: 2}}>
                    {get('title', '标题')}
                </Typography.Title>
                <Typography.Paragraph ellipsis={{rows: 4}}>
                    {get('description', '摘要')}
                </Typography.Paragraph>
            </div>
        </div>
    );
}

VideoPreview.propTypes = {
    videoData: videoDataPropTypes,
};
