import {getJson, postJson} from '@/util/ajaxUtils';

export function getCorp() {
    return getJson('/corps');
}

export function updateCorp(corp) {
    return postJson('/corps', {data: corp});
}

/**
 * 返回给定分类的所有设置项，格式为 key:value
 *
 * @param category 分类
 * @returns {Promise<{data: null, success: boolean, message: string, status: number}|*|undefined>}
 */
export function getSettingsByCategory(category) {
    return getJson(`/settings?category=${category}`);
}

/**
 * 返回实际的 SysSetting 对象列表，未作任何转换。
 *
 * @param category 分类
 * @returns {Promise<{data: null, success: boolean, message: string, status: number}|*|undefined>}
 */
export function getSettingListByCategory(category) {
    return getJson(`/settings/list?category=${category}`);
}

export function getAppCustomSettings() {
    return getSettingsByCategory('appCustom');
}

export function getAppCustomSettingList() {
    return getSettingListByCategory('appCustom');
}

export function saveSettings(keyValue) {
    return postJson('/settings', {data: keyValue});
}
