import React, {useEffect, useState, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {Pagination} from 'antd';
import _ from 'lodash';

import {useUserMessages} from '../historyService';
import MessageView from './MessageView';

const UserMessageList = forwardRef(({appId, userId}, ref) => {
    const [page, setPage] = useState({current: 1, pageSize: 10, total: 0});
    const {loading, value, retry} = useUserMessages(appId, userId, page.current, page.pageSize);
    // console.log('user messages', loading, value);

    useEffect(() => {
        if (loading || !userId) {
            return;
        }

        const {current, size, total} = value?.data;
        setPage({current, pageSize: size, total});
    }, [loading, userId, value]);

    useImperativeHandle(ref, () => ({
        reload: retry,
    }));

    const messages = value?.data?.records ?? [];

    function onPageChange(current, pageSize) {
        // console.log(page, size);
        setPage({...page, current, pageSize});
    }

    if (!userId || _.isEmpty(messages)) {
        return null;
    }

    return (
        <div>
            {messages.map(message => (
                <MessageView key={message.id} message={message} />
            ))}

            <Pagination {...page} size="small" showSizeChanger={false} onChange={onPageChange} />
        </div>
    );
});

UserMessageList.propTypes = {
    appId: PropTypes.string.isRequired,
    userId: PropTypes.string,
};

UserMessageList.displayName = 'UserMessageList';

export default UserMessageList;
