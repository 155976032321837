import React from 'react';
import PropTypes from 'prop-types';
import {Typography} from 'antd';

export default function TextView({children, className, style}) {
    if (!children) return null;

    const lines = children.split('\n');
    return lines.map((line, index) => (
        <Typography.Paragraph key={index} className={className} style={style}>
            {line}
        </Typography.Paragraph>
    ));
}

TextView.propTypes = {
    children: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
};
