import {createBrowserHistory} from 'history';

/**
 * 当前是否生产环境。
 *
 * @type {boolean}
 */
export const isProd = process.env.NODE_ENV === 'production';

/**
 * 部分功能只在开发环境下出现，所以使用此属性来控制。
 *
 * @type {boolean}
 */
export const showAtDev = !isProd;

/**
 * 应用的标题
 *
 * @type {string}
 */
export const APP_TITLE = process.env.REACT_APP_TITLE;

/**
 * api 根路径
 *
 * @type {string}
 */
export const API_ROOT = process.env.REACT_APP_API_ROOT;

/**
 * 具体实现可参见 {@link https://github.com/facebook/create-react-app/blob/master/packages/react-dev-utils/getPublicUrlOrPath.js|getPublicUrlOrPath}
 * 和 {@link https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/config/env.js env.js} .
 *
 * 定义方式如下：
 * 1. package.json 中配置 homepage，例如 http://example.com/abc, 则取值为 /abc/
 * 2. 启动时配置 process.env.PUBLIC_URL， 例如 cross-env PUBLIC_URL=/abc/
 * 3. 上述均未配置，则默认使用 /
 *
 * @type {string}
 */
export const PUBLIC_URL = process.env.PUBLIC_URL;

/**
 * 导出一个 history 对象给外部调用
 *
 * @type {History<LocationState>}
 */
export const history = createBrowserHistory({basename: process.env.PUBLIC_URL});
