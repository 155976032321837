import {useTitle as reactUseTitle} from 'react-use';

import {APP_TITLE} from '@/env';

/**
 * 使用 document.title 设置浏览器中显示的标题。
 *
 * @param title 为空时显示默认配置的标题，否则显示为 "title - APP_TITLE"
 * @param options 属性为 restoreOnUnmount，具体可参见 react-use/useTitle
 */
export default function useTitle(title, options) {
    let t = title;

    if (!title) {
        t = APP_TITLE;
    } else if (APP_TITLE) {
        t += ' - ' + APP_TITLE;
    }

    reactUseTitle(t, options);
}
