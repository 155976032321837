import React from 'react';
import PropTypes from 'prop-types';
import {Input} from 'antd';

const MAX_CONTENT_BYTE_LENGTH = 2048;

export default function TextContent({value, autoFocus = true, onChange}) {
    function onTextChange(e) {
        onChange(e.target.value);
    }

    return (
        <Input.TextArea
            placeholder={`文本内容最多支持 ${MAX_CONTENT_BYTE_LENGTH} 字节`}
            allowClear
            autoFocus={autoFocus}
            rows={8}
            // showCount
            // maxLength={500}
            value={value}
            onChange={onTextChange}
        />
    );
}

TextContent.propTypes = {
    autoFocus: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
