/* eslint-disable react/display-name */
import React, {useRef, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal} from 'antd';

import {DepartTag, PageGrid, UserTag} from '@/component';
import {DeleteOutlined} from '@/icons';
import {deleteTagLink} from '@/api/tags';
import UserViewModal from '../DepartsPage/UserViewModal';

const TagIncludeTable = forwardRef(({tagId, editable = true}, ref) => {
    const tableRef = useRef();
    const userViewModalRef = useRef();

    function refreshTable() {
        tableRef.current.refresh();
    }

    useImperativeHandle(ref, () => ({
        refreshTable,
    }));

    function onDeleteClick(record) {
        return () => {
            Modal.confirm({
                title: '提醒',
                content: `是否删除 [${record.linkName}]`,
                okButtonProps: {danger: true},
                onOk: () => {
                    console.log(record);
                    const {id, tagId} = record;
                    deleteTagLink(tagId, id).then(() => {
                        refreshTable();
                    });
                },
            });
        };
    }

    function onShowUserClick(userId) {
        return () => {
            console.log(userId);
            userViewModalRef.current.showModal(userId);
        };
    }

    const columns = [
        {
            title: '名称',
            dataIndex: 'linkName',
            render: (text, record) => {
                const {linkType, linkId} = record;
                if (linkType === 1) {
                    return <DepartTag>{text}</DepartTag>;
                } else if (linkType === 2) {
                    return (
                        <UserTag style={{cursor: 'pointer'}} onClick={onShowUserClick(linkId)}>
                            {text}
                        </UserTag>
                    );
                }
                return null;
            },
        },
    ];

    if (editable) {
        // noinspection JSCheckFunctionSignatures
        columns.push({
            title: '操作',
            key: 'action',
            width: 100,
            render: (text, record) => {
                return (
                    <Button type="link" icon={<DeleteOutlined />} onClick={onDeleteClick(record)}>
                        删除
                    </Button>
                );
            },
        });
    }

    return (
        <>
            <PageGrid ref={tableRef} url={`/tags/${tagId}/links`} columns={columns} />
            <UserViewModal ref={userViewModalRef} />
        </>
    );
});

TagIncludeTable.displayName = 'TagIncludeTable';

TagIncludeTable.propTypes = {
    tagId: PropTypes.string,
    editable: PropTypes.bool,
};

export default TagIncludeTable;
