import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Alert, Drawer, PageHeader} from 'antd';

import PreviewCommentList from './PreviewCommentList';

const PreviewPanel = forwardRef(({reloadTable}, ref) => {
    const [visible, setVisible] = useState(false);
    const [comment, setComment] = useState(null);
    const [highlightCommentId, setHighlightCommentId] = useState(null);

    function show(comment, highlight = false) {
        setVisible(true);
        setComment(comment);
        if (highlight) {
            setHighlightCommentId(comment.id);
        }
    }

    function hide() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    function afterVisibleChange(vis) {
        // console.log(vis);
        if (!vis) {
            reloadTable();
        }
    }

    return (
        <Drawer
            visible={visible}
            mask={false}
            width={600}
            onClose={hide}
            afterVisibleChange={afterVisibleChange}
        >
            {!_.isEmpty(comment) && (
                <PageHeader title={comment.newsTitle}>
                    <Alert
                        showIcon
                        type="warning"
                        banner
                        description="精选留言方会显示在图文页面"
                        message="提醒"
                    />
                    <PreviewCommentList
                        itemId={comment.itemId}
                        highlightCommentId={highlightCommentId}
                    />
                </PageHeader>
            )}
        </Drawer>
    );
});

PreviewPanel.displayName = 'PreviewPanel';

PreviewPanel.propTypes = {
    reloadTable: PropTypes.func.isRequired,
};

export default PreviewPanel;
