import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Collapse, List, Space} from 'antd';
import _ from 'lodash';

import {ApartmentOutlined, CloseOutlined, TagOutlined, UserOutlined} from '@/icons';

import styles from './SelectedPanel.module.less';

const TYPES = {
    departs: '部门',
    users: '人员',
    tags: '标签',
};

export default function SelectedPanel({selectedValues = {}, onDeleteSelected}) {
    // const {departs = [], users = [], tags = []} = selectedValues;

    function onDeleteClick(type, id) {
        return () => {
            onDeleteSelected(type, id);
        };
    }

    function renderIcon(type) {
        if (type === 'departs') {
            return <ApartmentOutlined />;
        }
        if (type === 'users') {
            return <UserOutlined />;
        }
        if (type === 'tags') {
            return <TagOutlined />;
        }
        return null;
    }

    function renderItem(type) {
        // eslint-disable-next-line react/display-name
        return item => {
            const {id, name} = item;

            const actions = [
                <Button
                    key="delete"
                    type="text"
                    className={styles.closeBtn}
                    icon={<CloseOutlined className={styles.closeIcon} />}
                    onClick={onDeleteClick(type, id)}
                />,
            ];

            return (
                <List.Item actions={actions} className={styles.item}>
                    <Space className={styles.itemInner}>
                        <Avatar icon={renderIcon(type)} size={24} />
                        <span>{name}</span>
                    </Space>
                </List.Item>
            );
        };
    }

    function renderValues(type, values) {
        if (_.isEmpty(values)) {
            return null;
        }

        return (
            <Collapse.Panel key={type} header={`已选${TYPES[type]}`}>
                <List size="small" dataSource={values} renderItem={renderItem(type)} />
            </Collapse.Panel>
        );
    }

    const types = Object.keys(TYPES);

    return (
        <Collapse ghost defaultActiveKey={types}>
            {types.map(type => {
                const values = _.get(selectedValues, type);
                return renderValues(type, values);
            })}
        </Collapse>
    );
}

const valueType = PropTypes.arrayOf(
    PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    })
);

SelectedPanel.propTypes = {
    selectedValues: PropTypes.shape({
        departs: valueType,
        users: valueType,
        tags: valueType,
    }),
    onDeleteSelected: PropTypes.func.isRequired,
};
