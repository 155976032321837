import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Modal} from 'antd';
import _ from 'lodash';

import SyncConfigTagsField from './SyncConfigTagsField';

const SyncConfigFormModal = forwardRef(
    ({modalTitle = '同步设置', showSrcDepart = false, onSave}, ref) => {
        const formRef = useRef();
        const [visible, setVisible] = useState(false);
        const [initialValues, setInitialValues] = useState(null);

        function showModal(initialValues) {
            setVisible(true);

            if (_.isEmpty(initialValues)) {
                setInitialValues(null);
            } else {
                setInitialValues(initialValues);
            }
        }

        function closeModal() {
            setVisible(false);
        }

        useImperativeHandle(ref, () => ({
            showModal,
            closeModal,
        }));

        function onOk() {
            formRef.current.submit();
        }

        function onFinish(values) {
            console.log('Received values of form: ', values);

            if (!onSave) {
                return;
            }

            const {tags} = values;
            const convertTags = tags?.map(({id, name}) => ({destId: id, destName: name}));
            onSave({...values, tags: convertTags}).then(success => {
                if (success) {
                    closeModal();
                }
            });
        }

        return (
            <>
                <Modal
                    destroyOnClose
                    maskClosable={false}
                    visible={visible}
                    title={modalTitle}
                    onCancel={closeModal}
                    onOk={onOk}
                >
                    <Form
                        ref={formRef}
                        onFinish={onFinish}
                        layout="vertical"
                        initialValues={initialValues}
                    >
                        {showSrcDepart && (
                            <Form.Item
                                name="srcDepart"
                                label="源部门名称"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input placeholder="源部门名称" maxLength={100} />
                            </Form.Item>
                        )}

                        <Form.Item
                            name="tags"
                            label="目标标签"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <SyncConfigTagsField />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
);

SyncConfigFormModal.displayName = 'SyncConfigFormModal';

SyncConfigFormModal.propTypes = {
    modalTitle: PropTypes.string,
    showSrcDepart: PropTypes.bool,
    onSave: PropTypes.func,
};

export default SyncConfigFormModal;
