import React, {useRef} from 'react';
import {Button, Divider, Form, Input, message, Space, Typography} from 'antd';
import {useHistory} from 'react-router-dom';

import {PasswordFormItem} from '@/component';
import {KeyOutlined} from '@/icons';

import {doResetPassword} from '@/api/auth';

import styles from './ProfilePage.module.less';
import {useAuthStore} from '@/context/auth';

export default function UserPassword() {
    const formRef = useRef();
    const history = useHistory();
    const [authStore] = useAuthStore();

    // 系统管理员密码最低位数为15，其他人员为8位
    const isAdmin = authStore?.hasAuthorities('global:admin:r', 'r')
        || authStore?.hasAuthorities('global:admin:r', 'w')
        || false;

    function onFinish(values) {
        console.log('Received values of form: ', values);
        doResetPassword(values)
            .then(data => {
                console.log(data);
                message.success('密码修改成功');
                history.push('/login');
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    return (
        <div className={styles.container} style={{maxWidth: 400}}>
            <Form ref={formRef} onFinish={onFinish} layout="vertical" size="large">
                <Form.Item name="origPass" label="原密码" rules={[{required: true}]}>
                    <Input.Password placeholder="原密码" />
                </Form.Item>
                <PasswordFormItem
                    label="新密码"
                    rules={[{required: true}]}
                    min={isAdmin ? 15 : 8}
                    inputProps={{autoComplete: 'new-password'}}
                />

                <Divider />
                <Form.Item>
                    <Space>
                        <Button icon={<KeyOutlined />} type="primary" htmlType="submit">
                            修改密码
                        </Button>
                        <Typography.Text type="secondary">修改密码后需重新登录</Typography.Text>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
}
