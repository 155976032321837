import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CloseOutlined from '@ant-design/icons/CloseOutlined';

import styles from './MasterSlavePanel.module.less';

const SlavePanel = forwardRef(
    (
        {
            className,
            style,
            width = 300,
            defaultVisible = true,
            destoryOnHide = false,
            closable = true,
            closeIcon,
            children,
        },
        ref
    ) => {
        const [visible, setVisible] = useState(defaultVisible);

        function show() {
            setVisible(true);
        }

        function hide() {
            setVisible(false);
        }

        function toggle() {
            if (visible) {
                hide();
            } else {
                show();
            }
        }

        useImperativeHandle(ref, () => ({
            show,
            hide,
            toggle,
        }));

        if (destoryOnHide && !visible) {
            return null;
        }

        let closeIconWrap;
        if (closable) {
            closeIconWrap = <span className={styles.closeX}>{closeIcon || <CloseOutlined />}</span>;
        }

        return (
            <div
                className={classNames(styles.slaveContainer, className, {
                    [styles.slaveHide]: !visible,
                })}
                style={{width, ...style}}
            >
                {closable && (
                    <div className={styles.close} onClick={hide}>
                        {closeIconWrap}
                    </div>
                )}
                <div className={styles.slaveContent}>{children}</div>
            </div>
        );
    }
);

SlavePanel.displayName = 'SlavePanel';

SlavePanel.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.number,
    defaultVisible: PropTypes.bool,
    destoryOnHide: PropTypes.bool,
    closable: PropTypes.bool,
    closeIcon: PropTypes.node,
    children: PropTypes.node,
};

export default SlavePanel;
