import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import classNames from 'classnames';

import styles from './AppLayout.module.less';

const AppLayout = ({
    header,
    sider,
    paddingContent = 24,
    contentContainerClassName,
    style,
    children,
}) => {
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Layout className={styles.container}>
            <Layout.Header className={styles.header}>{header}</Layout.Header>
            <Layout className={styles.layout}>
                {sider && (
                    <Layout.Sider
                        theme="light"
                        className={styles.sidebar}
                        collapsible
                        collapsed={collapsed}
                        onCollapse={() => setCollapsed(!collapsed)}
                    >
                        {sider}
                    </Layout.Sider>
                )}
                <Layout.Content
                    className={classNames(
                        styles.contentContainer,
                        {[styles.noSiderContentContainer]: !sider},
                        contentContainerClassName
                    )}
                >
                    <div className={styles.content} style={{padding: paddingContent, ...style}}>
                        {children}
                    </div>
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

AppLayout.propTypes = {
    header: PropTypes.node,
    sider: PropTypes.node,
    paddingContent: PropTypes.number,
    contentContainerClassName: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};

export default AppLayout;
