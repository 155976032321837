import React, {useRef} from 'react';
import AppContent from '@/page/component/AppLayout/AppContent';
import {Tabs} from 'antd';

import CommentTable from './CommentTable';
import CommentGroupByNewsTable from './CommentGroupByNewsTable';

import NewsViewModal from '@/page/app/MediaPage/pages/news/NewsViewModal';

export default function CommentPage() {
    const newsViewRef = useRef();
    const comentTableRef = useRef();
    const commentGroupTableRef = useRef();

    function onNewsPreviewClick(newsId) {
        newsViewRef.current.showModal(newsId);
    }

    function onTabChange() {
        comentTableRef.current && comentTableRef.current.hideDrawer();
        commentGroupTableRef.current && commentGroupTableRef.current.hideDrawer();
    }

    return (
        <AppContent>
            <Tabs onChange={onTabChange}>
                <Tabs.TabPane key="list" tab="留言列表">
                    <CommentTable ref={comentTableRef} onNewsPreviewClick={onNewsPreviewClick} />
                </Tabs.TabPane>
                <Tabs.TabPane key="list-by-news" tab="留言图文列表">
                    <CommentGroupByNewsTable
                        ref={commentGroupTableRef}
                        onNewsPreviewClick={onNewsPreviewClick}
                    />
                </Tabs.TabPane>
            </Tabs>

            <NewsViewModal ref={newsViewRef} />
        </AppContent>
    );
}
