import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Dropdown,
    Menu,
    Tag,
    Tooltip,
    Typography,
} from 'antd';

import {
    CopyOutlined,
    DeleteOutlined,
    EditOutlined,
    LinkOutlined,
    KeyOutlined,
    MoreOutlined,
} from '@/icons';

export default function ClientAppCard({
    clientApp,
    onCopyClick,
    onEditClick,
    onDeleteClick,
    onGenUrlClick,
    onRegenSignKeyClick,
}) {
    const {id, name, description, appName, accessCode, accessSignKey, wecomAppId} = clientApp;
    const labelStyle = {width: 100, fontWeight: 'bold'};

    function onMenuClick({key}) {
        if (key === 'edit') {
            onEditClick(clientApp);
        } else if (key === 'delete') {
            onDeleteClick(clientApp);
        } else if (key === 'genUrl') {
            onGenUrlClick(clientApp);
        }
    }

    const menu = (
        <Menu onClick={onMenuClick}>
            <Menu.Item key="genUrl" icon={<LinkOutlined />}>
                生成跳转链接
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="edit" icon={<EditOutlined />}>
                修改
            </Menu.Item>
            <Menu.Item key="delete" danger icon={<DeleteOutlined />}>
                删除
            </Menu.Item>
        </Menu>
    );

    const extra = (
        <Dropdown overlay={menu} placement="bottomRight">
            <Button icon={<MoreOutlined />} type="text" />
        </Dropdown>
    );

    return (
        <Col span={12} key={id}>
            <Card hoverable title={name} style={{marginBottom: 24}} extra={extra}>
                <Typography.Paragraph type="secondary" ellipsis={{rows: 1, expandable: true}}>
                    说明：{description ?? '无'}
                </Typography.Paragraph>

                <Divider />

                <Descriptions column={1}>
                    <Descriptions.Item
                        label="所属应用"
                        labelStyle={labelStyle}
                        style={{paddingBottom: 0}}
                    >
                        <Tag color="#2db7f5">{appName}</Tag>

                        <Tooltip title="点击拷贝企业微信 Id">
                            <Tag color="cyan" onClick={() => onCopyClick(wecomAppId)}>
                                {wecomAppId}
                            </Tag>
                        </Tooltip>
                    </Descriptions.Item>
                </Descriptions>

                <Divider />

                <Descriptions column={1}>
                    <Descriptions.Item label="App Id" labelStyle={labelStyle}>
                        {accessCode}

                        <Tooltip title="拷贝 App Id">
                            <CopyOutlined
                                style={{marginLeft: 16}}
                                onClick={() => onCopyClick(accessCode)}
                            />
                        </Tooltip>
                    </Descriptions.Item>
                    <Descriptions.Item label="Sign Key" labelStyle={labelStyle}>
                        {accessSignKey}

                        <Tooltip title="拷贝 Sign Key">
                            <CopyOutlined
                                style={{marginLeft: 16}}
                                onClick={() => onCopyClick(accessSignKey)}
                            />
                        </Tooltip>
                    </Descriptions.Item>
                </Descriptions>

                <div style={{display: 'flex'}}>
                    <div style={labelStyle} />
                    <div>
                        <Button
                            type="link"
                            icon={<KeyOutlined />}
                            style={{paddingLeft: 0}}
                            onClick={() => onRegenSignKeyClick(clientApp)}
                        >
                            重新生成 Sign Key
                        </Button>
                    </div>
                </div>
            </Card>
        </Col>
    );
}

ClientAppCard.propTypes = {
    clientApp: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        appName: PropTypes.string,
        accessCode: PropTypes.number,
        accessSignKey: PropTypes.string,
        wecomAppId: PropTypes.number,
    }),
    onCopyClick: PropTypes.func.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onGenUrlClick: PropTypes.func.isRequired,
    onRegenSignKeyClick: PropTypes.func.isRequired,
};
