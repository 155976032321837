import React from 'react';
import {PageHeader} from 'antd';
import {Link, useParams} from 'react-router-dom';

import AuditMsgTable from './AuditMsgTable';
import {useAuthStore} from '@/context/auth';
import useTitle from '@/hook/useTitle';

function MyAuditMsgList() {
    useTitle('我的待审消息');

    const {appId} = useParams();
    const [authStore] = useAuthStore();
    const userId = authStore?.user?.id;

    const routes = [
        {
            path: `/apps/${appId}/msg-send`,
            breadcrumbName: '发送消息',
        },
        {
            path: `/apps/${appId}/msg-send/list`,
            breadcrumbName: '我的待审核消息',
        },
    ];

    function itemRender(route, params, routes) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span>{route.breadcrumbName}</span>
        ) : (
            <Link to={route.path}>{route.breadcrumbName}</Link>
        );
    }

    return (
        <PageHeader breadcrumb={{routes, itemRender}} title="我的待审消息">
            <AuditMsgTable appId={appId} creatorId={userId} />
        </PageHeader>
    );
}

export default MyAuditMsgList;
