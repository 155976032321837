import {openGet, openPost} from './openAjaxUtils';
import {deleteJson, getJson, patchJson, postJson} from '@/util/ajaxUtils';

export function getOpenComments(appId, newsId, userId) {
    return openGet(`/open/comments/${appId}/${newsId}`, {params: {userId}});
}

export function addComment(appId, newsId, userId, userName, content) {
    return openPost(`/open/comments/${appId}/${newsId}`, {
        data: {
            appId,
            itemId: newsId,
            userId,
            userName,
            content,
        },
    });
}

export function replyComment(commentId, content) {
    return postJson(`/comments/reply/${commentId}`, {
        data: {
            content,
        },
    });
}

export function getCommentsUrl(appId) {
    return `/comments/list?appId=${appId}`;
}

export function getCommentGroupByNewsUrl(appId) {
    return `/comments/list-by-news?appId=${appId}`;
}

export function getComments(appId, itemId) {
    return getJson(`/comments/list-by-item`, {params: {appId, itemId}});
}

export function toggleComment(commentId, shown, stick) {
    return patchJson(`/comments/toggle/${commentId}`, {data: {shown, stick}});
}

export function deleteComment(commentId) {
    return deleteJson(`/comments/${commentId}`);
}
