import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from './Avatar';
import styles from './AvatarLabel.module.less';

export default function AvatarLabel({url, text, className, style, disable, onClick}) {
    const extra = !disable && onClick ? {onClick} : {};

    return (
        <div className={classNames(styles.avatarItem, className)} style={style} {...extra}>
            <Avatar src={url} text={text} />

            <span className={styles.userName}>{text}</span>
        </div>
    );
}

AvatarLabel.propTypes = {
    url: PropTypes.string,
    text: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    // 此值为 true 时，onclick 不起作用
    disable: PropTypes.bool,
    onClick: PropTypes.func,
};

AvatarLabel.defaultProps = {
    disable: false,
};
