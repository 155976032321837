import React from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Divider, Popover} from 'antd';
import ReactPlayer from 'react-player';
import _ from 'lodash';
import filesize from 'filesize';

import {TextView} from '@/component';
import {getFileDownloadUrl, getFileUrl, getMsgNewsUrl} from '@/page/util/appUrlUtils';
import NewsPreview from '@/page/component/NewsFormModal/NewsPreview';
import MsgMeta from './MsgMeta';
import {InfoCircleOutlined} from '@/icons';

export default function MsgPreview({message, showMeta = true, newsStyle}) {
    const {
        msgType,
        title,
        description,
        content,
        attachId,
        attachExt,
        attachSize,
        newsContents,
        userName,
    } = message;

    function renderText() {
        if (msgType !== 'text') {
            return null;
        }

        return <TextView>{content}</TextView>;
    }

    function onNewsPreviewClick(newsId, news) {
        // console.log(newsId, news);
        const url = getMsgNewsUrl(message.id, news.id);
        window.open(url, '_blank', 'noopener');
    }

    function onNewsLinkPreviewClick(newsId, news) {
        window.open(news.link, '_blank', 'noopener');
    }

    function renderNews() {
        if (msgType !== 'news' || _.isEmpty(newsContents)) {
            return null;
        }

        const tmpIds = [];
        const newsById = {};
        newsContents.forEach(news => {
            tmpIds.push(news.id);
            newsById[news.id] = news;
        });

        return (
            <NewsPreview
                tmpIds={tmpIds}
                newsById={newsById}
                style={{maxWidth: 370, ...newsStyle}}
                onPreviewClick={onNewsPreviewClick}
            />
        );
    }

    function renderNewsLinks() {
        if (msgType !== 'news_link' || _.isEmpty(newsContents)) {
            return null;
        }

        const tmpIds = [];
        const newsById = {};
        newsContents.forEach(news => {
            tmpIds.push(news.id);
            newsById[news.id] = news;
        });

        return (
            <NewsPreview
                tmpIds={tmpIds}
                newsById={newsById}
                style={{maxWidth: 370, ...newsStyle}}
                onPreviewClick={onNewsLinkPreviewClick}
            />
        );
    }

    function renderImage() {
        if (msgType !== 'image') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        // return <img src={url} alt={title} style={{maxWidth: '100%', height: 'auto'}} />;
        return (
            <Card cover={<img src={url} alt={title} style={{maxWidth: '100%', height: 'auto'}} />}>
                <Card.Meta title={title} />
            </Card>
        );
    }

    function renderVoice() {
        if (msgType !== 'voice') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        if (attachExt && ['amr', 'silk'].includes(attachExt.toLowerCase())) {
            const filename = `${userName}-${title}`;
            return (
                <div>
                    <Button.Group>
                        <Button href={getFileDownloadUrl(attachId, filename)}>
                            下载 {attachExt} [{filesize(attachSize)}]
                        </Button>
                        <Popover
                            title="文件格式说明"
                            content={
                                <p>
                                    <code>amr</code>、<code>silk</code>
                                    是音频格式文件，如果想正确播放，
                                    <br />
                                    可使用
                                    <a
                                        href="https://convertio.co/zh/"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        convertio
                                    </a>
                                    网站将其转换为 <code>mp3</code> 格式
                                </p>
                            }
                        >
                            <Button icon={<InfoCircleOutlined />} />
                        </Popover>
                    </Button.Group>
                </div>
            );
        }
        return <ReactPlayer controls playing={false} url={url} width="100%" height={50} />;
    }

    function renderVideo() {
        if (msgType !== 'video') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        // return <ReactPlayer controls playing={false} url={url} width="100%" />;
        return (
            <Card cover={<ReactPlayer controls playing={false} url={url} width="100%" />}>
                <Card.Meta title={title} description={description} />
            </Card>
        );
    }

    function renderFile() {
        if (msgType !== 'file') {
            return null;
        }

        const url = getFileDownloadUrl(attachId, attachExt);
        return (
            <div>
                下载：
                <Button href={url} type="link">
                    {title}
                </Button>
            </div>
        );
    }

    return (
        <div>
            {showMeta && (
                <>
                    <MsgMeta message={message} />
                    <Divider orientation="left">预览</Divider>
                </>
            )}
            {renderText()}
            {renderNews()}
            {renderNewsLinks()}
            {renderImage()}
            {renderVoice()}
            {renderVideo()}
            {renderFile()}
        </div>
    );
}

MsgPreview.propTypes = {
    message: PropTypes.object,
    showMeta: PropTypes.bool,
    newsStyle: PropTypes.object,
};
