/*
 * Created by liyi on 2021-02-01 17:04.
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';

export default function SyncContactErrTable({contacts}) {
    const columns = [
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: '部门',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: '地区',
            dataIndex: 'location',
            width: 100,
            key: 'location',
        },
        {
            title: '错误信息',
            dataIndex: 'errMessage',
            // eslint-disable-next-line react/display-name
            render: text => {
                return <span style={{color: 'red'}}>{text}</span>;
            },
        },
    ];

    return <Table rowKey="email" columns={columns} dataSource={contacts} pagination={false} />;
}

SyncContactErrTable.propTypes = {
    contacts: PropTypes.array,
};
