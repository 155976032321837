import {getJson, postJson, deleteJson} from '@/util/ajaxUtils';

export function getSyncConfigs() {
    return getJson(`/bi/user-sync/config/list`);
}

export function saveSyncConfigs(dataType, configs) {
    return postJson(`/bi/user-sync/config/add`, {params: {dataType}, data: configs});
}

export function deleteBySrcDepart(srcDepart) {
    return deleteJson(`/bi/user-sync/config/by-src-depart`, {params: {srcDepart}});
}

export function manualSync() {
    return postJson(`/bi/user-sync/manual-sync`);
}
