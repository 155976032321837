import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {getFileUrl} from '@/page/component/attachUtils';

import styles from './NewsMiniPreview.module.less';

export default function NewsMiniPreview({newsContents}) {
    if (_.isEmpty(newsContents)) {
        return null;
    }

    const {thumbAttachId, thumbAttachExt} = newsContents[0];
    const thumbUrl = getFileUrl(thumbAttachId, thumbAttachExt);

    return (
        <div className={styles.container}>
            <img src={thumbUrl} alt="preview" className={styles.thumbImg} />
            <ul>
                {newsContents.map(({id, title}) => (
                    <li key={id}>{title}</li>
                ))}
            </ul>
        </div>
    );
}

NewsMiniPreview.propTypes = {
    newsContents: PropTypes.array,
};
