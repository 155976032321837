import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {message, Modal} from 'antd';
import _ from 'lodash';
import {useBeforeUnload} from 'react-use';

import {html2Text, isLink} from '@/util/stringUtils';
import {isPromise} from '@/util/PromiseUtils';
import {FullscreenModal} from '@/component';
import NewsForm from './NewsForm';
import NewsPreview from './NewsPreview';
import Ping from '@/page/component/toolkit/Ping';

import styles from './NewsFormModal.module.less';

const initTmpId = _.uniqueId();

const INIT_NEWS = {
    itemId: null,
    title: null,
    description: null,
    mediaType: 'news',
    content: null,
    link: null,
    author: null,
    thumbAttachId: null,
    thumbAttachExt: null,

    // 图文控制项，链接图文不启用
    newsCommentType: 0,
    newsAccessType: 1,
    newsContentForbidCopy: false,
    newsContentMask: false,
    newsShowReadNum: true,
    newsShowPraiseNum: true,

    // 上传时生成的 data object 格式的缩略图 url，用于预览使用
    thumbUrl: null,
    attaches: [],
};

const VALID_NEWS_FIELDS = {
    content: '内容',
};

const VALID_NEWS_LINK_FIELDS = {
    link: '外链',
};

const COMMON_VALID_FIELDS = {
    title: '标题',
    thumbAttachId: '封面图',
};

const NewsFormModal = forwardRef(({itemFrom, onFormSuccess}, ref) => {
    const [visible, setVisible] = useState(false);
    const [businessId, setBusinessId] = useState(null);
    const [spinning, setSpinning] = useState(false);

    // 支持 news，news_link
    const [mediaType, setMediaType] = useState('news');
    const mediaTypeText = mediaType === 'news' ? '图文' : '链接图文';

    // 由于可能是多图文，所以对于每个图文都提供一个临时 id
    const [tmpIds, setTmpIds] = useState([initTmpId]);
    const [newsById, setNewsById] = useState({[initTmpId]: {...INIT_NEWS, itemFrom}});
    const [currentTmpId, setCurrentTmpId] = useState(initTmpId);

    // 是否已经编辑
    const [dirty, setDirty] = useState(false);

    // console.log('tmpIds newsById currentTmpId', tmpIds, newsById, currentTmpId);

    useBeforeUnload(visible, '确定关闭，已编辑内容将会丢弃');

    function showModal({businessId = null, mediaType = 'news', newsList} = {}) {
        setVisible(true);
        setMediaType(mediaType);

        // 编辑的时候此值为素材、消息、菜单、自动回复的 id
        if (businessId) {
            setBusinessId(businessId);
        }

        if (_.isEmpty(newsList)) {
            return;
        }

        const ids = [];
        const news = {};
        newsList.forEach(item => {
            const tmpId = item.id || _.uniqueId();
            ids.push(tmpId);
            news[tmpId] = item;
        });
        setTmpIds(ids);
        setNewsById(news);
        setCurrentTmpId(ids[0]);
    }

    function resetOnClose() {
        setVisible(false);
        setBusinessId(null);
        setSpinning(false);
        setDirty(false);

        setTmpIds([initTmpId]);
        setNewsById({[initTmpId]: {...INIT_NEWS, itemFrom}});
        setCurrentTmpId(initTmpId);
    }

    function closeModal(confirm = true) {
        if (!confirm || !dirty) {
            resetOnClose();
            return;
        }

        Modal.confirm({
            title: '警告',
            content: (
                <>
                    <p>确定关闭{mediaTypeText}编辑框?</p>
                    <p>如关闭，则已编辑内容将会丢弃！</p>
                </>
            ),
            okButtonProps: {danger: true},
            onOk: () => {
                resetOnClose();
            },
        });
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onAddNews() {
        const tmpId = _.uniqueId();
        setTmpIds([...tmpIds, tmpId]);
        setNewsById({...newsById, [tmpId]: {...INIT_NEWS, itemFrom}});
        setCurrentTmpId(tmpId);

        setDirty(true);
    }

    function onDeleteNews(tmpId) {
        // 最后一个，给出提示
        if (tmpIds.length === 1) {
            message.warn(`至少要有一个${mediaTypeText}`);
            return;
        }

        Modal.confirm({
            title: '警告',
            content: `确定删除${mediaTypeText}?`,
            okButtonProps: {danger: true},
            onOk: () => {
                // 如果 tmpId 在顶部，则下移一位，否则上移一位
                const index = tmpIds.indexOf(tmpId);
                let nextId;
                if (index === 0) {
                    nextId = tmpIds[1];
                } else {
                    nextId = tmpIds[index - 1];
                }

                const ids = tmpIds.filter(id => id !== tmpId);
                const byId = _.omit(newsById, tmpId);

                setTmpIds(ids);
                setNewsById(byId);
                setCurrentTmpId(nextId);
                setDirty(true);
            },
        });
    }

    /**
     * 上下移动。
     *
     * @param tmpId 临时 id
     * @param to -1：上移， 1：下移
     */
    function onMoveToClick(tmpId, to) {
        const index = tmpIds.indexOf(tmpId);
        const ids = [...tmpIds];

        // 从原位置删除
        ids.splice(index, 1);

        ids.splice(index + to, 0, tmpId);
        setTmpIds(ids);
        setDirty(true);
    }

    function onPreviewClick(tmpId) {
        setCurrentTmpId(tmpId);
    }

    function onNewsChange(tmpId, news) {
        setNewsById({...newsById, [tmpId]: news});
        setDirty(true);
    }

    function onAttachChange(tmpId, attaches) {
        // console.log('onAttachChange', tmpId, attaches);
        const news = newsById[tmpId];
        setNewsById({...newsById, [tmpId]: {...news, attaches}});
        setDirty(true);
    }

    function validNews(news) {
        let validFields;
        if (mediaType === 'news') {
            validFields = Object.assign({}, COMMON_VALID_FIELDS, VALID_NEWS_FIELDS);
        } else if (mediaType === 'news_link') {
            validFields = Object.assign({}, COMMON_VALID_FIELDS, VALID_NEWS_LINK_FIELDS);
        }

        const fields = Object.keys(validFields);
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const value = news[field];
            if (!value || !value.trim()) {
                message.error(`「${validFields[field]}」不能为空`);
                return false;
            }
        }

        if (mediaType === 'news_link') {
            if (!isLink(news.link)) {
                message.error('外链不是有效的链接');
                return false;
            }
        }

        return true;
    }

    function validAndFocusForm() {
        const extraTmpIds = tmpIds.filter(id => id !== currentTmpId);
        const validIds = [currentTmpId, ...extraTmpIds];

        for (let i = 0; i < validIds.length; i++) {
            const tmpId = validIds[i];
            const news = newsById[tmpId];
            if (!validNews(news)) {
                setCurrentTmpId(tmpId);
                return false;
            }
        }

        return true;
    }

    /**
     * 转换所有图文为数组形式。
     *
     * @return {[]}
     */
    function convertNews() {
        const list = [];
        let listOrder = 1;
        for (const tmpId of tmpIds) {
            const news = newsById[tmpId];
            news.listOrder = listOrder;

            // 摘要为空，则抓取内容的前 50 个字
            if (mediaType === 'news') {
                const {description} = news;
                if (!description || !description.trim()) {
                    news.description = html2Text(news.content).substr(0, 50);
                }
            }

            list.push(news);
            listOrder++;
        }

        return list;
    }

    function onOk() {
        const isValid = validAndFocusForm();
        if (!isValid || !onFormSuccess) {
            return;
        }

        const newsList = convertNews();
        console.table(newsList);

        setSpinning(true);
        const result = onFormSuccess(businessId, mediaType, newsList);
        if (isPromise(result)) {
            result.then(data => {
                if (data === true) {
                    closeModal(false);
                } else {
                    setSpinning(false);
                }
            });
        } else {
            if (result) {
                closeModal(false);
            } else {
                setSpinning(false);
            }
        }
    }

    return (
        <FullscreenModal
            destroyOnClose
            maskClosable={false}
            loading={spinning}
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
        >
            <div className={styles.main}>
                <NewsForm
                    mediaType={mediaType}
                    currentTmpId={currentTmpId}
                    newsById={newsById}
                    onNewsChange={onNewsChange}
                    onAttachChange={onAttachChange}
                />
            </div>
            <div className={styles.preview}>
                <NewsPreview
                    selectable
                    editable
                    newsById={newsById}
                    tmpIds={tmpIds}
                    currentTmpId={currentTmpId}
                    onAddNews={onAddNews}
                    onDeleteNews={onDeleteNews}
                    onPreviewClick={onPreviewClick}
                    onMoveToClick={onMoveToClick}
                />
            </div>

            <Ping />
        </FullscreenModal>
    );
});

NewsFormModal.displayName = 'NewsFormModal';

NewsFormModal.propTypes = {
    itemFrom: PropTypes.oneOf(['media', 'message', 'menu', 'reply']).isRequired,
    onFormSuccess: PropTypes.func,
};

export default NewsFormModal;
