import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';

import {FullscreenModal} from '@/component';
import {useDepartTree} from '../useDepartService';
import WecomTree from './WecomTree';
import LocalTree from './LocalTree';

import styles from './index.module.less';

const DepartEditModal = forwardRef(({refreshTree}, ref) => {
    const [visible, setVisible] = useState(false);
    const localTreeData = useDepartTree(false);
    const wecomTreeData = useDepartTree(true);

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
        refreshTree();
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function refreshTrees() {
        console.log('refreshTrees');
        localTreeData.retry();
        wecomTreeData.retry();
    }

    function onOk() {
        closeModal();
    }

    return (
        <FullscreenModal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="组织结构编辑"
            okText="完成编辑"
            cancelButtonProps={{style: {display: 'none'}}}
            onCancel={closeModal}
            onOk={onOk}
        >
            <div className={styles.container}>
                <div className={styles.localTree}>
                    <LocalTree localTreeData={localTreeData} refreshTrees={refreshTrees} />
                    <ul className={styles.description}>
                        <li>可通过「右键菜单」添加、修改、删除部门</li>
                        <li>
                            企业微信的「顶级部门」默认是公司名称，不可编辑，所以本地的「顶级部门」的编辑不会同步到企业微信
                        </li>
                        <li>如上说明，「顶级部门」名称可不与企业微信的一致</li>
                        <li>子部门则支持同步到企业微信</li>
                        <li>
                            <strong>建议：尽量保证二者的结构一致！！！</strong>
                        </li>
                        <li>
                            部门名称格式：[num] string
                            <ul>
                                <li>
                                    num: 企业微信部门 ID，可自动生成，也可手工编辑，但必须保证唯一
                                </li>
                                <li>string: 实际的部门名称</li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className={styles.wecomTree}>
                    <WecomTree wecomTreeData={wecomTreeData} />
                    <ul className={styles.description}>
                        <li>企业微信部门结构树只能查看，方便比对本地组织结构编辑效果</li>
                    </ul>
                </div>
            </div>
        </FullscreenModal>
    );
});

DepartEditModal.displayName = 'DepartEditModal';

DepartEditModal.propTypes = {
    refreshTree: PropTypes.func.isRequired,
};

export default DepartEditModal;
