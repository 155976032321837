import React, {useEffect, useRef, useState} from 'react';
import {Button, Form, Input, notification} from 'antd';
import {useHistory} from 'react-router-dom';
import classNames from 'classnames';

import {APP_TITLE} from '@/env';
import useTitle from '@/hook/useTitle';
import {useAuthStore} from '@/context/auth';
import {doLogin} from '@/api/auth';
import {LockOutlined, UserOutlined} from '@/icons';
import CaptchaFormItem from './CaptchaFormItem';

import styles from './LoginPage.module.less';

export default function LoginPage() {
    useTitle('登录');
    const [captchaKey, setCaptchaKey] = useState(null);
    const [, dispatch] = useAuthStore();
    const history = useHistory();
    const formRef = useRef();
    const captchaItemRef = useRef();

    useEffect(() => {
        dispatch('reset');
    }, [dispatch]);

    function onSsoClick() {
        const redirect = `${window.location.origin}/sso`;
        let REACT_APP_API_HOST_SSO ='';
        console.log(process.env.NODE_ENV,'process.env.NODE_ENV');
        if (process.env.NODE_ENV !== 'production') {
            REACT_APP_API_HOST_SSO = 'https://wcdl-portal-test.boehringer-ingelheim.cn/be-api/auth/generateAuthUrl?redirectUrl='
        } else {
            REACT_APP_API_HOST_SSO = 'https://wcdl-portal.boehringer-ingelheim.cn/be-api/auth/generateAuthUrl?redirectUrl='
        }
        window.location.href = `${REACT_APP_API_HOST_SSO}${encodeURIComponent(
            redirect
        )}`;
    }
    function onLoadCaptchaKey(captchaKey) {
        // console.log('captchaKey', captchaKey);
        setCaptchaKey(captchaKey);
    }

    function onFinish(values) {
        const loginValues = {...values, captchaKey};
        // console.log('Received values of form: ', loginValues);

        doLogin(loginValues)
            .then(({data}) => {
                // console.log(data);
                dispatch(['loginSuccess', data]);

                history.replace('/');
            })
            .catch(e => {
                const {status, message} = e;
                if (status !== 422) {
                    notification.error({
                        message: `错误: ${status}`,
                        description: message,
                    });
                }

                // 处理 422
                if (e.catch422Error) {
                    e.catch422Error(formRef.current);
                }

                // 重新载入验证码
                captchaItemRef.current.reload();
            });
    }

    function renderForm() {
        return (
            <Form ref={formRef} onFinish={onFinish}>
                <Form.Item
                    name="username"
                    rules={[
                        {
                            required: true,
                            message: 'Email 或手机号不能为空',
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined />}
                        placeholder="Email 或手机号"
                        size="large"
                        autoFocus
                    />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: '密码不能为空',
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<LockOutlined />}
                        size="large"
                        placeholder="密码"
                        visibilityToggle
                    />
                </Form.Item>

                <CaptchaFormItem ref={captchaItemRef} onLoadCaptchaKey={onLoadCaptchaKey} />

                <Form.Item className={styles.submitWrap}>
                    <Button htmlType="submit" size="large" className={styles.submit}>
                        登录
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    return (
        <div className={classNames(styles.container, styles.fixed)}>
            <div className={styles.card}>
                <img src={require('@/asset/login.jpg').default} alt="login" height={500} />
                <div className={styles.form}>
                    <p className={styles.title}>{APP_TITLE}</p>
                    <h1>用户登录</h1>
                    <div>
                        {renderForm()}
                        {/* 暂时屏蔽此功能
                        <div className={styles.forget}>
                            <Button type="link" onClick={() => history.push('/forget')}>
                                忘记密码
                            </Button>
                        </div>
                        */}
                    </div>
                    
                    <div className={styles.submitWrap} style={{width: '100%'}}>
                        <Button size="large" className={styles.submit} onClick={onSsoClick}>
                            使用 BI 帐号登录
                        </Button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}
