import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Card, Spin} from 'antd';

import AppTree from '@/page/component/AppTree';
import {convertDeparts2TreeFormat} from '@/util/treeUtils';

export default function WecomTree({wecomTreeData}) {
    const {loading, value} = wecomTreeData;
    const [treeData, setTreeData] = useState(null);

    useEffect(() => {
        if (loading) {
            return;
        }

        function nameCallback(node) {
            return `[${node.extId}] ${node.name}`;
        }

        const {data} = value || {};
        setTreeData(convertDeparts2TreeFormat(data, nameCallback));
    }, [loading, value]);

    return (
        <Card title="企业微信组织结构">
            <Spin spinning={loading}>
                {!loading && !!treeData && <AppTree treeData={treeData} />}
            </Spin>
        </Card>
    );
}

WecomTree.propTypes = {
    wecomTreeData: PropTypes.shape({
        loading: PropTypes.bool,
        value: PropTypes.object,
    }),
};
