import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Form, Input, Modal, Switch, Typography} from 'antd';

import {addTag, updateTag} from '@/api/tags';

const TagFormModal = forwardRef(({appId, refreshTags}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [tag, setTag] = useState({});
    const [sync, setSync] = useState(true);

    function showModal(tag) {
        setVisible(true);
        if (appId) {
            setTag(tag || {scope: 'app', scopeItemId: appId});
        } else {
            setTag(tag || {scope: 'global'});
        }
    }

    function closeModal() {
        setVisible(false);
        setSync(true);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const {scope, scopeItemId, ...rest} = values;
        let tag = values;
        if (scope === 'global') {
            tag = {...rest, scope};
        }
        const {id} = tag;
        const promise = id ? updateTag(id, tag, sync) : addTag(tag, sync);
        promise
            .then(() => {
                // console.log(data);
                refreshTags();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    function onSyncChange(checked) {
        // console.log(`switch to ${checked}`);
        setSync(checked);
    }

    const footer = [
        <div key="sync" style={{display: 'inline-block', marginRight: 24}}>
            <Typography.Text type="secondary" style={{marginRight: 24}}>
                同步到企业微信:
            </Typography.Text>
            <Switch
                key="sync"
                checked={sync}
                checkedChildren="开启"
                unCheckedChildren="关闭"
                onChange={onSyncChange}
            />
        </div>,
        <Button key="cancelBtn" onClick={closeModal}>
            取消
        </Button>,
        <Button key="okBtn" type="primary" onClick={onOk}>
            确定
        </Button>,
    ];

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="标签编辑"
            onCancel={closeModal}
            footer={footer}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={tag}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="scope">
                    <Input />
                </Form.Item>
                <Form.Item hidden name="scopeItemId">
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="标签名"
                    rules={[
                        {
                            required: true,
                            max: 32,
                        },
                    ]}
                >
                    <Input placeholder="标签名" autoFocus />
                </Form.Item>

                <Divider />

                <Form.Item
                    name="extId"
                    label="企业微信标签 ID"
                    extra="只在维护企业微信通讯录已存在的标签时使用，慎用！"
                    rules={[
                        {
                            pattern: /^[\d]+$/,
                            message: '只能是数字',
                        },
                    ]}
                >
                    <Input placeholder="企业微信标签 ID" />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

TagFormModal.displayName = 'TagFormModal';

TagFormModal.propTypes = {
    appId: PropTypes.string,
    refreshTags: PropTypes.func,
};

export default TagFormModal;
