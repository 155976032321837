import React from 'react';

import {TwoColumnLayout} from '@/component';
import AppContent from '@/page/component/AppLayout/AppContent';
import MediaRoutes from './MediaRoutes';
import MediaSider from './MediaSider';

import styles from './MediaPage.module.less';
import useTitle from '@/hook/useTitle';

export default function MediaPage() {
    useTitle('素材管理');

    return (
        <AppContent className={styles.container} autoPadding={false}>
            <TwoColumnLayout>
                <TwoColumnLayout.SidePanel enableScroll className={styles.sidePanel}>
                    <MediaSider />
                </TwoColumnLayout.SidePanel>
                <TwoColumnLayout.ContentPanel>
                    <MediaRoutes />
                </TwoColumnLayout.ContentPanel>
            </TwoColumnLayout>
        </AppContent>
    );
}
