import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';
import _ from 'lodash';

import MediaType from '@/page/app/MediaPage/MediaType';
import NewsLikeContent from './comp/NewsLikeContent';
import VideoFormModal from '@/page/component/VideoFormModal';
import VideoPreview from '@/page/component/VideoFormModal/VideoPreview';

import {valuePropTypes} from '@/page/app/component/MessageContent/content/propTypes';

export default function VideoContent({value, onChange}) {
    const videoFormModalRef = useRef();

    function onAddClick() {
        videoFormModalRef.current.showModal();
    }

    function onEditClick() {
        videoFormModalRef.current.showModal(value);
    }

    function onFormSuccess(values) {
        onChange && onChange(values);
        return true;
    }

    return (
        <>
            <NewsLikeContent
                hasValue={!_.isEmpty(value)}
                buttonTitle="添加视频"
                description={
                    <>
                        <p>格式支持: {MediaType.VIDEO.accept}</p>
                        <p>文件大小不超过 {filesize(MediaType.VIDEO.maxSize)}</p>
                    </>
                }
                onAddClick={onAddClick}
                onEditClick={onEditClick}
                onChange={onChange}
                type="video"
            >
                <VideoPreview videoData={value} />
            </NewsLikeContent>

            <VideoFormModal
                ref={videoFormModalRef}
                category="message"
                onFormSuccess={onFormSuccess}
            />
        </>
    );
}

VideoContent.propTypes = {
    value: valuePropTypes,
    onChange: PropTypes.func.isRequired,
};
