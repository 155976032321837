import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {useTags} from '@/page/admin/TagsPage/useTagsService';
import TagList from '@/page/admin/TagsPage/TagList';

import styles from '../MainPanel.module.less';

export default function TagTabPane({searchValue, onAddSelected}) {
    const {value} = useTags(searchValue);
    // console.log('loading, value', loading, value);
    const tags = _.get(value, 'data');

    function onTagSelect(tag) {
        // console.log(tag);
        const {id, name} = tag;
        onAddSelected('tags', {id, name});
    }

    return (
        <div className={styles.tabContent1}>
            <TagList tags={tags} onSelect={onTagSelect} />
        </div>
    );
}

TagTabPane.propTypes = {
    searchValue: PropTypes.string,
    onAddSelected: PropTypes.func.isRequired,
};
