import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, List, Modal} from 'antd';
import classNames from 'classnames';

import {DeleteOutlined, EditOutlined, MoreOutlined} from '@/icons';
import {RowAction} from '@/component';

import {deleteRole} from '@/api/roles';

import styles from './RolesPage.module.less';

export default function RoleItem({
    role,
    selRoleId,
    onShowFormModal,
    reloadRoles,
    onRoleSelect,
    editable = true,
    selectable = true,
    initSelectRoleIds = [],
    onRoleCheckToggle,
}) {
    function onModifyClick(role) {
        onShowFormModal && onShowFormModal(role);
    }

    function onDeleteClick(role) {
        Modal.confirm({
            title: '警告',
            content: `确定删除角色 [${role.name}]？`,
            okButtonProps: {danger: true},
            onOk: () => {
                deleteRole(role.id).then(() => {
                    reloadRoles && reloadRoles();
                });
            },
        });
    }

    function onCheckChange(e) {
        onRoleCheckToggle && onRoleCheckToggle({roleId: role.id, checked: e.target.checked});
    }

    function onItemClick(role) {
        return () => {
            onRoleSelect && onRoleSelect(role);
        };
    }

    const actions = [
        {
            key: 'modify',
            title: '修改',
            hide: true,
            icon: <EditOutlined />,
            onClick: onModifyClick,
        },
        {
            key: 'delete',
            title: '删除',
            hide: true,
            icon: <DeleteOutlined />,
            onClick: onDeleteClick,
        },
    ];

    const roleAction = editable ? (
        <RowAction showMenuIcon={false} text={<MoreOutlined />} record={role} actions={actions} />
    ) : null;

    return (
        <div
            className={classNames(styles.roleItem, {
                [styles.roleItemSelected]: role.id === selRoleId,
            })}
        >
            {selectable && (
                <div className={styles.checkArea}>
                    <Checkbox
                        defaultChecked={initSelectRoleIds.includes(role.id)}
                        onChange={onCheckChange}
                    />
                </div>
            )}
            <List.Item
                extra={roleAction}
                className={styles.roleContent}
                onClick={onItemClick(role)}
            >
                <List.Item.Meta title={role.name} description={role.memo} />
            </List.Item>
        </div>
    );
}

RoleItem.propTypes = {
    role: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        memo: PropTypes.string,
    }).isRequired,
    selRoleId: PropTypes.string,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    initSelectRoleIds: PropTypes.arrayOf(PropTypes.string),
    onRoleCheckToggle: PropTypes.func,

    onShowFormModal: PropTypes.func,
    reloadRoles: PropTypes.func,
    onRoleSelect: PropTypes.func,
};
