import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {message} from 'antd';
import filesize from 'filesize';

import ReactUeditor from './ifanrx-react-ueditor';
import {isProd, PUBLIC_URL} from '@/env';
import {postJson} from '@/util/ajaxUtils';
import {getVideoThumb} from '@/util/videoUtils';
import {updateImageDataURL} from '@/api/attach';

// 文件最大不能超过 100Mb
const MAX_FILE_SIZE = 100 * 1024 * 1024;

const UEditor = forwardRef(
    ({getRef, onReady, value, onChange, category = 'news_content', itemId}, ref) => {
        const editorRef = useRef();
        const loadingRef = useRef();
        // console.log('ueditor', value);

        // const [loading, setLoading] = useState(false);
        // const [videoProgress, setVideoProgress] = useState(-1);

        function setLoading(value) {
            if (value) {
                loadingRef.current = message.loading('上传中', 0);
            } else {
                loadingRef.current && loadingRef.current();
            }
        }

        useImperativeHandle(ref, () => ({
            setContent: content => {
                editorRef.current &&
                    editorRef.current.setContent &&
                    editorRef.current.setContent(content || '');
            },
            getContent: () => {
                // console.log('editorRef', editorRef);
                if (editorRef.current && editorRef.current.getContent) {
                    return editorRef.current.getContent();
                }
                return null;
            },
        }));

        function getEditorRef(ueditorRef) {
            // console.log('getEditorRef', ueditorRef);
            editorRef.current = ueditorRef;
        }

        function uploadFile(file) {
            const fileData = new FormData();
            fileData.append('category', category);
            fileData.append('itemId', itemId);
            fileData.append('file', file);

            setLoading(true);
            return postJson(`/attach`, {
                data: fileData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                // onUploadProgress: progressEvent => {
                //     console.log(progressEvent);
                //     const percentCompleted = Math.round(
                //         (progressEvent.loaded * 100) / progressEvent.total
                //     );
                //     console.log(percentCompleted);
                //     setVideoProgress(percentCompleted);
                // },
            }).then(({data}) => {
                // console.log('file', data);
                setLoading(false);
                const {
                    urls: {file},
                } = data[0];
                // return file.replace(`${API_ROOT}/`, '');
                return file;
            });
        }

        function uploadImage(e) {
            const {type} = e.target.files[0];
            const supportTypes = ['jpeg', 'png', 'gif'];
            const types = supportTypes.map(type => `image/${type}`);
            if (!types.includes(type)) {
                // console.log('supportTypes.includes(type)', supportTypes.includes(type));
                let errMsg = '图片只支持 ' + supportTypes.join(', ');
                message.error(errMsg);
                return Promise.reject(errMsg);
            }

            return uploadFile(e.target.files[0])
                .then(url => {
                    setLoading(false);
                    return url;
                })
                .catch(e => {
                    message.error(e.message);
                    setLoading(false);
                });
        }

        function uploadVideo(e) {
            const file = e.target.files[0];
            const {type, size} = file;
            const supportTypes = ['mp4'];
            const types = supportTypes.map(type => `video/${type}`);
            if (!types.includes(type)) {
                // console.log('supportTypes.includes(type)', supportTypes.includes(type));
                let errMsg = '视频只支持 ' + supportTypes.join(', ');
                message.error(errMsg);
                return Promise.reject(errMsg);
            }

            if (size > MAX_FILE_SIZE) {
                let errMsg = '视频最大不能超过 ' + filesize(MAX_FILE_SIZE);
                message.error(errMsg);
                return Promise.reject(errMsg);
            }

            return uploadFile(file)
                .then(url => {
                    return getVideoThumb(url).then(({dataURL, width, height}) => {
                        console.log(dataURL);

                        return updateImageDataURL('vidoethumb', null, width, height, dataURL).then(
                            json => {
                                console.log('updateImageDataURL', json);
                                const poster = json?.data?.urls?.file;

                                setLoading(false);

                                return {videoUrl: url, posterUrl: poster};
                            }
                        );
                    });
                })
                .catch(e => {
                    // console.log('aaa', e);
                    message.warn(e.message);
                    setLoading(false);
                });
        }

        function uploadAudio(e) {
            const file = e.target.files[0];
            const {type, size} = file;
            const supportTypes = ['mp3', 'mpeg'];
            const types = supportTypes.map(type => `audio/${type}`);

            if (!types.includes(type)) {
                // console.log('supportTypes.includes(type)', supportTypes.includes(type));
                let errMsg = '音频只支持 mp3';
                message.error(errMsg);
                return Promise.reject(errMsg);
            }

            if (size > MAX_FILE_SIZE) {
                let errMsg = '音频最大不能超过 ' + filesize(MAX_FILE_SIZE);
                message.error(errMsg);
                return Promise.reject(errMsg);
            }
            // console.log(type, size);

            return uploadFile(file)
                .then(url => ({audioUrl: url}))
                .catch(e => {
                    message.warn(e.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        function pasteImageStart(imageAmount) {
            console.log('paste start', 'image amount is ' + imageAmount);
            if (imageAmount > 0) {
                setLoading(true);
            }
        }

        /**
         * 抓取远程图片
         * @param src
         * @return {Promise<{data: null, success: boolean, message: string, status: number} | * | undefined>}
         */
        function handlePasteImage(src) {
            console.log('src', src);
            const fileData = new FormData();
            fileData.append('category', category);
            fileData.append('itemId', itemId);
            fileData.append('base64', 'true');
            fileData.append('urls', btoa(src));

            // setLoading(true);
            return postJson(`/attach/fetch-remote-file`, {
                data: fileData,
            }).then(({data}) => {
                // console.log('file', data);
                // setLoading(false);
                const {
                    sysAttach: {
                        urls: {file},
                    },
                } = data[0];
                // return file.replace(`${API_ROOT}/`, '');
                return file;
            });
        }

        function pasteImageDone() {
            console.log('paste done');
            setLoading(false);
        }

        return (
            <>
                <ReactUeditor
                    ueditorPath={`${PUBLIC_URL}/static/ueditor`}
                    getRef={getEditorRef}
                    debug={!isProd}
                    value={value ?? ''}
                    onReady={onReady}
                    onChange={onChange}
                    plugins={['uploadImage', 'uploadSimpleVideo', 'uploadSimpleAudio']}
                    uploadImage={uploadImage}
                    simpleUploadVideo={uploadVideo}
                    simpleUploadAudio={uploadAudio}
                    pasteImageStart={pasteImageStart}
                    handlePasteImage={handlePasteImage}
                    pasteImageDone={pasteImageDone}
                />
            </>
        );
    }
);

UEditor.displayName = 'UEditor';

UEditor.propTypes = {
    getRef: PropTypes.func,
    onReady: PropTypes.func,
    value: PropTypes.string,
    onChange: PropTypes.func,
    category: PropTypes.string,
    itemId: PropTypes.string,
};

export default UEditor;
