import React, {useEffect, useState} from 'react';
import {Tabs} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';

import useTitle from '@/hook/useTitle';
import AppLayout from '../component/AppLayout';
import AppLayoutHeader from '../component/AppLayout/AppLayoutHeader';
import UserProfile from './UserProfile';
import UserPassword from './UserPassword';

const {TabPane} = Tabs;

const tabs = [
    {
        path: '/profile',
        component: UserProfile,
        title: '个人信息',
    },
    {
        path: '/profile/password',
        component: UserPassword,
        title: '修改密码',
    },
];

export default function ProfilePage() {
    const [pageTitle, setPageTitle] = useState(tabs[0].title);
    useTitle(pageTitle);

    const location = useLocation();
    const history = useHistory();
    const [activeKey, setActiveKey] = useState(tabs[0].path);

    useEffect(() => {
        setActiveKey(location.pathname);
        const tab = tabs.find(value => value.path === location.pathname);
        if (tab) {
            setPageTitle(tab.title);
        }
    }, [location.pathname]);

    function onTabChange(key) {
        history.push(key);
    }

    return (
        <AppLayout header={<AppLayoutHeader />}>
            <div style={{overflowY: 'auto'}}>
                <Tabs activeKey={activeKey} onChange={onTabChange} centered>
                    {tabs.map(tab => (
                        <TabPane tab={tab.title} key={tab.path}>
                            <tab.component />
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        </AppLayout>
    );
}
