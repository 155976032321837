import {API_ROOT, PUBLIC_URL} from '@/env';

export function getMediaUrl(mediaId) {
    const {origin} = window.location;

    return `${origin}${PUBLIC_URL}/article/media/${mediaId}`;
}

export function getMediaNewsUrl(mediaId, newsId) {
    const {origin} = window.location;

    return `${origin}${PUBLIC_URL}/article/media-news/${mediaId}/${newsId}`;
}

export function getMsgNewsUrl(msgId, newsId) {
    const {origin} = window.location;

    return `${origin}${PUBLIC_URL}/article/news/${msgId}/${newsId}`;
}

export const getFileUrl = (attachId, attachExt) => {
    return `${API_ROOT}/attach/files/${attachId}.${attachExt}`;
};

export const getFileDownloadUrl = (attachId, filename) => {
    let url = `${API_ROOT}/attach/download/${attachId}`;
    if (filename) {
        url += `?filename=${filename}`;
    }
    return url;
};
