import React, {useEffect, useState} from 'react';
import {Drawer, Layout, Menu, Typography} from 'antd';
import {Link, matchPath, useLocation} from 'react-router-dom';
import _ from 'lodash';

import {MenuConfig} from '@/page/admin/AdminRoutes';
import {useResponsiveView} from '@/component';

import './AdminSidebar.less';
import {useMenuService} from '../adminService';

const {Sider} = Layout;
const {Title} = Typography;

export default function AdminSidebar({collapsed, onCollapse}) {
    const menus = useMenuService();

    const [selMenuKey, setSelMenuKey] = useState(MenuConfig[0].path);
    const location = useLocation();
    const {isMobile} = useResponsiveView();

    useEffect(() => {
        // 初始进入或刷新页面时预先判断下需要高亮哪个菜单
        // AdminRouteConfig 需要去掉收尾两个配置项
        menus.forEach(config => {
            const m = matchPath(location.pathname, config);
            if (m && m.path) {
                setSelMenuKey(m.path);
            }
        });
    }, [location.pathname, menus]);

    if (_.isEmpty(menus)) {
        return null;
    }

    function renderSider(siderProps) {
        return (
            <Sider
                {...siderProps}
                className="app-admin-sider"
                style={{backgroundColor: '#fff', overflowY: 'auto'}}
            >
                <Menu selectedKeys={[selMenuKey]}>
                    {menus.map(({label, path, icon}) => {
                        return (
                            <Menu.Item key={path} icon={icon}>
                                <Link to={path}>{label}</Link>
                            </Menu.Item>
                        );
                    })}
                </Menu>
            </Sider>
        );
    }

    if (isMobile) {
        return (
            <Drawer
                closable={false}
                placement="left"
                visible={!collapsed}
                onClose={onCollapse}
                bodyStyle={{padding: '24px 0'}}
            >
                <Title level={4} style={{paddingLeft: 16}}>
                    系统管理
                </Title>
                {renderSider({width: '100%'})}
            </Drawer>
        );
    }

    return renderSider();
}
