import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Switch, Divider, InputNumber, Typography, Modal} from 'antd';

import {addDepart, updateDepart} from '@/api/departs';
import {isTopNode} from '@/util/treeUtils';
import {NAME_REGEXP, NAME_MESSAGE} from '@/util/stringUtils';

const DepartFormModal = forwardRef(({refreshTree}, ref) => {
    const [visible, setVisible] = useState(false);
    const [formData, setFormData] = useState({});
    const [sync, setSync] = useState(true);
    // 禁用同步按钮
    const [disabledSync, setDisabledSync] = useState(false);

    const formRef = useRef();

    function showModal({id, extId, name, parentId} = {}) {
        setVisible(true);
        setFormData({id, extId, name, parentId});

        // 根节点不可同步
        if (isTopNode(parentId)) {
            setSync(false);
            setDisabledSync(true);
        }
    }

    function closeModal() {
        setVisible(false);
        setSync(true);
        setDisabledSync(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        let promise;
        if (!values.id) {
            promise = addDepart(values, sync);
        } else {
            promise = updateDepart(values.id, values, sync);
        }

        promise
            .then(data => {
                console.log(data);
                refreshTree && refreshTree();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    function onSyncChange(checked) {
        console.log(`switch to ${checked}`);
        setSync(checked);
    }

    const footer = [
        <div key="sync" style={{display: 'inline-block', marginRight: 24}}>
            <Typography.Text type="secondary" style={{marginRight: 24}}>
                同步到企业微信:
            </Typography.Text>
            <Switch
                key="sync"
                checked={sync}
                disabled={disabledSync}
                checkedChildren="开启"
                unCheckedChildren="关闭"
                onChange={onSyncChange}
            />
        </div>,
        <Button key="cancelBtn" onClick={closeModal}>
            取消
        </Button>,
        <Button key="okBtn" type="primary" onClick={onOk}>
            确定
        </Button>,
    ];

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={formData.id ? '修改部门' : '添加部门'}
            onCancel={closeModal}
            footer={footer}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={formData}>
                <Form.Item
                    name="name"
                    label="部门名称"
                    rules={[
                        {
                            required: true,
                            max: 30,
                        },
                        {
                            pattern: NAME_REGEXP,
                            message: NAME_MESSAGE,
                        },
                    ]}
                >
                    <Input placeholder="部门名称" autoFocus />
                </Form.Item>

                <Divider />

                <Form.Item
                    name="extId"
                    label="企业微信部门 ID"
                    extra="只在添加企业微信通讯录已存在的部门时使用，慎用！"
                >
                    <InputNumber
                        placeholder="企业微信部门 ID"
                        style={{width: '100%'}}
                        disabled={isTopNode(formData.parentId)}
                    />
                </Form.Item>

                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>
                <Form.Item hidden name="parentId">
                    <Input />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

DepartFormModal.propTypes = {
    refreshTree: PropTypes.func.isRequired,
};

export default DepartFormModal;
