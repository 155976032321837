import axios from 'axios';
import {notification} from 'antd';
import _ from 'lodash';
import {stringify} from 'qs';

import {API_ROOT, history} from '@/env';
import {getTokens, setTokens} from './TokenStore';

const apiInstance = axios.create({
    baseURL: API_ROOT,
    headers: {'content-type': 'application/json'},
    // 取消默认的 validateStatus 实现，自定义出错验证
    validateStatus: () => true,

    // axios 对于数组类型的参数会默认在参数名后面加上 []，这个其实是不合规范的
    // 所以这儿 使用 qs.stringify 重新实现了参数序列化方法，也就是不再出现 []
    paramsSerializer: params => {
        return stringify(params, {arrayFormat: 'repeat'});
    },
});

// 下述 code 是后台预定义使用的，除此之外不做考虑
const codeMessage = {
    200: '服务器成功返回请求的数据。',
    // 201: '新建或修改数据成功。',
    // 202: '一个请求已经进入后台排队（异步任务）。',
    // 204: '删除数据成功。',

    // com.someok.spring.core.rest.ResultStatus#BAD_REQUEST
    400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',

    // com.someok.spring.core.rest.ResultStatus#UNAUTHORIZED
    401: '用户没有权限（令牌、用户名、密码错误）。',

    // com.someok.spring.core.rest.ResultStatus#FORBIDDEN
    403: '用户得到授权，但是访问是被禁止的。',

    // com.someok.spring.core.rest.ResultStatus#NOT_FOUND
    404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
    // 406: '请求的格式不可得。',
    // 410: '请求的资源被永久删除，且不会再得到的。',

    // com.someok.spring.core.rest.ResultStatus#UNPROCESSABLE_ENTITY
    // 这个在配合表单时可以进一步对表单字段进行验证
    422: '当创建一个对象时，发生一个验证错误。',

    // com.someok.spring.core.rest.ResultStatus#INTERNAL_SERVER_ERROR
    500: '服务器发生错误，请检查服务器。',
    // 502: '网关错误。',
    // 503: '服务不可用，服务器暂时过载或维护。',
    // 504: '网关超时。',
};

function log(msg, ...extra) {
    console.log(
        `%c[request] %c${msg}`,
        'color: #eb2f96; font-weight: bold',
        'color: #1890ff',
        ...extra
    );
}

function checkStatus({showErrorNotification = true}) {
    return response => {
        log('response', response);

        const resdata = response.data || {};
        const {status} = resdata;
        if (status === 200) {
            return resdata;
        }

        const message =
            resdata.message || codeMessage[response.status] || response.statusText || '未知错误';

        // 422 表示字段错误，需要调用方特别处理
        if (showErrorNotification && ![401, 422].includes(status)) {
            notification.error({
                message: `错误: ${status}`,
                description: message,
            });
        }

        /**
         * 返回 422 错误的时候，其实返回的是字段错误说明，在 data 中是个 {field, message} 组成的数组（一般只有一个）
         *
         * 所以将这个处理方法置入 Error 对象中，方便调用方在 catch 中处理。
         *
         * 处理方式是将错误信息显示在对应字段下方。
         *
         * 注意，传入参数为 antd Form 对象。
         *
         * 例如：
         * .catch(e => {
         *       e.catch422Error && e.catch422Error(form);
         *   });
         * @param form
         */
        function catch422Error(form) {
            const data = _.get(response, 'data.data');
            if (Array.isArray(data)) {
                const fieldData = [];
                data.forEach(({field, message = '未知错误'}) => {
                    if (field) {
                        fieldData.push({name: field, errors: [message]});
                    }
                });
                if (form && form.setFields && !_.isEmpty(fieldData)) {
                    form.setFields(fieldData);
                }
            }
        }

        const err = new Error(message);
        err.status = status;
        err.response = response;
        if (status === 422) {
            err.catch422Error = catch422Error;
        }
        throw err;
    };
}

function gotoLogin() {
    history.push('/login');
}

async function refreshToken() {
    log('fetch refresh_token');

    const {refresh_token} = getTokens() || {};
    if (!refresh_token) {
        log('local refresh_token not exist');
        return false;
    }

    try {
        const response = await apiInstance.request({
            url: '/auth/refresh-token',
            params: {token: refresh_token},
        });

        const {status} = response;
        if (status === 401) {
            log('refresh_token is outdated');
            return false;
        }

        const json = await response.data;
        const {data = {}} = json || {};
        if (!data.access_token || !data.refresh_token) {
            notification.error({
                message: `错误: ${status}`,
                description: 'Token 读取出错',
            });
            return false;
        }

        log('save new tokens', data);
        setTokens(data);

        return true;
    } catch (e) {
        log('refresh_token fetch failure', e);
        return false;
    }
}

async function request(url, method, config) {
    log('request data', `${method.toUpperCase()}`, url, config);

    // 是否将出现的异常抛出到程序中自己处理
    // 默认是 false
    /* eslint-disable */
    const {custom: {noAuth = false, throwError = false, showErrorNotification = true} = {}} =
        config || {};

    /* eslint-enable */

    function doRequest() {
        let req;
        if (noAuth) {
            req = apiInstance.request({
                ...config,
                method,
                url,
            });
        } else {
            const {access_token} = getTokens() || {};
            const authHeader = access_token
                ? {
                      headers: {
                          Authorization: `Bearer ${access_token}`,
                      },
                  }
                : {};

            req = apiInstance.request({
                ...config,
                ...authHeader,
                method,
                url,
            });
        }

        return req;
    }

    try {
        let response = await doRequest();

        // access_token 无效或过期，使用 refresh_token 刷新 tokens
        if (response.status === 401) {
            const refresh = await refreshToken();

            // 刷新成功后再次执行 ajax 请求
            if (refresh) {
                response = await doRequest();
            } else {
                // 刷新失败则跳转到登录页
                // 为防止调用方出错，此处返回个默认值
                gotoLogin();
                return {
                    status: 401,
                    message: 'Unauthorized',
                    data: null,
                    success: false,
                };
            }
        }

        const result = await checkStatus({showErrorNotification})(response);

        log('over............');
        console.log('\n');

        return result;
    } catch (e) {
        log('has error', e);
        log('status', e.status);

        // 422 表示提交的字段验证失败，需要调用方自行处理
        if (e.status === 422) {
            throw e;
        }

        if (throwError) {
            throw e;
        } else {
            const status = e.status;
            if (status === 401) {
                // 跳转到登录页
                gotoLogin();
            }
            return e;
        }
    }
}

/*
 ==================================================
 参数说明：

 get、delete:

 如果需要传递参数，只允许两种方式：
 1、在 url 后面用 ? & 拼接参数
 2、在 config 里面是有 params: {a: 1, b: 2}
 两种方式起到的效果其实一样。

 post、put:

 通过 data: {} 在 body 中传递 json 数据

 ==================================================
*/

export function getJson(url, config) {
    return request(url, 'get', config);
}

export function deleteJson(url, config) {
    return request(url, 'delete', config);
}

export function postJson(url, config) {
    return request(url, 'post', config);
}

export function putJson(url, config) {
    return request(url, 'put', config);
}

export function patchJson(url, config) {
    return request(url, 'patch', config);
}

export function upload({category, itemId, files, field = 'file'}) {
    if (_.isEmpty(files)) {
        notification.error({
            message: '请选择需要上传的附件',
        });
        return Promise.reject(new Error('附件不能为空'));
    }

    if (!category) {
        return Promise.reject(new Error('[category] 参数不能为空'));
    }
    if (!itemId) {
        return Promise.reject(new Error('[itemId] 参数不能为空'));
    }

    const formData = new FormData();
    formData.append('category', category);
    formData.append('itemId', itemId);

    files.forEach(file => {
        formData.append(field, file);
    });

    return request('/attach', 'post', {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
        data: formData,
    });
}
