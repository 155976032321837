import React, {useEffect, useState} from 'react';
import {Menu} from 'antd';
import {Link, matchPath, useLocation} from 'react-router-dom';
import _ from 'lodash';

import {MenuConfig} from './AppRoutes';
import {useAppStore} from './context';

export default function AppSiderMenu() {
    const [{isMgr, extAttr}] = useAppStore();
    const auditMgr = extAttr?.userAttr?.auditMgr ?? false;

    const menus = MenuConfig.filter(({children}) => _.isEmpty(children));

    MenuConfig.forEach(({children}) => {
        if (children && children.length > 0) {
            menus.push(...children);
        }
    });

    const [selMenuKey, setSelMenuKey] = useState(MenuConfig[0].path);
    const location = useLocation();
    const [{appId}] = useAppStore();

    useEffect(() => {
        // 初始进入或刷新页面时预先判断下需要高亮哪个菜单
        // AdminRouteConfig 需要去掉收尾两个配置项
        menus.forEach(config => {
            const m = matchPath(location.pathname, config);
            if (m && m.path) {
                setSelMenuKey(m.path);
            }
        });
    }, [location.pathname, menus]);

    if (appId == null) {
        return null;
    }

    const defaultOpenKeys = MenuConfig.filter(({children}) => children && children.length > 0).map(
        ({path, key}) => path || key
    );

    return (
        <Menu selectedKeys={[selMenuKey]} mode="inline" defaultOpenKeys={defaultOpenKeys}>
            {MenuConfig.map(({label, key, path, icon, isAppMgr, isAuditMgr, children}) => {
                if (isAppMgr && !isMgr) {
                    return null;
                }

                if (isAuditMgr && !auditMgr) {
                    return null;
                }

                const hasChildren = !_.isEmpty(children);
                if (hasChildren) {
                    return (
                        <Menu.SubMenu key={key || path} icon={icon} title={label}>
                            {children.map(item => {
                                if (item.isAppMgr && !isMgr) {
                                    return null;
                                }

                                const to = item.path.replace(':appId', appId);
                                return (
                                    <Menu.Item key={item.path} icon={item.icon}>
                                        <Link to={to}>{item.label}</Link>
                                    </Menu.Item>
                                );
                            })}
                        </Menu.SubMenu>
                    );
                }

                if (!path) {
                    return null;
                }

                const to = path.replace(':appId', appId);

                return (
                    <Menu.Item key={path} icon={icon}>
                        <Link to={to}>{label}</Link>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
}
