import React, {useRef} from 'react';
import {Button, Modal, PageHeader, Table, Tag} from 'antd';
import _ from 'lodash';

import {DeleteOutlined, PlusOutlined} from '@/icons';
import SyncConfigFormModal from './SyncConfigFormModal';
import {useSyncConfig} from './SyncConfigContext';

SyncConfigDepart2TagMapper.propTypes = {};

function SyncConfigDepart2TagMapper(props) {
    const SyncConfigDepart2TagMapperRef = useRef();

    const {configs, saveConfig, deleteConfigsBySrcDepart, retry} = useSyncConfig();
    const {tag = []} = configs || {};

    // 将 tag 列表按照 srcDepart 分组，之后转换成列表格式
    let listBySrcDepart = [];
    if (!_.isEmpty(tag)) {
        const tagMap = _.groupBy(tag, 'srcDepart');
        let keys = Object.keys(tagMap);

        listBySrcDepart = keys.map(key => {
            const items = tagMap[key];
            return {srcDepart: key, items};
        });
    }

    // console.log('listBySrcDepart', listBySrcDepart);

    function onAddClick() {
        SyncConfigDepart2TagMapperRef.current.showModal();
    }

    async function onSave(values) {
        // console.log('onSave', values);
        const {tags, srcDepart} = values;
        const configs = tags.map(({destId, destName}) => ({srcDepart, destId, destName}));
        const {status} = await saveConfig('tag', configs);
        if (status === 200) {
            retry();
            return true;
        }

        return false;
    }

    function onDeleteClick(record) {
        return () => {
            Modal.confirm({
                title: '确认删除',
                content: `确认删除源部门为 [${record.srcDepart}] 的所有映射关系吗？`,
                okButtonProps: {danger: true},
                onOk: async () => {
                    const {status} = await deleteConfigsBySrcDepart(record.srcDepart);
                    if (status === 200) {
                        retry();
                    }
                },
            });
        };
    }

    const columns = [
        {
            dataIndex: 'srcDepart',
            title: '源部门',
            width: 300,
        },
        {
            dataIndex: 'items',
            title: '目标标签',
            // eslint-disable-next-line react/display-name
            render: (__, record) => {
                if (_.isEmpty(record?.items)) {
                    return null;
                }

                // record 为 列表，需将其转换为标签
                return record?.items?.map(({id, destName}) => (
                    <Tag key={id} color="#2db7f5">
                        {destName}
                    </Tag>
                ));
            },
        },
        {
            key: 'action',
            title: '操作',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (__, record) => {
                return (
                    <Button
                        size="small"
                        type="link"
                        icon={<DeleteOutlined />}
                        onClick={onDeleteClick(record)}
                    >
                        删除
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            <PageHeader
                title="部门 ↦ 标签映射"
                subTitle="所有符合条件的部门下人员均同步到对应标签下"
                extra={
                    <Button
                        type="primary"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={onAddClick}
                    >
                        新增
                    </Button>
                }
            >
                <Table
                    dataSource={listBySrcDepart}
                    columns={columns}
                    rowKey="srcDepart"
                    pagination={false}
                />
            </PageHeader>
            <SyncConfigFormModal
                ref={SyncConfigDepart2TagMapperRef}
                modalTitle="部门 ↦ 标签映射"
                showSrcDepart
                onSave={onSave}
            />
        </>
    );
}

export default SyncConfigDepart2TagMapper;
