import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import MediaType, {getFileMediaUrl} from '../MediaType';
import PreviewModal from '@/page/component/UploadWallModal/PreviewModal';
import FileMediaWrapper from './FileMediaWrapper';

export default function ImagePage({editable = true, enableDownload = true, onItemChecked}) {
    const previewRef = useRef();

    function onNameClick(record) {
        const {attachId, attachExt} = record;
        const url = getFileMediaUrl(attachId, attachExt);
        previewRef.current.showModal(url);
    }

    return (
        <>
            <FileMediaWrapper
                editable={editable}
                enableDownload={enableDownload}
                onItemChecked={onItemChecked}
                type={MediaType.IMAGE}
                switchVisible
                initialGrid
                onNameClick={onNameClick}
            />

            <PreviewModal ref={previewRef} />
        </>
    );
}

ImagePage.propTypes = {
    editable: PropTypes.bool,
    enableDownload: PropTypes.bool,
    onItemChecked: PropTypes.func,
};
