import {getJson, postJson, patchJson} from '@/util/ajaxUtils';
import axios from 'axios';

export function doLogin({username, password, captchaKey, captchaText}) {
    return postJson('/auth/login', {
        custom: {noAuth: true, throwError: true, showErrorNotification: false},
        params: {username, password, captchaKey, captchaText},
    });
}

export function doProfile() {
    return getJson('/auth/profile');
}

/**
 * 修改用户密码。
 *
 * @param data 格式为 {origPass, password}
 * @return {Promise<*>}
 */
export function doResetPassword(data) {
    return patchJson('/auth/profile/pass-reset', {data});
}

export function getCaptcha() {
    return getJson('/open/captcha', {custom: {noAuth: true}});
}

export function passResetRequest(params) {
    return postJson('/password-reset/request', {params, custom: {noAuth: true}});
}

export function passResetCheck(email, code) {
    return getJson('/password-reset/request-check', {
        params: {email, code},
        custom: {
            showErrorNotification: false,
            throwError: true,
            noAuth: true,
        },
    });
}

export function passReset(data) {
    return postJson('/password-reset/reset', {data, custom: {noAuth: true}});
}
export function getOidcUserinfo(accessToken) {
    console.log(process.env.NODE_ENV,'process.env.NODE_ENV')
    if(process.env.NODE_ENV == 'production'){
        return axios
        .get(
            `https://wcdl-portal.boehringer-ingelheim.cn/be-api/auth/getOidcUserinfo?token=${accessToken}`
        )
        .then(({data}) => data);
    } else {
        return axios
        .get(
            `https://wcdl-portal-test.boehringer-ingelheim.cn/be-api/auth/getOidcUserinfo?token=${accessToken}`
        )
        .then(({data}) => data);
    }
    
}

export function loginByEmail(email) {
    return postJson('/auth/login-by-email', {params:{email}});
}
