import React from 'react';

import {
    ContainerOutlined,
    FileTextOutlined,
    PaperClipOutlined,
    PictureOutlined,
    SoundOutlined,
    VideoCameraOutlined,
} from '@/icons';
import {getFileDownloadUrl, getFileUrl} from '@/page/component/attachUtils';

const MediaType = Object.freeze({
    NEWS: {
        key: 'news',
        label: '图文',
        path: '/apps/:appId/media/news',
        icon: <ContainerOutlined />,
        component: require('./pages/NewsPage').default,
        searchPlaceholder: '按标题、描述查询',
    },
    IMAGE: {
        key: 'image',
        label: '图片',
        path: '/apps/:appId/media/image',
        icon: <PictureOutlined />,
        component: require('./pages/ImagePage').default,
        searchPlaceholder: '按文件名查询',
        isFile: true,
        accept: '.png, .jpg, .jpeg',
        maxSize: 2097152, // 2MB
    },
    TEXT: {
        key: 'text',
        label: '文本',
        path: '/apps/:appId/media/text',
        icon: <FileTextOutlined />,
        component: require('./pages/TextPage').default,
        searchPlaceholder: '按文本查询',
    },
    VOICE: {
        key: 'voice',
        label: '语音',
        path: '/apps/:appId/media/voice',
        icon: <SoundOutlined />,
        component: require('./pages/VoicePage').default,
        searchPlaceholder: '按文件名查询',
        isFile: true,
        accept: '.mp3, .amr',
        maxSize: 2097152, // 2MB
    },
    VIDEO: {
        key: 'video',
        label: '视频',
        path: '/apps/:appId/media/video',
        icon: <VideoCameraOutlined />,
        component: require('./pages/VideoPage').default,
        searchPlaceholder: '按文件名查询',
        isFile: true,
        accept: '.mp4',
        maxSize: 10485760, // 10MB
    },
    FILE: {
        key: 'file',
        label: '文件',
        path: '/apps/:appId/media/file',
        icon: <PaperClipOutlined />,
        component: require('./pages/FilePage').default,
        searchPlaceholder: '按文件名查询',
        isFile: true,
        accept: '*',
        maxSize: 20971520, // 20MB
    },
});

export default MediaType;

export const mediaTypeValues = Object.values(MediaType);

export const getFileMediaUrl = (attachId, attachExt) => {
    return getFileUrl(attachId, attachExt);
};

export const getFileMediaDownloadUrl = attachId => {
    return getFileDownloadUrl(attachId);
};
