import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';

import {PlusOutlined} from '@/icons';
import MediaListView from '../MediaListView';
import MediaType from '../MediaType';
import TextFormModal from './text/TextFormModal';
import TextViewModal from './text/TextViewModal';

export default function TextPage({editable = true, onItemChecked}) {
    const listRef = useRef();
    const formModalRef = useRef();
    const viewModalRef = useRef();

    function onAddClick() {
        formModalRef.current.showModal();
    }

    function onSaveAfter() {
        listRef.current.reload();
    }

    function onNameClick(record) {
        viewModalRef.current.showModal(record.content);
    }

    function onEditClick(record) {
        const {id, content} = record;
        formModalRef.current.showModal(id, content);
    }

    const columnOptions = {onNameClick};
    const mediaType = MediaType.TEXT;

    return (
        <MediaListView
            ref={listRef}
            editable={editable}
            onItemChecked={onItemChecked}
            type={mediaType}
            columnKeys={['title', 'creatorName', 'createTime']}
            columnOptions={columnOptions}
            columnActionProps={{onEditClick}}
        >
            {editable && (
                <Button icon={<PlusOutlined />} type="primary" onClick={onAddClick}>
                    添加{mediaType.label}
                </Button>
            )}

            <TextFormModal ref={formModalRef} onSaveAfter={onSaveAfter} />
            <TextViewModal ref={viewModalRef} />
        </MediaListView>
    );
}

TextPage.propTypes = {
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
};
