import React from 'react';
import {Tag} from 'antd';
import PropTypes from 'prop-types';

import {EditOutlined} from '@/icons';

export default function EditTag({showTag = true, children, ...rest}) {
    if (!showTag) {
        return null;
    }

    return (
        <Tag color="#2db7f5" icon={<EditOutlined />} style={{cursor: 'pointer'}} {...rest}>
            {children}
        </Tag>
    );
}

EditTag.propTypes = {
    showTag: PropTypes.bool,
    children: PropTypes.node,
};
