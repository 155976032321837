import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Divider, Form, Input} from 'antd';
import _ from 'lodash';

import VideoUpload from './VideoUpload';
import {videoDataPropTypes} from './propTypes';

import titleStyles from '@/page/component/NewsFormModal/NewsForm/index.module.less';

export default function VideoForm({category, videoData, onChange}) {
    const formRef = useRef();

    function onFinish(values) {
        console.log('Received values of form: ', values);
    }

    function onValuesChange(changedValues, allValues) {
        onChange && onChange({...videoData, ...allValues});
    }

    function onUploadChange(vidoe) {
        // console.log('onUploadChange', vidoe, videoData);
        let title = _.get(videoData, 'title');
        if (!title) {
            title = _.get(vidoe, 'title');
            formRef.current.setFieldsValue({title});
        }
        onChange && onChange({...videoData, ...vidoe, title});
    }

    return (
        <Form
            ref={formRef}
            onFinish={onFinish}
            onValuesChange={onValuesChange}
            layout="vertical"
            initialValues={videoData}
        >
            <Form.Item hidden name="id">
                <Input />
            </Form.Item>

            <Form.Item name="title">
                <Input
                    className={titleStyles.titleInput}
                    autoFocus
                    placeholder="在此输入标题"
                    maxLength={30}
                    size="large"
                />
            </Form.Item>

            <Divider />

            <VideoUpload category={category} videoData={videoData} onChange={onUploadChange} />

            <Divider />

            <Form.Item
                name="description"
                label="摘要"
                rules={[
                    {
                        max: 50,
                    },
                ]}
            >
                <Input.TextArea placeholder="摘要（选填）" maxLength={50} rows={2} />
            </Form.Item>
        </Form>
    );
}

VideoForm.propTypes = {
    category: PropTypes.oneOf(['media', 'message']),
    videoData: videoDataPropTypes,
    onChange: PropTypes.func,
};
