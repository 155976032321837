import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Form, Input, Modal} from 'antd';

import {updateAppCallback} from '@/api/apps';

const AppCallbackFormModal = forwardRef(({appId, reloadApp}, ref) => {
    const [visible, setVisible] = useState(false);
    const formRef = useRef();

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        updateAppCallback(appId, values).then(() => {
            reloadApp();
            closeModal();
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="修改应用回调信息"
            onCancel={closeModal}
            onOk={onOk}
        >
            <Alert
                message="提醒"
                description={
                    <>
                        <p>
                            下述配置可在企业微信控制台对应应用的「接收消息」下「API接收消息」中获得
                        </p>
                        <p>注：需先配置此处内容，才可以使得回调 URL 起作用</p>
                    </>
                }
                type="warning"
                showIcon
                closable
                style={{marginBottom: 24}}
            />

            <Form ref={formRef} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="callbackToken"
                    label="Token"
                    rules={[
                        {
                            required: true,
                            max: 500,
                        },
                    ]}
                >
                    <Input placeholder="Token" />
                </Form.Item>

                <Form.Item
                    name="callbackSecretKey"
                    label="EncodingAESKey"
                    rules={[
                        {
                            required: true,
                            max: 500,
                        },
                    ]}
                >
                    <Input placeholder="EncodingAESKey" />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

AppCallbackFormModal.displayName = 'AppCallbackFormModal';

AppCallbackFormModal.propTypes = {
    appId: PropTypes.string.isRequired,
    reloadApp: PropTypes.func.isRequired,
};

export default AppCallbackFormModal;
