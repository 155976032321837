import React, {useState, useRef} from 'react';
import {useParams} from 'react-router-dom';

import MsgHistory from './MsgHistory';
import {getReceivedMsgHistoryUrl} from '@/api/message';
import SendSearchForm from './search/SendSearchForm';
import ReceivedMsgDrawer from './ReceivedMsgDrawer';
import useTitle from '@/hook/useTitle';

export default function MsgHistoryReceivedPage() {
    useTitle('已接收消息');

    const msgDrawerRef = useRef();
    const {appId} = useParams();
    const [searchFormData, setSearchFormData] = useState(null);

    function onTitleClick(record) {
        return () => {
            console.log(record);
            msgDrawerRef.current.show(record);
        };
    }

    return (
        <>
            <MsgHistory
                title="已接收消息"
                onTitleClick={onTitleClick}
                url={getReceivedMsgHistoryUrl(appId)}
                columnKeys={['status', 'msgType', 'title', 'sender', 'createTime']}
                searchForm={<SendSearchForm showSendFrom={false} onSearch={setSearchFormData} />}
                searchFormData={searchFormData}
            />
            <ReceivedMsgDrawer ref={msgDrawerRef} appId={appId} />
        </>
    );
}
