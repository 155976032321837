import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './LoginContainer.module.less';

const LoginContainer = ({title, children}) => {
    return (
        <div className={classNames(styles.container, styles.fixed)}>
            <div className={classNames(styles.bottomBg, styles.fixed)}>
                <div className={styles.logo}>
                    <h1>{title}</h1>
                </div>
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    );
};

LoginContainer.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
};

export default LoginContainer;
