const msgTypes = {
    text: {
        label: '文本',
    },
    news: {
        label: '图文',
    },
    news_link: {
        label: '链接图文',
    },
    voice: {
        label: '语音',
    },
    video: {
        label: '视频',
    },
    image: {
        label: '图片',
    },
    file: {
        label: '文件',
    },
};

export default msgTypes;
