import React from 'react';
import PropTypes from 'prop-types';
import {Tag, Typography} from 'antd';
import _ from 'lodash';

import {DepartTag, TagTag, UserTag, TagGroup} from '@/component';

import {formatLong} from '@/util/dateUtils';

import styles from './MsgMeta.module.less';

export default function MsgMeta({message}) {
    // console.log('message', message);
    const {toAll, tos, safe, createTime, userName, msgDirection, fixedTimeMsg, fixedTime} = message;

    function renderTagItem(Comp) {
        // eslint-disable-next-line react/display-name
        return item => {
            const {msgToId, msgToName} = item;

            return <Comp key={msgToId}>{msgToName}</Comp>;
        };
    }

    function renderTos() {
        const toUsers = tos.filter(to => to.msgToType === 'touser');
        const toDeparts = tos.filter(to => to.msgToType === 'toparty');
        const toTags = tos.filter(to => to.msgToType === 'totag');

        return (
            <div className={styles.tos}>
                {!_.isEmpty(toDeparts) && (
                    <div>
                        <TagGroup renderItem={renderTagItem(DepartTag)} data={toDeparts} />
                    </div>
                )}
                {!_.isEmpty(toUsers) && (
                    <div>
                        <TagGroup renderItem={renderTagItem(UserTag)} data={toUsers} />
                    </div>
                )}
                {!_.isEmpty(toTags) && (
                    <div>
                        <TagGroup renderItem={renderTagItem(TagTag)} data={toTags} />
                    </div>
                )}
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.item}>
                <div className={styles.title}>发送人：</div>
                <Tag>{userName}</Tag>
            </div>

            <div className={styles.item}>
                <div className={styles.title}>创建时间：</div>
                <Typography.Text type="secondary">{formatLong(createTime)}</Typography.Text>
            </div>

            {fixedTimeMsg && (
                <>
                    <div className={styles.item}>
                        <div className={styles.title}>发送时间：</div>
                        <Typography.Text type="secondary">{formatLong(fixedTime)}</Typography.Text>
                    </div>
                </>
            )}

            {msgDirection === 'send' && (
                <>
                    <div className={styles.item}>
                        <div className={styles.title}>分享配置：</div>
                        {safe === 0 ? (
                            <Tag color="#2db7f5">可分享</Tag>
                        ) : (
                            <Tag color="#f50">不可分享</Tag>
                        )}
                    </div>
                    <div className={styles.item}>
                        <div className={styles.title}>发送范围：</div>
                        {toAll ? '所有人' : renderTos()}
                    </div>
                </>
            )}
        </div>
    );
}

MsgMeta.propTypes = {
    message: PropTypes.object,
};
