import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import {SoundOutlined} from '@/icons';
import MediaType from '@/page/app/MediaPage/MediaType';
import {getFileUrl} from '@/page/component/attachUtils';
import Player from '@/page/component/Player';
import CommonFileContent from './comp/CommonFileContent';
import CommonFileView from './comp/CommonFileView';
import {valuePropTypes} from './propTypes';

export default function VoiceContent({value, onChange}) {
    const playerRef = useRef();

    return (
        <>
            <CommonFileContent
                type={MediaType.VOICE}
                value={value}
                onChange={onChange}
                buttonTitle="添加语音"
                previewStyle={{maxWidth: 500, width: 500}}
                description={
                    <>
                        <p>格式支持: {MediaType.VOICE.accept}</p>
                        <p>文件大小不超过 {filesize(MediaType.VOICE.maxSize)}</p>
                        <p>语音时长不超过1分钟</p>
                    </>
                }
            >
                {({title, attachId, attachExt, attachSize}) => (
                    <>
                        <CommonFileView
                            icon={<SoundOutlined />}
                            title={title}
                            attachId={attachId}
                            attachExt={attachExt}
                            attachSize={attachSize}
                            onClick={() =>
                                playerRef.current.showPlayer(
                                    'audio',
                                    title,
                                    getFileUrl(attachId, attachExt)
                                )
                            }
                        />
                    </>
                )}
            </CommonFileContent>
            <Player ref={playerRef} />
        </>
    );
}

VoiceContent.propTypes = {
    value: valuePropTypes,
    onChange: PropTypes.func.isRequired,
};
