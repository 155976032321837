import React from 'react';
import PropTypes from 'prop-types';
import {Menu, Modal} from 'antd';

import {ContextMenuContainer} from '@/component';
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PicCenterOutlined,
    PicRightOutlined,
    UpOutlined,
} from '@/icons';
import {deleteDepart, switchOrder} from '@/api/departs';

import {isTopNode} from '@/util/treeUtils';

export default function DepartsTreeMenu({
    enableAddRoot,
    refreshTree,
    contextMenuAttrs,
    menuContainerRef,
    showFormModal,
    closeFormModal,
}) {
    const {depart, extraAttrs} = contextMenuAttrs || {};
    const {id, parentId, name} = depart || {};
    const {hasNext, hasPrev, nextId, prevId} = extraAttrs || {};

    const topNode = isTopNode(parentId);

    function switchNodeOrder(id1, id2) {
        switchOrder(id1, id2).then(data => {
            console.log(data);
            refreshTree();
        });
    }

    function onMenuClick({key}) {
        // console.log(key);
        // console.log('contextMenuAttrs', contextMenuAttrs);

        if (key === 'addSibing') {
            showFormModal({parentId});
        } else if (key === 'addSubNode') {
            showFormModal({parentId: id});
        } else if (key === 'edit') {
            showFormModal(depart);
        } else if (key === 'delete') {
            Modal.confirm({
                title: '提醒',
                content: `确定删除部门「${name}」？`,
                okButtonProps: {danger: true},
                onOk: () => {
                    deleteDepart(id).then(data => {
                        console.log(data);
                        refreshTree();
                        closeFormModal();
                    });
                },
            });
        } else if (key === 'movePrev') {
            switchNodeOrder(id, prevId);
        } else if (key === 'moveNext') {
            switchNodeOrder(id, nextId);
        }
    }

    return (
        <ContextMenuContainer ref={menuContainerRef}>
            <Menu onClick={onMenuClick} selectable={false}>
                <Menu.Item
                    key="addSibing"
                    icon={<PicCenterOutlined />}
                    disabled={topNode && !enableAddRoot}
                >
                    添加同级部门
                </Menu.Item>

                {id && [
                    <Menu.Item key="addSubNode" icon={<PicRightOutlined />}>
                        添加下级部门
                    </Menu.Item>,

                    <Menu.Divider key="d2" />,

                    <Menu.Item key="movePrev" icon={<UpOutlined />} disabled={!hasPrev}>
                        上移
                    </Menu.Item>,
                    <Menu.Item key="moveNext" icon={<DownOutlined />} disabled={!hasNext}>
                        下移
                    </Menu.Item>,
                    <Menu.Divider key="d3" />,

                    <Menu.Item key="edit" icon={<EditOutlined />}>
                        修改
                    </Menu.Item>,

                    <Menu.Item key="delete" icon={<DeleteOutlined />}>
                        删除
                    </Menu.Item>,
                ]}
            </Menu>
        </ContextMenuContainer>
    );
}

DepartsTreeMenu.propTypes = {
    // 是否允许添加顶级节点，如果存在根节点则拒绝操作
    enableAddRoot: PropTypes.bool,
    refreshTree: PropTypes.func.isRequired,
    contextMenuAttrs: PropTypes.shape({
        id: PropTypes.string,
        parentId: PropTypes.string,
    }),

    menuContainerRef: PropTypes.object,

    showFormModal: PropTypes.func.isRequired,
    closeFormModal: PropTypes.func.isRequired,
};
