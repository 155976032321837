import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Item.module.less';

export default function Item({title, className, style, children}) {
    return (
        <div className={classNames(styles.container, className)} style={style}>
            <div className={styles.title}>{title}:</div>
            <div className={styles.content}>{children}</div>
        </div>
    );
}

Item.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
