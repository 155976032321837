import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Card, Empty, Pagination, Spin, Typography} from 'antd';
import _ from 'lodash';
import classNames from 'classnames';

import {getFileMediaUrl} from '../MediaType';
import {gridAction} from './ActionButtons';
import MediaTag from './MediaTag';
import {CheckOutlined} from '@/icons';

import styles from './MediaGrid.module.less';
import {useAppStore} from '@/page/app/context';
import {mediaTypeProps} from '../propTypes';

export default function MediaGrid({
    editable = true,
    type,
    onItemChecked,
    loading,
    mediaRecords,
    pagination,
    reload,
    columnOptions = {},
    columnActionProps,
}) {
    // console.log('type', type);
    const [{isMgr, userId}] = useAppStore();

    if (_.isEmpty(mediaRecords)) {
        return <Empty />;
    }

    const {onNameClick} = columnOptions;

    function onViewClick(record) {
        return e => {
            e.preventDefault();

            onNameClick && onNameClick(record);
        };
    }

    function renderCover(record) {
        let isVideo = type.key === 'video';
        let cover;
        if (isVideo) {
            if (record.thumbAttachId) {
                const url = getFileMediaUrl(record.thumbAttachId, record.thumbAttachExt);
                cover = <img alt={record.title} src={url} className={styles.cover} />;
            } else {
                cover = (
                    <div
                        className={styles.cover}
                        style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}
                    >
                        <Avatar size={64} icon={type.icon} />
                    </div>
                );
            }
        } else {
            const url = getFileMediaUrl(
                record.thumbAttachId ?? record.attachId,
                record.thumbAttachExt ?? record.attachExt
            );
            cover = <img alt={record.title} src={url} className={styles.cover} />;
        }
        return (
            <div className={styles.imgWrap} onClick={onViewClick(record)}>
                {cover}
            </div>
        );
    }

    function renderTitle(record) {
        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" onClick={onViewClick(record)} style={{color: 'rgba(0, 0, 0, 0.85)'}}>
                {record.title}
            </a>
        );
    }

    function renderTag(record) {
        const {newsNum, mediaType} = record;

        if (mediaType === 'news' && newsNum > 1) {
            return <MediaTag className={styles.newsTag}>图文列表 [{newsNum}]</MediaTag>;
        }

        if (mediaType === 'news_link') {
            return <MediaTag className={styles.newsTag}>外链 [{newsNum}]</MediaTag>;
        }

        return null;
    }

    function renderChecked(record) {
        if (!onItemChecked) {
            return null;
        }

        return (
            <div className={styles.checked} onClick={() => onItemChecked(record)}>
                <CheckOutlined className={styles.checkedIcon} />
            </div>
        );
    }

    return (
        <Spin spinning={loading}>
            <div className={styles.container}>
                {mediaRecords.map(record => {
                    const {mediaType} = record;
                    const actions = gridAction({
                        editable,
                        reload,
                        record,
                        isMgr,
                        userId,
                        ...columnActionProps,
                    });

                    const isNews = mediaType.startsWith('news');
                    return (
                        <Card
                            key={record.id}
                            className={styles.card}
                            hoverable
                            cover={renderCover(record)}
                            actions={actions}
                        >
                            <Card.Meta
                                className={classNames({[styles.cardMeta]: isNews})}
                                title={renderTitle(record)}
                                description={
                                    record.description && (
                                        <Typography.Paragraph ellipsis={{rows: 2}}>
                                            {record.description}
                                        </Typography.Paragraph>
                                    )
                                }
                            />
                            {renderTag(record)}
                            {renderChecked(record)}
                        </Card>
                    );
                })}
            </div>

            <div className={styles.pagination}>
                <Pagination {...pagination} />
            </div>
        </Spin>
    );
}

MediaGrid.propTypes = {
    ...mediaTypeProps,
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
    loading: PropTypes.bool,
    mediaRecords: PropTypes.array,
    pagination: PropTypes.object,
    reload: PropTypes.func,
    columnOptions: PropTypes.object,
    columnActionProps: PropTypes.object,
};
