import {deleteJson, getJson, patchJson, postJson} from '@/util/ajaxUtils';

export function getRoles(scope = 'global', scopeItemId, fallbackOnApp = false) {
    return getJson('/roles', {params: {scope, scopeItemId, fallbackOnApp}});
}

export function addRole(role) {
    return postJson('/roles', {data: role});
}

export function updateRole(roleId, role) {
    return patchJson(`/roles/${roleId}`, {data: role});
}

export function deleteRole(roleId) {
    return deleteJson(`/roles/${roleId}`);
}

export function addRoleUsers(roleId, userIds) {
    return postJson(`/roles/${roleId}/users`, {data: userIds});
}

export function getRoleAuthorities(roleId) {
    return getJson(`/roles/${roleId}/authorities`);
}

export function updateRoleAuthorities(roleId, authorities) {
    return postJson(`/roles/${roleId}/authorities`, {data: authorities});
}

export function getPermissions(scope) {
    return getJson('/permissions/auth-groups', {params: {scope}});
}

export function getUserPermissions(userId, scope, scopeItemId) {
    return getJson(`/users/${userId}/permissions`, {params: {scope, scopeItemId}});
}

export function updateUserRoles(userId, roleIds) {
    return postJson(`/users/${userId}/roles`, {data: roleIds});
}

export function deleteUserRoles(userId, roleIds) {
    return deleteJson(`/users/${userId}/roles`, {data: roleIds});
}
