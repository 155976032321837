import React, {forwardRef, useImperativeHandle, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {DatePicker, Modal, Space, TimePicker, message, Typography} from 'antd';
import dayjs from 'dayjs';
import IsToday from 'dayjs/plugin/isToday';
import IsTomorrow from 'dayjs/plugin/isTomorrow';

import {isPromise} from '@/util/PromiseUtils';

dayjs.extend(IsToday);
dayjs.extend(IsTomorrow);

const FixedTimeModal = forwardRef(({onChange}, ref) => {
    // 记录是否需要审核
    const needAuditRef = useRef(false);

    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    // 默认显示 1 小时后时间
    const nextHourTime = getNextHourTime();

    const [date, setDate] = useState(nextHourTime);
    const [time, setTime] = useState(nextHourTime);

    function getNextHourTime() {
        return dayjs().add(1, 'hour');
    }

    function showModal(needAudit = false, timestamp) {
        needAuditRef.current = needAudit;

        setVisible(true);
        setLoading(false);

        let initTime;
        if (timestamp) {
            initTime = dayjs(timestamp);
        } else {
            initTime = getNextHourTime();
        }
        setDate(initTime);
        setTime(initTime);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function displayDateTime() {
        console.log('displayDateTime', date);
        const dateStr = date.isToday()
            ? '今天'
            : date.isTomorrow()
            ? '明天'
            : date.format('M月D日');
        return dateStr + ' ' + time.format('HH:mm');
    }

    /**
     * 只能选择当前日期之后一个月范围
     *
     * @param currentDate
     * @return {*}
     */
    function disabledDate(currentDate) {
        return currentDate.isBefore(dayjs(), 'day') || currentDate.isAfter(dayjs().add(1, 'month'));
    }

    function onOk() {
        const dateTimeStr = date.format('YYYY-MM-DD') + ' ' + time.format('HH:mm');
        const dateTime = dayjs(dateTimeStr, 'YYYY-MM-DD HH:mm');
        console.log(dateTime);
        if (dateTime.isBefore(dayjs())) {
            message.error(`选择的时间 [${dateTimeStr}] 早于当前时间`);
            return;
        }

        console.log('ok', dateTime.valueOf());
        setLoading(true);
        const result = onChange(dateTime.valueOf());
        if (isPromise(result)) {
            result.catch(e => {
                console.log(e);
                setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }

    return (
        <Modal
            destroyOnClose
            title="定时发送"
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            okText={needAuditRef.current ? '发送审核': '发送'}
            okButtonProps={{loading, danger: needAuditRef.current}}
            cancelButtonProps={{danger: needAuditRef.current}}
        >
            <div>选择定时发送时间：</div>
            <Space style={{margin: '24px 0'}}>
                <DatePicker
                    allowClear={false}
                    disabledDate={disabledDate}
                    value={date}
                    onChange={setDate}
                />
                <TimePicker allowClear={false} format="HH:mm" value={time} onChange={setTime} />
            </Space>
            <div>
                <Typography.Text type="secondary">
                    该消息将于
                    <Typography.Text style={{margin: '0 8px'}}>{displayDateTime()}</Typography.Text>
                    发出
                </Typography.Text>
            </div>
        </Modal>
    );
});

FixedTimeModal.displayName = 'FixedTimeModal';

FixedTimeModal.propTypes = {
    okText: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

export default FixedTimeModal;
