import {useAsyncRetry} from 'react-use';

import {getDeparts, getUserLinks} from '@/api/departs';

export function useDepartTree(fromWecom = false) {
    return useAsyncRetry(async () => {
        return await getDeparts(fromWecom);
    }, []);
}

export function useUserDepartLinks(userId) {
    return useAsyncRetry(async () => {
        if (!userId) {
            return {};
        }

        return await getUserLinks(userId);
    }, [userId]);
}
