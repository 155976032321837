import {useAsyncRetry} from 'react-use';

import {getRoleAuthorities, getRoles, getPermissions} from '@/api/roles';

export function useRoleList(scope, scopeItemId, fallbackOnApp = false) {
    return useAsyncRetry(async () => {
        return await getRoles(scope, scopeItemId, fallbackOnApp);
    }, []);
}

export function useRoleAuthorities(role) {
    return useAsyncRetry(async () => {
        if (!role) {
            return null;
        }
        return await getRoleAuthorities(role.id);
    }, [role]);
}

export function usePermissions(scope) {
    return useAsyncRetry(async () => {
        return await getPermissions(scope);
    }, []);
}
