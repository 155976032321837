import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import classNames from 'classnames';

import styles from './ColorfulList.module.less';

/**
 * 对于 ant design List 的封装，增加 item hover 背景色和点击选择时背景色变化
 */
export default function ColorfulList({
    renderItem,
    onItemClick,
    getItemId,
    parentSelItemId,
    enablePrentSelState = false,
    itemContainerClassName,
    itemContainerStyle,
    ...rest
}) {
    const [selItemId, setSelItemId] = useState(null);

    function getId(item, index) {
        return getItemId ? getItemId(item, index) : item['id'];
    }

    function onListItemClick(item, index) {
        return () => {
            if (!enablePrentSelState) {
                const id = getId(item, index);
                setSelItemId(id);
            }
            onItemClick && onItemClick(item, index);
        };
    }

    function renderListItem(item, index) {
        const id = getId(item, index);

        return (
            <div
                className={classNames(
                    styles.item,
                    {
                        [styles.itemSelected]: enablePrentSelState
                            ? parentSelItemId === id
                            : selItemId === id,
                    },
                    itemContainerClassName
                )}
                style={itemContainerStyle}
                onClick={onListItemClick(item, index)}
            >
                {renderItem(item, index)}
            </div>
        );
    }

    return <List renderItem={renderListItem} {...rest} />;
}

ColorfulList.propTypes = {
    renderItem: PropTypes.func.isRequired,
    onItemClick: PropTypes.func,
    getItemId: PropTypes.func,

    /**
     * 如果多个列表一起用，这时候每个列表的点选状态显然需要外部传入，此属性用于控制此种效果
     */
    enablePrentSelState: PropTypes.bool,

    /**
     * 外部传入的已选条目 id
     */
    parentSelItemId: PropTypes.string,

    itemContainerClassName: PropTypes.string,
    itemContainerStyle: PropTypes.object,
};
