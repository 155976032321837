import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TwoColumnLayout.module.less';

export default function PanelHeader({className, style, icon, title, subTitle, children}) {
    return (
        <div className={classNames(styles.panelTitleBar, className)} style={style}>
            <div className={styles.panelTitle}>
                {icon && <div className={styles.panelTitleIcon}>{icon}</div>}
                {title}
                {subTitle && <div className={styles.subTitle}>{subTitle}</div>}
            </div>

            <div>{children}</div>
        </div>
    );
}

PanelHeader.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    icon: PropTypes.node,
    title: PropTypes.node,
    subTitle: PropTypes.node,
    children: PropTypes.node,
};
