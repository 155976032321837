import React, {useRef, useState} from 'react';
import {Button, Empty} from 'antd';
import _ from 'lodash';

import {PageLoading, TwoColumnLayout} from '@/component';
import {AppstoreAddOutlined, PlusOutlined} from '@/icons';

import {useApps} from './useAppsService';

import AppList from './AppList';
import AppInfo from './AppInfo';
import WecomAppFormModal from './WecomAppFormModal';
import useTitle from '@/hook/useTitle';
import {useEditAuthService} from '@/page/admin/adminService';

export default function AppsPage() {
    useTitle('应用管理 - 系统管理');

    const wecomAppFormModalRef = useRef();

    const editable = useEditAuthService(['global:app:w']);

    const [selApp, setSelApp] = useState({});
    const {loading, value, retry} = useApps();
    const appList = _.get(value, 'data');

    function onAppSelect(app) {
        setSelApp(app);
    }

    function onAddAppClick() {
        wecomAppFormModalRef.current.showModal();
    }

    function onSaveSuccess() {
        retry();
    }

    function onAppDeleted() {
        setSelApp({});
        retry();
    }

    if (loading) {
        return <PageLoading />;
    }

    return (
        <TwoColumnLayout>
            <TwoColumnLayout.SidePanel>
                <TwoColumnLayout.PanelHeader title="应用" icon={<AppstoreAddOutlined />}>
                    {editable && (
                        <Button type="primary" icon={<PlusOutlined />} onClick={onAddAppClick} />
                    )}
                    <WecomAppFormModal ref={wecomAppFormModalRef} onSaveSuccess={onSaveSuccess} />
                </TwoColumnLayout.PanelHeader>
                <TwoColumnLayout.PanelContent>
                    <AppList appList={appList} onAppSelect={onAppSelect} />
                </TwoColumnLayout.PanelContent>
            </TwoColumnLayout.SidePanel>
            <TwoColumnLayout.ContentPanel>
                {_.isEmpty(selApp) ? (
                    <Empty style={{marginTop: 140}} description="选择应用查看详细信息" />
                ) : (
                    <AppInfo
                        appId={selApp.id}
                        onAppDeleted={onAppDeleted}
                        editable={editable}
                        deletable={editable}
                        roleEditable={editable}
                        appAllowAddUsers={false}
                    />
                )}
            </TwoColumnLayout.ContentPanel>
        </TwoColumnLayout>
    );
}
