import {useEffect, useState} from 'react';

import {useDepartTree} from '@/page/admin/DepartsPage/useDepartService';
import {convertDeparts2TreeFormat} from '@/util/treeUtils';

export function useDepartTreeData() {
    const [treeData, setTreeData] = useState(null);
    const {loading, value} = useDepartTree();
    // console.log('loading, value', loading, value, treeData);

    useEffect(() => {
        if (loading) {
            return;
        }

        const {data} = value || {};
        setTreeData(convertDeparts2TreeFormat(data));
    }, [loading, value]);

    return {treeData, loading};
}
