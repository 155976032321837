import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, List} from 'antd';
import classNames from 'classnames';

import {appPropTypes} from './propTypes';
import styles from './AppItem.module.less';

export default function AppItem({app, selAppId, onSelect}) {
    function onAppClick(app) {
        return () => {
            onSelect && onSelect(app);
        };
    }

    return (
        <List.Item
            className={classNames(styles.item, {[styles.selItem]: app.id === selAppId})}
            onClick={onAppClick(app)}
        >
            <List.Item.Meta
                title={app.name}
                description={app.description || '暂无应用介绍'}
                avatar={<Avatar shape="square" src={app.squareLogoUrl} alt="应用 Logo" />}
            />
        </List.Item>
    );
}

AppItem.propTypes = {
    app: appPropTypes,
    selAppId: PropTypes.string,
    onSelect: PropTypes.func,
};
