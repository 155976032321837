import React, {useEffect, forwardRef, useImperativeHandle} from 'react';
import PropTypes from 'prop-types';
import {Form, Input, Spin, Tooltip} from 'antd';
import {useAsyncRetry} from 'react-use';

import {SafetyOutlined} from '@/icons';
import {getCaptcha} from '@/api/auth';

import styles from './CaptchaFormItem.module.less';

const CaptchaFormItem = forwardRef(({onLoadCaptchaKey}, ref) => {
    const {loading, retry, value = {}} = useAsyncRetry(async () => {
        return await getCaptcha();
    }, []);
    // console.log(loading, value);

    const {key, image} = value.data || {};

    useEffect(() => {
        if (key) {
            onLoadCaptchaKey && onLoadCaptchaKey(key);
        }
    }, [key, onLoadCaptchaKey]);

    useImperativeHandle(ref, () => ({
        reload: retry,
    }));

    function onCaptchaClick() {
        retry();
    }

    return (
        <Form.Item
            name="captchaText"
            rules={[
                {
                    required: true,
                    message: '验证码不能为空',
                },
            ]}
        >
            <div className={styles.container}>
                <Input prefix={<SafetyOutlined />} placeholder="验证码" size="large" />
                <div className={styles.captcha} onClick={onCaptchaClick}>
                    <Tooltip title="点击刷新验证码图片">
                        <Spin spinning={loading}>
                            <img src={image} alt="Captcha" />
                        </Spin>
                    </Tooltip>
                </div>
            </div>
        </Form.Item>
    );
});

CaptchaFormItem.displayName = 'CaptchaFormItem';

CaptchaFormItem.propTypes = {
    onLoadCaptchaKey: PropTypes.func,
};

export default CaptchaFormItem;
