import React, {useRef, useState} from 'react';
import {useParams} from 'react-router-dom';

import MsgHistory from './MsgHistory';
import {getSendMsgHistoryUrl} from '@/api/message';
import SendSearchForm from './search/SendSearchForm';
import SendMsgDrawer from './SendMsgDrawer';
import useTitle from '@/hook/useTitle';

export default function MsgHistorySendPage() {
    useTitle('已发送消息');

    const previewRef = useRef();
    const {appId} = useParams();
    const [searchFormData, setSearchFormData] = useState(null);

    function onTitleClick(record) {
        return () => {
            // console.log(record);
            previewRef.current.show(record.id);
        };
    }

    return (
        <>
            <MsgHistory
                title="已发送消息"
                onTitleClick={onTitleClick}
                url={getSendMsgHistoryUrl(appId)}
                searchForm={<SendSearchForm onSearch={setSearchFormData} />}
                searchFormData={searchFormData}
            />
            <SendMsgDrawer ref={previewRef} appId={appId} />
        </>
    );
}
