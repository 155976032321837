import React from 'react';
import {Button, Form, Input, Select} from 'antd';

import {MinusCircleOutlined, PlusOutlined} from '@/icons';

const {Option} = Select;

/**
 * 动态添加删除关键字表单行。
 *
 * @return {JSX.Element}
 * @constructor
 */
export default function KeywordsReplyFormRuleList() {
    const initialValue = [{type: 1, keyword: ''}];

    return (
        <Form.List name="rules" initialValue={initialValue}>
            {(fields, {add, remove}) => {
                // console.log(fields, add, remove, errors);

                return (
                    <>
                        <div style={{marginBottom: 10}}>关键字</div>
                        {fields.map((field, index) => {
                            return (
                                <Input.Group key={field.key} {...field} compact>
                                    <Form.Item
                                        name={[field.name, 'type']}
                                        style={{width: '20%'}}
                                        initialValue={1}
                                    >
                                        <Select>
                                            <Option value={1}>包含</Option>
                                            <Option value={2}>完全匹配</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        name={[field.name, 'keyword']}
                                        rules={[{required: true, message: '关键字不能为空'}]}
                                        style={{width: '80%'}}
                                    >
                                        <Input
                                            placeholder="回复关键字"
                                            maxLength={30}
                                            addonAfter={
                                                fields.length > 1 && (
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Input.Group>
                            );
                        })}

                        {fields.length < 20 && (
                            <Form.Item>
                                <Button type="link" onClick={() => add()} icon={<PlusOutlined />}>
                                    添加
                                </Button>
                            </Form.Item>
                        )}
                    </>
                );
            }}
        </Form.List>
    );
}
