import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Input, message, Modal, Popover, Radio, Space, Steps} from 'antd';
import _ from 'lodash';

import {InfoCircleOutlined} from '@/icons';

import CustomSendValidateView from './CustomSendValidateView';
import {getTypeAlias} from './utils';

const STEPS = [
    {
        key: 'init',
        title: '输入',
    },
    {
        key: 'validate',
        title: '验证输入',
    },
];

const CustomSendModal = forwardRef(({appId, onSelect}, ref) => {
    const [visible, setVisible] = useState(false);

    // 支持的类别为 email 和 mobile
    const [type, setType] = useState('email');
    const typeAlias = getTypeAlias(type);

    // input
    const [rawInput, setRawInput] = useState('');
    const [list, setList] = useState([]);

    // step
    const [curStep, setCurStep] = useState(0);

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
        setType('email');
        setRawInput('');
        setList([]);
        setCurStep(0);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onRadioChange(e) {
        let value = e.target.value;
        console.log(value);
        setType(value);
    }

    function onChange(e) {
        let value = e.target.value;
        if (!value) {
            setRawInput('');
            setList([]);
        } else {
            setRawInput(value);
            setList(value.split('\n'));
        }
    }

    function checkInput() {
        console.log('list', list);
        if (_.isEmpty(list)) {
            message.warn(`${typeAlias} 列表不能为空`);
            return false;
        }

        if (list.length > 1000) {
            message.warn(`单次操作 ${typeAlias} 不能超过 1000 个`);
            return false;
        }

        return true;
    }

    function onNextClick() {
        if (!checkInput()) {
            return;
        }
        setCurStep(curStep + 1);
    }

    function onBackClick() {
        setCurStep(0);
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="自定义发送"
            footer={null}
            onCancel={closeModal}
        >
            <Steps current={curStep}>
                {STEPS.map(({key, title}) => (
                    <Steps.Step key={key} title={title} />
                ))}
            </Steps>

            <div style={{marginTop: 24, marginBottom: 24}}>
                {curStep === 0 && (
                    <Space direction="vertical" size="large" style={{display: 'flex'}}>
                        <Radio.Group value={type} onChange={onRadioChange}>
                            <Radio value="email">Email</Radio>
                            <Radio value="mobile">手机号</Radio>
                        </Radio.Group>
                        <Alert
                            showIcon
                            message={`每行一个 ${typeAlias}，请不要出现空格和其它标点符号`}
                            description="不能超过 1000 行"
                        />
                        <Input.TextArea
                            placeholder={`每行一个 ${typeAlias}`}
                            value={rawInput}
                            onChange={onChange}
                            rows={20}
                        />
                        <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button.Group>
                                <Button type="primary" onClick={onNextClick}>
                                    下一步：验证人员
                                </Button>
                                <Popover
                                    title="说明"
                                    content={
                                        <>
                                            <p>
                                                验证 Email 或手机号是否存在于本应用的「可见范围」，
                                            </p>
                                            <p>不可见人员将会在发送消息时忽略</p>
                                        </>
                                    }
                                >
                                    <Button type="primary" icon={<InfoCircleOutlined />} />
                                </Popover>
                            </Button.Group>
                        </Space>
                    </Space>
                )}

                {curStep === 1 && (
                    <CustomSendValidateView
                        appId={appId}
                        type={type}
                        data={list}
                        onBackClick={onBackClick}
                        closeModal={closeModal}
                        onSelect={onSelect}
                    />
                )}
            </div>
        </Modal>
    );
});

CustomSendModal.displayName = 'CustomSendModal';

CustomSendModal.propTypes = {
    appId: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default CustomSendModal;
