import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Descriptions, Tooltip} from 'antd';
import _ from 'lodash';

import {EditTag} from '@/component';
import {EditOutlined, InfoCircleOutlined} from '@/icons';
import UserClickableTags from '@/page/admin/DepartsPage/UserClickableTags';
import {updateAppAudits} from '@/api/msgAudit';
import AppUserSelector from '@/page/admin/selector/AppUserSelector';

import styles from '@/page/admin/AppsPage/AppInfo.module.less';

MsgAuditTags.propTypes = {
    appId: PropTypes.string.isRequired,
    roleEditable: PropTypes.bool,
    appAudits: PropTypes.array,
    retryAudit: PropTypes.func.isRequired,
};

function MsgAuditTags({appId, roleEditable = false, appAudits, retryAudit}) {
    const appUserSelectorRef = useRef();
    const clickedAuditType = useRef();
    console.log('clickedAuditType', clickedAuditType.current);

    let appAuditMgrs = [];
    let appAuditSenders = [];
    appAuditMgrs = appAudits
        ?.filter(({appAuditType}) => appAuditType === 'app_audit_mgr')
        ?.map(({userId, userName}) => ({id: userId, name: userName}));
    appAuditSenders = appAudits
        ?.filter(({appAuditType}) => appAuditType === 'app_audit_sender')
        ?.map(({userId, userName}) => ({id: userId, name: userName}));

    function getAuditIds(audits) {
        if (_.isEmpty(audits)) {
            return [];
        }

        return audits.map(({id}) => id);
    }

    function onAuditClick(type) {
        return () => {
            clickedAuditType.current = type;
            appUserSelectorRef.current.showModal({
                modalTitle: type === 'app_audit_mgr' ? '选择消息审核人' : '选择需审核发送人',
                initIds:
                    type === 'app_audit_mgr'
                        ? getAuditIds(appAuditMgrs)
                        : getAuditIds(appAuditSenders),
            });
        };
    }

    function onUserSelect(values) {
        console.log('onUserSelect', values);

        return updateAppAudits(appId, clickedAuditType.current, values)
            .then(data => {
                retryAudit();
                return true;
            })
            .catch(e => {
                console.log(e);
                return false;
            });
    }

    return (
        <>
            <Descriptions column={1} className={styles.descriptions}>
                <Descriptions.Item label="消息审核人">
                    <div className={styles.tags}>
                        <EditTag
                            showTag={roleEditable}
                            icon={<EditOutlined />}
                            className={styles.tag}
                            onClick={onAuditClick('app_audit_mgr')}
                        >
                            编辑
                        </EditTag>
                        <UserClickableTags
                            users={appAuditMgrs}
                            tagProps={{className: styles.tag}}
                        />
                    </div>
                </Descriptions.Item>
                <Descriptions.Item
                    label={
                        <>
                            需审核发送人
                            <Tooltip title="此处人员发送的消息均需审核">
                                <Button type="text" icon={<InfoCircleOutlined />} danger />
                            </Tooltip>
                        </>
                    }
                >
                    <div className={styles.tags}>
                        <EditTag
                            showTag={roleEditable}
                            icon={<EditOutlined />}
                            className={styles.tag}
                            onClick={onAuditClick('app_audit_sender')}
                        >
                            编辑
                        </EditTag>
                        <UserClickableTags
                            users={appAuditSenders}
                            tagProps={{className: styles.tag}}
                        />
                    </div>
                </Descriptions.Item>
            </Descriptions>

            <AppUserSelector ref={appUserSelectorRef} appId={appId} onSelect={onUserSelect} />
        </>
    );
}

export default MsgAuditTags;
