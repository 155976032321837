import {useAsync} from 'react-use';
import dayjs from 'dayjs';
import {getSumReport} from '@/api/report';

/**
 * 昨天、最近 7 天、近 30 天的统计数据
 *
 * @param appId
 * @param alias
 */
export function useSumReports(appId, alias) {
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const last1 = [yesterday, yesterday];
    const last7 = [dayjs().subtract(8, 'day').format('YYYY-MM-DD'), yesterday];
    const last30 = [dayjs().subtract(31, 'day').format('YYYY-MM-DD'), yesterday];

    return useAsync(async () => {
        const last1Data = await getSumReport(alias, appId, last1[0], last1[1]);
        const last7Data = await getSumReport(alias, appId, last7[0], last7[1]);
        const last30Data = await getSumReport(alias, appId, last30[0], last30[1]);

        return [
            {...last1Data.data, title: '昨天', id: 'last1'},
            {...last7Data.data, title: '7天', id: 'last7'},
            {
                ...last30Data.data,
                title: '30天',
                id: 'last30',
            },
        ];
    }, [appId, alias]);
}
