/*
 * Created by liyi on 2021-01-12 17:10.
 */
import React, {useRef, useState} from 'react';
import {DatePicker, Input, PageHeader, Space, Select, Button} from 'antd';

import {PageGrid} from '@/component';
import {getColumns} from './logsColumns';
import {getStartTimestamp, getEndTimestamp} from '@/util/dateUtils';

const {Option} = Select;

export default function LogsList() {
    const tableRef = useRef();

    const [level, setLevel] = useState(null);
    const [creatorName, setCreatorName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const columns = getColumns(['name', 'level', 'log', 'time', 'ip']);

    function onLeverChange(value) {
        setLevel(value);
    }

    function onStartDateChange(date) {
        setStartDate(date);
    }

    function onEndDateChange(date) {
        setEndDate(date);
    }

    function onSearchChange({target: {value}}) {
        setCreatorName(value);
    }

    function onSubmit() {
        const begin = startDate && getStartTimestamp(startDate);
        const end = endDate && getEndTimestamp(endDate);
        const params = {
            level: level,
            creatorName: creatorName,
            begin: begin,
            end: end,
        };
        tableRef.current.reload2FirstPage(params);
    }

    function onReset() {
        setCreatorName(null);
        setLevel(null);
        setEndDate(null);
        setStartDate(null);
        tableRef.current.reload2FirstPage({});
    }

    function renderExtra() {
        return (
            <Space>
                <Select value={level} style={{width: 120}} onChange={onLeverChange}>
                    <Option value={null}>选择级别</Option>
                    <Option value="INFO">INFO</Option>
                    <Option value="WARN">WARN</Option>
                    <Option value="ERROR">ERROR</Option>
                </Select>
                <DatePicker placeholder="起始时间" value={startDate} onChange={onStartDateChange} />
                <DatePicker placeholder="截止时间" value={endDate} onChange={onEndDateChange} />
                <Input placeholder="创建人" value={creatorName} onChange={onSearchChange} />
                <Button type="primary" onClick={onSubmit}>
                    搜索
                </Button>
                <Button onClick={onReset}>重置</Button>
            </Space>
        );
    }

    return (
        <div style={{flex: 'auto', flexDirection: 'row'}}>
            <PageHeader title="系统日志" extra={renderExtra()} />
            <div style={{margin: '0 24px 24px'}}>
                <PageGrid ref={tableRef} url={'/logs'} columns={columns} />
            </div>
        </div>
    );
}
