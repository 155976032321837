import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Badge} from 'antd';

/**
 * 根据 bool 型的值显示相应文本
 */
const BoolView = ({
    showBadge,
    value,
    trueText,
    trueClassName,
    trueStyle,
    falseText,
    falseClassName,
    falseStyle,
}) => {
    if (value) {
        return (
            <span className={trueClassName} style={trueStyle}>
                {showBadge && <Badge status="success" />}

                {trueText}
            </span>
        );
    }
    return (
        <span className={falseClassName} style={falseStyle}>
            {showBadge && <Badge status="error" />}
            {falseText}
        </span>
    );
};

BoolView.propTypes = {
    showBadge: PropTypes.bool,
    value: PropTypes.bool,
    trueText: PropTypes.string.isRequired,
    trueClassName: PropTypes.string,
    trueStyle: PropTypes.object,
    falseText: PropTypes.string.isRequired,
    falseClassName: PropTypes.string,
    falseStyle: PropTypes.object,
};
BoolView.defaultProps = {
    showBadge: true,
    value: false,
    trueClassName: null,
    trueStyle: null,
    falseClassName: null,
    falseStyle: null,
};

export default memo(BoolView);
