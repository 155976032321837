import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Divider, Input, message, Modal} from 'antd';
import _ from 'lodash';
import {addTagLinkByEmails} from '@/api/tags';

const BatchAddModal = forwardRef(({tagId, reload}, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emails, setEmails] = useState([]);
    const [addMemberNum, setAddMemberNum] = useState(-1);
    const [notExistEmails, setNotExistEmails] = useState([]);
    console.log('tag id', tagId);

    function showModal() {
        setVisible(true);
        setLoading(false);
        setEmails([]);
        setAddMemberNum(-1);
        setNotExistEmails([]);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onChange(e) {
        let value = e.target.value;
        if (!value) {
            setEmails([]);
        } else {
            setEmails(value.split('\n'));
        }
    }

    function onOk() {
        console.log('emails', emails);
        if (_.isEmpty(emails)) {
            message.warn('Email 列表不能为空');
            return;
        }

        if (emails.length > 1000) {
            message.warn('单次操作 Email 不能超过 1000 个');
            return;
        }

        setLoading(true);
        addTagLinkByEmails(tagId, emails)
            .then(({data}) => {
                const {addMemberNum, notExistEmails} = data;
                setAddMemberNum(addMemberNum);
                setNotExistEmails(notExistEmails);
                // console.log(data);
                reload();
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function renderInfo() {
        if (addMemberNum === -1 && _.isEmpty(notExistEmails)) {
            return null;
        }

        const description = (
            <ul>
                <li>
                    共添加 <strong>{addMemberNum}</strong> 人
                </li>
                {!_.isEmpty(notExistEmails) && (
                    <li>
                        平台中不存在 email:
                        <ul>
                            {notExistEmails.map(email => (
                                <li key={email}>{email}</li>
                            ))}
                        </ul>
                    </li>
                )}
            </ul>
        );

        return (
            <>
                <Alert showIcon type="warning" message="结果" description={description} />
                <Divider />
            </>
        );
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="批量添加 Email 对应人员到标签"
            onCancel={closeModal}
            okButtonProps={{loading}}
            onOk={onOk}
        >
            {renderInfo()}
            <Alert showIcon message="每行一个 Email，请不要出现空格和其它标点符号" />
            <Divider />
            <Input.TextArea placeholder="每行一个 Email" onChange={onChange} rows={20} />
        </Modal>
    );
});

BatchAddModal.displayName = 'BatchAddModal';

BatchAddModal.propTypes = {
    tagId: PropTypes.string.isRequired,
    reload: PropTypes.func.isRequired,
};

export default BatchAddModal;
