import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Button, Form, Input, message, Modal} from 'antd';
import copy from 'copy-to-clipboard';

import {PasswordFormItem} from '@/component';
import {KeyOutlined} from '@/icons';
import {generatePassword} from '@/util/stringUtils';
import {resetPassword} from '@/api/users';

const UserResetPassModal = forwardRef((props, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [user, setUser] = useState({});

    function showModal(user) {
        setVisible(true);
        setUser(user);
    }

    function closeModal() {
        setVisible(false);
        setUser({});
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onGenPassClick() {
        const password = generatePassword();
        formRef.current.setFieldsValue({password});
    }

    function onOk() {
        formRef.current.submit();
    }

    function onFinish({password}) {
        console.log('Received values of form: ', password);
        resetPassword(user.id, password).then(data => {
            console.log(data);
            const result = copy(password);
            if (result) {
                message.success(`[${password}] 拷贝到剪贴板`);
            }
            closeModal();
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={`重置密码: ${user.name}`}
            onCancel={closeModal}
            onOk={onOk}
            okText="重置密码"
            okButtonProps={{danger: true}}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical">
                <PasswordFormItem
                    InputComponet={Input}
                    label="新密码"
                    rules={[{required: true}]}
                    inputProps={{autoComplete: 'new-password'}}
                />
            </Form>
            <Button icon={<KeyOutlined />} onClick={onGenPassClick}>
                生成随机密码
            </Button>
        </Modal>
    );
});

UserResetPassModal.displayName = 'UserResetPassModal';

export default UserResetPassModal;
