import React from 'react';
import PropTypes from 'prop-types';
import {Divider, Space, Tag, Tooltip, Typography} from 'antd';

import {formatLong} from '@/util/dateUtils';
import {VerticalAlignBottomOutlined, VerticalAlignTopOutlined} from '@/icons';
import msgTypes from '@/page/component/msg/MsgTypes';
import MsgPreview from '../MsgPreview';
import {sendFromText} from '../msgUtils';

import styles from './MessageView.module.less';

export default function MessageView({message}) {
    const {msgType, msgDirection, sendFrom, createTime, creatorName, userName} = message;

    function renderMsgType() {
        const type = msgTypes[msgType];
        if (type) {
            return <Tag color="#2db7f5">{type.label}</Tag>;
        }
        return '';
    }

    function renderUser() {
        let name;
        if (msgDirection === 'send') {
            name = creatorName === 'daemon' ? null : creatorName;
        } else {
            name = userName;
        }

        if (!name) {
            return null;
        }

        return <Tag color="cyan">{name}</Tag>;
    }

    function renderFrom() {
        return sendFromText(sendFrom);
    }

    function renderHeader() {
        return (
            <div className={styles.header}>
                <Space className={styles.main}>
                    {msgDirection === 'send' ? (
                        <Tooltip title="已发送消息">
                            <Tag color="gold">
                                <VerticalAlignTopOutlined />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tooltip title="已接收消息">
                            <Tag color="red">
                                <VerticalAlignBottomOutlined />
                            </Tag>
                        </Tooltip>
                    )}

                    {renderMsgType()}
                    <Tag color="blue">{renderFrom()}</Tag>
                    {renderUser()}
                </Space>
                <Typography.Text type="secondary">{formatLong(createTime)}</Typography.Text>
            </div>
        );
    }

    return (
        <div>
            {renderHeader()}
            <MsgPreview message={message} showMeta={false} newsStyle={{maxWidth: '100%'}} />
            <Divider />
        </div>
    );
}

MessageView.propTypes = {
    message: PropTypes.object,
};
