import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Switch, Tooltip} from 'antd';

import {LikeOutlined} from '@/icons';
import {deleteComment, toggleComment} from '@/api/comment';

import styles from './styles.module.less';

export default function CommentAction({
    id,
    onReplyClick,
    reload,
    shown = false,
    stick = false,
    praiseNum = 0,
    isChild = false,
}) {
    function onShownChange(checked) {
        let _shown;
        let _stick = stick;
        if (!checked) {
            _shown = false;
            _stick = false;
        } else {
            _shown = true;
        }
        toggleComment(id, _shown, _stick).then(data => {
            console.log(data);
            reload();
        });
    }

    function onStickChange(checked) {
        let _shown = shown;
        let _stick;
        if (checked) {
            _shown = true;
            _stick = true;
        } else {
            _stick = false;
        }
        toggleComment(id, _shown, _stick).then(data => {
            console.log(data);
            reload();
        });
    }

    function onDeleteClick(commentId) {
        Modal.confirm({
            title: '警告',
            content: '确定删除此留言？',
            okButtonProps: {danger: true},
            onOk: () => {
                deleteComment(commentId).then(data => {
                    console.log(data);
                    reload();
                });
            },
        });
    }

    return (
        <div className={styles.actionWrap}>
            <div className={styles.praise}>
                <Tooltip title="当前点赞数">
                    <LikeOutlined style={{marginRight: 10}} />
                    {praiseNum}
                </Tooltip>
            </div>
            {!isChild && (
                <>
                    <div className={styles.switchWrap}>
                        <Switch
                            size="small"
                            checked={shown}
                            style={{marginRight: 10}}
                            onChange={onShownChange}
                        />
                        精选
                    </div>
                    <div className={styles.switchWrap}>
                        <Switch
                            size="small"
                            checked={stick}
                            style={{marginRight: 10}}
                            onChange={onStickChange}
                        />
                        置顶
                    </div>
                </>
            )}
            <Button.Group size="small">
                {!isChild && (
                    <Tooltip title="精选留言方可回复">
                        <Button type="primary" disabled={!shown} onClick={() => onReplyClick(id)}>
                            回复留言
                        </Button>
                    </Tooltip>
                )}
                <Button danger type="primary" onClick={() => onDeleteClick(id)}>
                    删除
                </Button>
            </Button.Group>
        </div>
    );
}

CommentAction.propTypes = {
    id: PropTypes.string.isRequired,
    onReplyClick: PropTypes.func,
    reload: PropTypes.func,
    shown: PropTypes.bool,
    stick: PropTypes.bool,
    praiseNum: PropTypes.number,
    isChild: PropTypes.bool,
};
