import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Input, message, Modal} from 'antd';

const AuditMsgRejectModal = forwardRef(({appId, onUpdateRejectData}, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [msgId, setMsgId] = useState(null);
    const [auditDesc, setAuditDesc] = useState('');

    function showModal(msgId) {
        setVisible(true);
        setMsgId(msgId);
    }

    function closeModal() {
        setVisible(false);
        setLoading(false);
        setMsgId(null);
        setAuditDesc('');
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onChange(e) {
        setAuditDesc(e.target.value);
    }

    function onOk() {
        if (!auditDesc || !auditDesc.trim()) {
            message.error('审核原因不能为空');
            return;
        }

        onUpdateRejectData(msgId, auditDesc).then(() => {
            closeModal();
        });
    }

    return (
        <Modal
            destroyOnClose
            title="消息驳回"
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{loading}}
        >
            <Alert
                showIcon
                type="warning"
                message="注意"
                description="下述原因将会发送到发送人的企业微信中"
                style={{marginBottom: 16}}
            />

            <Input.TextArea
                placeholder="请输入审核不通过原因"
                allowClear
                showCount
                value={auditDesc}
                rows={4}
                maxLength={500}
                onChange={onChange}
            />
        </Modal>
    );
});

AuditMsgRejectModal.displayName = 'AuditMsgRejectModal';

AuditMsgRejectModal.propTypes = {
    appId: PropTypes.string.isRequired,
    onUpdateRejectData: PropTypes.func.isRequired,
};

export default AuditMsgRejectModal;
