/* eslint-disable react/display-name */
import React from 'react';
import {Avatar, Button} from 'antd';
import _ from 'lodash';
import filesize from 'filesize';

import {formatLong} from '@/util/dateUtils';
import MediaType, {getFileMediaUrl} from '../MediaType';
import MediaTag from './MediaTag';

const columnDefines = ({onNameClick = _.noop} = {}) => ({
    avatar: {
        title: '',
        key: 'avatar',
        width: 64,
        render: (text, record) => {
            const {mediaType, attachId, attachExt, thumbAttachId, thumbAttachExt} = record;

            const {icon} = MediaType[mediaType.toUpperCase()] || MediaType.NEWS;

            if (['voice', 'video', 'file'].includes(mediaType)) {
                return <Avatar icon={icon} />;
            } else if (['image', 'news', 'news_link'].includes(mediaType)) {
                const url = getFileMediaUrl(thumbAttachId ?? attachId, thumbAttachExt ?? attachExt);
                return <Avatar src={url} />;
            }

            return null;
        },
    },
    title: {
        title: '标题',
        dataIndex: 'title',
        render: (text, record) => {
            return (
                <Button type="link" onClick={() => onNameClick(record)} style={{padding: '4px 0'}}>
                    {text}
                </Button>
            );
        },
    },
    size: {
        title: '大小',
        dataIndex: 'attachSize',
        render: text => {
            return filesize(text);
        },
    },
    newsNum: {
        title: '类型',
        dataIndex: 'newsNum',
        render: (text, {mediaType}) => {
            const label = mediaType === 'news' ? '图文' : '外链';

            if (text === 1) {
                return label;
            }
            return (
                <MediaTag>
                    {label}列表 [{text}]
                </MediaTag>
            );
        },
    },
    createTime: {
        title: '创建时间',
        dataIndex: 'createTime',
        render: text => {
            return formatLong(text);
        },
    },
    creatorName: {
        title: '创建人',
        dataIndex: 'creatorName',
        render: text => {
            return text;
        },
    },
});

export function getColumns(keys, options) {
    const defines = columnDefines(options);
    return keys.map(key => defines[key]).filter(Boolean);
}
