import React from 'react';
import _ from 'lodash';
import {Avatar} from 'antd';

import {DoubleRightOutlined} from '@/icons';

import AppLayoutHeader from '../component/AppLayout/AppLayoutHeader';
import {useAppStore} from './context';

export default function AppHeader() {
    const [appStore] = useAppStore();

    function renderAppInfo() {
        const app = _.get(appStore, 'app', {});
        const name = app?.name;
        const squareLogoUrl = app?.squareLogoUrl;

        return (
            <div style={{display: 'flex', alignItems: 'center'}}>
                <DoubleRightOutlined style={{marginRight: 24}} />
                <Avatar shape="square" src={squareLogoUrl} alt="APP" />
                <div style={{marginLeft: 16}}>{name}</div>
            </div>
        );
    }

    return <AppLayoutHeader subTitle={renderAppInfo()} />;
}
