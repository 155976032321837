import React from 'react';
import PropTypes from 'prop-types';

import './typo.less';

export default function ArticleContent({children}) {
    return (
        <div className="typo clearfix" style={{minHeight: 300}}>
            {children}
        </div>
    );
}

ArticleContent.propTypes = {
    children: PropTypes.node,
};
