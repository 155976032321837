import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Drawer} from 'antd';
import {useAsync} from 'react-use';

import MsgPreview from '@/page/app/msg-history/MsgPreview';
import {getAuditMsg} from '@/api/msgAudit';

const AuditMsgDrawer = forwardRef(({appId}, ref) => {
    const [visible, setVisible] = useState(false);
    const [msgId, setMsgId] = useState(null);

    const {value, loading} = useAsync(() => {
        if (!msgId) {
            console.log('msgid is null');
            return Promise.resolve({});
        }

        return getAuditMsg(appId, msgId).then(({data}) => {
            return data;
        });
    }, [msgId]);

    console.log('value, loading', value, loading);

    function show(msgId) {
        setVisible(true);
        setMsgId(msgId);
    }

    function hide() {
        setVisible(false);
    }

    function isShown() {
        return visible;
    }

    useImperativeHandle(ref, () => ({
        show,
        hide,
        isShown,
    }));

    const jsonData = value?.jsonData && JSON.parse(value?.jsonData);
    console.log(jsonData);

    // 将数据拼凑成 MsgMeta 格式
    let message;
    if (jsonData?.message) {
        const {message: content, tos, ...rest} = jsonData;

        message = {
            ...content,
            ...rest,
            userName: value?.creatorName,
            createTime: value?.createTime,
            msgDirection: 'send',
            tos,
        };
        console.log('message', message);
    }

    return (
        <Drawer
            visible={visible}
            mask={false}
            width={500}
            onClose={hide}
            destroyOnClose
            title={'预览'}
        >
            {message && <MsgPreview message={message} />}
        </Drawer>
    );
});

AuditMsgDrawer.displayName = 'AuditMsgDrawer';

AuditMsgDrawer.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default AuditMsgDrawer;
