import React from 'react';
import PropTypes from 'prop-types';
import {PageHeader} from 'antd';
import {Link, useParams} from 'react-router-dom';

import AuditMsgTable from './AuditMsgTable';
import AppContent from '@/page/component/AppLayout/AppContent';

/**
 * 审核人的消息列表。
 *
 * @constructor
 */
function MgrAuditMsgList() {
    const {appId} = useParams();

    return (
        <AppContent>
            <PageHeader title="待审核消息">
                <AuditMsgTable appId={appId} />
            </PageHeader>
        </AppContent>
    );
}

export default MgrAuditMsgList;
