import {useAsyncRetry} from 'react-use';

import {getMenus} from '@/api/menus';

export function useMenus(appId) {
    // const menus = mockMenus(appId);
    return useAsyncRetry(() => {
        // return Promise.resolve({data: menus});
        return getMenus(appId).then(menus => {
            console.log('getMenus', menus);
            return menus;
        });
    }, []);
}
