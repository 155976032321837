import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {
    Avatar,
    Button,
    Col,
    Descriptions,
    Divider,
    message,
    Modal,
    PageHeader,
    Menu,
    Dropdown,
    Row,
    Tooltip,
    Typography,
} from 'antd';
import _ from 'lodash';
import copy from 'copy-to-clipboard';

import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    MoreOutlined,
    InfoCircleOutlined,
} from '@/icons';
import {DepartTag, TagTag, UserTag} from '@/component';
import {useApp} from './useAppsService';
import AppCallbackFormModal from './AppCallbackFormModal';
import WecomAppFormModal from './WecomAppFormModal';
import AppRoleWrapper from './role/AppRoleWrapper';
import AddAllowUserModal from './AddAllowUserModal';

import {deleteApp} from '@/api/apps';

import styles from './AppInfo.module.less';

export default function AppInfo({
    appId,
    onAppDeleted,
    editable = false,
    deletable = false,
    roleEditable = true,
    appAllowAddUsers = false,
}) {
    const callbackFormModalRef = useRef();
    const updateModalRef = useRef();
    const addAllowUserModalRef = useRef();

    const {value, retry} = useApp(appId);
    const app = value?.data ?? {}; // _.get(value, 'data', {});

    function onDeleteClick() {
        Modal.confirm({
            title: '警告',
            content: (
                <>
                    <p>确定删除应用「{app.name}」？</p>
                    <Typography.Text type="danger">
                        由于企业微信接口限制，删除之后，请到「企业微信控制台」删除相关应用！
                    </Typography.Text>
                </>
            ),
            okButtonProps: {danger: true},
            onOk: () => {
                deleteApp(appId).then(data => {
                    console.log(data);
                    onAppDeleted && onAppDeleted();
                });
            },
        });
    }

    function onCopyClick() {
        if (!app.callbackUrl) {
            message.warn('尚未定义回调链接');
            return;
        }

        copy(app.callbackUrl);
        message.info('回调链接拷贝成功');
    }

    function onAddAllowUsers() {
        console.log('onAddAllowUsers');
        addAllowUserModalRef.current.showModal();
    }

    function renderExtra() {
        if (!editable && !deletable) {
            return null;
        }

        const menu = (
            <Menu>
                <Menu.Item onClick={onAddAllowUsers}>导入人员到应用可见标签</Menu.Item>
            </Menu>
        );

        return (
            <Button.Group>
                {editable && (
                    <Button
                        icon={<EditOutlined />}
                        onClick={() =>
                            updateModalRef.current.showModal({
                                appId,
                                agentid: app.agentid,
                                secret: app.secret,
                            })
                        }
                    >
                        修改
                    </Button>
                )}

                {deletable && (
                    <Button icon={<DeleteOutlined />} onClick={onDeleteClick}>
                        删除
                    </Button>
                )}

                {appAllowAddUsers && (
                    <Dropdown overlay={menu} placement="bottomRight">
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                )}
            </Button.Group>
        );
    }

    function renderAllow(allows, AllowTag) {
        if (_.isEmpty(allows)) {
            return '-';
        }

        return (
            <div className={styles.tags}>
                {allows.map(({allowId, allowName}) => (
                    <AllowTag key={allowId} className={styles.tag}>
                        {allowName}
                    </AllowTag>
                ))}
            </div>
        );
    }

    return (
        <PageHeader extra={renderExtra()}>
            <Row gutter={16} style={{marginTop: 24}}>
                <Col span={6} style={{textAlign: 'right', paddingRight: 32}}>
                    <Avatar shape="square" size={64} src={app.squareLogoUrl} />
                </Col>
                <Col span={18}>
                    <Descriptions column={1} className={styles.descriptions}>
                        <Descriptions.Item label="应用名称">{app.name}</Descriptions.Item>
                        <Descriptions.Item label="应用说明">
                            {app.description || '-'}
                        </Descriptions.Item>
                    </Descriptions>

                    <AppRoleWrapper appId={appId} roleEditable={roleEditable} />

                    <Divider orientation="left">企业微信配置</Divider>
                    <Descriptions column={1} className={styles.descriptions}>
                        <Descriptions.Item label="AgentId">{app.agentid}</Descriptions.Item>
                        <Descriptions.Item label="Secret">{app.secret}</Descriptions.Item>
                    </Descriptions>

                    <Divider orientation="left">
                        应用可见范围
                        <Tooltip title="需在企业微信控制台配置">
                            <Button type="text" danger icon={<InfoCircleOutlined />} />
                        </Tooltip>
                    </Divider>
                    <Descriptions column={1} className={styles.descriptions}>
                        <Descriptions.Item label="可见部门">
                            {renderAllow(app.allowDeparts, DepartTag)}
                        </Descriptions.Item>
                        <Descriptions.Item label="可见人员">
                            {renderAllow(app.allowUsers, UserTag)}
                        </Descriptions.Item>
                        <Descriptions.Item label="可见标签">
                            {renderAllow(app.allowTags, TagTag)}
                        </Descriptions.Item>
                    </Descriptions>

                    <Divider orientation="left">企业微信接收消息服务器配置</Divider>
                    <Descriptions column={1} className={styles.descriptions}>
                        <Descriptions.Item label="URL">
                            <div>
                                <div>{app.callbackUrl}</div>

                                <div>
                                    <Tooltip
                                        title={
                                            <>
                                                <p>
                                                    将此 URL
                                                    配置到企业微信控制台对应应用的「API接收消息」中
                                                </p>
                                                <p>需先配置 Token, Key</p>
                                            </>
                                        }
                                    >
                                        <Button type="text" danger icon={<InfoCircleOutlined />} />
                                    </Tooltip>

                                    <Tooltip title="拷贝链接">
                                        <Button
                                            type="text"
                                            icon={<CopyOutlined />}
                                            onClick={onCopyClick}
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </Descriptions.Item>
                        <Descriptions.Item label="Token">{app.callbackToken}</Descriptions.Item>
                        <Descriptions.Item label="EncodingAESKey">
                            {app.callbackSecretKey}
                        </Descriptions.Item>
                    </Descriptions>

                    {editable && (
                        <Button onClick={() => callbackFormModalRef.current.showModal()}>
                            Token, Key 配置
                        </Button>
                    )}
                </Col>
            </Row>

            <AppCallbackFormModal ref={callbackFormModalRef} appId={appId} reloadApp={retry} />
            <WecomAppFormModal ref={updateModalRef} onSaveSuccess={retry} />
            <AddAllowUserModal ref={addAllowUserModalRef} appId={appId} allowTags={app.allowTags} />
        </PageHeader>
    );
}

AppInfo.propTypes = {
    appId: PropTypes.string.isRequired,
    onAppDeleted: PropTypes.func,
    editable: PropTypes.bool,
    deletable: PropTypes.bool,
    roleEditable: PropTypes.bool,

    /**
     * 是否允许批量上传人员到可见范围标签
     */
    appAllowAddUsers: PropTypes.bool,
};
