import React from 'react';
import PropTypes from 'prop-types';
import {Route} from 'react-router-dom';
import _ from 'lodash';

import {GLOBAL_ADMIN_READ, GLOBAL_ADMIN_WRITE, useAuthStore} from '@/context/auth';
import Forbidden from './Forbidden';

/**
 * 对与 Route 的封装，增加 onAuthorities 字段用于控制权限。
 *
 * @param onAuthorities 此路由支持的权限列表
 * @param rest 其它 Route 属性
 * @return {JSX.Element|null}
 * @constructor
 */
export default function AuthRoute({onAuthorities, ...rest}) {
    const [authStore] = useAuthStore();

    // 没有提供支持的权限，则正常渲染
    if (_.isEmpty(onAuthorities)) {
        return <Route {...rest} />;
    }

    const userAuths = _.get(authStore, 'authorities', []) ?? [];
    if (userAuths.includes(GLOBAL_ADMIN_READ) || userAuths.includes(GLOBAL_ADMIN_WRITE)) {
        return <Route {...rest} />;
    }

    for (const auth of onAuthorities) {
        if (userAuths.includes(auth)) {
            return <Route {...rest} />;
        }
    }

    // 没有权限则不渲染相应 route
    return <Forbidden />;
}

AuthRoute.propTypes = {
    // 支持的授权列表
    onAuthorities: PropTypes.arrayOf(PropTypes.string),
};
