import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Form, Input, message, Select} from 'antd';
import _ from 'lodash';

import MessageContent from '@/page/app/component/MessageContent';

const {Option} = Select;

const MenuForm = forwardRef(({isMain, appId, parentId, menuInfo, onFinish}, ref) => {
    const [form] = Form.useForm();
    const contentRef = useRef();

    const [select, setSelect] = useState('view');

    const [formFields, setFormFields] = useState(menuInfo);
    form.setFieldsValue(formFields);

    useEffect(() => {
        const menuType = menuInfo && menuInfo.type ? menuInfo.type : 'view';
        setSelect(menuType);
        form.setFieldsValue(menuInfo);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuInfo]);

    useEffect(() => {
        if (select === 'click' && !_.isEmpty(menuInfo)) {
            contentRef.current.setData(_.get(menuInfo, 'msgType', 'text'), menuInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [select]);

    useImperativeHandle(ref, () => ({
        submit,
    }));

    function submit() {
        form.submit();
    }

    function handleChange(value) {
        setFormFields(form.getFieldsValue(true));
        setSelect(value);
    }

    function onFinishForm(values) {
        console.log('onFinishForm', values);
        const {type} = values;
        if (type === 'click') {
            const {msgType, safe, content} = contentRef.current.getData();
            if (_.isEmpty(content)) {
                message.warn('「消息内容」不能为空');
                return;
            }
            onFinish({
                msgType,
                safe,
                ...content,
                ...values,
            });
        } else {
            onFinish(values);
        }
    }

    return (
        <Form form={form} onFinish={onFinishForm} layout="vertical">
            <Form.Item hidden name="id">
                <Input />
            </Form.Item>

            <Form.Item hidden name="appId" initialValue={appId}>
                <Input />
            </Form.Item>

            <Form.Item hidden name="parentId" initialValue={parentId}>
                <Input />
            </Form.Item>

            <Form.Item hidden name="listOrder">
                <Input />
            </Form.Item>

            <Form.Item
                name="name"
                label={isMain ? '主菜单' : '子菜单'}
                rules={[
                    {
                        required: true,
                    },
                ]}
            >
                <Input placeholder="菜单名" />
            </Form.Item>

            <Form.Item style={{marginLeft: 20}} name="type" label="菜单内容" initialValue={select}>
                <Select placeholder="请选择类型" onChange={handleChange}>
                    <Option value="view">跳转到网页</Option>
                    <Option value="click">发送消息</Option>
                </Select>
            </Form.Item>

            {select === 'view' && (
                <Form.Item
                    style={{flex: 1, marginLeft: 20}}
                    label="网址"
                    name="url"
                    rules={[
                        {
                            required: true,
                            max: 1024,
                        },
                    ]}
                >
                    <Input placeholder="请输入跳转网址" maxLength={1000} />
                </Form.Item>
            )}

            {select === 'click' && <MessageContent ref={contentRef} />}

            <Divider />

            <Form.Item style={{display: 'none'}}>
                <Button type="primary" htmlType="submit" />
            </Form.Item>
        </Form>
    );
});

MenuForm.propTypes = {
    onFinish: PropTypes.func,
    menuInfo: PropTypes.object,
    appId: PropTypes.string,
    isMain: PropTypes.bool,
    parentId: PropTypes.string,
};

MenuForm.displayName = 'MenuForm';

export default MenuForm;
