import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import styles from './WaterMask.module.less';

export default function WaterMask({text, width = 200, fontSize = 16, height = 150}) {
    const [maskDataUrl, setMaskDataUrl] = useState(null);

    useEffect(() => {
        const canvas = document.createElement('canvas');

        canvas.setAttribute('width', width + 'px');
        canvas.setAttribute('height', height + 'px');
        const ctx = canvas.getContext('2d');

        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        const fontArgs = ctx.font.split(' ');
        console.log('fontArgs', fontArgs);
        ctx.font = `${fontSize}px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`;
        ctx.fillStyle = 'rgba(184, 184, 184, 1)';
        ctx.rotate((Math.PI / 180) * -30);
        ctx.fillText(text, 0, height - fontSize * 2);

        setMaskDataUrl(canvas.toDataURL());
    }, [fontSize, height, text, width]);
    console.log(maskDataUrl);
    if (!text) {
        return null;
    }

    const style = {backgroundImage: `url('${maskDataUrl}')`};
    return <div className={styles.container} style={style} />;
}

WaterMask.propTypes = {
    text: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    fontSize: PropTypes.number,
};
