import {useAsync} from 'react-use';
import dayjs from 'dayjs';
import _ from 'lodash';

import {getHourlyReportNews, getSumReport, getSingleNewsStat} from '@/api/report';

/**
 * 昨天、最近 7 天、近 30 天、上个月的统计数据
 *
 * @param appId
 * @param alias
 */
export function useNewsSumReports(appId, alias) {
    const yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD');
    const last1 = [yesterday, yesterday];
    const last7 = [dayjs().subtract(8, 'day').format('YYYY-MM-DD'), yesterday];
    const last30 = [dayjs().subtract(31, 'day').format('YYYY-MM-DD'), yesterday];
    const prevMonth = [
        dayjs().subtract(62, 'day').format('YYYY-MM-DD'),
        dayjs().subtract(32, 'day').format('YYYY-MM-DD'),
    ];

    return useAsync(async () => {
        const last1Data = await getSumReport(alias, appId, last1[0], last1[1]);
        const last7Data = await getSumReport(alias, appId, last7[0], last7[1]);
        const last30Data = await getSumReport(alias, appId, last30[0], last30[1]);
        const prevMonthData = await getSumReport(alias, appId, prevMonth[0], prevMonth[1]);

        return [
            {...last1Data.data, title: '昨天', id: 'last1'},
            {...last7Data.data, title: '7天', id: 'last7'},
            {
                ...last30Data.data,
                title: '30天',
                id: 'last30',
            },
            {
                ...prevMonthData.data,
                title: '上月',
                id: 'prevMonth',
            },
        ];
    }, [appId]);
}

export function useHourlyNewsData(appId) {
    return useAsync(async () => {
        const {data} = await getHourlyReportNews(appId);
        // console.log('useHourlyNewsData', data);
        if (_.isEmpty(data)) {
            return [];
        }

        return data.reduce((accumulator, currentValue, index) => {
            const {
                date,
                dateHour,
                sendCount,
                newsCount,
                toUserCount,
                readCount,
                readUserCount,
            } = currentValue;

            accumulator.push({
                category: '群发图文（次）',
                index,
                date,
                dateHour,
                value: sendCount,
            });
            accumulator.push({
                category: '文章（篇）',
                index,
                date,
                dateHour,
                value: newsCount,
            });
            accumulator.push({
                category: '群发人数',
                index,
                date,
                dateHour,
                value: toUserCount,
            });
            accumulator.push({
                category: '阅读数（次）',
                index,
                date,
                dateHour,
                value: readCount,
            });
            accumulator.push({
                category: '阅读数（人）',
                index,
                date,
                dateHour,
                value: readUserCount,
            });

            return accumulator;
        }, []);
    }, []);
}

export function useSingleNewsStat(appId, newsId, start, end) {
    // console.log('useSingleNewsStat', newsId, start, end);
    return useAsync(async () => {
        const {data} = await getSingleNewsStat(appId, newsId, start, end);
        if (_.isEmpty(data)) {
            return [];
        }

        return data.reduce((accumulator, currentValue, index) => {
            const {
                accessDate,
                praiseCount,
                shareCount,
                shareUserCount,
                readCount,
                readUserCount,
            } = currentValue;

            accumulator.push({
                category: '阅读数（次）',
                accessDate,
                value: readCount,
            });
            accumulator.push({
                category: '阅读数（人）',
                accessDate,
                value: readUserCount,
            });
            accumulator.push({
                category: '转发分享（次）',
                accessDate,
                value: shareCount,
            });
            accumulator.push({
                category: '转发分享（人）',
                accessDate,
                value: shareUserCount,
            });
            accumulator.push({
                category: '点赞数',
                accessDate,
                value: praiseCount,
            });

            return accumulator;
        }, []);
    }, [appId, newsId, start, end]);
}
