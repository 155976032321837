import React from 'react';
import PropTypes from 'prop-types';
import {useAsyncRetry} from 'react-use';

import {getAppRoles} from '@/api/apps';
import {getAppAudits} from '@/api/msgAudit';
import AppRoleView from './AppRoleView';
import MsgAuditView from './MsgAuditView';
import {useAppCustomSettings} from '@/page/admin/AppCustomPage/appCustomService';

AppRoleWrapper.propTypes = {
    appId: PropTypes.string.isRequired,
    roleEditable: PropTypes.bool,
};

/**
 * app role、audit 的封装。
 *
 * @param appId 应用id
 * @param roleEditable 是否允许编辑角色
 * @returns {JSX.Element}
 * @constructor
 */
function AppRoleWrapper({appId, roleEditable = false}) {
    const {appMsgAudit} = useAppCustomSettings(appId);
    console.log('-----supportAppMsgAudit', appMsgAudit);

    const {value: appRoles, retry: retryRoles} = useAsyncRetry(() => {
        return getAppRoles(appId).then(({data}) => data);
    }, [appId]);
    console.log('-----appRoleValue, loading', appRoles);

    const {value: appAudits, retry: retryAudit} = useAsyncRetry(() => {
        if (!appMsgAudit) {
            return Promise.resolve([]);
        }
        return getAppAudits(appId).then(({data}) => data);
    }, [appId, appMsgAudit]);

    console.log('-----appAudits', appAudits);

    /**
     * 修改角色的时候同时刷新角色列表和审核人列表
     */
    function reload() {
        retryRoles();
        retryAudit();
    }

    return (
        <>
            <AppRoleView
                appId={appId}
                roleEditable={roleEditable}
                appRoles={appRoles}
                reload={reload}
            />
            <MsgAuditView
                appId={appId}
                roleEditable={roleEditable}
                supportAppMsgAudit={appMsgAudit}
                appAudits={appAudits}
                retryAudit={retryAudit}
            />
        </>
    );
}

export default AppRoleWrapper;
