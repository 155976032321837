import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useAsync} from 'react-use';

import {TagTag} from '@/component';

import {getUserTags} from '@/api/users';

export default function UserTags({userId}) {
    const {loading, value} = useAsync(() => {
        return getUserTags(userId);
    }, [userId]);

    // console.log('loading, value, error', loading, value, error);

    if (loading) {
        return null;
    }

    const tags = value?.data;
    if (_.isEmpty(tags)) {
        return null;
    }

    return tags.map(({id, name}) => (
        <TagTag key={id} style={{marginTop: 4, marginBottom: 4}}>
            {name}
        </TagTag>
    ));
}

UserTags.propTypes = {
    userId: PropTypes.string.isRequired,
};
