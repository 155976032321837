import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Avatar, Modal} from 'antd';
import _ from 'lodash';

import UserView from './UserView';
import {getUser} from '@/api/users';

import styles from './UserViewModal.module.less';

const UserViewModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState({});

    /**
     * 打开 Modal
     *
     * @param record 如果是对象，表示传入的用户对象，则直接展示；如果是字符串，则认为是 userId，则载入用户信息
     */
    function showModal(record) {
        setVisible(true);
        if (_.isPlainObject(record)) {
            setRecord(record);
        } else if (_.isString(record)) {
            getUser(record).then(({data}) => {
                setRecord(data);
            });
        }
    }

    function closeModal() {
        setVisible(false);
        setRecord({});
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title={record.name}
            width={800}
            onCancel={closeModal}
            okButtonProps={{
                style: {display: 'none'},
            }}
        >
            {record && record.thumbAvatar && (
                <div className={styles.avatarWrap}>
                    <Avatar
                        className={styles.avatar}
                        src={record.thumbAvatar}
                        alt="UN"
                        size={128}
                    />
                </div>
            )}
            <UserView user={record} />
        </Modal>
    );
});

export default UserViewModal;
