import {getJson, postJson, patchJson, putJson, deleteJson} from '@/util/ajaxUtils';

/**
 * 返回所有部门（树形结构）
 *
 * @return {Promise<*>}
 */
export function getDeparts(fromWecom = false) {
    return getJson('/departs', {params: {fromWecom}});
}

export function addDepart(depart, toWecom = false) {
    return postJson('/departs', {data: depart, params: {toWecom}});
}

export function updateDepart(id, depart, toWecom = false) {
    return patchJson(`/departs/${id}`, {data: depart, params: {toWecom}});
}

export function deleteDepart(id) {
    return deleteJson(`/departs/${id}`);
}

/**
 * 上移或下移，变更给定两个部门的排序。
 *
 * @param departId1
 * @param departId2
 * @return {Promise<{data: null, success: boolean, message: string, status: number}|*|*|undefined>}
 */
export function switchOrder(departId1, departId2) {
    return patchJson(`/departs/switch-order`, {params: {departId1, departId2}});
}

export function getDepartUsers(departId, params) {
    return getJson(`/departs/${departId}/users`, {params});
}

export function addDepartUser(departId, user, toWecom = false) {
    return postJson(`/departs/${departId}/users`, {data: user, params: {toWecom}});
}

export function updateDepartUser(departId, user, toWecom = false) {
    return putJson(`/departs/${departId}/users/${user.id}`, {data: user, params: {toWecom}});
}

export function getUserLinks(userId) {
    return getJson(`/depart-user-link`, {params: {userId}});
}

export function batchUpdateUserLinks(userId, links) {
    return postJson(`/depart-user-link`, {params: {userId}, data: links});
}
