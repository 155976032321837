import {PureComponent} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import styles from './PortalOverlay.module.less';

/**
 * Portal 类的基础组件，用于创建个 Portal 容器
 */
export default class PortalOverlay extends PureComponent {
    static propTypes = {
        className: PropTypes.string,
        children: PropTypes.node.isRequired,
    };

    static defaultProps = {
        className: null,
    };

    constructor(props) {
        super(props);

        this.overlayEl = document.createElement('div');
        this.overlayEl.classList.add('app-overlay', styles.overlay, styles.hide);
        if (props.className) {
            this.overlayEl.classList.add(props.className);
        }

        this.overlayEl.onclick = this.close;

        const body = document.querySelector('body');
        body.appendChild(this.overlayEl);
    }

    componentWillUnmount() {
        if (this.overlayEl) {
            const body = document.querySelector('body');
            body.removeChild(this.overlayEl);
        }
    }

    /**
     * 用于外部调用显示
     *
     * @public
     */
    show = () => {
        if (this.overlayEl) {
            this.overlayEl.classList.remove(styles.hide);
        }
    };

    close = () => {
        if (this.overlayEl) {
            this.overlayEl.classList.add(styles.hide);
        }
    };

    render() {
        return ReactDOM.createPortal(this.props.children, this.overlayEl);
    }
}
