import {useAsyncRetry} from 'react-use';

import {getSingleReply, getAllKeywordReply, getKeywordReply} from '@/api/reply';

export function useSingleReply(replyType, appId) {
    return useAsyncRetry(() => {
        return getSingleReply(replyType, appId);
    }, [replyType, appId]);
}

export function useKeywordReplyList(appId) {
    return useAsyncRetry(() => {
        return getAllKeywordReply(appId);
    }, [appId]);
}

export function useKeywordReply(appId, replyId) {
    return useAsyncRetry(async () => {
        if (!replyId) {
            return {data: null};
        }
        return await getKeywordReply(appId, replyId);
    }, [appId, replyId]);
}
