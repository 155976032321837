import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Comment} from 'antd';
import {formatLong} from '@/util/dateUtils';

import {TextView} from '@/component';

import CommentAction from './CommentAction';
import styles from './styles.module.less';

export default function CommentItem({
    comment,
    highlightCommentId,
    reload,
    onReplyClick,
    isChild = false,
    children,
}) {
    const isHighlight = comment.id === highlightCommentId;

    useEffect(() => {
        if (!isHighlight) {
            return;
        }

        // 自动滚动到可视区域
        const el = document.getElementById(comment.id);
        el.scrollIntoView();
    }, [comment.id, isHighlight]);

    return (
        <Comment
            key={comment.id}
            id={comment.id}
            content=""
            author={comment.userName}
            datetime={formatLong(comment.createTime)}
            avatar={
                <Avatar src={comment.thumbAvatar} alt="UN">
                    {comment.userName.charAt(0)}
                </Avatar>
            }
        >
            <div className={isHighlight ? styles.highlight : null}>
                <CommentAction
                    id={comment.id}
                    praiseNum={comment.praiseNum}
                    shown={comment.shown}
                    stick={comment.stick}
                    reload={reload}
                    onReplyClick={onReplyClick}
                    isChild={isChild}
                />

                <div className={styles.commentContent}>
                    <TextView>{comment.content}</TextView>
                </div>
            </div>
            {children}
        </Comment>
    );
}

CommentItem.propTypes = {
    comment: PropTypes.object.isRequired,
    highlightCommentId: PropTypes.string,
    onReplyClick: PropTypes.func,
    reload: PropTypes.func,
    isChild: PropTypes.bool,
    children: PropTypes.node,
};
