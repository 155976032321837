import PropTypes from 'prop-types';
import {Input, Form} from 'antd';
import React from 'react';

const PasswordFormItem = ({
    InputComponet = Input.Password,
    rules = [],
    label = '密码',
    min = 8,
    max = 40,
    inputProps,
    ...rest
}) => {
    return (
        <Form.Item
            name="password"
            label={label}
            rules={[
                {
                    min,
                    max,
                },
                {
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!-/:-@[-`{-~])[!-~]{6,}$/,
                    message: '必须包含大写、小写、数字和特殊字符，不包含空格、中文',
                },
                ...rules,
            ]}
            {...rest}
        >
            <InputComponet placeholder={label} {...inputProps} />
        </Form.Item>
    );
};

PasswordFormItem.propTypes = {
    InputComponet: PropTypes.func,
    rules: PropTypes.array,
    min: PropTypes.number,
    max: PropTypes.number,
    inputProps: PropTypes.object,
    label: PropTypes.node,
};

export default PasswordFormItem;
