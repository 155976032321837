import React, {useMemo, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Space} from 'antd';
import _ from 'lodash';

import {DeleteOutlined, EditOutlined, PlusOutlined, TagOutlined} from '@/icons';
import {TwoColumnLayout} from '@/component';
import TagIncludeTable from './TagIncludeTable';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';
import {addTagLinks} from '@/api/tags';
import BatchAddModal from './BatchAddModal';

export default function TagInfo({appId, tag, editable = true, onEditTagClick, onDeleteTagClick}) {
    const dutSelectorRef = useRef();
    const tableRef = useRef();
    const batchAddModalRef = useRef();

    const supportEditGlobalTag = useMemo(() => {
        if (!editable) {
            return false;
        }
        return !(appId && tag.scope === 'global');
    }, [appId, editable, tag.scope]);

    function onSelectClick() {
        dutSelectorRef.current.showModal();
    }

    function onBatchClick() {
        batchAddModalRef.current.showModal();
    }

    function reload() {
        tableRef.current.refreshTable();
    }

    function onSelect(values) {
        // console.log(values);
        if (_.isEmpty(values)) {
            message.warn('未选择任何部门/人员');
            return;
        }

        const departs = _.get(values, 'departs', []);
        const users = _.get(values, 'users', []);

        const departLinks = departs.map(({id}) => ({
            linkType: 1,
            linkId: id,
        }));
        const userLinks = users.map(({id}) => ({
            linkType: 2,
            linkId: id,
        }));

        const links = [...departLinks, ...userLinks];
        // console.log(links);
        return addTagLinks(tag.id, links).then(() => {
            reload();
            return true;
        });
    }

    return (
        <>
            <TwoColumnLayout.PanelHeader
                title={tag.name}
                subTitle={`企业微信 id: ${tag.extId}`}
                icon={<TagOutlined />}
            >
                {supportEditGlobalTag && (
                    <Space>
                        <Button type="primary" icon={<PlusOutlined />} onClick={onSelectClick}>
                            添加部门/成员
                        </Button>
                        <Button icon={<PlusOutlined />} onClick={onBatchClick}>
                            批量添加
                        </Button>
                        <Button icon={<EditOutlined />} onClick={onEditTagClick}>
                            修改标签
                        </Button>
                        <Button icon={<DeleteOutlined />} onClick={onDeleteTagClick}>
                            删除标签
                        </Button>
                    </Space>
                )}
            </TwoColumnLayout.PanelHeader>
            <TwoColumnLayout.PanelContent>
                <TagIncludeTable ref={tableRef} editable={supportEditGlobalTag} tagId={tag.id} />
            </TwoColumnLayout.PanelContent>

            <DepartUserTagSelector
                ref={dutSelectorRef}
                showTagTab={false}
                modalTitle="部门/成员选择"
                onSelect={onSelect}
            />

            <BatchAddModal ref={batchAddModalRef} tagId={tag.id} reload={reload} />
        </>
    );
}

TagInfo.propTypes = {
    appId: PropTypes.string,
    tag: PropTypes.shape({
        id: PropTypes.string.isRequired,
        scope: PropTypes.oneOf(['global', 'app']),
        extId: PropTypes.number,
        name: PropTypes.string.isRequired,
    }).isRequired,

    editable: PropTypes.bool,

    onEditTagClick: PropTypes.func,
    onDeleteTagClick: PropTypes.func,
};
