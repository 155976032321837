import React, {forwardRef, useImperativeHandle, useState} from 'react';

import {FullscreenModal} from '@/component';
import ArticleView from '@/page/article/comp/ArticleView';
import ArticleContainer from '@/page/article/comp/ArticleContainer';

const NewsContentPreviewModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [news, setNews] = useState(null);

    function showModal(news) {
        setVisible(true);
        setNews(news);
    }

    function closeModal() {
        setVisible(false);
        setNews(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <FullscreenModal
            destroyOnClose
            contentWidth={500}
            visible={visible}
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
        >
            <ArticleContainer>
                <ArticleView news={news} showDescription />
            </ArticleContainer>
        </FullscreenModal>
    );
});

NewsContentPreviewModal.displayName = 'NewsContentPreviewModal';

export default NewsContentPreviewModal;
