import React, {useRef} from 'react';
import {Button, Descriptions, PageHeader, Spin} from 'antd';

import './SettingsPage.less';

import CorpFormModal from './CorpFormModal';
import {EditOutlined} from '@/icons';
import {useAsyncRetry} from 'react-use';
import {getCorp} from '@/api/settings';
import {get as getData} from './utils';
import {useEditAuthService} from '@/page/admin/adminService';

const CorpSetting = () => {
    const formModalRef = useRef();

    const editable = useEditAuthService(['global:corp:w']);

    const {loading, value: corp, retry} = useAsyncRetry(() => {
        return getCorp();
    }, []);

    function get(key) {
        return getData(corp, `data.${key}`);
    }

    function onEditClick() {
        formModalRef.current.showModal(corp.data);
    }

    return (
        <PageHeader
            title="企业设置"
            extra={
                editable && <Button type="primary" icon={<EditOutlined />} onClick={onEditClick} />
            }
        >
            <Spin spinning={loading}>
                <Descriptions size="small" column={1} bordered className="settings-page">
                    <Descriptions.Item label="企业名称">{get('corpName')}</Descriptions.Item>
                    <Descriptions.Item label="企业别名">{get('corpAlias')}</Descriptions.Item>
                    <Descriptions.Item label="企业微信 ID">
                        {get('corpIdAtWecom')}
                    </Descriptions.Item>
                    <Descriptions.Item label="企业微信通讯录 Secret">
                        {get('contactSecret')}
                    </Descriptions.Item>
                </Descriptions>

                <Descriptions
                    size="small"
                    column={1}
                    bordered
                    className="settings-page"
                    style={{marginTop: 10}}
                >
                    <Descriptions.Item label="企业微信通讯录事件回调 Token">
                        {get('contactCallbackToken')}
                    </Descriptions.Item>
                    <Descriptions.Item label="企业微信通讯录事件回调 EncodingAESKey">
                        {get('contactCallbackSecret')}
                    </Descriptions.Item>
                    <Descriptions.Item label="企业微信通讯录事件回调 url">
                        {get('contactCallbackUrl')}
                    </Descriptions.Item>
                </Descriptions>
            </Spin>
            <CorpFormModal ref={formModalRef} reload={retry} />
        </PageHeader>
    );
};

export default CorpSetting;
