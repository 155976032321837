import {deleteJson, getJson, postJson, putJson} from '@/util/ajaxUtils';

export function getSingleReply(replyType, appId) {
    return getJson(`/autoreply/${appId}/single/${replyType}`);
}

export function saveSingleReply(replyType, appId, data) {
    return postJson(`/autoreply/${appId}/single/${replyType}`, {data});
}

export function deleteSingleReply(replyType, appId) {
    return deleteJson(`/autoreply/${appId}/single/${replyType}`);
}

export function getAllKeywordReply(appId) {
    return getJson(`/autoreply/${appId}/keywords`);
}

export function getKeywordReply(appId, replyId) {
    return getJson(`/autoreply/${appId}/keywords/${replyId}`);
}

export function addKeywordReply(appId, data) {
    return postJson(`/autoreply/${appId}/keywords`, {data});
}

export function updateKeywordReply(appId, replyId, data) {
    return putJson(`/autoreply/${appId}/keywords/${replyId}`, {data});
}

export function deleteKeywordReply(appId, replyId) {
    return deleteJson(`/autoreply/${appId}/keywords/${replyId}`);
}
