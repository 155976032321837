const query = {
    xs: {
        maxWidth: 575,
    },
    sm: {
        minWidth: 576,
        maxWidth: 767,
    },
    md: {
        minWidth: 768,
        maxWidth: 991,
    },
    lg: {
        minWidth: 992,
        maxWidth: 1199,
    },
    xl: {
        minWidth: 1200,
        maxWidth: 1599,
    },
    xxl: {
        minWidth: 1600,
    },
};

export const isMobileQuery = params => {
    const ret = params && (params.xs || params.sm || params.md);
    return !!ret;
};

export default query;
