import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {message, Modal} from 'antd';
import _ from 'lodash';

import MessageContent from '@/page/app/component/MessageContent';
import {saveSingleReply} from '@/api/reply';

const SingleReplyFormModal = forwardRef(({modalTitle, appId, replyType, reload}, ref) => {
    const [submiting, setSubmiting] = useState(false);
    const [visible, setVisible] = useState(false);
    const contentRef = useRef();

    function showModal(reply) {
        setVisible(true);

        if (!_.isEmpty(reply)) {
            const {msgType, ...rest} = reply;
            // console.log('........', rest);
            const timeout = setTimeout(() => {
                contentRef.current.setData(msgType, rest);
                clearTimeout(timeout);
            }, 500);
        }
    }

    function closeModal() {
        setVisible(false);
        setSubmiting(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        const {msgType, safe, content} = contentRef.current.getData();
        // console.log('msgType, safe, content', msgType, safe, content);

        if (_.isEmpty(content)) {
            message.warn('「消息内容」不能为空');
            return;
        }

        const replyData = {
            msgType,
            ...content,
            safe,
        };

        console.table(replyData);
        setSubmiting(true);
        saveSingleReply(replyType, appId, replyData)
            .then(data => {
                console.log(data);
                reload && reload();
                closeModal();
            })
            .finally(() => {
                setSubmiting(false);
            });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            width={1000}
            title={modalTitle}
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{loading: submiting}}
        >
            <MessageContent ref={contentRef} style={{maxWidth: '100%'}} />
        </Modal>
    );
});

SingleReplyFormModal.displayName = 'ReplyFormModal';

SingleReplyFormModal.propTypes = {
    modalTitle: PropTypes.string,
    appId: PropTypes.string.isRequired,
    replyType: PropTypes.oneOf(['global', 'join']).isRequired,
    reload: PropTypes.func,
};

export default SingleReplyFormModal;
