import React from 'react';
import PropTypes from 'prop-types';
import {Button, Typography} from 'antd';

import {PlusOutlined} from '@/icons';
import styles from './PlusButton.module.less';

export default function PlusButton({title, children, ...rest}) {
    return (
        <div className={styles.container}>
            <Button
                icon={<PlusOutlined className={styles.icon} />}
                className={styles.button}
                {...rest}
            >
                {title}
            </Button>
            {children && (
                <Typography.Text type="secondary" className={styles.description}>
                    {children}
                </Typography.Text>
            )}
        </div>
    );
}

PlusButton.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node,
};
