import {useAsyncRetry} from 'react-use';

import {getAllApps, getApp} from '@/api/apps';

export function useApps() {
    return useAsyncRetry(() => {
        return getAllApps();
    }, []);
}

export function useApp(appId) {
    return useAsyncRetry(() => {
        return getApp(appId);
    }, [appId]);
}
