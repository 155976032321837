import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Empty, Spin, Space, Input, Modal} from 'antd';
import _ from 'lodash';

import {TwoColumnLayout} from '@/component';
import {PlusOutlined, TagsOutlined} from '@/icons';

import TagList from './TagList';
import TagInfo from './TagInfo';
import TagFormModal from './TagFormModal';
import {deleteTag} from '@/api/tags';

import {useTags} from './useTagsService';

/**
 *
 * @param appId 暂时废弃，因为企业微信暂不支持应用标签添加、修改成员
 * @param editable
 * @param className
 * @return {JSX.Element}
 * @constructor
 */
export default function TagsPanel({appId, editable = true, className}) {
    const tagFormModalRef = useRef();
    const [selTag, setSelTag] = useState(null);
    const [searchValue, setSearchValue] = useState(null);
    const {loading, value, retry} = useTags(searchValue);
    // console.log(value);

    const tags = _.get(value, 'data', []);

    function onSearch(value) {
        setSearchValue(value);
    }

    function onTagSelect(tag) {
        // console.log(tag);
        setSelTag(tag);
    }

    function onEditTagClick() {
        // console.log(selTag);
        tagFormModalRef.current.showModal(selTag);
    }

    function onDeleteTagClick() {
        // console.log(selTag);
        Modal.confirm({
            title: '警告',
            content: `确定要删除标签「${selTag.name}」`,
            okButtonProps: {danger: true},
            onOk: () => {
                deleteTag(selTag.id).then(data => {
                    console.log(data);
                    retry();
                    setSelTag(null);
                });
            },
        });
    }

    return (
        <TwoColumnLayout className={className}>
            <TwoColumnLayout.SidePanel style={{width: 500}}>
                <TwoColumnLayout.PanelHeader title="标签" icon={<TagsOutlined />}>
                    <Space>
                        <Input.Search allowClear placeholder="标签查询" onSearch={onSearch} />
                        {editable && (
                            <Button
                                type="primary"
                                icon={<PlusOutlined />}
                                onClick={() => tagFormModalRef.current.showModal()}
                            />
                        )}
                    </Space>
                </TwoColumnLayout.PanelHeader>
                <TwoColumnLayout.PanelContent>
                    <Spin spinning={loading}>
                        <TagList tags={tags} onSelect={onTagSelect} />
                    </Spin>

                    <TagFormModal ref={tagFormModalRef} appId={appId} refreshTags={retry} />
                </TwoColumnLayout.PanelContent>
            </TwoColumnLayout.SidePanel>
            <TwoColumnLayout.ContentPanel>
                {selTag ? (
                    <TagInfo
                        appId={appId}
                        tag={selTag}
                        editable={editable}
                        onEditTagClick={onEditTagClick}
                        onDeleteTagClick={onDeleteTagClick}
                    />
                ) : (
                    <Empty description="点击标签查看详细信息" style={{marginTop: 100}} />
                )}
            </TwoColumnLayout.ContentPanel>
        </TwoColumnLayout>
    );
}

TagsPanel.propTypes = {
    appId: PropTypes.string,
    className: PropTypes.string,
    editable: PropTypes.bool,
};
