import React from 'react';

import TagsPanel from '@/page/admin/TagsPage/TagsPanel';
import useTitle from '@/hook/useTitle';

/**
 * @return {JSX.Element}
 * @constructor
 */
export default function AppTagsPage() {
    useTitle('可用标签');

    return <TagsPanel editable={false} />;
}
