import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Table} from 'antd';
import classNames from 'classnames';

import {ResponsiveConsumer} from '../ResponsiveView';

import {getJson} from '@/util/ajaxUtils';

import styles from './styles.module.less';

export default class PageGrid extends Component {
    /* eslint-disable react/forbid-prop-types */
    static propTypes = {
        url: PropTypes.string.isRequired,
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string,
                dataIndex: PropTypes.string,
                key: PropTypes.string,
                render: PropTypes.func,
            }).isRequired
        ).isRequired,

        size: PropTypes.number,

        // 初始的过滤参数
        initParams: PropTypes.object,

        tableProps: PropTypes.object,
        paginationProps: PropTypes.object,
    };

    static defaultProps = {
        size: 10,
        tableProps: null,
        paginationProps: null,
        initParams: {},
    };

    constructor(props) {
        super(props);

        this.state = {
            url: props.url,

            current: 1,
            size: props.size,
            records: [],
            total: 0,

            // 用于记录查询过滤条件
            filterParams: props.initParams || {},

            loading: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.url !== this.props.url) {
            this.setState({url: this.props.url}, () => {
                this.reload2FirstPage();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    /**
     * 用于外部调用强制刷新表格，一般在传入查询参数的时候使用
     * 此刷新会返回第一页。
     *
     * @public
     */
    reload2FirstPage = params => {
        this.setState(
            {
                current: 1,
                filterParams: params || {},
            },
            () => {
                this.loadData();
            }
        );
    };

    /**
     * 重新载入当前页数据。不修改任何参数。
     *
     * @public
     */
    refresh = () => {
        this.loadData();
    };

    loadData = () => {
        const {url, filterParams, current, size} = this.state;

        getJson(url, {
            params: {
                ...filterParams,
                current,
                size,
            },
        }).then(({data}) => {
            this._isMounted &&
                this.setState({
                    ...data,
                    loading: false,
                });
        });
    };

    onShowSizeChange = (current, pageSize) => {
        this.setState(
            {
                current,
                size: pageSize,
            },
            () => {
                this.loadData();
            }
        );
    };

    onChange = pageNumber => {
        this.setState(
            {
                current: pageNumber,
            },
            () => {
                this.loadData();
            }
        );
    };

    showTotal = total => {
        return `共 ${total} 条记录`;
    };

    render() {
        const {loading, records, current, size, total} = this.state;
        const {columns, tableProps, paginationProps} = this.props;
        const pagination = {
            position: ['bottomRight'],
            current,
            pageSize: size,
            total,
            // size: 'small',
            simple: false,
            showSizeChanger: true,
            onShowSizeChange: this.onShowSizeChange,
            onChange: this.onChange,
            showTotal: this.showTotal,
            ...paginationProps,
        };
        return (
            <ResponsiveConsumer>
                {({isMobile}) => {
                    if (isMobile) {
                        pagination.simple = true;
                    }

                    const cls = classNames({
                        [styles.tableResponsive]: isMobile,
                    });
                    return (
                        <Table
                            className={cls}
                            rowKey="id"
                            loading={loading}
                            dataSource={records}
                            columns={columns}
                            pagination={pagination}
                            {...tableProps}
                        />
                    );
                }}
            </ResponsiveConsumer>
        );
    }
}
