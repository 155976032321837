import React, {useRef} from 'react';
import {Button, message, PageHeader, Tag} from 'antd';
import {PlusOutlined} from '@/icons';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';
import {useSyncConfig} from './SyncConfigContext';

function SyncConfigGlobalDepart() {
    const DepartUserTagSelectorRef = useRef();

    const {configs, saveConfig, retry} = useSyncConfig();
    const {global_depart = []} = configs || {};

    function onClick() {
        DepartUserTagSelectorRef.current.showModal();
    }

    async function onSelect(values) {
        const {departs = []} = values || {};
        console.log('onSelect', values);
        if (departs.length > 1) {
            message.error('只能选择一个部门');
            return false;
        }

        const {id, name} = departs[0];
        const config = {
            dataType: 'global_depart',
            destId: id,
            destName: name,
        };
        const {status} = await saveConfig('global_depart', [config]);
        if (status === 200) {
            retry();
            return true;
        }

        return false;
    }

    return (
        <>
            <PageHeader
                title="全局目标部门"
                subTitle="所有新增人员均同步到该部门"
                extra={
                    <Button type="primary" size="small" icon={<PlusOutlined />} onClick={onClick}>
                        选择部门
                    </Button>
                }
            >
                {global_depart.map(({destId, destName}) => (
                    <Tag key={destId} color="#2db7f5">
                        {destName}
                    </Tag>
                ))}
            </PageHeader>
            <DepartUserTagSelector
                ref={DepartUserTagSelectorRef}
                modalTitle="选择部门"
                showTagTab={false}
                showDepartTab={true}
                showUserTab={false}
                onSelect={onSelect}
            />
        </>
    );
}

export default SyncConfigGlobalDepart;
