import {openGet} from './openAjaxUtils';

export function getOpenMedia(mediaId) {
    return openGet(`/open/article/media/${mediaId}`);
}

export function getOpenNews(id) {
    return openGet(`/open/article/news/${id}`);
}

export function getUserInfoByWecomCode(appId, code) {
    return openGet(`/open/wecom/userinfo`, {params: {appId, code}});
}

export function getWecomCorpId() {
    return openGet(`/open/wecom/corpid`);
}

export function getOpenApp(appId) {
    return openGet(`/open/apps/${appId}`);
}

export function getOpenMessage(msgId) {
    return openGet(`/open/messages/${msgId}`);
}

export function getOpenUser(userId) {
    return openGet(`/open/users/${userId}`);
}
