import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Form, Input, Radio, Modal, Space, Divider} from 'antd';
import _ from 'lodash';
import {isEmail} from '@/util/stringUtils';
import {addTagLinkByEmails} from '@/api/tags';

/**
 * 批量添加Email对应人员到选定标签
 *
 * 注意：前提条件是此应用存在标签，否则会给出错误信息
 */
const AddAllowUserModal = forwardRef(({appId, allowTags}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addMemberNum, setAddMemberNum] = useState(-1);
    const [notExistEmails, setNotExistEmails] = useState([]);
    // console.log('allowTags', allowTags);

    const enabled = !_.isEmpty(allowTags);

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
        setLoading(false);
        setAddMemberNum(-1);
        setNotExistEmails([]);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function getEmailList(emailString) {
        return emailString
            .split('\n')
            .map(email => email && email.trim().toLowerCase())
            .filter(Boolean);
    }

    function emailValidator(rule, value) {
        // 由于与 required: true 配合，所以这个验证发现是空值则返回成功，算是忽略下面操作
        if (!value || !value.trim()) {
            return Promise.resolve();
        }

        const emails = getEmailList(value);

        // 1000 是企业微信的规定，一次添加人员到标签的人数不能超过此值
        const maxEmailNum = 1000;
        if (emails.length > maxEmailNum) {
            return Promise.reject(new Error(`每次提交的 Email 数量不能超过 ${maxEmailNum}`));
        }

        const illegalEmail = emails.find(email => !isEmail(email.trim()));
        // console.log('illegalEmail', illegalEmail);

        if (illegalEmail) {
            return Promise.reject(new Error(`${illegalEmail} 不是合法的 Email 格式`));
        }

        return Promise.resolve();
    }

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const {destTagId, emails} = values;
        setLoading(true);
        addTagLinkByEmails(destTagId, getEmailList(emails))
            .then(({data}) => {
                const {addMemberNum, notExistEmails} = data;
                setAddMemberNum(addMemberNum);
                setNotExistEmails(notExistEmails);
                // console.log(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }


    function renderInfo() {
        if (addMemberNum === -1 && _.isEmpty(notExistEmails)) {
            return null;
        }

        const description = (
            <ul>
                <li>
                    共添加 <strong>{addMemberNum}</strong> 人
                </li>
                {!_.isEmpty(notExistEmails) && (
                    <li>
                        平台中不存在 email:
                        <ul>
                            {notExistEmails.map(email => (
                                <li key={email}>{email}</li>
                            ))}
                        </ul>
                    </li>
                )}
            </ul>
        );

        return (
            <>
                <Alert showIcon type="warning" message="结果" description={description} />
                <Divider />
            </>
        );
    }

    function renderContent() {
        if (!enabled) {
            return (
                <Alert
                    showIcon
                    type="warning"
                    message="警告"
                    description="此应用没有可见标签，无法执行此功能"
                />
            );
        }

        const initialValues = {
            destTagId: allowTags[0].allowId,
        };
        return (
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={initialValues}>
                <Form.Item
                    name="destTagId"
                    label="目标标签"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Radio.Group>
                        <Space direction="vertical">
                            {allowTags.map(({allowId, allowName}) => (
                                <Radio key={allowId} value={allowId}>
                                    {allowName}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="emails"
                    label="Email 列表"
                    rules={[
                        {
                            required: true,
                            type: 'string',
                            whitespace: true,
                        },
                        () => ({
                            validator: emailValidator,
                        }),
                    ]}
                    tooltip={{title: '每行一个 Email'}}
                >
                    <Input.TextArea placeholder="每行一个 Email" rows={10} />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        );
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="导入人员到应用可见标签"
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{disabled: !enabled, loading}}
        >
            {renderInfo()}
            {renderContent()}
        </Modal>
    );
});

AddAllowUserModal.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default AddAllowUserModal;
