import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, DatePicker, Form, Input, Select} from 'antd';

import MsgTypes from '@/page/component/msg/MsgTypes';
import {getEndTimestamp, getStartTimestamp} from '@/util/dateUtils';

export default function SendSearchForm({showSendFrom = true, onSearch}) {
    const formRef = useRef();

    function onResetClick() {
        formRef.current.resetFields();
        onSearch && onSearch(null);
    }

    function onFinish(values) {
        // console.log('onFinish', values);
        const {startDate, endDate} = values;
        const start = startDate ? getStartTimestamp(startDate) : undefined;
        const end = endDate ? getEndTimestamp(endDate) : undefined;

        const formData = {...values, start, end};
        // console.log(formData);
        onSearch && onSearch(formData);
    }

    return (
        <Form ref={formRef} layout="inline" onFinish={onFinish}>
            <Form.Item name="title">
                <Input placeholder="消息主题" style={{width: 120}} />
            </Form.Item>
            <Form.Item name="creatorName">
                <Input placeholder="操作人" style={{width: 100}} />
            </Form.Item>
            <Form.Item name="msgType">
                <Select style={{width: 80}} placeholder="类型">
                    {Object.keys(MsgTypes).map(key => {
                        const {label} = MsgTypes[key];
                        return (
                            <Select.Option key={key} value={key}>
                                {label}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Form.Item>
            {showSendFrom && (
                <Form.Item name="sendFrom">
                    <Select style={{width: 80}} placeholder="来源">
                        <Select.Option value="manual">Web</Select.Option>
                        <Select.Option value="menu">菜单</Select.Option>
                        <Select.Option value="reply">自动回复</Select.Option>
                        <Select.Option value="api">应用</Select.Option>
                    </Select>
                </Form.Item>
            )}
            <Form.Item name="startDate">
                <DatePicker placeholder="起始时间" />
            </Form.Item>
            <Form.Item name="endDate">
                <DatePicker placeholder="截止时间" />
            </Form.Item>
            <Form.Item>
                <Button.Group>
                    <Button htmlType="submit" type="primary">
                        搜索
                    </Button>
                    <Button htmlType="reset" onClick={onResetClick}>
                        重置
                    </Button>
                </Button.Group>
            </Form.Item>
        </Form>
    );
}

SendSearchForm.propTypes = {
    showSendFrom: PropTypes.bool,
    onSearch: PropTypes.func,
};
