import React from 'react';
import {useParams} from 'react-router-dom';

import AppInfo from '@/page/admin/AppsPage/AppInfo';
import AppContent from '@/page/component/AppLayout/AppContent';
import useTitle from '@/hook/useTitle';
import {useAppStore} from 'page/app/context';

export default function AppSettingsPage() {
    useTitle('应用设置');

    const {appId} = useParams();

    const [appStore] = useAppStore();
    console.log('appStore', appStore);
    const appAllowAddUsers = appStore?.extAttr?.appAllowAddUsers ?? false;

    return (
        <AppContent>
            <AppInfo
                appId={appId}
                roleEditable
                editable
                deletable={false}
                appAllowAddUsers={appAllowAddUsers}
            />
        </AppContent>
    );
}
