import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import NewsLikeContent from './comp/NewsLikeContent';
import NewsFormModal from '@/page/component/NewsFormModal';
import NewsPreview from '@/page/component/NewsFormModal/NewsPreview';
import {cleanNewsContents} from './contentUtils';
import NewsContentPreviewModal from './comp/NewsContentPreviewModal';

export default function NewsContent({value, onChange}) {
    const newsFormModalRef = useRef();
    const previewRef = useRef();

    const newsContents = value || [];

    const tmpIds = [];
    const newsById = {};
    newsContents.forEach(news => {
        const tmpId = news.id || _.uniqueId();
        tmpIds.push(tmpId);
        newsById[tmpId] = news;
    });

    function onAddClick() {
        newsFormModalRef.current.showModal();
    }

    function onEditClick() {
        newsFormModalRef.current.showModal({newsList: newsContents});
    }

    function onPreviewClick(tmpId, news) {
        // console.log('tmpId, news', tmpId, news);
        previewRef.current.showModal(news);
    }

    /**
     * 如果从素材选择未做任何编辑，或修改时未做任何编辑，则保持消息对于原有图文的指向，也就是说消息的 itemId 指向原本的素材图文
     *
     * 如果点开了图文编辑表单，且执行了「确定」操作，则图文内容原本的 id、itemId 均将被清除，也就是说会作为全新的图文保存
     *
     * @param businessId
     * @param mediaType
     * @param newsList
     * @return {boolean}
     */
    function onFormSuccess(businessId, mediaType, newsList) {
        onChange && onChange(cleanNewsContents(newsList));
        return true;
    }

    return (
        <>
            <NewsLikeContent
                hasValue={!_.isEmpty(value)}
                buttonTitle="添加图文"
                onAddClick={onAddClick}
                onEditClick={onEditClick}
                onChange={onChange}
                type="news"
            >
                <NewsPreview newsById={newsById} tmpIds={tmpIds} onPreviewClick={onPreviewClick} />
            </NewsLikeContent>

            <NewsFormModal
                ref={newsFormModalRef}
                itemFrom="message"
                onFormSuccess={onFormSuccess}
            />

            <NewsContentPreviewModal ref={previewRef} />
        </>
    );
}

NewsContent.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
};
