/* eslint-disable react/display-name */
import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Modal} from 'antd';

import {PageGrid} from '@/component';
import {getNewsReadUserUrl} from '@/api/report';
import {formatLong} from '@/util/dateUtils';
import UserViewModal from '@/page/admin/DepartsPage/UserViewModal';

const NewsReadUserModal = forwardRef(({appId}, ref) => {
    const userViewModalRef = useRef();
    const [visible, setVisible] = useState(false);
    const [newsId, setNewsId] = useState(null);

    function showModal(newsId) {
        setVisible(true);
        setNewsId(newsId);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onNameClick(record) {
        return () => {
            userViewModalRef.current.showModal(record);
        };
    }

    const columns = [
        {
            dataIndex: 'thumbAvatar',
            width: 64,
            render: (text, record) => {
                if (text) {
                    // UN 表示 unknown
                    return <Avatar src={text} alt="UN" />;
                }
                const {name} = record;
                if (name) {
                    return <Avatar>{name.charAt(0)}</Avatar>;
                }
                return '';
            },
        },
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => {
                const {name, alias, position} = record;

                return (
                    <div>
                        <Button
                            type="link"
                            onClick={onNameClick(record)}
                            style={{padding: '4px 0'}}
                        >
                            {name}
                        </Button>
                    </div>
                );
            },
        },
        {
            title: '最后访问',
            dataIndex: 'lastReadTime',
            render: text => {
                return formatLong(text);
            },
        },
    ];

    return (
        <>
            <Modal
                destroyOnClose
                visible={visible}
                title="图文阅读人员"
                onCancel={closeModal}
                okButtonProps={{style: {display: 'none'}}}
                cancelText="关闭"
            >
                {newsId && (
                    <PageGrid
                        columns={columns}
                        url={getNewsReadUserUrl(appId, newsId)}
                        tableProps={{size: 'small'}}
                        paginationProps={{simple: true}}
                    />
                )}
            </Modal>
            <UserViewModal ref={userViewModalRef} />
        </>
    );
});

NewsReadUserModal.displayName = 'NewsReadUserModal';

NewsReadUserModal.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default NewsReadUserModal;
