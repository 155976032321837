import React from 'react';
import PropTypes from 'prop-types';
import {Button, Tooltip} from 'antd';
import {DownloadOutlined} from '@/icons';

ChartDownloader.propTypes = {
    name: PropTypes.string,
    chartRef: PropTypes.object.isRequired,
};

function ChartDownloader({name, chartRef}) {
    function onClick() {
        const ref = (chartRef && chartRef.current) || chartRef;
        if (ref.downloadImage) {
            ref.downloadImage(name);
        }
    }

    return (
        <Tooltip title="下载图表">
            <Button icon={<DownloadOutlined />} onClick={onClick} />
        </Tooltip>
    );
}

export default ChartDownloader;
