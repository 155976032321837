import {getJson, postJson} from '@/util/ajaxUtils';

const custom = {noAuth: true, throwError: true, showErrorNotification: false};

export function openGet(url, config) {
    return getJson(url, {
        custom,
        ...config,
    });
}

export function openPost(url, config) {
    return postJson(url, {
        custom,
        ...config,
    });
}
