// -----------------------------------------
// app audit
// -----------------------------------------

import {getJson, postJson, patchJson} from '@/util/ajaxUtils';

export function getAppAudits(appId) {
    return getJson(`/appaudits/${appId}`);
}

export function updateAppAudits(appId, type, userIds) {
    return postJson(`/appaudits/${appId}`, {params: {type}, data: userIds});
}

export function isAppAuditUser(type, appId, userId) {
    return getJson(`/appaudits/${appId}/${userId}`, {params: {type}});
}

export function addAuditMsg(appId, auditMsg) {
    return postJson(`/appaudits/${appId}/messages`, {data: auditMsg});
}

export function getAuditMsg(appId, msgId) {
    return getJson(`/appaudits/${appId}/messages/${msgId}`);
}

export function getAuditMsgPageUrl(appId, creatorId) {
    return `/appaudits/${appId}/messages` + (creatorId ? `?creatorId=${creatorId}` : '');
}

/**
 * 更新审核状态
 *
 * @param appId
 * @param msgId
 * @param auditResult 1: 通过，2:驳回
 * @param auditDesc 驳回原因
 * @returns {Promise<{data: null, success: boolean, message: string, status: number}|*|undefined>}
 */
export function updateAuditResult(appId, msgId, auditResult, auditDesc) {
    return patchJson(`/appaudits/${appId}/messages/${msgId}`, {
        params: {auditResult},
        data: auditDesc,
    });
}
