import PropTypes from 'prop-types';
import {useInterval} from 'react-use';

import {ping} from '@/api/toolkit';

/**
 * 某些编辑类组件（例如图文编辑）可能耗时较长，所以使用这个组件间隔一段时间 ping 后台，以保证 token 不过期。
 *
 * @param {number} seconds 间隔秒数
 * @return {null}
 * @constructor
 */
export default function Ping({seconds = 60}) {
    useInterval(async () => {
        ping().catch(() => {});

        // 1 分钟执行一次
    }, seconds * 1000);

    return null;
}

Ping.propTypes = {
    seconds: PropTypes.number,
};
