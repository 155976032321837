import React from 'react';
import {Divider} from 'antd';

import AppContent from '@/page/component/AppLayout/AppContent';

import useTitle from '@/hook/useTitle';
import {useAppStore} from '@/page/app/context';
import KeywordsReply from './KeywordsReply';
import SingleReply from './SingleReply';

export default function MsgAutoReply() {
    useTitle('自动回复');
    const [{isMgr}] = useAppStore();

    return (
        <AppContent>
            <SingleReply
                editable={isMgr}
                replyType="join"
                title="用户加入通知"
                subTitle="当成员被加入应用的可见范围时，将收到此通知"
            />

            <Divider />

            <SingleReply
                editable={isMgr}
                replyType="global"
                title="全局自动回复"
                subTitle="设置成员发送任意消息后应用回复的内容"
            />

            <Divider />

            <KeywordsReply editable={isMgr} />
        </AppContent>
    );
}
