import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SidePanel from './SidePanel';
import ContentPanel from './ContentPanel';
import PanelHeader from './PanelHeader';
import PanelContent from './PanelContent';

import styles from './TwoColumnLayout.module.less';

/**
 * 两列布局.
 *
 * todo: 增加响应式支持
 *
 * @param className
 * @param style
 * @param enableScroll 是否允许滚动，也就是是 overflow-y: auto
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
function TwoColumnLayout({className, style, children}) {
    return (
        <div className={classNames(styles.container, className)} style={style}>
            {children}
        </div>
    );
}

TwoColumnLayout.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};

TwoColumnLayout.SidePanel = SidePanel;
TwoColumnLayout.ContentPanel = ContentPanel;
TwoColumnLayout.PanelHeader = PanelHeader;
TwoColumnLayout.PanelContent = PanelContent;

export default TwoColumnLayout;
