import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Form, Input, Modal} from 'antd';

import {updateCorp} from '@/api/settings';

const CorpFormModal = forwardRef(({reload}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [corp, setCorp] = useState({});

    function showModal(corp) {
        setVisible(true);
        setCorp(corp);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        updateCorp(values).then(data => {
            console.log(data);
            reload();
            closeModal();
        });
    }

    function renderCorpIdAtWecom() {
        return (
            <span>
                位置：企业微信控制台 &gt; 我的企业 &gt; 企业信息 &gt;{' '}
                <a
                    href="https://work.weixin.qq.com/wework_admin/frame#profile"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    企业 ID
                </a>
            </span>
        );
    }

    function renderContactSecretAtWecom() {
        return (
            <span>
                位置：企业微信控制台 &gt; 管理工具 &gt; 通讯录同步 &gt;{' '}
                <a
                    href="https://work.weixin.qq.com/wework_admin/frame#apps/contactsApi"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Secret
                </a>
            </span>
        );
    }

    function renderContactCallbackTokenAtWecom() {
        return (
            <span>
                位置：企业微信控制台 &gt; 管理工具 &gt; 通讯录同步 &gt; 设置接收事件服务器 &gt;{' '}
                <a
                    href="https://work.weixin.qq.com/wework_admin/frame#apps/contactsApi"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Token
                </a>
            </span>
        );
    }

    function renderContactCallbackSecretAtWecom() {
        return (
            <span>
                位置：企业微信控制台 &gt; 管理工具 &gt; 通讯录同步 &gt; 设置接收事件服务器 &gt;{' '}
                <a
                    href="https://work.weixin.qq.com/wework_admin/frame#apps/contactsApi"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    EncodingAESKey
                </a>
            </span>
        );
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title="编辑企业信息"
            onCancel={closeModal}
            onOk={onOk}
        >
            <Alert
                message="企业信息需预先在企业微信上维护"
                type="warning"
                showIcon
                style={{marginBottom: 24}}
            />

            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={corp}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Form.Item
                    name="corpName"
                    label="企业名称"
                    rules={[
                        {
                            required: true,
                            max: 100,
                        },
                    ]}
                >
                    <Input placeholder="企业名称" />
                </Form.Item>

                <Form.Item
                    name="corpAlias"
                    label="企业别名"
                    rules={[
                        {
                            max: 100,
                        },
                    ]}
                >
                    <Input placeholder="企业别名" />
                </Form.Item>

                <Form.Item
                    name="corpIdAtWecom"
                    label="企业微信上的企业 ID"
                    rules={[
                        {
                            required: true,
                            max: 32,
                        },
                    ]}
                    extra={renderCorpIdAtWecom()}
                >
                    <Input placeholder="企业 ID" />
                </Form.Item>

                <Form.Item
                    name="contactSecret"
                    label="企业微信上的通讯录 Secret"
                    rules={[
                        {
                            required: true,
                            max: 1000,
                        },
                    ]}
                    extra={renderContactSecretAtWecom()}
                >
                    <Input placeholder="通讯录 Secret" />
                </Form.Item>

                <Form.Item
                    name="contactCallbackToken"
                    label="企业微信通讯录事件回调 Token"
                    rules={[
                        {
                            max: 100,
                        },
                    ]}
                    extra={renderContactCallbackTokenAtWecom()}
                >
                    <Input placeholder="Token" />
                </Form.Item>

                <Form.Item
                    name="contactCallbackSecret"
                    label="企业微信通讯录事件回调 EncodingAESKey"
                    rules={[
                        {
                            max: 100,
                        },
                    ]}
                    extra={renderContactCallbackSecretAtWecom()}
                >
                    <Input placeholder="EncodingAESKey" />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

CorpFormModal.displayName = 'CorpFormModal';

CorpFormModal.propTypes = {
    reload: PropTypes.func,
};

export default CorpFormModal;
