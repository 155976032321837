import React, {useRef} from 'react';
import {Button, Space, Modal, message} from 'antd';
import SyncLogModal from './SyncLogModal';
import {manualSync} from '@/api/bi';

function SyncLog() {
    const SyncLogModalRef = useRef();

    function onShowLogClick() {
        SyncLogModalRef.current.showModal();
    }

    function onSyncClick() {
        Modal.confirm({
            title: '确认手工触发同步？',
            content:
                '手工触发同步会立即同步所有用户，这个操作约需 5-10 分钟，可在后续查看日志内容，是否继续？',
            okButtonProps: {danger: true},
            onOk() {
                manualSync().then(() => {
                    message.info('同步开始，请稍后查看同步日志');
                });
            },
        });
    }

    return (
        <>
            <Space style={{margin: '0 24px 24px'}}>
                <Button type="primary" onClick={onShowLogClick}>
                    查看同步日志
                </Button>
                <Button danger onClick={onSyncClick}>
                    手工触发同步
                </Button>
            </Space>
            <SyncLogModal ref={SyncLogModalRef} />
        </>
    );
}

export default SyncLog;
