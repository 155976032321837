import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';
import _ from 'lodash';

import {ColorfulList} from '@/component';

export default function TagList({tags, onSelect}) {
    if (_.isEmpty(tags)) {
        return null;
    }

    function onItemClick(item) {
        onSelect && onSelect(item);
    }

    function renderItem(item) {
        return (
            <List.Item>
                <List.Item.Meta title={item.name} />
            </List.Item>
        );
    }

    return <ColorfulList onItemClick={onItemClick} dataSource={tags} renderItem={renderItem} />;
}

TagList.propTypes = {
    tags: PropTypes.array,
    onSelect: PropTypes.func,
};
