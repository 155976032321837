/* eslint-disable react/display-name */
import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {PageHeader} from 'antd';
import {useToggle} from 'react-use';

import AppContent from '@/page/component/AppLayout/AppContent';
import {PageGrid} from '@/component';
import {getColumns} from './msgHistoryColumns';

export default function MsgHistory({
    title,
    onTitleClick,
    url,
    searchForm,
    searchFormData,
    columnKeys = ['status', 'msgType', 'title', 'safe', 'sendFrom', 'sender', 'createTime'],
}) {
    const gridRef = useRef();

    // 用于识别是否初始，防止多次载入 grid 数据
    const [init, setInit] = useToggle(true);

    useEffect(() => {
        if (!init) {
            gridRef.current.reload2FirstPage(searchFormData);
        }

        setInit(false);

        // 不要将 init 放入依赖
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchFormData]);

    function refresh() {
        gridRef.current.refresh();
    }

    const columns = getColumns(columnKeys, {onTitleClick, refresh});

    return (
        <AppContent>
            <PageHeader title={title} extra={searchForm}>
                <PageGrid ref={gridRef} url={url} columns={columns} />
            </PageHeader>
        </AppContent>
    );
}

MsgHistory.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    onTitleClick: PropTypes.func.isRequired,
    columnKeys: PropTypes.array,
    searchForm: PropTypes.node,
    searchFormData: PropTypes.object,
};
