import React, {useEffect, useRef, useState} from 'react';
import {Button, message, Modal, PageHeader, Pagination, Row} from 'antd';
import {useAsyncRetry} from 'react-use';
import copy from 'copy-to-clipboard';

import {PlusOutlined} from '@/icons';
import ClientAppFormModal from './ClientAppFormModal';
import ClientAppCard from './ClientAppCard';
import TicketUrlModal from './TicketUrlModal';

import {getClientAppPage, regenerateSignKey, deleteClientApp} from '@/api/clientApps';

export default function ClientAppsPage() {
    const formModalRef = useRef();
    const urlModalRef = useRef();

    const [pager, setPager] = useState({current: 1, pageSize: 10, total: 0});

    const {loading, value, retry} = useAsyncRetry(() => {
        return getClientAppPage(pager?.current ?? 1, pager?.pageSize ?? 10);
    }, []);
    // console.log('loading, value, retry', loading, value);
    // console.log('pager', pager);

    useEffect(() => {
        if (loading) {
            return;
        }

        const {current, size, total} = value?.data;
        setPager({current, pageSize: size, total});
    }, [loading, value]);

    const clientApps = value?.data?.records ?? [];

    // console.log('clientApps', clientApps);

    function onAddClick() {
        formModalRef.current.showModal();
    }

    function onEditClick(clientApp) {
        formModalRef.current.showModal(clientApp);
    }

    function onDeleteClick(clientApp) {
        // console.log(clientApp);
        Modal.confirm({
            title: '警告',
            content: `确定删除「${clientApp.name}」？`,
            okText: '删除',
            okButtonProps: {danger: true},
            onOk: () => {
                deleteClientApp(clientApp.id).then(() => {
                    // console.log(data);
                    retry();
                });
            },
        });
    }

    function onGenUrlClick(clientApp) {
        // console.log(clientApp);
        urlModalRef.current.showModal(clientApp);
    }

    function onRegenSignKeyClick(clientApp) {
        // console.log(clientApp);
        Modal.confirm({
            title: '警告',
            content: `确定重新生成「${clientApp.name}」的 Sign Key？`,
            okText: '重新生成',
            onOk: () => {
                regenerateSignKey(clientApp.id).then(data => {
                    console.log(data);
                    retry();
                });
            },
        });
    }

    function onCopyClick(text) {
        if (copy(text)) {
            message.success('拷贝成功');
        }
    }

    function onPageChange(current, pageSize) {
        // console.log(page, size);
        setPager({...pager, current, pageSize});
        retry();
    }

    const extra = (
        <Button type="primary" icon={<PlusOutlined />} onClick={onAddClick}>
            添加外部应用
        </Button>
    );

    return (
        <PageHeader title={`外部应用 (${pager.total})`} extra={extra} style={{overflowY: 'auto'}}>
            <Row gutter={24}>
                {clientApps.map(clientApp => (
                    <ClientAppCard
                        key={clientApp.id}
                        clientApp={clientApp}
                        onCopyClick={onCopyClick}
                        onEditClick={onEditClick}
                        onDeleteClick={onDeleteClick}
                        onGenUrlClick={onGenUrlClick}
                        onRegenSignKeyClick={onRegenSignKeyClick}
                    />
                ))}
            </Row>

            {pager.total > 0 && (
                <div style={{marginTop: '24px 0', paddingBottom: 48, textAlign: 'center'}}>
                    <Pagination {...pager} showSizeChanger={false} onChange={onPageChange} />
                </div>
            )}

            <ClientAppFormModal ref={formModalRef} reload={retry} />
            <TicketUrlModal ref={urlModalRef} />
        </PageHeader>
    );
}
