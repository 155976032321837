import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal} from 'antd';

import {CloudDownloadOutlined} from '@/icons';

import {addApp, getWecomAppInfo, updateApp} from '@/api/apps';
import WecomAppInfo from './WecomAppInfo';

const WecomAppFormModal = forwardRef(({onSaveSuccess}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [okButtonEnabled, setOkButtonEnabled] = useState(false);
    const [appId, setAppId] = useState(null);
    const [wecomToken, setWecomToken] = useState({});
    const [appInfo, setAppInfo] = useState(null);

    function showModal({appId, agentid, secret} = {}) {
        setVisible(true);
        setAppId(appId);
        setWecomToken({agentid, secret});
    }

    function isAdd() {
        return !appId;
    }

    // console.log('appid', appId);

    function closeModal() {
        setVisible(false);
        setAppInfo(null);
        setOkButtonEnabled(false);
        setAppId(null);
        setWecomToken({});
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        console.log(appId);
        console.log(appInfo);
        const promise = isAdd() ? addApp(appInfo) : updateApp(appId, appInfo);
        promise.then(data => {
            console.log(data);
            closeModal();
            onSaveSuccess();
        });
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        getWecomAppInfo(values).then(({data}) => {
            console.log(data);
            setOkButtonEnabled(true);
            setAppInfo(data);
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd() ? '添加应用' : '修改应用'}
            width={900}
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{disabled: !okButtonEnabled}}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={wecomToken}>
                <Form.Item
                    name="agentid"
                    label="企业微信应用 AgentId"
                    rules={
                        isAdd()
                            ? [
                                  {
                                      required: true,
                                      max: 100,
                                  },
                              ]
                            : null
                    }
                >
                    <Input placeholder="AgentId" disabled={!isAdd()} />
                </Form.Item>
                <Form.Item
                    name="secret"
                    label="企业微信应用 Secret"
                    rules={[
                        {
                            required: true,
                            max: 500,
                        },
                    ]}
                >
                    <Input placeholder="Secret" />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" icon={<CloudDownloadOutlined />}>
                        载入企业微信应用信息
                    </Button>
                </Form.Item>
            </Form>

            {appInfo && <WecomAppInfo appInfo={appInfo} />}
        </Modal>
    );
});

WecomAppFormModal.displayName = 'WecomAppFormModal';

WecomAppFormModal.propTypes = {
    onSaveSuccess: PropTypes.func.isRequired,
};

export default WecomAppFormModal;
