import React from 'react';

import AppContent from '@/page/component/AppLayout/AppContent';
import useTitle from '@/hook/useTitle';
import MsgSendPanel from './MsgSendPanel';

export default function MsgSendPage() {
    useTitle('发送消息');

    return (
        <AppContent>
            <MsgSendPanel />
        </AppContent>
    );
}
