import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Tabs, Input} from 'antd';

import {ApartmentOutlined, TagOutlined, TeamOutlined, UserOutlined} from '@/icons';
import DepartTabPane from './tabPane/DepartTabPane';
import UserTabPane from './tabPane/UserTabPane';
import TagTabPane from './tabPane/TagTabPane';
import AppAllowUserTabPane from './tabPane/AppAllowUserTabPane';

import styles from './MainPanel.module.less';

const TABS = {
    departTab: {
        key: 'departTab',
        tab: '部门',
        icon: <ApartmentOutlined />,
        component: DepartTabPane,
    },

    userTab: {key: 'userTab', tab: '人员', icon: <TeamOutlined />, component: UserTabPane},

    // 只在应用发送消息时可用
    allowUserTab: {
        key: 'allowUserTab',
        tab: '可见人员',
        icon: <UserOutlined />,
        component: AppAllowUserTabPane,
    },

    tagTab: {key: 'tagTab', tab: '标签', icon: <TagOutlined />, component: TagTabPane},
};

export default function MainPanel({
    showDepartTab = true,
    showUserTab = true,
    showTagTab = true,
    allowDepartIds,
    allowUsers,
    onAddSelected,
}) {
    const tabs = [];
    if (showDepartTab) {
        tabs.push(TABS.departTab);
    }
    if (showUserTab) {
        tabs.push(TABS.userTab);
    }
    if (Array.isArray(allowUsers) && allowUsers.length > 0) {
        tabs.push(TABS.allowUserTab);
    }
    if (showTagTab) {
        tabs.push(TABS.tagTab);
    }
    const [activeKey, setActiveKey] = useState(tabs[0].key);

    // 用于记录输入时查询框的值
    const [searchValue, setSearchValue] = useState(null);
    // 用于记录查询框最终值，之所以分为 2 个，是为了防止输入时候随时查询
    const [finalSearchValue, setFinalSearchValue] = useState(null);

    function onTabChange(key) {
        setActiveKey(key);
        setSearchValue(null);
        setFinalSearchValue(null);
    }

    function onSearchChange(e) {
        setSearchValue(e.target.value);
    }

    function onSearch(value) {
        setFinalSearchValue(value);
    }

    function clearSearchValue() {
        setSearchValue(null);
        setFinalSearchValue(null);
    }

    function getExtraProps(key) {
        if (key === 'departTab') {
            return {allowDepartIds};
        }
        if (key === 'userTab') {
            return {allowDepartIds, searchValue: finalSearchValue, clearSearchValue};
        }
        if (key === 'allowUserTab') {
            return {allowUsers};
        }
        if (key === 'tagTab') {
            return {searchValue: finalSearchValue, clearSearchValue};
        }
        return {};
    }

    function renderSearch() {
        let placeholder = '查询';
        if (activeKey === 'userTab') {
            placeholder = '按姓名、手机号、Email查询';
        } else if (activeKey === 'tagTab') {
            placeholder = '按标签名查询';
        }
        return (
            <Input.Search
                allowClear
                style={{width: 300}}
                placeholder={placeholder}
                value={searchValue}
                onChange={onSearchChange}
                onSearch={onSearch}
            />
        );
    }

    const extra = ['userTab', 'tagTab'].includes(activeKey) ? renderSearch() : null;

    return (
        <Tabs tabBarExtraContent={extra} activeKey={activeKey} onChange={onTabChange}>
            {tabs.map(({key, tab, icon, component: Comp}) => (
                <Tabs.TabPane
                    key={key}
                    tab={
                        <>
                            {icon}
                            {tab}
                        </>
                    }
                >
                    <div className={styles.tabPane}>
                        <Comp onAddSelected={onAddSelected} {...getExtraProps(key)} />
                    </div>
                </Tabs.TabPane>
            ))}
        </Tabs>
    );
}

MainPanel.propTypes = {
    /**
     * 是否显示部门选择 tab
     */
    showDepartTab: PropTypes.bool,

    /**
     * 是否显示人员选择 tab
     */
    showUserTab: PropTypes.bool,

    /**
     * 是否显示标签选择 tab
     */
    showTagTab: PropTypes.bool,

    /**
     * 可见的部门 id
     *
     * 如果为为 null 或 undefined, 表示所有部门可见
     *
     * 如果是 [] ，表示所有不可见
     * 如果有值，则对应部门和子部门可见
     */
    allowDepartIds: PropTypes.arrayOf(PropTypes.string),

    allowUsers: PropTypes.array,

    onAddSelected: PropTypes.func.isRequired,
};
