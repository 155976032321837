import React from 'react';
import PropTypes from 'prop-types';
import {Link, useHistory} from 'react-router-dom';
import classNames from 'classnames';
import {Avatar, Divider, Dropdown, Menu, Modal, Tooltip} from 'antd';
import _ from 'lodash';

import {APP_TITLE, showAtDev} from '@/env';
import {
    AppstoreAddOutlined,
    ControlTwoTone,
    LockOutlined,
    LogoutOutlined,
    UserOutlined,
} from '@/icons';
import {AvatarLabel} from '@/component';
import {useAuthStore} from '@/context/auth';
import {useHasAdminAuthService} from '@/page/admin/adminService';

import styles from './AppLayoutHeader.module.less';

const AppLayoutHeader = ({enabledHomeLink = true, subTitle, className, style}) => {
    const [authStore] = useAuthStore();
    const hasAdminAuth = useHasAdminAuthService();
    const history = useHistory();

    function onMenuClick({key}) {
        if (key === 'profile') {
            history.push('/profile');
        } else if (key === 'password') {
            history.push('/profile/password');
        } else if (key === 'logout') {
            Modal.confirm({
                title: '退出系统',
                content: '确定退出当前登录？',
                okButtonProps: {
                    danger: true,
                },
                onOk: () => {
                    history.push('/login');
                },
            });
        }
    }

    const menu = (
        <Menu className={styles.menu} onClick={onMenuClick}>
            <Menu.Item key="profile" icon={<UserOutlined />}>
                个人信息
            </Menu.Item>
            <Menu.Item key="password" icon={<LockOutlined />}>
                修改密码
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" danger icon={<LogoutOutlined />}>
                退出
            </Menu.Item>
        </Menu>
    );

    function renderHomeTitle() {
        return (
            <div className={styles.title}>
                <img
                    src={require('@/asset/bi-logo_blue.svg').default}
                    alt="logo"
                    style={{height: 30}}
                />
                <h1>{APP_TITLE}</h1>
            </div>
        );
    }

    return (
        <div className={classNames(styles.container, className)} style={style}>
            <div className={styles.titleWrap}>
                {enabledHomeLink ? (
                    <Tooltip title="返回首页" placement="right">
                        <Link to="/">{renderHomeTitle()}</Link>{' '}
                    </Tooltip>
                ) : (
                    renderHomeTitle()
                )}

                <h2 className={styles.subTitle}>{subTitle}</h2>
            </div>
            <div className={styles.actions}>
                {showAtDev && (
                    <>
                        <div className={styles.action}>
                            <Link to="/dev/icons">
                                <Avatar icon={<AppstoreAddOutlined />} style={{marginRight: 10}} />
                                Icons
                            </Link>
                        </div>
                        <Divider type="vertical" className={styles.divider} />
                    </>
                )}

                {hasAdminAuth && (
                    <>
                        <div className={styles.action}>
                            <Link to="/admin">
                                <Avatar icon={<ControlTwoTone />} style={{marginRight: 10}} />
                                系统管理
                            </Link>
                        </div>
                        <Divider type="vertical" className={styles.divider} />
                    </>
                )}
                <div className={styles.action}>
                    <Dropdown arrow overlay={menu} placement="bottomCenter">
                        <div>
                            <AvatarLabel text={_.get(authStore, 'user.name')} />
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    );
};

AppLayoutHeader.propTypes = {
    enabledHomeLink: PropTypes.bool,
    subTitle: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
export default AppLayoutHeader;
