/*
 * Created by liyi on 2021-03-15 11:13.
 */

import {postJson, putJson, patchJson} from '@/util/ajaxUtils';

export function exportContact() {
    return putJson(`/contact-sync`);
}

export function importContact(file) {
    return postJson('/contact-sync', {data: file});
}

export function dealLeaveContact(users) {
    return postJson(`/contact-sync/leave-users`, {data: users});
}

export function updateSyncContactTaskInfo(jobId) {
    return patchJson(`/syncLogs/${jobId}`);
}
