import React from 'react';
import PropTypes from 'prop-types';
import {Upload} from 'antd';

import {API_ROOT} from '@/env';
import {getTokens} from '@/util/TokenStore';

const UPLOAD_URL = `${API_ROOT}/attach`;

/**
 * 封装 antd 的 Upload 上传组件，默认提供了 action 和 category 等属性。
 *
 * @param category 附件类别
 * @param itemId 业务 id，可以为空
 * @param maxImgWidth 如是图片，则缩减图片尺寸到此宽度
 * @param maxImgHeight 如是图片，则缩减图片尺寸到此高度
 * @param children
 * @param rest
 * @return {JSX.Element}
 * @constructor
 */
export default function AppUpload({
    category = '_default',
    itemId = null,
    maxImgWidth = null,
    maxImgHeight = null,
    children,
    ...rest
}) {
    let extra = {};
    if (maxImgWidth && maxImgHeight) {
        extra = {maxImgWidth, maxImgHeight};
    }

    if (itemId) {
        extra.itemId = itemId;
    }

    return (
        <Upload
            action={UPLOAD_URL}
            data={{category, ...extra}}
            headers={{
                Authorization: `Bearer ${getTokens().access_token}`, // 获取 token 放在这儿可以在执行的时候再读取
            }}
            {...rest}
        >
            {children}
        </Upload>
    );
}

AppUpload.propTypes = {
    category: PropTypes.string.isRequired,
    itemId: PropTypes.string,
    maxImgWidth: PropTypes.number,
    maxImgHeight: PropTypes.number,
    children: PropTypes.node,
};
