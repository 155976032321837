import {deleteJson, getJson, patchJson, postJson} from '@/util/ajaxUtils';

export function getAllTags(searchValue) {
    return getJson(`/tags`, {params: {s: searchValue}});
}

export function addTag(data, toWecom = false) {
    return postJson(`/tags`, {data, params: {toWecom}});
}

export function updateTag(tagId, data, toWecom = false) {
    return patchJson(`/tags/${tagId}`, {data, params: {toWecom}});
}

export function deleteTag(tagId) {
    return deleteJson(`/tags/${tagId}`);
}

export function addTagLinks(tagId, links) {
    return postJson(`/tags/${tagId}/links`, {data: links});
}

export function addTagLinkByEmails(tagId, emails) {
    return postJson(`/tags/${tagId}/link-by-emails`, {data: emails});
}

export function deleteTagLink(tagId, linkId) {
    return deleteJson(`/tags/${tagId}/links/${linkId}`);
}
