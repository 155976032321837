import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';
import _ from 'lodash';

import {getFileDownloadUrl, getFileUrl} from '@/page/util/appUrlUtils';
import MsgTypes from '@/page/component/msg/MsgTypes';
import NewsMiniPreview from './NewsMiniPreview';

import styles from './MsgMiniPreview.module.less';

export default function MsgMiniPreview({
    msgType,
    title,
    content,
    attachId,
    attachExt,
    newsContents,
}) {
    function renderText() {
        if (msgType !== 'text') {
            return null;
        }

        return <div>{title}</div>;
    }

    function renderNews() {
        if (msgType !== 'news') {
            return null;
        }

        return <NewsMiniPreview newsContents={newsContents} />;
    }

    function renderNewsLinks() {
        if (msgType !== 'news_link') {
            return null;
        }

        return <NewsMiniPreview newsContents={newsContents} />;
    }

    function renderImage() {
        if (msgType !== 'image') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        return <img src={url} alt={title} style={{width: 'auto', maxHeight: 100}} />;
    }

    function renderVoice() {
        if (msgType !== 'voice') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        return (
            <Button href={url} target="_blank" type="link" className={styles.linkBtn}>
                {title}
            </Button>
        );
    }

    function renderVideo() {
        if (msgType !== 'video') {
            return null;
        }

        const url = getFileUrl(attachId, attachExt);
        return (
            <Button href={url} target="_blank" type="link" className={styles.linkBtn}>
                {title}
            </Button>
        );
    }

    function renderFile() {
        if (msgType !== 'file') {
            return null;
        }

        const url = getFileDownloadUrl(attachId, attachExt);
        return (
            <Button href={url} target="_blank" type="link" className={styles.linkBtn}>
                {title}
            </Button>
        );
    }

    return (
        <div className={styles.container}>
            <div className={styles.msgType}>{_.get(MsgTypes, msgType + '.label')}:</div>
            <div>
                {renderText()}
                {renderNews()}
                {renderNewsLinks()}
                {renderImage()}
                {renderVoice()}
                {renderVideo()}
                {renderFile()}
            </div>
        </div>
    );
}

MsgMiniPreview.propTypes = {
    msgType: PropTypes.string.isRequired,
    title: PropTypes.string,
    content: PropTypes.string,
    attachId: PropTypes.string,
    attachExt: PropTypes.string,
    newsContents: PropTypes.array,
};
