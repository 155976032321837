import {deleteJson, getJson, patchJson, postJson} from '@/util/ajaxUtils';

export function getUser(userId) {
    return getJson(`/users/${userId}`);
}

/**
 * 从所有部门中彻底删除用户。
 *
 * 此方法与 departs.js 中的 deleteDepartUser 不同，后者是如果用户属于多个部门，则单纯从给定部门中移出。
 *
 * @param userId
 * @return {Promise<*>}
 */
export function deleteUser(userId) {
    return deleteJson(`/users/${userId}`);
}

/**
 * 重置密码。
 *
 * @param userId
 * @param password
 * @return {Promise<*>}
 */
export function resetPassword(userId, password) {
    return patchJson(`/users/${userId}/pass-reset`, {data: password});
}

export function getUserTags(userId) {
    return getJson(`/users/${userId}/tags`);
}

export function batchUpdateUserTags(userId, tagIds) {
    return postJson(`/users/${userId}/tags`, {data: tagIds});
}
