import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Spin} from 'antd';
import _ from 'lodash';

import NewsPreview from '@/page/component/NewsFormModal/NewsPreview';
import {useNewsContentsService} from './newsService';

const NewsListModal = forwardRef(({onItemClick}, ref) => {
    const [visible, setVisible] = useState(false);
    const [media, setMedia] = useState(null);

    const {loading, value} = useNewsContentsService(_.get(media, 'id'));
    // console.log('news content ....', value);
    const newsContents = _.get(value, 'data');

    function showModal(media) {
        setVisible(true);
        setMedia(media);
    }

    function closeModal() {
        setVisible(false);
        setMedia(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    if (_.isEmpty(newsContents)) {
        return null;
    }

    const tmpIds = [];
    const newsById = {};
    newsContents.forEach(news => {
        tmpIds.push(news.id);
        newsById[news.id] = news;
    });

    // console.log('tmpIds newsById::::::::::', tmpIds, newsById);

    function onPreviewClick(tmpId, news) {
        console.log('tmpId, news', tmpId, news);
        onItemClick && onItemClick(news);
    }

    const mediaType = _.get(media, 'mediaType', 'news');
    const title = mediaType === 'news_link' ? '外链' : '图文';
    return (
        <Modal
            destroyOnClose
            title={`${title}预览`}
            visible={visible}
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
        >
            <Spin spinning={loading}>
                <NewsPreview
                    mediaType={mediaType}
                    tmpIds={tmpIds}
                    newsById={newsById}
                    onPreviewClick={onPreviewClick}
                    style={{width: 270, margin: '0 auto'}}
                />
            </Spin>
        </Modal>
    );
});

NewsListModal.displayName = 'NewsListModal';

NewsListModal.propTypes = {
    onItemClick: PropTypes.func,
};

export default NewsListModal;
