import React from 'react';
import {useParams} from 'react-router-dom';
import {Space} from 'antd';

import useTitle from '@/hook/useTitle';
import RecentList from './RecentList';
import DetailsReportList from '../component/DetailsReportList';
import LineChart from './LineChart';

const alias = 'user-increase';

export default function UserIncreaseReport() {
    useTitle('成员增长 - 数据统计');
    const {appId} = useParams();

    const detailsColumns = [
        {
            title: '时间',
            dataIndex: 'reportDate',
        },
        {
            title: '开通应用可见（人）',
            dataIndex: 'subscribeCount',
            align: 'right',
        },
        {
            title: '关闭应用可见（人）',
            dataIndex: 'unsubscribeCount',
            align: 'right',
        },
        {
            title: '已关注的可见成员',
            dataIndex: 'allowUserCount',
            align: 'right',
        },
    ];

    return (
        <Space direction="vertical" size={48} style={{display: 'flex'}}>
            <RecentList appId={appId} alias={alias} />
            <LineChart appId={appId} alias={alias} />
            <DetailsReportList appId={appId} alias={alias} columns={detailsColumns} />
        </Space>
    );
}
