/* eslint-disable react/display-name */
import React from 'react';
import {Avatar, Badge, Typography, Tag, Button} from 'antd';
import _ from 'lodash';

import {MailOutlined, MobileOutlined} from '@/icons';

const {Text} = Typography;

const columnDefines = ({onNameClick = _.noop} = {}) => ({
    avatar: {
        dataIndex: 'thumbAvatar',
        width: 64,
        render: (text, record) => {
            if (text) {
                // UN 表示 unknown
                return <Avatar src={text} alt="UN" />;
            }
            const {name} = record || {};
            if (name) {
                return <Avatar>{name.charAt(0)}</Avatar>;
            }
            return '';
        },
    },
    name: {
        title: '姓名',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
            const {name, alias, position} = record || {};

            return (
                <>
                    <div>
                        <Button
                            type="link"
                            onClick={onNameClick(record)}
                            style={{padding: '4px 0'}}
                        >
                            {name}
                        </Button>
                    </div>
                    {alias && (
                        <Text type="secondary" style={{marginRight: 10}}>
                            [别名：{alias}]
                        </Text>
                    )}
                    {position && <Text type="secondary">{position}</Text>}
                </>
            );
        },
    },
    contact: {
        title: '联系方式',
        dataIndex: 'email',
        render: (text, record) => {
            const {email, mobile} = record || {};

            return (
                <>
                    {email && (
                        <div>
                            <Text type="secondary">
                                <MailOutlined style={{marginRight: 5}} />
                            </Text>
                            {email}
                        </div>
                    )}
                    {mobile && (
                        <div>
                            <Text type="secondary">
                                <MobileOutlined style={{marginRight: 5}} />
                            </Text>
                            {mobile}
                        </div>
                    )}
                </>
            );
        },
    },
    state: {
        title: '状态',
        dataIndex: 'enabled',
        render: (text, record) => {
            const {onjob, enabled} = record || {};
            return (
                <div>
                    {onjob ? (
                        <div>
                            <Badge status="success" /> 在职
                        </div>
                    ) : (
                        <div>
                            <Badge status="error" /> 离职
                        </div>
                    )}
                    {enabled ? (
                        <div>
                            <Badge status="success" /> 账号正常
                        </div>
                    ) : (
                        <div>
                            <Badge status="error" /> 账号禁用
                        </div>
                    )}
                </div>
            );
        },
    },

    wecomStatus: {
        title: '企业微信状态',
        dataIndex: 'status',
        render: text => {
            if (text === 1) {
                return <Tag color="#2db7f5">已激活</Tag>;
            } else if (text === 2) {
                return <Tag color="red">已禁用</Tag>;
            } else if (text === 4) {
                return <Tag color="purple">未激活</Tag>;
            } else if (text === 5) {
                return <Tag color="#f50">退出企业</Tag>;
            }
            return '';
        },
    },

    biUserSync: {
        title: '用户同步',
        dataIndex: 'enableEdit',
        render: text => {
            if (text) {
                return <Tag color="#2db7f5">可编辑</Tag>;
            } else {
                return <Tag color="red">自动同步</Tag>;
            }
        },
    },
});

export function getColumns(keys, options) {
    const defines = columnDefines(options);
    return keys.map(key => defines[key]).filter(Boolean);
}
