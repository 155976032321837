import {getJson, deleteJson, postJson} from '@/util/ajaxUtils';

export function getAttach(id) {
    return getJson(`/attach/${id}`);
}

export function deleteAttach(id) {
    return deleteJson(`/attach/${id}`);
}

export function updateImageDataURL(category, itemId, imgWidth, imgHeight, dataUrl) {
    return postJson(`/attach/image-data-url`, {
        params: {category, itemId, imgWidth, imgHeight},
        data: dataUrl,
    });
}
