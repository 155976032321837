import React, {lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';

import {PageLoading} from '@/component';
import {showAtDev} from '@/env';
import NotFoundPage from '@/page/NotFoundPage';

export default function AppRoutes() {
    return (
        <Suspense fallback={<PageLoading />}>
            <Switch>
                <Route exact path="/" component={require('@/page/HomePage').default} />

                <Route exact path="/login" component={require('@/page/auth/LoginPage').default} />
                <Route exact path="/sso" component={require('@/page/auth/BISsoPage').default} />
                <Route
                    exact
                    path="/forget"
                    component={require('@/page/auth/ForgetPassPage').default}
                />
                <Route
                    exact
                    path="/password-reset"
                    component={require('@/page/auth/PasswordResetPage').default}
                />
                <Route
                    exact
                    path="/change-passord"
                    component={require('@/page/auth/ChangePassPage').default}
                />

                <Route path="/profile" component={require('@/page/ProfilePage').default} />

                {showAtDev && (
                    <Route path="/dev/icons" component={require('@/page/IconsPage').default} />
                )}

                <Route path="/apps/:appId" component={require('@/page/app/AppPage').default} />
                <Route path="/admin" component={require('@/page/admin/AdminPage').default} />

                <Route
                    path="/article/media/:mediaId"
                    component={lazy(() =>
                        import(
                            /* webpackChunkName: "article.mediaNewsView" */ '@/page/article/MediaNewsListViewPage'
                        )
                    )}
                />
                <Route
                    path="/article/media-news/:mediaId/:newsId"
                    component={lazy(() =>
                        import(
                            /* webpackChunkName: "article.mediaNewsView" */ '@/page/article/MediaNewsViewPage'
                        )
                    )}
                />
                <Route
                    path="/article/news/:msgId/:newsId"
                    component={lazy(() =>
                        import(
                            /* webpackChunkName: "article.newsView" */ '@/page/article/MessageNewsViewPage'
                        )
                    )}
                />

                <Route
                    path="/demo"
                    component={lazy(() =>
                        import(/* webpackChunkName: "app.demo" */ '@/page/DemoPage')
                    )}
                />

                <Route path="*" render={() => <NotFoundPage showButton />} />
            </Switch>
        </Suspense>
    );
}
