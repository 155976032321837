import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Modal, Spin} from 'antd';

import styles from './PreviewModal.module.less';

const PreviewModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [loading, setLoading] = useState(false);

    function showModal(url) {
        setLoading(true);
        setVisible(true);
        setPreviewUrl(url);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onImageLoad() {
        setLoading(false);
    }

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title="预览"
            width={800}
            wrapClassName={styles.modalWrap}
            okButtonProps={{style: {display: 'none'}}}
            onCancel={closeModal}
        >
            <Spin spinning={loading}>
                <img
                    src={previewUrl}
                    alt="图片预览"
                    style={{maxWidth: '100%', maxHeight: '100%'}}
                    onLoad={onImageLoad}
                />
            </Spin>
        </Modal>
    );
});

PreviewModal.displayName = 'PreviewModal';

export default PreviewModal;
