import React from 'react';

import RolesPanel from './RolesPanel';
import useTitle from '@/hook/useTitle';
import {useEditAuthService} from '@/page/admin/adminService';

export default function RolesPage() {
    useTitle('角色管理 - 系统管理');

    const editable = useEditAuthService(['global:role:w']);

    return <RolesPanel editable={editable} />;
}
