import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Modal} from 'antd';

import {TextView} from '@/component';

const TextViewModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [content, setContent] = useState(null);

    function showModal(content) {
        setVisible(true);
        setContent(content);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title={'文本素材'}
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
        >
            <TextView>{content}</TextView>
        </Modal>
    );
});

TextViewModal.displayName = 'TextViewModal';

export default TextViewModal;
