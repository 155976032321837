import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './AppContent.module.less';

export default function AppContent({className, style, autoPadding = true, children}) {
    const containerStyle = autoPadding ? {padding: 24, ...style} : style;

    return (
        <div className={classNames(styles.appContent, className)} style={containerStyle}>
            {children}
        </div>
    );
}

AppContent.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    autoPadding: PropTypes.bool,
    children: PropTypes.node,
};
