import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Divider, Button, Input, Menu, Modal, PageHeader, Space} from 'antd';
import _ from 'lodash';

import {
    ApartmentOutlined,
    AuditOutlined,
    DeleteOutlined,
    EditOutlined,
    FileProtectOutlined,
    LockOutlined,
    TagsOutlined,
} from '@/icons';
import {PageGrid, RowAction} from '@/component';

import RoleSelector from '../selector/RoleSelector';

import UserResetPassModal from './UserResetPassModal';
import MultiDepartEditModal from './MultiDepartEditModal';
import SyncContactToDBModal from './SyncContactToDBModal';
import SyncContactToWecomModal from './SyncContactToWecomModal';

import UserViewModal from './UserViewModal';
import UserFormModal from './UserFormModal';
import {getColumns} from './userListColumns';
import {batchUpdateUserTags, deleteUser, getUserTags} from '@/api/users';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';

function getDefaultSearchUrl(value) {
    return `/users?s=${encodeURIComponent(value)}`;
}

export default function UserList({
    depart,
    editable,
    columnKeys = ['avatar', 'name', 'contact', 'state', 'wecomStatus', 'biUserSync'],
    columnAction,
    getSearchUrl = getDefaultSearchUrl,
}) {
    const formModalRef = useRef();
    const roleSelRef = useRef();
    const tableRef = useRef();
    const viewRef = useRef();
    const syncDBRef = useRef();
    const syncWecomRef = useRef();
    const resetPassModalRef = useRef();
    const departEditModalRef = useRef();
    const dutSelectorRef = useRef();

    const [pageTitle, setPageTitle] = useState(null);
    const [url, setUrl] = useState(null);
    const [searchFlag, setSearchFlag] = useState(false);

    // console.log('url.....', url, depart);

    // 部门切换的时候重置相关数据
    useEffect(() => {
        if (!_.isEmpty(depart)) {
            reset2Depart();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [depart]);

    function reset2Depart() {
        setPageTitle(depart.name);
        setUrl(`/departs/${depart.id}/users`);
        setSearchFlag(false);
    }

    // if (_.isEmpty(depart) || !depart.id) {
    // return <Empty style={{marginTop: 100}} />;
    // }

    function onAddClick() {
        formModalRef.current.showModal();
    }

    function onNameClick(record) {
        return () => {
            viewRef.current.showModal(record);
        };
    }

    function onModifyClick(record) {
        formModalRef.current.showModal(record.id);
    }

    function onModifyDepartsClick(record) {
        departEditModalRef.current.showModal(record.id);
    }

    let selectUserId;

    function onModifyTagsClick(record) {
        console.log('record', record);
        if (!record.enableSync) {
            Modal.warn({
                title: '警告',
                content: '此用户并不同步到企业微信，故不可编辑标签',
            });
            return;
        }

        selectUserId = record.id;
        getUserTags(selectUserId).then(({data}) => {
            dutSelectorRef.current.showModal({
                initialValues: {tags: data},
            });
        });
    }

    function onTagsSelect(values) {
        // console.log('onTagsSelect', selectUserId, values);
        const {tags} = values;
        const tagIds = tags.map(({id}) => id);
        console.log(tagIds);
        return batchUpdateUserTags(selectUserId, tagIds).then(({data}) => {
            console.log(data);
            const {delErrors, newErrors} = data;
            if (!_.isEmpty(delErrors) || !_.isEmpty(newErrors)) {
                Modal.warn({
                    title: '批量更新标签时出现错误',
                    content: (
                        <>
                            {!_.isEmpty(delErrors) && (
                                <div>
                                    <p>删除已有标签出错：</p>
                                    <ul>
                                        {delErrors.map((tagName, index) => (
                                            <li key={index}>{tagName}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {!_.isEmpty(newErrors) && (
                                <div>
                                    <p>新增标签出错：</p>
                                    <ul>
                                        {newErrors.map((tagName, index) => (
                                            <li key={index}>{tagName}</li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </>
                    ),
                });
            }
            return true;
        });
    }

    function onDeleteClick(record) {
        const content = (
            <div>
                <p>确定删除 [{record.name}]？</p>
                <Divider />
                <p>注意：由于企业微信的限制，强烈不建议删除用户</p>
                <p>否则想要再次恢复相同 email 的人员时将比较麻烦</p>
                <p>建议使用「用户修改」中的「禁用」功能</p>
            </div>
        );

        Modal.confirm({
            title: '警告',
            content,
            okButtonProps: {danger: true},
            onOk: () => {
                deleteUser(record.id).then(() => {
                    refreshTable();
                });
            },
        });
    }

    function refreshTable() {
        tableRef.current.refresh();
    }

    function onSearch(value) {
        if (!value) {
            return;
        }

        setSearchFlag(true);

        setPageTitle(`查询：${value}`);
        setUrl(getSearchUrl(value));
    }

    function onSearchBlur(e) {
        if (!e.target.value) {
            reset2Depart();
        }
    }

    function onRoleSelect(user) {
        console.log(user);
        roleSelRef.current.showModal(user.id, true);
    }

    function onRoleView(user) {
        console.log(user);
        roleSelRef.current.showModal(user.id, false);
    }

    function onResetPass(user) {
        resetPassModalRef.current.showModal(user);
    }

    // function onMenuClick({key}) {
    //     if (key === 'sync2Local') {
    //         syncDBRef.current.showModal();
    //     } else if (key === 'sync2Wecom') {
    //         syncWecomRef.current.showModal();
    //     }
    // }

    function renderExtra() {
        // const menu = (
        //     <Menu onClick={onMenuClick}>
        //         <Menu.Item key="sync2Local">同步Csv数据到企业微信</Menu.Item>
        //         {/*<Menu.Item key="sync2Wecom">同步本地数据到企业微信</Menu.Item>*/}
        //     </Menu>
        // );
        return (
            <Space>
                <Input.Search
                    allowClear
                    loading={false}
                    onSearch={onSearch}
                    onBlur={onSearchBlur}
                    placeholder="按姓名、手机号、Email 查询"
                    style={{width: 260}}
                />
                {editable && (
                    <Button type="primary" onClick={onAddClick}>
                        添加成员
                    </Button>
                )}
            </Space>
        );
    }

    const columns = getColumns(columnKeys, {onNameClick});
    if (editable) {
        const actions = record => {
            let results = [
                {
                    key: 'setRole',
                    title: '赋角色',
                    hide: true,
                    icon: <FileProtectOutlined />,
                    onClick: onRoleSelect,
                },
                {
                    key: 'viewRoles',
                    title: '查看角色',
                    hide: true,
                    icon: <AuditOutlined />,
                    onClick: onRoleView,
                },
                {
                    key: 'divider',
                    isDivider: true,
                    hide: true,
                },
                {
                    key: 'resetPass',
                    title: '重置密码',
                    hide: true,
                    icon: <LockOutlined />,
                    onClick: onResetPass,
                },
                {
                    key: 'divider1',
                    isDivider: true,
                    hide: true,
                },
                {
                    key: 'modifyDeparts',
                    title: '修改部门',
                    hide: true,
                    icon: <ApartmentOutlined />,
                    onClick: onModifyDepartsClick,
                },
                {
                    key: 'modifyTags',
                    title: '修改标签',
                    hide: true,
                    icon: <TagsOutlined />,
                    onClick: onModifyTagsClick,
                },
            ];

            results.push(
                {
                    key: 'divider2',
                    isDivider: true,
                    hide: true,
                },
                {
                    key: 'modify',
                    title: '修改',
                    hide: true,
                    icon: <EditOutlined />,
                    onClick: onModifyClick,
                },
                {
                    key: 'delete',
                    title: '删除',
                    hide: true,
                    icon: <DeleteOutlined />,
                    onClick: onDeleteClick,
                }
            );

            return results;
        };

        columns.push({
            title: '操作',
            key: 'action',
            width: 100,
            // eslint-disable-next-line react/display-name
            render: (text, record) => <RowAction actions={actions(record)} record={record} />,
        });
    }

    if (!editable && columnAction) {
        columns.push(columnAction);
    }

    return (
        <div style={{flex: 'auto', flexDirection: 'row'}}>
            <PageHeader title={pageTitle} extra={renderExtra()} />

            <div style={{margin: '0 0 24px'}}>
                {url && <PageGrid ref={tableRef} url={url} columns={columns} />}
            </div>

            {!_.isEmpty(depart) && (
                <UserFormModal ref={formModalRef} departId={depart.id} reloadUsers={refreshTable} />
            )}

            <UserViewModal ref={viewRef} />

            <RoleSelector ref={roleSelRef} scope="global" />

            <UserResetPassModal ref={resetPassModalRef} />

            <SyncContactToDBModal ref={syncDBRef} reloadUsers={refreshTable} />

            <SyncContactToWecomModal ref={syncWecomRef} />

            <MultiDepartEditModal
                ref={departEditModalRef}
                reloadUsers={searchFlag ? null : refreshTable}
            />

            <DepartUserTagSelector
                ref={dutSelectorRef}
                showDepartTab={false}
                showUserTab={false}
                showTagTab
                modalTitle="标签选择"
                onSelect={onTagsSelect}
            />
        </div>
    );
}

UserList.propTypes = {
    depart: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
    }),
    editable: PropTypes.bool,
    columnKeys: PropTypes.arrayOf(PropTypes.string),
    columnAction: PropTypes.object,
    getSearchUrl: PropTypes.func,
};
