import React from 'react';
import PropTypes from 'prop-types';
import {Button, Typography} from 'antd';
import _ from 'lodash';
import classNames from 'classnames';

import {DeleteOutlined, DownOutlined, PlusOutlined, UpOutlined} from '@/icons';
import {getFileUrl} from '@/page/component/attachUtils';

import styles from './NewsPreview.module.less';

const NewsPreview = ({
    tmpIds,
    newsById,
    currentTmpId,
    onAddNews,
    onDeleteNews,
    onPreviewClick,
    onMoveToClick,
    selectable = false,
    editable = false,
    className,
    style,
}) => {
    // console.log('tmpIds, newsById, currentTmpId', tmpIds, newsById, currentTmpId);

    function get(tmpId, key, defaultValue) {
        const value = _.get(newsById[tmpId], key);
        if (!value || !value.trim()) {
            return defaultValue;
        }

        return value;
    }

    function onMoveClick(tmpId, pos) {
        return e => {
            e.stopPropagation();
            onMoveToClick(tmpId, pos);
        };
    }

    function onDeleteClick(tmpId) {
        return e => {
            e.stopPropagation();
            onDeleteNews(tmpId);
        };
    }

    function onItemPreviewClick(tmpId) {
        return () => {
            onPreviewClick && onPreviewClick(tmpId, newsById[tmpId]);
        };
    }

    function renderPreviewActions({tmpId, showUp = true, showDown = true} = {}) {
        if (!editable) {
            return null;
        }

        return (
            <div className={styles.previewActions}>
                <div className={styles.orderActions}>
                    {showDown && (
                        <div className={styles.previewAction} onClick={onMoveClick(tmpId, 1)}>
                            <DownOutlined />
                        </div>
                    )}

                    {showUp && (
                        <div onClick={onMoveClick(tmpId, -1)} className={styles.previewAction}>
                            <UpOutlined />
                        </div>
                    )}
                </div>
                <div onClick={onDeleteClick(tmpId)} className={styles.previewAction}>
                    <DeleteOutlined />
                </div>
            </div>
        );
    }

    function renderCoverImage(tmpId, large = true) {
        const {itemFrom, apiPicUrl, thumbAttachId, thumbAttachExt, thumbUrl} =
            newsById[tmpId] || {};

        const style = {objectFit: 'cover'};
        // const style = large ? {objectFit: 'cover'} : {objectFit: 'none'};

        let url = null;
        if (thumbUrl) {
            return <img className={styles.coverImg} style={style} src={thumbUrl} alt="封面" />;
        }

        if (thumbAttachId) {
            url = getFileUrl(thumbAttachId, thumbAttachExt);

            return <img className={styles.coverImg} style={style} src={url} alt="封面" />;
        }

        if (itemFrom === 'api' && apiPicUrl) {
            return <img className={styles.coverImg} style={style} src={apiPicUrl} alt="封面" />;
        }

        return null;
    }

    function renderSinglePreview() {
        const tmpId = currentTmpId || tmpIds[0];
        return (
            <div
                className={classNames(styles.preview, {[styles.selected]: selectable})}
                onClick={onItemPreviewClick(tmpId)}
            >
                <div className={styles.previewCover}>{renderCoverImage(tmpId)}</div>
                <div className={styles.detail}>
                    <Typography.Title level={5} ellipsis={{rows: 2}}>
                        {get(tmpId, 'title', '标题')}
                    </Typography.Title>
                    <Typography.Paragraph ellipsis={{rows: 4}}>
                        {get(tmpId, 'description', '摘要')}
                    </Typography.Paragraph>
                </div>

                {renderPreviewActions({tmpId: tmpId, showUp: false, showDown: false})}
            </div>
        );
    }

    function renderMultiPreviews() {
        return (
            <div className={styles.multiPreviews}>
                {tmpIds.map((id, index) => {
                    const selected = selectable && id === currentTmpId;
                    const title = get(id, 'title', '标题');

                    if (index === 0) {
                        return (
                            <div
                                key={id}
                                className={classNames(styles.preview, {
                                    [styles.selected]: selected,
                                })}
                                onClick={onItemPreviewClick(id)}
                            >
                                <div className={styles.previewCover}>
                                    {renderCoverImage(id)}

                                    <div className={styles.detail}>
                                        <Typography.Text ellipsis className={styles.title}>
                                            {title}
                                        </Typography.Text>
                                    </div>
                                </div>
                                {renderPreviewActions({tmpId: id, showUp: false})}
                            </div>
                        );
                    }

                    return (
                        <div
                            key={id}
                            className={classNames(styles.preview, {[styles.selected]: selected})}
                            onClick={onItemPreviewClick(id)}
                        >
                            <div className={classNames(styles.detail, styles.oneLineDetail)}>
                                <Typography.Text ellipsis className={styles.title}>
                                    {title}
                                </Typography.Text>
                                <div className={styles.thumbCover}>
                                    {renderCoverImage(id, false)}
                                </div>
                            </div>
                            {renderPreviewActions({
                                tmpId: id,
                                showDown: index !== tmpIds.length - 1,
                            })}
                        </div>
                    );
                })}
            </div>
        );
    }

    return (
        <div className={className} style={style}>
            {tmpIds.length <= 1 ? renderSinglePreview() : renderMultiPreviews()}

            {editable && tmpIds.length < 8 && (
                <div className={styles.buttonWrap}>
                    <Button
                        type="primary"
                        style={{width: '100%'}}
                        icon={<PlusOutlined />}
                        onClick={onAddNews}
                    >
                        添加图文
                    </Button>
                </div>
            )}
        </div>
    );
};

NewsPreview.propTypes = {
    /**
     * 编辑时允许点击预览列表切换表单内容，点击后会高亮被点击预览条目
     */
    selectable: PropTypes.bool,

    /**
     * 是否允许编辑功能，与表单配合时使用
     */
    editable: PropTypes.bool,

    /**
     * 临时 id 数组，最多 8 个
     */
    tmpIds: PropTypes.arrayOf(PropTypes.string).isRequired,

    /**
     * 以临时 id 为 key，表单内容为 value 的对象
     */
    newsById: PropTypes.object.isRequired,

    /**
     * 当前活动中的临时 id
     */
    currentTmpId: PropTypes.string,

    onMoveToClick: PropTypes.func,
    onPreviewClick: PropTypes.func,
    onAddNews: PropTypes.func,
    onDeleteNews: PropTypes.func,

    className: PropTypes.string,
    style: PropTypes.object,
};

export default NewsPreview;
