import React from 'react';
import PropTypes from 'prop-types';
import {Result, Button} from 'antd';
import {useHistory} from 'react-router-dom';

import useTitle from '@/hook/useTitle';

export default function NotFoundPage({showButton = false}) {
    useTitle('404');
    const history = useHistory();

    function renderExtra() {
        if (!showButton) {
            return null;
        }

        return (
            <Button type="primary" onClick={() => history.replace('/')}>
                返回首页
            </Button>
        );
    }

    return (
        <Result
            status="404"
            style={{marginTop: 200, backgroundColor: 'transparent'}}
            title="404"
            subTitle="此页面不存在"
            extra={renderExtra()}
        />
    );
}

NotFoundPage.propTypes = {
    showButton: PropTypes.bool,
};
