/*
 * Created by liyi on 2021-01-12 18:43.
 */
import React from 'react';
import {Typography, Tag} from 'antd';

import {format, FORMAT_LONG} from '@/util/dateUtils';

const {Text} = Typography;

const columnDefines = () => ({
    name: {
        title: '创建人',
        dataIndex: 'creatorName',
        width: 100,
    },
    level: {
        title: '级别',
        dataIndex: 'level',
        width: 100,
        // eslint-disable-next-line react/display-name
        render: text => {
            switch (text) {
                case 'INFO':
                    return <Tag color="green">{text}</Tag>;
                case 'WARN':
                    return <Tag color="orange">{text}</Tag>;
                case 'ERROR':
                    return <Tag color="red">{text}</Tag>;
                default:
                    return <Tag>{text}</Tag>;
            }
        },
    },
    log: {
        title: '日志',
        dataIndex: 'log',
    },
    time: {
        title: '时间',
        dataIndex: 'createTime',
        width: 200,
        // eslint-disable-next-line react/display-name
        render: text => {
            return <Text>{format(text, FORMAT_LONG)}</Text>;
        },
    },
    ip: {
        title: 'IP',
        dataIndex: 'ip',
    },
});

export function getColumns(keys, options) {
    return keys.map(key => columnDefines(options)[key]).filter(Boolean);
}
