import React from 'react';
import PropTypes from 'prop-types';
import {Button, message, Modal, Tag, PageHeader, Typography, Spin, Pagination, Row} from 'antd';
import _ from 'lodash';

AppListBySetting.propTypes = {
    /**
     * 应用id列表
     */
    appIds: PropTypes.arrayOf(PropTypes.string),

    /**
     * 以应用id为key，name 为值的 obj
     */
    appIdMap: PropTypes.objectOf(PropTypes.string),
};

function AppListBySetting({appIds, appIdMap}) {
    if (_.isEmpty(appIds)) {
        return null;
    }

    return (
        <div>
            {appIds.map(id => {
                const appName = appIdMap[id];
                if (!appName) {
                    return null;
                }

                return <Tag key={id}>{appName}</Tag>;
            })}
        </div>
    );
}

export default AppListBySetting;
