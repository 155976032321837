import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'antd';

import {ColorfulList} from '@/component';
import styles from '@/page/admin/selector/DepartUserTagSelector/MainPanel.module.less';

export default function AppAllowUserTabPane({allowUsers, onAddSelected}) {
    // console.log('allowUsers', allowUsers);

    function onItemClick(item) {
        const {allowId: id, allowName: name} = item;
        onAddSelected && onAddSelected('users', {id, name});
    }

    function renderItem(item) {
        return (
            <List.Item>
                <List.Item.Meta title={item.allowName} />
            </List.Item>
        );
    }

    return (
        <div className={styles.tabContent1}>
            <ColorfulList
                onItemClick={onItemClick}
                dataSource={allowUsers}
                renderItem={renderItem}
            />
        </div>
    );
}

AppAllowUserTabPane.propTypes = {
    allowUsers: PropTypes.array,
    onAddSelected: PropTypes.func,
};
