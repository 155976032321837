import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Drawer, Spin} from 'antd';
import _ from 'lodash';

import {useMessage} from '../historyService';
import msgTypes from '@/page/component/msg/MsgTypes';
import MsgPreview from '../MsgPreview';

const SendMsgDrawer = forwardRef(({appId}, ref) => {
    const [visible, setVisible] = useState(false);
    const [msgId, setMsgId] = useState(null);
    const {loading, value} = useMessage(appId, msgId);

    // console.log('loading, value', loading, value);
    const message = _.get(value, 'data');
    const type = _.get(value, 'data.msgType');
    let typeLabel = null;
    if (type) {
        typeLabel = _.get(msgTypes[type], 'label');
    }

    function show(msgId) {
        setVisible(true);
        setMsgId(msgId);
    }

    function hide() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    return (
        <Drawer
            visible={visible}
            mask={false}
            width={500}
            onClose={hide}
            destroyOnClose
            title={typeLabel + '预览'}
        >
            <Spin spinning={loading}>
                {!_.isEmpty(message) && <MsgPreview message={message} />}
            </Spin>
        </Drawer>
    );
});

SendMsgDrawer.displayName = 'SendMsgDrawer';

SendMsgDrawer.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default SendMsgDrawer;
