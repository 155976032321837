import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Spin} from 'antd';
import _ from 'lodash';

import MainPanel from './MainPanel';
import SelectedPanel from './SelectedPanel';
import {isPromise} from '@/util/PromiseUtils';

import styles from './index.module.less';

const DepartUserTagSelector = forwardRef(
    (
        {
            showDepartTab = true,
            showUserTab = true,
            showTagTab = true,
            modalTitle,
            modalWidth = 1200,
            allowDepartIds,
            allowUsers,
            onSelect,
        },
        ref
    ) => {
        const [visible, setVisible] = useState(false);
        const [title, setTitle] = useState(modalTitle);
        const [spinning, setSpinning] = useState(false);

        // 格式为 {departs: [{id, name}], users: [{id, name}], tags: [{id, name}]}
        const [selectedValues, setSelectedValues] = useState(null);
        console.log('selectedValues', selectedValues);

        /**
         * 打开 Modal，同时可以传入的相应的参数
         *
         * @param modalTitle Modal 标题，不传，则使用 props 中的值
         * @param initialValues 初始选择项，格式为 {departs: [{id, name}], users: [{id, name}], tags: [{id, name}]}
         */
        function showModal({modalTitle, initialValues} = {}) {
            // console.log('initialValues', initialValues);
            setVisible(true);
            if (modalTitle) {
                setTitle(modalTitle);
            }
            if (!_.isEmpty(initialValues)) {
                const {departs, users, tags} = initialValues;
                setSelectedValues({departs, users, tags});
            } else {
                setSelectedValues(null);
            }
        }

        function closeModal() {
            setVisible(false);
            // setSelectedValues(null);
            setSpinning(false);
        }

        useImperativeHandle(ref, () => ({
            showModal,
            closeModal,
        }));

        function onAddSelected(type, {id, name}) {
            let values = _.get(selectedValues, type, []);
            const value = values.find(item => item.id === id);
            if (!value) {
                values = [...values, {id, name}];
                setSelectedValues({...selectedValues, [type]: values});
            }
        }

        function onDeleteSelected(type, delId) {
            let values = _.get(selectedValues, type, []);
            values = values.filter(({id}) => id !== delId);
            setSelectedValues({...selectedValues, [type]: values});
        }

        // console.log('selectedValues----------', selectedValues);

        function onOk() {
            // console.log(selectedValues);
            setSpinning(true);

            // 将返回值中的 undefined 置为 []
            const values = {};
            Object.keys(selectedValues || {}).forEach(key => {
                const arr = selectedValues[key];
                values[key] = arr || [];
            });

            const result = onSelect(values);
            if (isPromise(result)) {
                result.then(data => {
                    // console.log(data);
                    if (data === true) {
                        closeModal();
                    } else {
                        setSpinning(false);
                    }
                });
            } else {
                if (result) {
                    closeModal();
                } else {
                    setSpinning(false);
                }
            }
        }

        return (
            <Modal
                destroyOnClose
                title={title}
                visible={visible}
                onCancel={closeModal}
                onOk={onOk}
                width={modalWidth}
            >
                <Spin spinning={spinning}>
                    <div className={styles.container}>
                        <div className={styles.mainPanel}>
                            <MainPanel
                                onAddSelected={onAddSelected}
                                allowDepartIds={allowDepartIds}
                                allowUsers={allowUsers}
                                showDepartTab={showDepartTab}
                                showUserTab={showUserTab}
                                showTagTab={showTagTab}
                            />
                        </div>
                        <div className={styles.selectedPanel}>
                            <SelectedPanel
                                selectedValues={selectedValues}
                                onDeleteSelected={onDeleteSelected}
                            />
                        </div>
                    </div>
                </Spin>
            </Modal>
        );
    }
);

DepartUserTagSelector.displayName = 'DepartUserTagSelector';

DepartUserTagSelector.propTypes = {
    /**
     * 是否显示部门选择 tab
     */
    showDepartTab: PropTypes.bool,

    /**
     * 是否显示人员选择 tab
     */
    showUserTab: PropTypes.bool,

    /**
     * 是否显示标签选择 tab
     */
    showTagTab: PropTypes.bool,

    modalTitle: PropTypes.string,

    modalWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * 可见的部门 id
     *
     * 如果为为 null 或 undefined, 表示所有部门可见
     *
     * 如果是 [] ，表示所有不可见
     * 如果有值，则对应部门和子部门可见
     */
    allowDepartIds: PropTypes.arrayOf(PropTypes.string),

    allowUsers: PropTypes.array,

    /**
     * 点击「确定」按钮后触发，返回 true 的时候关闭 Modal
     *
     * 支持返回 Promise
     */
    onSelect: PropTypes.func.isRequired,
};

export default DepartUserTagSelector;
