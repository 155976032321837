import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Switch} from 'antd';

import styles from './RoleAuthItem.module.less';

export default function RoleAuthItem({memo, read, write, initValues = [], onToggle}) {
    const [readChecked, setRead] = useState(initValues.includes(read));
    const [writeChecked, setWrite] = useState(initValues.includes(write));

    function onReadChange(checked) {
        let readFlag;
        let writeFlag = writeChecked;
        if (!checked) {
            readFlag = false;
            writeFlag = false;
            setRead(readFlag);
            setWrite(writeFlag);
        } else {
            readFlag = checked;
            setRead(checked);
        }
        onToggle && onToggle({read, write, readChecked: readFlag, writeChecked: writeFlag});
    }

    function onWriteChange(checked) {
        let readFlag = readChecked;
        let writeFlag;
        if (checked) {
            readFlag = true;
            writeFlag = true;
            setRead(true);
            setWrite(true);
        } else {
            writeFlag = checked;
            setWrite(writeFlag);
        }
        onToggle && onToggle({read, write, readChecked: readFlag, writeChecked: writeFlag});
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{memo}</div>
            <div className={styles.actions}>
                <div className={styles.action}>
                    {read && (
                        <>
                            <span className={styles.actionTitle}>可读</span>
                            <Switch size="small" checked={readChecked} onChange={onReadChange} />
                        </>
                    )}
                </div>
                <div className={styles.action}>
                    {write && (
                        <>
                            <span className={styles.actionTitle}>可写</span>
                            <Switch size="small" checked={writeChecked} onChange={onWriteChange} />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

RoleAuthItem.propTypes = {
    memo: PropTypes.string,
    read: PropTypes.string,
    write: PropTypes.string,
    initValues: PropTypes.array,
    onToggle: PropTypes.func,
};
