import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, message} from 'antd';
import {PlusOutlined, TeamOutlined} from '@/icons';
import _ from 'lodash';

import {PageGrid, RowAction, TwoColumnLayout} from '@/component';
import {getColumns} from '../../DepartsPage/userListColumns';
import UserViewModal from '../../DepartsPage/UserViewModal';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';

import {rolePropTypes} from '../propTypes';
import {deleteUserRoles, addRoleUsers} from '@/api/roles';

export default function RoleUserList({role, editable}) {
    const tableRef = useRef();
    const viewRef = useRef();
    const userSelectorRef = useRef();

    function onNameClick(record) {
        return () => {
            viewRef.current.showModal(record);
        };
    }

    function onDeleteClick(record) {
        // console.log(record);
        Modal.confirm({
            title: '警告',
            content: `确定在本角色下移除人员 [${record.name}]？`,
            okButtonProps: {danger: true},
            onOk: () => {
                deleteUserRoles(record.id, [role.id]).then(() => {
                    refreshTable();
                });
            },
        });
    }

    function onRoleUserClick() {
        userSelectorRef.current.showModal();
    }

    function onRoleUserSelect(values) {
        // console.log(values);
        const users = _.get(values, 'users', []);
        if (_.isEmpty(users)) {
            message.warn('未选择任何用户');
            return false;
        }

        const userIds = users.map(({id}) => id);
        // console.log(userIds);

        return addRoleUsers(role.id, userIds).then(data => {
            refreshTable();
            return data.status === 200;
        });
    }

    function refreshTable() {
        tableRef.current.refresh();
    }

    const columns = getColumns(['name', 'contact', 'state'], {onNameClick});

    if (editable) {
        const actions = [
            {
                key: 'delete',
                title: '移出角色',
                hide: false,
                onClick: onDeleteClick,
            },
        ];
        columns.push({
            title: '操作',
            key: 'action',
            width: 120,
            // eslint-disable-next-line react/display-name
            render: (text, record) => <RowAction actions={actions} record={record} />,
        });
    }

    // console.log('role userlist', role);
    return (
        <>
            <TwoColumnLayout.PanelHeader icon={<TeamOutlined />} title="拥有角色的人员">
                {editable && (
                    <Button type="primary" icon={<PlusOutlined />} onClick={onRoleUserClick} />
                )}
            </TwoColumnLayout.PanelHeader>

            <TwoColumnLayout.PanelContent>
                <PageGrid ref={tableRef} url={`/roles/${role.id}/users`} columns={columns} />

                <UserViewModal ref={viewRef} />
                <DepartUserTagSelector
                    ref={userSelectorRef}
                    showTagTab={false}
                    showDepartTab={false}
                    modalTitle="人员选择"
                    onSelect={onRoleUserSelect}
                />
            </TwoColumnLayout.PanelContent>
        </>
    );
}

RoleUserList.propTypes = {
    editable: PropTypes.bool,
    role: PropTypes.shape(rolePropTypes),
};
