import {getJson} from '@/util/ajaxUtils';
import {API_ROOT} from '@/env';

export function getSumReport(alias, appId, start, end) {
    return getJson(`/report/${appId}/sumdata/${alias}`, {params: {start, end}});
}

export function getRangeReports(alias, appId, start, end) {
    return getJson(`/report/${appId}/range-list/${alias}`, {params: {start, end}});
}

export function reportDownloadUrl(alias, appId) {
    return `${API_ROOT}/report/files/${appId}/download/${alias}`;
}

export function getHourlyReportNews(appId) {
    return getJson(`/report/${appId}/news/hourly`);
}

export function getNewsStatUrl(appId) {
    return `${API_ROOT}/report/${appId}/news/stat`;
}

export function newsStatDownloadUrl(appId, start, end, title) {
    return `${API_ROOT}/report/files/${appId}/download-newsstat?start=${start}&end=${end}&title=${title}`;
}

export function getNewsReadUserUrl(appId, newsId) {
    return `${API_ROOT}/report/${appId}/news/users?newsId=${newsId}`;
}

export function getSingleNewsStat(appId, newsId, start, end) {
    return getJson(`/report/${appId}/single-news/stat`, {params: {newsId, start, end}});
}
