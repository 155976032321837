import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {useNewsService} from '../articleService';
import ArticleContainer from './ArticleContainer';
import ArticleView from './ArticleView';
import ArticleError from './ArticleError';
import {getOpenApp} from '@/api/article';
import NewsComment from './NewsComment';

/**
 * 根据图文内容 id 载入并展示。
 *
 * @param id 内容 id
 * @param appId 应用 id，提供则读取应用信息
 * @param userId 有用户 id，则显示评论框
 * @param showDescription 是否显示描述
 * @return {JSX.Element}
 * @constructor
 */
export default function NewsView({id, appId, userId, showDescription = true}) {
    const {loading, value, error} = useNewsService(id);
    console.log('NewsView..........', loading, value);
    const [news, setNews] = useState({});
    const [app, setApp] = useState(null);

    useEffect(() => {
        if (loading) {
            return;
        }

        setNews(_.get(value, 'data.news', {}));
    }, [loading, value]);

    useEffect(() => {
        if (!appId) {
            return;
        }

        getOpenApp(appId).then(data => {
            console.log(data);
            const app = _.get(data, 'data');
            if (app) {
                setApp(app);
            }
        });
    }, [appId]);

    // const stat = _.get(value, 'data.stat', {});

    return (
        <ArticleContainer>
            {error ? (
                <ArticleError error={error} />
            ) : (
                <>
                    <ArticleView news={news} app={app} showDescription={showDescription} />

                    <NewsComment userId={userId} news={news} />
                </>
            )}
        </ArticleContainer>
    );
}

NewsView.propTypes = {
    id: PropTypes.string.isRequired,
    appId: PropTypes.string,
    userId: PropTypes.string,
    showDescription: PropTypes.bool,
};
