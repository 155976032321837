import {getJson, deleteJson, patchJson, postJson, putJson} from '@/util/ajaxUtils';
import {API_ROOT} from '@/env';

export function getClientAppPage(current, size) {
    return getJson(`${API_ROOT}/clientapps`, {params: {current, size}});
}

export function addClientApp(clientApp) {
    return postJson(`/clientapps`, {data: clientApp});
}

export function updateClientApp(id, clientApp) {
    return putJson(`/clientapps/${id}`, {data: clientApp});
}

export function deleteClientApp(id) {
    return deleteJson(`/clientapps/${id}`);
}

export function regenerateSignKey(id) {
    return patchJson(`/clientapps/${id}/signkey`);
}

export function getTicketUrl(id, destUrl) {
    return getJson(`/clientapps/${id}/ticket-url`, {params: {destUrl}});
}
