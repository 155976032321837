import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {PageGrid} from '@/component';
import {getCommentGroupByNewsUrl} from '@/api/comment';
import {getColumns} from './columns';
import PreviewPanel from '@/page/app/CommentPage/PreviewPanel';

const CommentGroupByNewsTable = forwardRef(({onNewsPreviewClick}, ref) => {
    const tableRef = useRef();
    const previewRef = useRef();

    const {appId} = useParams();

    useImperativeHandle(ref, () => ({
        hideDrawer: () => previewRef.current.hide(),
    }));

    function onContentClick(record) {
        previewRef.current.show(record);
    }

    function reloadTable() {
        tableRef.current.refresh();
    }

    const columns = getColumns(
        ['userName', 'newestCommentContent', 'newsCommentCount', 'newsTitle', 'createTime'],
        {
            onNewsPreviewClick,
            onContentClick,
        }
    );

    return (
        <>
            <PageGrid ref={tableRef} columns={columns} url={getCommentGroupByNewsUrl(appId)} />
            <PreviewPanel ref={previewRef} reloadTable={reloadTable} />
        </>
    );
});

CommentGroupByNewsTable.displayName = 'CommentGroupByNewsTable';

CommentGroupByNewsTable.propTypes = {
    onNewsPreviewClick: PropTypes.func,
};

export default CommentGroupByNewsTable;
