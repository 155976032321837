import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal} from 'antd';
import {useParams} from 'react-router-dom';

import {updateCommonMedia} from '@/api/media';

const FileTitleFormModal = forwardRef(({reload}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [titleData, setTitleData] = useState(null);
    const {appId} = useParams();

    function showModal(id, title) {
        setVisible(true);
        setTitleData({id, title});
    }

    function closeModal() {
        setVisible(false);
        setTitleData(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish({id, title}) {
        console.log('Received values of form: ', {id, title});

        updateCommonMedia(appId, id, {title}).then(data => {
            console.log(data);
            reload();
            closeModal();
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={'修改素材标题'}
            onCancel={closeModal}
            onOk={onOk}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={titleData}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="素材标题"
                    name="title"
                    rules={[
                        {
                            required: true,
                            max: 120,
                        },
                    ]}
                >
                    <Input placeholder="请输入标题内容" allowClear autoFocus />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

FileTitleFormModal.displayName = 'FileTitleFormModal';

FileTitleFormModal.propTypes = {
    reload: PropTypes.func.isRequired,
};

export default FileTitleFormModal;
