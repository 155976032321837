import React from 'react';
import {BrowserRouter, Router} from 'react-router-dom';
import {ConfigProvider} from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/zh-cn';

import AppRoutes from './AppRoutes';
import {AuthChecker, AuthStoreProvider} from '@/context/auth';
import {history} from '@/env';

const App = () => (
    <ConfigProvider locale={zhCN}>
        <BrowserRouter>
            <Router history={history}>
                <AuthStoreProvider>
                    <AuthChecker />
                    <AppRoutes />
                </AuthStoreProvider>
            </Router>
        </BrowserRouter>
    </ConfigProvider>
);

export default App;
