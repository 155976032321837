import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import dayjsLocale from 'dayjs/locale/zh-cn';

dayjs.locale(dayjsLocale);
dayjs.extend(RelativeTime);

export const FORMAT_DEFAULT = 'YYYY-MM-DD';
export const FORMAT_LONG = 'YYYY-MM-DD HH:mm';
export const FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ssZ';

/**
 * 将给定时间戳转换为 yyyy-mm-dd 格式字符串
 *
 * @param timestamp
 * @param template
 */
export function format(timestamp, template = FORMAT_DEFAULT) {
    if (!timestamp) return '';

    return dayjs(timestamp).format(template);
}

export function formatLong(timestamp) {
    return format(timestamp, FORMAT_LONG);
}

/**
 * 将给定时间戳转换为更适合阅读的字符串.
 *
 * 如果给定时间戳大于当前时间，则返回 YYYY-MM-DD HH:mm 格式字符串。
 *
 * 否则显示格式如： x 天前
 *
 * @param timestamp 时间戳
 * @param long 如果需要显示日期，默认只显示日期，true 的时候显示带时间的日期
 * @return {string|*|string} 格式化后字符串
 */
export function fromNow(timestamp, long = false) {
    if (!timestamp) return '';
    if (timestamp > Date.now()) return long ? formatLong(timestamp) : format(timestamp);

    return dayjs(timestamp).fromNow();
}

/**
 * 将给定时间戳转换为更适合阅读的字符串.
 *
 * @param timestamp 时间戳
 * @param long 如果需要显示日期，默认只显示日期，true 的时候显示带时间的日期
 * @deprecated 使用 {@link #fromNow(timestamp}
 */
export function humanize(timestamp, long = false) {
    if (!timestamp) return '';

    const now = Date.now();

    // 如果给定时间比当前时间还大,说明有问题
    if (now < timestamp) {
        return '';
    }

    const diff = now - timestamp;
    if (diff <= 1000) {
        return '刚刚';
    } else if (diff <= 60 * 1000) {
        return Math.round(diff / 1000) + '秒前';
    } else if (diff <= 60 * 60 * 1000) {
        return Math.round(diff / (60 * 1000)) + '分钟前';
    } else if (diff <= 24 * 60 * 60 * 1000) {
        return Math.round(diff / (60 * 60 * 1000)) + '小时前';
    } else if (diff <= 48 * 60 * 60 * 1000) {
        return '昨天';
    }

    return long ? formatLong(timestamp) : format(timestamp);
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function getTimeText(time, defaultText) {
    if (!time) return defaultText;
    if (typeof time === 'number') return formatLong(time);
    if (isValidDate(time)) return formatLong(time.getTime());
    return defaultText;
}

export function getDate(time, defaultDate = new Date()) {
    if (!time) return defaultDate;
    if (typeof time === 'number') return new Date(time);
    if (isValidDate(time)) return time;
    return defaultDate;
}

/**
 * 时间格式字符串转起始时间戳
 */
export function getStartTimestamp(time) {
    return dayjs(format(time)).valueOf();
}

/**
 * 时间格式字符串转截止时间戳
 */
export function getEndTimestamp(time) {
    return dayjs(format(time)).add(1, 'day').valueOf() - 1;
}
