import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal, Select} from 'antd';

import {useApps} from '../TagsPage/useTagsService';
import {addClientApp, updateClientApp} from '@/api/clientApps';

const ClientAppFormModal = forwardRef(({reload}, ref) => {
    const formRef = useRef();
    const {value} = useApps();

    const [visible, setVisible] = useState(false);
    const [clientApp, setClientApp] = useState(null);
    // console.log('clientApp', clientApp);

    const apps = value?.data ?? [];

    function showModal(capp) {
        setVisible(true);
        setClientApp(capp);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function isAdd() {
        return !clientApp?.id;
    }

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', isAdd(), values);
        const promise = isAdd() ? addClientApp(values) : updateClientApp(clientApp.id, values);

        promise.then(data => {
            console.log(data);
            reload();
            closeModal();
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd() ? '添加外部应用' : '修改外部应用'}
            onCancel={closeModal}
            onOk={onOk}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={clientApp}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="外部应用名称"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="外部应用名称" autoFocus maxLength={30} />
                </Form.Item>
                <Form.Item name="description" label="说明">
                    <Input placeholder="说明" maxLength={100} />
                </Form.Item>

                {isAdd() && (
                    <Form.Item
                        name="appId"
                        label="所属应用"
                        rules={[
                            {
                                required: true,
                                message: '请选择应用',
                            },
                        ]}
                    >
                        <Select>
                            {apps.map(({id, name}) => (
                                <Select.Option key={id} value={id}>
                                    {name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

ClientAppFormModal.displayName = 'ClientAppFormModal';

ClientAppFormModal.propTypes = {
    reload: PropTypes.func.isRequired,
};

export default ClientAppFormModal;
