import React from 'react';
import PropTypes from 'prop-types';
import {Result} from 'antd';

export default function ArticleError({error}) {
    const {status, message = '未知错误'} = error ?? {};

    const statusStr = status && [404, 500, 403].includes(status) ? '' + status : '500';

    return <Result status={statusStr} title={statusStr} subTitle={message} />;
}

ArticleError.propTypes = {
    error: PropTypes.shape({
        status: PropTypes.number,
        message: PropTypes.string,
    }),
};
