import React from 'react';
import {Tag} from 'antd';
import PropTypes from 'prop-types';

import {ApartmentOutlined} from '@/icons';

export default function DepartTag({children, ...rest}) {
    return (
        <Tag color="geekblue" icon={<ApartmentOutlined />} {...rest}>
            {children}
        </Tag>
    );
}

DepartTag.propTypes = {
    children: PropTypes.node,
};
