import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Button, Modal, PageHeader} from 'antd';
import _ from 'lodash';

import {DeleteOutlined, EditOutlined} from '@/icons';
import MsgMiniPreview from '@/page/component/msg/preview/MsgMiniPreview';
import SingleReplyFormModal from './SingleReplyFormModal';
import {deleteSingleReply} from '@/api/reply';

import {useSingleReply} from './replyService';

export default function SingleReply({editable = true, replyType, title, subTitle}) {
    const formModal = useRef();

    const {appId} = useParams();
    const {value, retry} = useSingleReply(replyType, appId);
    // console.log('{loading, value}', {loading, value});

    const reply = _.get(value, 'data');

    function onEditClick() {
        formModal.current.showModal(reply);
    }

    function onDeleteClick() {
        Modal.confirm({
            title: '警告',
            content: '确定要删除「消息自动回复」？',
            okButtonProps: {danger: true},
            onOk: () => {
                deleteSingleReply(replyType, appId).then(() => {
                    retry();
                });
            },
        });
    }

    function renderReply() {
        if (!reply) {
            return null;
        }

        return <MsgMiniPreview {...reply} />;
    }

    return (
        <PageHeader
            title={title}
            subTitle={subTitle}
            extra={
                editable && (
                    <>
                        <Button type="primary" icon={<EditOutlined />} onClick={onEditClick}>
                            编辑
                        </Button>
                        {reply && (
                            <Button danger icon={<DeleteOutlined />} onClick={onDeleteClick}>
                                删除
                            </Button>
                        )}
                    </>
                )
            }
        >
            {renderReply()}

            <SingleReplyFormModal
                ref={formModal}
                appId={appId}
                replyType={replyType}
                modalTitle="消息自动回复编辑"
                reload={retry}
            />
        </PageHeader>
    );
}

SingleReply.propTypes = {
    editable: PropTypes.bool,
    replyType: PropTypes.oneOf(['global', 'join']).isRequired,
    title: PropTypes.node.isRequired,
    subTitle: PropTypes.node,
};
