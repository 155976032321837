import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Button, Empty, List} from 'antd';

import {PageLoading, TwoColumnLayout} from '@/component';

import {FileProtectOutlined, PlusOutlined} from '@/icons';
import RoleItem from './RoleItem';
import RoleFormModal from './RoleFormModal';
import {useRoleList} from './useRoleService';
import {roleScopePropTypes} from './propTypes';

export default function RoleList({
    scope,
    scopeItemId,
    fallbackOnApp = false,
    onSelect,
    editable = true,
    selectable = false,
    initSelectRoleIds = [],
    onlyShowInitRoles = false,
    onRoleCheckToggle,
}) {
    const formModalRef = useRef();
    const {loading, retry, value} = useRoleList(scope, scopeItemId, fallbackOnApp);
    const [selRoleId, setSelRoleId] = useState(null);

    const [roles, setRoles] = useState([]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (onlyShowInitRoles) {
            const initRoles = value.data || [];
            const showRoles = initRoles.filter(role => initSelectRoleIds.includes(role.id));
            setRoles(showRoles);
        } else {
            setRoles(value.data);
        }
    }, [initSelectRoleIds, onlyShowInitRoles, value]);

    function onRoleClick(role) {
        if (role) {
            setSelRoleId(role.id);
        }
        onSelect && onSelect(role);
    }

    if (loading) {
        return <PageLoading />;
    }

    return (
        <>
            <TwoColumnLayout.PanelHeader icon={<FileProtectOutlined />} title="角色">
                {editable && (
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => formModalRef.current.showModal()}
                    />
                )}
            </TwoColumnLayout.PanelHeader>
            <TwoColumnLayout.PanelContent>
                {_.isEmpty(roles) ? (
                    <Empty style={{marginTop: 100}} />
                ) : (
                    <List
                        dataSource={roles}
                        renderItem={item => (
                            <RoleItem
                                role={item}
                                selRoleId={selRoleId}
                                editable={editable}
                                selectable={selectable}
                                initSelectRoleIds={initSelectRoleIds}
                                onRoleCheckToggle={onRoleCheckToggle}
                                reloadRoles={retry}
                                onRoleSelect={onRoleClick}
                                onShowFormModal={
                                    formModalRef.current && formModalRef.current.showModal
                                }
                            />
                        )}
                    />
                )}
            </TwoColumnLayout.PanelContent>

            <RoleFormModal
                ref={formModalRef}
                reloadRoles={retry}
                scope={scope}
                scopeItemId={scopeItemId}
            />
        </>
    );
}

RoleList.propTypes = {
    ...roleScopePropTypes,
    onSelect: PropTypes.func,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    initSelectRoleIds: PropTypes.arrayOf(PropTypes.string),
    onlyShowInitRoles: PropTypes.bool,
    onRoleCheckToggle: PropTypes.func,
};
