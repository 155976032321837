import {useState, useEffect} from 'react';
import {useAsyncRetry} from 'react-use';
import {getMediaList} from '@/api/media';

export function useMediaList(appId, type, query, current = 1, size = 10) {
    const [mediaData, setMediaData] = useState({
        records: [],
        total: 0,
    });

    const {loading, value, retry} = useAsyncRetry(async () => {
        return await getMediaList(appId, type, query, current, size);
    }, [appId, type, query, current, size]);

    useEffect(() => {
        if (loading) {
            return;
        }

        const {
            data: {records, total},
        } = value;
        setMediaData({
            records,
            total,
        });
    }, [loading, value]);

    return {
        loading,
        retry,
        mediaRecords: mediaData.records,
        total: mediaData.total,
    };
}
