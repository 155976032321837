import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {mediaTypeValues} from './MediaType';

export const MediaRouteConfig = [
    {
        path: '/apps/:appId/media',
        exact: true,
        component: require('./pages/NewsPage').default,
    },
    ...mediaTypeValues,
];

export const MenuConfig = MediaRouteConfig.filter(item => !!item.label);

export default function MediaRoutes() {
    return (
        <Switch>
            {MediaRouteConfig.map(({path, exact, component}) => {
                return (
                    <Route
                        key={`media-route${path}`}
                        exact={exact}
                        path={path}
                        component={component}
                    />
                );
            })}
        </Switch>
    );
}
