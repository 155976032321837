import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {TwoColumnLayout} from '@/component';

import RoleList from './RoleList';
import RoleContent from './RoleContent';
import {roleScopePropTypes} from './propTypes';

export default function RolesPanel({
    className,
    scope = 'global',
    scopeItemId,
    fallbackOnApp = false,
    showUserList = true,
    editable = true,
    selectable = false,
    initSelectRoleIds = [],
    onlyShowInitRoles = false,
    onRoleCheckToggle,
}) {
    const [role, setRole] = useState(null);

    function onRoleSelect(role) {
        setRole(role);
    }

    return (
        <TwoColumnLayout className={className}>
            <TwoColumnLayout.SidePanel enableScroll={false}>
                <RoleList
                    scope={scope}
                    scopeItemId={scopeItemId}
                    fallbackOnApp={fallbackOnApp}
                    onSelect={onRoleSelect}
                    editable={editable}
                    selectable={selectable}
                    initSelectRoleIds={initSelectRoleIds}
                    onlyShowInitRoles={onlyShowInitRoles}
                    onRoleCheckToggle={onRoleCheckToggle}
                />
            </TwoColumnLayout.SidePanel>

            <TwoColumnLayout.ContentPanel enableScroll={false}>
                <RoleContent role={role} showUserList={showUserList} editable={editable} />
            </TwoColumnLayout.ContentPanel>
        </TwoColumnLayout>
    );
}

RolesPanel.propTypes = {
    ...roleScopePropTypes,
    className: PropTypes.string,
    showUserList: PropTypes.bool,
    editable: PropTypes.bool,
    selectable: PropTypes.bool,
    initSelectRoleIds: PropTypes.arrayOf(PropTypes.string),
    onlyShowInitRoles: PropTypes.bool,
    onRoleCheckToggle: PropTypes.func,
};
