import React from 'react';
import PropTypes from 'prop-types';
import {Button, PageHeader} from 'antd';

import {PageGrid} from '@/component';
import {ExportOutlined} from '@/icons';
import {reportDownloadUrl} from '@/api/report';

export default function DetailsReportList({appId, alias, columns}) {
    const extra = (
        <Button icon={<ExportOutlined />} href={reportDownloadUrl(alias, appId)}>
            导出
        </Button>
    );

    return (
        <>
            <PageHeader title="详情数据" style={{padding: '16px 0'}} extra={extra} />
            <PageGrid
                columns={columns}
                url={`/report/${appId}/list/${alias}`}
                tableProps={{size: 'small'}}
            />
        </>
    );
}

DetailsReportList.propTypes = {
    appId: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
};
