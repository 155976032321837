import {useEffect} from 'react';
import _ from 'lodash';

import {useHistory, useLocation} from 'react-router-dom';
import {initReducerContext} from '@someok/simple-reducer-store';
import {getTokens} from '@/util/TokenStore';

import {doProfile} from '@/api/auth';

import authActions from './actions';

export const GLOBAL_ADMIN_READ = 'global:admin:r';
export const GLOBAL_ADMIN_WRITE = 'global:admin:w';

/**
 * 判断用户是否存在给定权限。
 *
 * @param auths 允许的权限
 * @param useGlobalRW 允许读取超级权限中的读或写权限，如果不提供，则不读取超级权限
 * @return {boolean}
 */
function hasAuthorities(auths, useGlobalRW) {
    // console.log('auths, readOrWrite', auths, useGlobalRW);
    if (_.isEmpty(auths)) {
        throw new Error('[auths] 参数不能为空');
    }

    const userAuths = this.authorities ?? [];

    for (const auth of auths) {
        if (userAuths.includes(auth)) {
            return true;
        }
    }

    if (useGlobalRW === 'r') {
        return userAuths.includes(GLOBAL_ADMIN_READ);
    }
    if (useGlobalRW === 'w') {
        return userAuths.includes(GLOBAL_ADMIN_WRITE);
    }

    return false;
}

/**
 * 检测当前人员是否有系统管理权限
 */
function hasGlobalAuths() {
    const userAuths = this.authorities ?? [];

    if (_.isEmpty(userAuths)) {
        return false;
    }

    return !!userAuths.find(auth => auth.startsWith('global:'));
}

const initialState = {
    isLogin: false,
    user: undefined,
    authorities: undefined,

    hasAuthorities,
    hasGlobalAuths,
};

const [useAuthStore, AuthStoreProvider, AuthContext] = initReducerContext(
    initialState,
    authActions
);

export {useAuthStore, AuthStoreProvider, AuthContext};

// 密码 90 天过期
export const PASS_EXPIRE_DAYS = 90;
const PASS_EXPIRE_MS = PASS_EXPIRE_DAYS * 24 * 60 * 60 * 1000;

export const IGNORE_ROUTES = ['/login', '/logout', '/forget', '/password-reset', '/article','/sso'];

/**
 * 给定页面是否可公开访问。
 *
 * @param pathname
 * @return {boolean}
 */
function isPublicRoute(pathname) {
    for (let ignoreroute of IGNORE_ROUTES) {
        if (pathname.startsWith(ignoreroute)) {
            return true;
        }
    }
    return false;
}

/**
 * 检查登录信息是否存在不存在则跳转到登录页。
 *
 * @return {null}
 * @constructor
 */
export function AuthChecker() {
    const [authStore, dispatch] = useAuthStore();
    const history = useHistory();
    let location = useLocation();
    const {isLogin} = authStore;

    useEffect(() => {
        if (isPublicRoute(location.pathname)) {
            console.log('ignore check auth...');
            return;
        }

        // console.log('check auth...');
        // console.log('isLogin: ', isLogin);
        if (!isLogin) {
            const tokens = getTokens();
            // console.log('tokens: ', tokens);
            if (tokens) {
                doProfile().then(json => {
                    if (json && json.data) {
                        // console.log(data);
                        dispatch(['loginSuccess', json.data]);
                    }
                });
            } else {
                history.replace('/login');
            }
        } else {
            const {passReset, passModifyTime} = authStore.user;
            // console.log('{passReset, passModifyTime}', {passReset, passModifyTime});

            // 如果当前处于管理员修改密码状态或密码已过期，则直接跳转到密码修改页
            if (passReset) {
                history.push('/change-passord');
            } else if (Date.now() - passModifyTime >= PASS_EXPIRE_MS) {
                history.push('/change-passord?expire=true');
            }
        }
    }, [authStore.user, dispatch, history, isLogin, location.pathname]);

    return null;
}
