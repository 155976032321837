/* eslint-disable react/display-name */
import React from 'react';
import {Button, Space, Tag} from 'antd';
import _ from 'lodash';

import {CheckCircleOutlined, CloseCircleOutlined, FileTextOutlined} from '@/icons';
import msgTypes from '@/page/component/msg/MsgTypes';
import {formatLong} from '@/util/dateUtils';
import {sendFromText} from './msgUtils';
import FixedTimeStatus from './FixedTimeStatus';

const columnDefines = ({onTitleClick = _.noop, refresh = _.noop} = {}) => ({
    status: {
        title: '状态',
        dataIndex: 'status',
        width: 120,
        render: (text, record) => {
            const {msgDirection, draft, status, fixedTimeMsg, fixedTime, id, appId} = record;

            if (fixedTimeMsg) {
                return (
                    <FixedTimeStatus
                        fixedTime={fixedTime}
                        appId={appId}
                        messageId={id}
                        status={status}
                        refresh={refresh}
                    />
                );
            }

            let label = msgDirection === 'send' ? '发送' : '接收';

            let icon = null;
            if (draft) {
                icon = <FileTextOutlined style={{color: '#8c8c8c'}} />;
                label = '草稿';
            } else if (status === 1) {
                icon = <CheckCircleOutlined style={{color: '#52c41a'}} />;
            } else if (status === 2) {
                icon = <CloseCircleOutlined style={{color: '#f5222d'}} />;
            }

            return (
                <Space>
                    {icon}
                    <span>{label}</span>
                </Space>
            );
        },
    },

    msgType: {
        title: '消息类型',
        dataIndex: 'msgType',
        width: 100,
        render: text => {
            const type = msgTypes[text];
            if (type) {
                return type.label;
            }
            return '';
        },
    },

    title: {
        title: '消息主题',
        dataIndex: 'title',
        render: (text, record) => {
            const {mediaDeleted = false} = record;

            return (
                <Button type="link" onClick={onTitleClick(record)} disabled={mediaDeleted}>
                    {mediaDeleted && (
                        <Tag color="red" style={{marginRight: 10}}>
                            素材已删除
                        </Tag>
                    )}
                    {text}
                </Button>
            );
        },
    },

    safe: {
        title: '分享配置',
        dataIndex: 'safe',
        render: text => {
            return text === 0 ? (
                <Tag color="#2db7f5">可分享</Tag>
            ) : (
                <Tag color="#f50">不可分享</Tag>
            );
        },
    },

    sendFrom: {
        title: '来源',
        dataIndex: 'sendFrom',
        render: text => {
            return sendFromText(text);
        },
    },

    sender: {
        title: '操作人',
        dataIndex: 'userName',
    },

    createTime: {
        title: '时间',
        dataIndex: 'createTime',
        render: text => {
            return formatLong(text);
        },
    },
});

export function getColumns(keys, options) {
    const defines = columnDefines(options);
    return keys.map(key => defines[key]).filter(Boolean);
}
