import {useAsync} from 'react-use';

import {getOpenMedia, getOpenMessage, getOpenNews} from '@/api/article';

export function useMediaService(mediaId) {
    return useAsync(() => {
        return getOpenMedia(mediaId);
    }, [mediaId]);
}

export function useMessageService(msgId) {
    return useAsync(() => {
        return getOpenMessage(msgId);
    }, [msgId]);
}

export function useNewsService(id) {
    return useAsync(() => {
        return getOpenNews(id);
    }, [id]);
}
