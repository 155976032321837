import {deleteJson, getJson, patchJson, postJson, putJson} from '@/util/ajaxUtils';

export function getMenus(appId) {
    return getJson('/menus', {params: {appId}});
}

export function getMenuInfo(menuId) {
    return getJson(`/menus/${menuId}`);
}

export function addMenu(menu) {
    return postJson('/menus', {data: menu});
}

export function updateMenu(menu) {
    return putJson(`/menus`, {data: menu});
}

export function deleteMenu(menuId) {
    return deleteJson(`/menus/${menuId}`);
}

export function updateOrder(menuId, childrenIds) {
    return patchJson(`/menus/${menuId}`, {data: childrenIds});
}

export function publishMenu(appId) {
    return postJson(`/menus/publish`, {data: appId});
}
