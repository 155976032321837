import React, {useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Divider, message, Modal, PageHeader} from 'antd';
import _ from 'lodash';

import {FileTextTwoTone} from '@/icons';
import {saveFiexedTimeMessage, sendMessage} from '@/api/message';
import {addAuditMsg} from '@/api/msgAudit';

import MessageContent from '@/page/app/component/MessageContent';
import SendArea from './SendArea';
import {convertTos, isEmptySelData} from './utils';
import {byteLength} from '@/util/stringUtils';
import SendButtons from './SendButtons';
import {useAppStore} from '@/page/app/context';

const MAX_CONTENT_BYTE_LENGTH = 2048;

export default function MsgSendPanel() {
    const contentRef = useRef();
    const sendRef = useRef();
    const fixedTimeModalRef = useRef();

    const history = useHistory();

    const [submiting, setSubmiting] = useState(false);

    const {appId} = useParams();

    const [{extAttr}] = useAppStore();
    // console.log('-----appData', extAttr);
    // 判断本应用是否支持批量发送email或手机号，以及是否需要消息审核
    const {batchSendEmailOrMobile = false, appMsgAudit = false, userAttr = {}} = extAttr;
    const isAuditMgr = userAttr?.auditMgr ?? false;
    const isAuditSender = userAttr?.auditSender ?? false;
    console.log(
        'batchSendEmailOrMobile appMsgAudit isAuditMgr isAuditSender',
        batchSendEmailOrMobile,
        appMsgAudit,
        isAuditMgr,
        isAuditSender
    );

    function checkMessage() {
        const {msgType, safe, content} = contentRef.current.getData();
        const {toAll, tos, toUserCount} = sendRef.current.getData();

        // console.log('optional', tos?.departs);
        // console.log('optional', tos?.users ?? 'users');
        // console.log();
        console.log({toAll, tos, toUserCount});
        // console.log(msgType);
        // console.log('safe', safe);
        console.log(content);

        if (!toAll && isEmptySelData(tos)) {
            message.warn('「发送范围」不能为空');
            return false;
        }

        if (_.isEmpty(content)) {
            message.warn('「消息内容」不能为空');
            return false;
        }

        if (msgType === 'text' && byteLength(content.content) > MAX_CONTENT_BYTE_LENGTH) {
            message.warn(`文本内容不能超过 ${MAX_CONTENT_BYTE_LENGTH} 字节`);
            return false;
        }

        return {toAll, tos, toUserCount, msgType, safe, content};
    }

    function afterSendMsg(audit = false) {
        if (audit) {
            history.push(`/apps/${appId}/msg-send/list`);
        } else {
            history.push(`/apps/${appId}/msg-history-send`);
        }
    }

    function onFixedTimeClick(needAudit = false) {
        return () => {
            const data = checkMessage();
            if (!data) {
                return;
            }

            fixedTimeModalRef.current.showModal(needAudit);
        };
    }

    /**
     * 定时发送
     *
     * @param needAudit
     * @returns {(function(*=): void)|*}
     */
    function onFixedTimeChange(needAudit = false) {
        return timestamp => {
            console.log('onFixedTimeChange', needAudit, timestamp);

            const data = checkMessage();
            if (!data) {
                return false;
            }

            if (needAudit) {
                onAuditFixedTimeChange(timestamp, data);
            } else {
                onNormalFixedTimeChange(timestamp, data);
            }
        };
    }

    /**
     * 正常模式定时发送
     *
     * @param timestamp
     * @param data
     * @returns {Promise<{data: null, success: boolean, message: string, status: number} | * | * | undefined>|boolean}
     */
    function onNormalFixedTimeChange(timestamp, data) {
        const {toAll, tos, toUserCount, msgType, safe, content} = data;
        const values = {
            toAll,
            tos: toAll ? null : convertTos(tos),
            toUserCount,
            msgType,
            safe,

            fixedTimeMsg: true,
            fixedTime: timestamp,

            message: content,
            newsContents: content.newsContents,
        };
        console.table(values);

        return saveFiexedTimeMessage(appId, values).then(data => {
            console.log(data);
            message.success('定时任务保存成功');
            contentRef.current.setEditDirty(false);

            afterSendMsg(false);
        });
    }

    function onAuditFixedTimeChange(timestamp, data) {
        saveAuditData(data, timestamp);
    }

    function onSendClick(needAudit = false) {
        return () => {
            console.log('onSendClick', needAudit);
            const data = checkMessage();
            if (!data) {
                return;
            }

            if (needAudit) {
                onAuditSendClick(data);
            } else {
                onNormalSendClick(data);
            }
        };
    }

    /**
     * 正常发送
     */
    function onNormalSendClick(data) {
        const {toAll, tos, toUserCount, msgType, safe, content} = data;
        Modal.confirm({
            title: '提示',
            content: '确定要发送消息？',
            okText: '确定发送',
            onOk: () => {
                sendMessage2Wecom(toAll, tos, toUserCount, msgType, safe, content);
            },
        });
    }

    /**
     * 发送审核
     */
    function onAuditSendClick(data) {
        saveAuditData(data);
    }

    /**
     * 保存审核记录
     * @param data
     * @param timestamp 存在则表示此消息为定时消息
     */
    function saveAuditData(data, timestamp) {
        const {toAll, tos, toUserCount, safe, msgType, content} = data;
        let title = _.truncate(content?.content ?? content?.title, {length: 30});
        title = title.replaceAll('\n', '');

        const toList = toAll ? null : convertTos(tos);
        const jsonData = {
            toAll,
            tos: toList,
            toUserCount,
            msgType,
            safe,
            message: content,
            newsContents: content.newsContents,

            // 定时消息
            ...(timestamp ? {fixedTimeMsg: true, fixedTime: timestamp} : {}),
        };

        const auditMsg = {
            title,
            toAll,
            jsonTos: JSON.stringify(toList),
            toUserCount,
            msgType,
            jsonData: JSON.stringify(jsonData),

            // 定时消息
            ...(timestamp ? {fixedTimeMsg: true, fixedTime: timestamp} : {}),
        };
        console.table(auditMsg);

        Modal.confirm({
            title: '提示',
            content: '确定要发送消息到审核人？',
            okText: '确定发送',
            onOk: () => {
                setSubmiting(true);

                addAuditMsg(appId, auditMsg)
                    .then(data => {
                        console.log(data);
                        message.success('发送成功');
                        contentRef.current.setEditDirty(false);
                        afterSendMsg(true);
                    })
                    .finally(() => {
                        setSubmiting(false);
                    });
            },
        });
    }

    function sendMessage2Wecom(toAll, tos, toUserCount, msgType, safe, content) {
        setSubmiting(true);
        const values = {
            toAll,
            tos: toAll ? null : convertTos(tos),
            toUserCount,
            msgType,
            safe,
            message: content,
            newsContents: content.newsContents,
        };
        console.table(values);

        sendMessage(appId, values)
            .then(data => {
                console.log(data);
                message.success('发送成功');
                contentRef.current.setEditDirty(false);

                afterSendMsg(false);
            })
            .catch(() => {
                setSubmiting(false);
            });
    }

    return (
        <div style={{maxWidth: 900, marginLeft: 24}}>
            <PageHeader
                title="发送消息"
                avatar={{icon: <FileTextTwoTone />, style: {backgroundColor: '#e6f7ff'}}}
                style={{paddingLeft: 10}}
            />

            <SendArea ref={sendRef} appId={appId} batchSendEmailOrMobile={batchSendEmailOrMobile} />

            <MessageContent ref={contentRef} style={{marginBottom: 24}} />

            <Divider />

            <SendButtons
                submiting={submiting}
                appId={appId}
                isAuditSender={isAuditSender}
                onSendClick={onSendClick}
                onFixedTimeClick={onFixedTimeClick}
                onFixedTimeChange={onFixedTimeChange}
                fixedTimeModalRef={fixedTimeModalRef}
            />
            {/*
            <Space size="large">
                <Button
                    type="primary"
                    icon={<SendOutlined />}
                    loading={submiting}
                    disabled={submiting}
                    onClick={onSendClick}
                >
                    发送
                </Button>
                <Button icon={<ClockCircleOutlined />} onClick={onFixedTimeClick}>
                    定时发送
                </Button>
            </Space>*/}

            {/*<FixedTimeModal ref={fixedTimeModalRef} okText="发送" onChange={onFixedTimeChange} />*/}
        </div>
    );
}
