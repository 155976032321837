import {openGet, openPost} from './openAjaxUtils';

export function getUserNewsPraise(userId, newsId) {
    return openGet(`/open/praise/news/${newsId}`, {params: {userId}});
}

export function toggleNewsPraise(appId, userId, userName, newsId) {
    return openPost('/open/praise/news', {data: {appId, userId, userName, itemId: newsId}});
}

export function toggleCommentPraise(appId, userId, userName, commentId) {
    return openPost('/open/praise/comment', {data: {appId, userId, userName, itemId: commentId}});
}
