import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Spin} from 'antd';
import {useParams} from 'react-router-dom';
import _ from 'lodash';

import {CheckOutlined} from '@/icons';
import {PageGrid, RowAction} from '@/component';
import AppTree from '@/page/component/AppTree';
import {useDepartTreeData} from './tabPaneService';
import {getColumns} from '@/page/admin/DepartsPage/userListColumns';

import styles from '../MainPanel.module.less';
import {getTreeData} from '@/page/admin/selector/DepartUserTagSelector/tabPane/departTreeUtils';
import UserViewModal from '@/page/admin/DepartsPage/UserViewModal';

export default function UserTabPane({
    allowDepartIds,
    searchValue,
    clearSearchValue,
    onAddSelected,
}) {
    const tableRef = useRef();
    const viewRef = useRef();

    const {loading, treeData} = useDepartTreeData();
    const [depart, setDepart] = useState({});
    const {appId} = useParams();
    const [url, setUrl] = useState(null);

    const filterTreeData = loading ? treeData : getTreeData(treeData, allowDepartIds);

    useEffect(() => {
        /**
         * 在应用下搜索时需要在可见范围内搜索。
         *
         * 在系统管理或者应用配置选人的时候则是在全部组织结构中搜索。
         *
         * @return {string} url
         */
        function getAppSearchUrl() {
            if (Array.isArray(allowDepartIds)) {
                return `/app-users?appId=${appId}&s=${encodeURIComponent(searchValue)}`;
            }
            return `/users?s=${encodeURIComponent(searchValue)}`;
        }

        if (searchValue) {
            setUrl(getAppSearchUrl());
        } else if (!_.isEmpty(depart)) {
            setUrl(`/departs/${depart.id}/users`);
        } else {
            setUrl(null);
        }
    }, [allowDepartIds, appId, depart, searchValue]);

    function onSelect(idArr, {node}) {
        // console.log(idArr, node);
        const {
            depart: {id, name},
        } = node;

        clearSearchValue && clearSearchValue();

        // onAddSelected('departs', {id, name});
        setDepart({id, name});
    }

    function onUserSelect(record) {
        onAddSelected('users', {id: record.id, name: record.name});
    }

    function onNameClick(record) {
        return () => {
            viewRef.current.showModal(record);
        };
    }

    const columns = getColumns(['avatar', 'name'], {onNameClick});

    const actions = [
        {
            key: 'select',
            title: '选择人员',
            hide: false,
            icon: <CheckOutlined style={{marginRight: 10}} />,
            onClick: onUserSelect,
        },
    ];
    const columnAction = {
        title: '操作',
        key: 'selAction',
        width: 120,
        // eslint-disable-next-line react/display-name
        render: (text, record) => <RowAction actions={actions} record={record} />,
    };
    columns.push(columnAction);

    return (
        <div className={styles.tabContent2}>
            <div className={styles.tabContentSider}>
                <div className={styles.tabContentInner}>
                    <Spin spinning={loading}>
                        {!loading && !!filterTreeData && (
                            <AppTree treeData={filterTreeData} onSelect={onSelect} />
                        )}
                    </Spin>
                </div>
            </div>
            <div className={styles.tabContentMain}>
                <div className={styles.tabContentInner}>
                    {url && (
                        <PageGrid
                            ref={tableRef}
                            url={url}
                            columns={columns}
                            paginationProps={{simple: true, showSizeChanger: false}}
                        />
                    )}

                    <UserViewModal ref={viewRef} />
                </div>
            </div>
        </div>
    );
}

UserTabPane.propTypes = {
    /**
     * 可见的部门 id
     *
     * 如果为为 null 或 undefined, 表示所有部门可见
     *
     * 如果是 [] ，表示所有不可见
     * 如果有值，则对应部门和子部门可见
     */
    allowDepartIds: PropTypes.arrayOf(PropTypes.string),

    /**
     * 查询条件
     */
    searchValue: PropTypes.string,

    clearSearchValue: PropTypes.func,

    onAddSelected: PropTypes.func.isRequired,
};
