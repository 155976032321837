import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Spin} from 'antd';
import classNames from 'classnames';

import styles from './FullscreenModal.module.less';

export default function FullscreenModal({
    children,
    contentWidth = '80%',
    contentClassName,
    contentStyle,
    loading = false,
    ...rest
}) {
    const style = contentWidth ? {...contentStyle, width: contentWidth} : contentStyle;
    const okStyle = {
        marginRight: `calc((100vw - ${
            typeof contentWidth === 'string' ? contentWidth : contentWidth + 'px'
        }) / 2 - 24px)`,
    };

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            width="100%"
            okButtonProps={{style: okStyle}}
            className={styles.modal}
            wrapClassName={styles.wrap}
            {...rest}
        >
            <Spin spinning={loading}>
                <div className={classNames(styles.container, contentClassName)} style={style}>
                    {children}
                </div>
            </Spin>
        </Modal>
    );
}

FullscreenModal.propTypes = {
    children: PropTypes.node,
    contentWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.object,
    loading: PropTypes.bool,
};
