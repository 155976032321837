import React, {forwardRef, useImperativeHandle, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Divider, Form, Input, message, Modal} from 'antd';

import MessageContent from '@/page/app/component/MessageContent';
import KeywordsReplyFormRuleList from './KeywordsReplyFormRuleList';
import {addKeywordReply, updateKeywordReply} from '@/api/reply';
import {useKeywordReply} from './replyService';

const KeywordsReplyFormModal = forwardRef(({modalTitle, appId, reload}, ref) => {
    const msgContentRef = useRef();
    const formRef = useRef();

    const [visible, setVisible] = useState(false);
    const [submiting, setSubmiting] = useState(false);

    const [replyId, setReplyId] = useState(null);
    const {loading, value} = useKeywordReply(appId, replyId);
    // console.log('loading, value', loading, value);

    useEffect(() => {
        setSubmiting(loading);

        if (loading) {
            return;
        }

        const replyData = _.get(value, 'data', {});
        if (_.isEmpty(replyData)) {
            return;
        }

        const {replyName, replyKeyJson, replyGlobal, id, msgType, ...rest} = replyData;
        formRef.current.setFieldsValue({replyName, rules: JSON.parse(replyKeyJson)});

        msgContentRef.current.setData(msgType, rest);
    }, [loading, value]);

    function showModal(replyId) {
        setVisible(true);
        setReplyId(replyId);
    }

    function closeModal() {
        setVisible(false);
        setSubmiting(false);
        setReplyId(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const {replyName, rules} = values;
        const {msgType, safe, content} = msgContentRef.current.getData();
        console.log(msgType, safe, content);
        if (_.isEmpty(content)) {
            message.warn('「消息内容」不能为空');
            return;
        }

        const replyData = {
            replyName,
            replyKeyJson: JSON.stringify(rules),
            msgType,
            ...content,
            safe,
        };
        console.table(replyData);

        setSubmiting(true);
        const promise = replyId
            ? updateKeywordReply(appId, replyId, replyData)
            : addKeywordReply(appId, replyData);
        promise
            .then(data => {
                console.log('add', data);
                reload();
                closeModal();
            })
            .finally(() => {
                setSubmiting(false);
            });
    }

    function onOk() {
        formRef.current.submit();
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            width={1000}
            title={modalTitle}
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{loading: submiting}}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical">
                <Form.Item
                    name="replyName"
                    label="规则名"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input placeholder="规则名" maxLength={30} autoFocus />
                </Form.Item>

                <KeywordsReplyFormRuleList />
            </Form>

            <Divider />
            <MessageContent ref={msgContentRef} autoFocus={false} style={{maxWidth: '100%'}} />
        </Modal>
    );
});

KeywordsReplyFormModal.displayName = 'KeywordsReplyFormModal';

KeywordsReplyFormModal.propTypes = {
    modalTitle: PropTypes.string,
    appId: PropTypes.string.isRequired,
    reload: PropTypes.func,
};

export default KeywordsReplyFormModal;
