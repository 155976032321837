import React, {useRef} from 'react';
import {Button, PageHeader, Tag} from 'antd';
import {PlusOutlined} from '@/icons';
import {useSyncConfig} from './SyncConfigContext';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';

function SyncConfigGlobalTags() {
    const DepartUserTagSelectorRef = useRef();

    const {configs, saveConfig, retry} = useSyncConfig();
    const {global_tag = []} = configs || {};

    function onClick() {
        // 将 global_tag 值转换为 [{id, name}] 格式
        const value = global_tag.map(({destId, destName}) => ({id: destId, name: destName}));
        DepartUserTagSelectorRef.current.showModal({initialValues: {tags: value}});
    }

    async function onTagSelect(values) {
        console.log('onTagSelect', values);
        const {tags = []} = values || {};
        console.log('onSave', tags);
        // 将 [{id, name}] 格式转换为 global_tag 格式 [{destId, destName}]
        const dbTags = tags.map(({id, name}) => ({destId: id, destName: name}));
        const {status} = await saveConfig('global_tag', dbTags);
        if (status === 200) {
            retry();
            return true;
        }
        return false;
    }

    return (
        <>
            <PageHeader
                title="全局目标标签"
                subTitle="所有新增人员均同步到下列标签"
                extra={
                    <Button type="primary" size="small" icon={<PlusOutlined />} onClick={onClick}>
                        选择标签
                    </Button>
                }
            >
                {global_tag.map(({destId, destName}) => (
                    <Tag key={destId} color="#2db7f5">
                        {destName}
                    </Tag>
                ))}
            </PageHeader>
            <DepartUserTagSelector
                ref={DepartUserTagSelectorRef}
                modalTitle="选择标签"
                showTagTab={true}
                showDepartTab={false}
                showUserTab={false}
                onSelect={onTagSelect}
            />
        </>
    );
}

export default SyncConfigGlobalTags;
