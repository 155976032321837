import {useAsync, useAsyncRetry} from 'react-use';
import {getMessage, getUserMessages} from '@/api/message';

export function useMessage(appId, msgId) {
    return useAsync(async () => {
        if (!msgId) {
            return null;
        }

        return getMessage(appId, msgId);
    }, [msgId]);
}

export function useUserMessages(appId, userId, current = 1, size = 10) {
    return useAsyncRetry(async () => {
        if (!userId) {
            return null;
        }

        return getUserMessages(appId, userId, current, size);
    }, [appId, userId, current, size]);
}
