import {useContext} from 'react';

import ResponsiveContext from './Context';
import ResponsiveView from './ResponsiveView';

export const ResponsiveConsumer = ResponsiveContext.Consumer;
// export const ResponsiveProvider = ResponsiveContext.Provider;

export function useResponsiveView() {
    const value = useContext(ResponsiveContext);

    if (!value) {
        throw new Error(
            'useResponsiveView must be used in ResponsiveContext.Provider or ResponsiveView'
        );
    }

    return value;
}

export default ResponsiveView;
