import React, {useEffect, useRef} from 'react';
import {findDOMNode} from 'react-dom';

import PropTypes from 'prop-types';
import {Button, Modal, Popover, Space, Tag, Typography} from 'antd';

import {PageGrid, TextView} from '@/component';
import {getAuditMsgPageUrl, updateAuditResult} from '@/api/msgAudit';
import msgTypes from '@/page/component/msg/MsgTypes';
import {formatLong} from '@/util/dateUtils';
import AuditMsgRejectModal from './AuditMsgRejectModal';
import AuditMsgDrawer from './AuditMsgDrawer';

AuditMsgTable.propTypes = {
    appId: PropTypes.string.isRequired,

    /**
     * 发送人id
     *
     * 如果传递此参数，则显示发送人可见的列表，否则是审核人可见列表
     */
    creatorId: PropTypes.string,
};

function AuditMsgTable({appId, creatorId}) {
    const pageGridRef = useRef();
    const rejectModalRef = useRef();
    const msgDrawerRef = useRef();

    // 点击表格内标题显示 drawer, 点击表格其它地方隐藏之
    useEffect(() => {
        function handleClick(e) {
            // console.log(e.target.dataset);
            if (e.target.dataset['auditMsgId']) {
                console.log('show drawer');
                msgDrawerRef.current.show(e.target.dataset['auditMsgId']);
            } else if (msgDrawerRef.current.isShown()) {
                console.log('hide drawer');
                msgDrawerRef.current.hide();
            }
        }

        const pageGridDom = findDOMNode(pageGridRef.current);
        pageGridDom.addEventListener('click', handleClick);
        return () => {
            pageGridDom.removeEventListener('click', handleClick);
        };
    }, []);

    function refresh() {
        pageGridRef.current.refresh();
    }

    function onApproved(msgId) {
        return () => {
            console.log(msgId);
            Modal.confirm({
                title: '审核通过',
                content: '此消息将会直接发送到目标用户',
                okText: '通过',
                onOk: () => {
                    updateAuditResult(appId, msgId, 1).then(data => {
                        console.log(data);
                        refresh();
                    });
                },
            });
        };
    }

    function onReject(msgId) {
        return () => {
            rejectModalRef.current.showModal(msgId);
        };
    }

    function onUpdateRejectData(msgId, auditDesc) {
        return updateAuditResult(appId, msgId, 2, auditDesc).then(() => {
            refresh();
            return true;
        });
    }

    const columns = [
        {
            title: '标题',
            dataIndex: 'title',
            render: (title, record) => {
                return <Typography.Link data-audit-msg-id={record.id}>{title}</Typography.Link>;
            },
        },
        {
            title: '类型',
            dataIndex: 'msgType',
            width: 100,
            render: text => {
                const type = msgTypes[text];
                if (type) {
                    return type.label;
                }
                return '';
            },
        },
        {
            title: '发送目标',
            dataIndex: 'toAll',
            render: (toAll, record) => {
                return (
                    <>
                        {toAll ? (
                            <Tag color="blue">所有人</Tag>
                        ) : (
                            <Tag color="#108ee9">{record.toUserCount} 人</Tag>
                        )}
                    </>
                );
            },
        },
        {
            title: '定时消息',
            dataIndex: 'fixedTimeMsg',
            render: (fixedTimeMsg, record) => {
                return fixedTimeMsg ? <Tag>{formatLong(record.fixedTime)}</Tag> : '';
            },
        },
        {
            title: '发送人',
            dataIndex: 'creatorName',
        },
        {
            title: '发送时间',
            dataIndex: 'createTime',
            render: createTime => {
                return formatLong(createTime);
            },
        },
        {
            title: '状态',
            dataIndex: 'auditResult',
            render: (auditResult, record) => {
                if (auditResult === 1) {
                    return <Tag color="green">审核通过</Tag>;
                } else if (auditResult === 2) {
                    return (
                        <Popover
                            title="原因"
                            content={
                                <TextView style={{maxWidth: 400}}>{record.auditDesc}</TextView>
                            }
                            placement="right"
                        >
                            <Tag color="red">审核不通过</Tag>
                        </Popover>
                    );
                } else {
                    return creatorId ? (
                        <Tag color="#f50">待审核</Tag>
                    ) : (
                        <Space direction="horizontal">
                            <Button type="link" size="small" onClick={onApproved(record.id)}>
                                通过
                            </Button>
                            <Button type="link" danger size="small" onClick={onReject(record.id)}>
                                驳回
                            </Button>
                        </Space>
                    );
                }
            },
        },
    ];

    return (
        <>
            <PageGrid
                ref={pageGridRef}
                url={getAuditMsgPageUrl(appId, creatorId)}
                columns={columns}
            />
            <AuditMsgRejectModal
                ref={rejectModalRef}
                appId={appId}
                onUpdateRejectData={onUpdateRejectData}
            />
            <AuditMsgDrawer ref={msgDrawerRef} appId={appId} />
        </>
    );
}

export default AuditMsgTable;
