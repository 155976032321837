import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, message} from 'antd';
import {useParams} from 'react-router-dom';
import copy from 'copy-to-clipboard';

import Player from '@/page/component/Player';
import {CloudUploadOutlined} from '@/icons';
import MediaType, {getFileMediaUrl} from '../MediaType';
import MediaListView from '@/page/app/MediaPage/MediaListView';
import VideoFormModal from '@/page/component/VideoFormModal';
import {addCommonMedia, updateCommonMedia} from '@/api/media';
import {getFileUrl} from '@/page/util/appUrlUtils';

export default function VideoPage({editable = true, onItemChecked}) {
    const playerRef = useRef();
    const listRef = useRef();
    const videoFormModalRef = useRef();
    const {appId} = useParams();

    const mediaType = MediaType.VIDEO;

    function onNameClick(record) {
        const {title, description, attachId, attachExt} = record;
        const url = getFileMediaUrl(attachId, attachExt);

        playerRef.current.showPlayer('video', title, url, description);
    }

    function reload() {
        listRef.current.reload();
    }

    function onAddClick() {
        videoFormModalRef.current.showModal();
    }

    function onEditClick(record) {
        // console.log(record);
        videoFormModalRef.current.showModal(record);
    }

    function onCopyLinkClick(record) {
        const url = getFileUrl(record.attachId, record.attachExt);

        const result = copy(url);
        if (result) {
            message.success(`链接拷贝到剪贴板`);
        } else {
            message.warn('链接拷贝失败');
        }
    }

    function onFormSuccess(videoData) {
        // console.log(videoData);
        const video = {...videoData, mediaType: mediaType.key};

        const promise = video.id
            ? updateCommonMedia(appId, video.id, video)
            : addCommonMedia(appId, video);

        return promise.then(data => {
            console.log(data);
            reload();
            return true;
        });
    }

    return (
        <>
            <MediaListView
                ref={listRef}
                editable={editable}
                onItemChecked={onItemChecked}
                type={mediaType}
                columnKeys={['avatar', 'title', 'size', 'creatorName', 'createTime']}
                columnOptions={{onNameClick}}
                columnActionProps={{onEditClick, onCopyLinkClick, enableDownload: true}}
                switchVisible
                initialGrid
            >
                {editable && (
                    <>
                        <Button type="primary" icon={<CloudUploadOutlined />} onClick={onAddClick}>
                            上传视频
                        </Button>
                    </>
                )}
            </MediaListView>
            <Player ref={playerRef} />
            <VideoFormModal
                ref={videoFormModalRef}
                category="media"
                onFormSuccess={onFormSuccess}
            />
        </>
    );
}

VideoPage.propTypes = {
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
};
