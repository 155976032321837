const actions = {
    initAppId: (draft, appId) => {
        draft.appId = appId;
    },

    initApp: (draft, {app, roleType, userId, extAttr}) => {
        draft.app = app;
        draft.isMgr = roleType === 'app_mgr';
        draft.isUser = roleType === 'app_user';
        draft.appId = app.id;
        draft.userId = userId;
        draft.extAttr = extAttr;
    },
};

export default actions;
