import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Layout, Button} from 'antd';
import classNames from 'classnames';

import {useResponsiveView} from '@/component';

import {ControlTwoTone, MenuOutlined, RollbackOutlined} from '@/icons';
import styles from './AdminHeader.module.less';

const {Header} = Layout;

export default function AdminHeader({onToggleSidebar}) {
    const {isMobile} = useResponsiveView();

    function renderMenuLink() {
        if (!isMobile) {
            return null;
        }

        return (
            <Button type="text" style={{color: 'white'}} onClick={onToggleSidebar}>
                <MenuOutlined />
            </Button>
        );
    }

    return (
        <Header className={classNames(styles.header, {[styles.mobileHeader]: isMobile})}>
            <div className={styles.headerLogo}>
                {renderMenuLink()}
                <ControlTwoTone style={{fontSize: 32, marginRight: 10}} />
                系统管理
            </div>
            <Link to="/" className={styles.backLink}>
                <RollbackOutlined style={{marginRight: 5}} />
                返回首页
            </Link>
        </Header>
    );
}

AdminHeader.propTypes = {
    onToggleSidebar: PropTypes.func,
};
