import React from 'react';
import PropTypes from 'prop-types';
import {Empty, Typography} from 'antd';

import {TwoColumnLayout} from '@/component';

import {rolePropTypes} from '../propTypes';
import RoleAuthorities from './RoleAuthorities';
import RoleUserList from './RoleUserList';

const {Text} = Typography;

export default function RoleContent({role, showUserList = true, editable = true}) {
    if (!role) {
        return (
            <Empty
                description={<Text type="secondary">点击角色查看详细信息</Text>}
                style={{marginTop: 140}}
            />
        );
    }

    return (
        <TwoColumnLayout>
            <TwoColumnLayout.SidePanel>
                <RoleAuthorities role={role} editable={editable} />
            </TwoColumnLayout.SidePanel>

            {showUserList && (
                <TwoColumnLayout.ContentPanel style={{overflow: 'hidden'}}>
                    <RoleUserList role={role} editable={editable} />
                </TwoColumnLayout.ContentPanel>
            )}
        </TwoColumnLayout>
    );
}

RoleContent.propTypes = {
    role: PropTypes.shape(rolePropTypes),
    showUserList: PropTypes.bool,
    editable: PropTypes.bool,
};
