import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, message, Modal, Typography, Upload} from 'antd';
import filesize from 'filesize';

import {PlusOutlined} from '@/icons';
import PreviewModal from './PreviewModal';
import AppUpload from '../AppUpload';

const UploadWallModal = forwardRef(
    ({type, modalTitle, itemId, category, afterUpload, afterCloseModal}, ref) => {
        const previewModalRef = useRef();
        const [visible, setVisible] = useState(false);
        // const [uploadFileList, setFileList] = useState([]);
        // console.log('type: ', type);

        const isImage = type.key === 'image';

        // 附件上传后的展示方式
        const listType = isImage ? 'picture-card' : 'picture';

        function showModal() {
            setVisible(true);
        }

        function closeModal() {
            setVisible(false);
        }

        useImperativeHandle(ref, () => ({
            showModal,
            closeModal,
        }));

        function onUploadChange({file}) {
            // console.log('onUploadChange');
            // console.log('file, fileList', file, fileList);
            if (file.status === 'done') {
                const {
                    response: {data},
                } = file;
                // console.log(data);
                afterUpload && afterUpload(data);
            }
        }

        function beforeUpload(file) {
            // console.log('beforeUpload', file);
            const {size} = file;
            const {maxSize} = type;

            if (size <= 5) {
                message.warn('文件必须大于 5 个字节');
                return Upload.LIST_IGNORE;
            }

            if (!maxSize || size <= maxSize) {
                return true;
            }

            message.warn('文件大小不能超过 ' + filesize(maxSize));

            // Upload.LIST_IGNORE 算是 antd 对于 beforeUpload 的 hack
            // 如果返回 false 的话，该文件缩略图仍然会显示在预览列表中，
            // 所以开发方新增了这个特定的属性用于处理这个问题
            return Upload.LIST_IGNORE;
        }

        function onPreview(file) {
            if (isImage) {
                previewModalRef.current.showModal(file.response.data[0].urls.file);
            }
        }

        function onOk() {
            afterCloseModal && afterCloseModal();
            closeModal();
        }

        const accept = type.accept && type.accept !== '*' ? type.accept : null;

        return (
            <Modal
                destroyOnClose
                width={800}
                maskClosable={false}
                visible={visible}
                title={modalTitle}
                onCancel={closeModal}
                onOk={onOk}
                cancelButtonProps={{style: {display: 'none'}}}
            >
                {isImage && (
                    <div>
                        <Typography.Text type="secondary">
                            {type.accept && `格式支持： ${type.accept}, `}
                            {type.maxSize && `文件大小不超过 ${filesize(type.maxSize)}`}
                        </Typography.Text>
                        <Divider />
                    </div>
                )}
                <AppUpload
                    category={category}
                    accept={accept}
                    listType={listType}
                    showUploadList={{showRemoveIcon: false}}
                    beforeUpload={beforeUpload}
                    onPreview={onPreview}
                    onChange={onUploadChange}
                >
                    {isImage ? (
                        <div>
                            <PlusOutlined />
                            <div style={{marginTop: 8}}>上传{type.label}</div>
                        </div>
                    ) : (
                        <div>
                            <Button type="primary" icon={<PlusOutlined />}>
                                上传{type.label}
                            </Button>
                            <Typography.Text type="secondary" style={{marginLeft: 24}}>
                                {accept && `格式支持： ${type.accept}, `}
                                {type.maxSize && `文件大小不超过 ${filesize(type.maxSize)}`}
                            </Typography.Text>
                        </div>
                    )}
                </AppUpload>

                <PreviewModal ref={previewModalRef} />
            </Modal>
        );
    }
);

UploadWallModal.displayName = 'UploadWallModal';

UploadWallModal.propTypes = {
    type: PropTypes.shape({
        key: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        accept: PropTypes.string,
        maxSize: PropTypes.number,
    }).isRequired,
    modalTitle: PropTypes.string.isRequired,
    itemId: PropTypes.string,
    category: PropTypes.oneOf(['media', 'message']).isRequired,
    afterUpload: PropTypes.func,
    afterCloseModal: PropTypes.func,
};

export default UploadWallModal;
