import {deleteJson, getJson, patchJson, postJson, putJson} from '@/util/ajaxUtils';

/**
 * 载入企业微信中的应用信息。
 *
 * @param params 参数为 {agentid, secret}
 * @return {Promise<*>}
 */
export function getWecomAppInfo(params) {
    return getJson('/wecom/apps', {params});
}

export function getAllApps() {
    return getJson('/apps');
}

export function getUserAppMeta(userId) {
    return getJson('/apps/apps-by-user', {params: {userId}});
}

export function toggleUserAppPined(userId, appId, pined) {
    return patchJson(`/apps/pined`, {params: {userId, appId, pined}});
}

export function getApp(appId) {
    return getJson(`/apps/${appId}`);
}

export function getUserApp(userId, appId) {
    return getJson('/apps/app-by-user', {params: {userId, appId}});
}

export function addApp(data) {
    return postJson('/apps', {data});
}

export function updateApp(appId, data) {
    return putJson(`/apps/${appId}`, {data});
}

export function updateAppCallback(appId, data) {
    return patchJson(`/apps/${appId}/callback`, {data});
}

export function deleteApp(appId) {
    return deleteJson(`/apps/${appId}`);
}

// -----------------------------------------
// app role
// -----------------------------------------

export function getAppRoles(appId) {
    return getJson(`/approles/${appId}`);
}

export function updateAppRoles(appId, type, roles) {
    return postJson(`/approles/${appId}`, {params: {type}, data: roles});
}

export function deleteAppRole(appRoleId) {
    return deleteJson(`/approles`, {params: {appRoleId}});
}
