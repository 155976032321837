import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Button, PageHeader, Modal} from 'antd';
import _ from 'lodash';

import {PlusOutlined} from '@/icons';
import KeywordsReplyFormModal from './KeywordsReplyFormModal';
import KeywordsTable from './KeywordsTable';
import {useKeywordReplyList} from './replyService';
import {deleteKeywordReply} from '@/api/reply';

export default function KeywordsReply({editable = true}) {
    const formModal = useRef();
    const {appId} = useParams();
    const {value, retry} = useKeywordReplyList(appId);
    // console.log('{loading, value}', {loading, value});

    const dataSource = _.get(value, 'data', []);

    function onAddClick() {
        formModal.current.showModal();
    }

    function onEditClick(record) {
        console.log(record);
        formModal.current.showModal(record.id);
    }

    function onDeleteClick(record) {
        console.log(record);
        Modal.confirm({
            title: '警告',
            content: `确定删除「${record.replyName}」?`,
            okButtonProps: {danger: true},
            onOk: () => {
                deleteKeywordReply(appId, record.id).then(data => {
                    console.log(data);
                    retry();
                });
            },
        });
    }

    return (
        <PageHeader
            title="关键词自动回复"
            subTitle="设置成员发送关键词消息后应用回复的内容"
            extra={
                editable && (
                    <Button type="primary" icon={<PlusOutlined />} onClick={onAddClick}>
                        添加
                    </Button>
                )
            }
        >
            <KeywordsTable
                appId={appId}
                dataSource={dataSource}
                onEditClick={onEditClick}
                onDeleteClick={onDeleteClick}
                editable={editable}
            />

            <KeywordsReplyFormModal
                ref={formModal}
                appId={appId}
                reload={retry}
                modalTitle="关键词自动回复编辑"
            />
        </PageHeader>
    );
}

KeywordsReply.propTypes = {
    editable: PropTypes.bool,
};
