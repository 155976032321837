import React, {forwardRef, useImperativeHandle, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Drawer, PageHeader} from 'antd';

import MsgPreviewContainer from './MsgPreviewContainer';
import UserMessageList from './UserMessageList';
import MessageReplyModal from './MessageReplyModal';

import styles from './styles.module.less';

const ReceivedMsgDrawer = forwardRef(({appId}, ref) => {
    const replyModalRef = useRef();
    const msgListRef = useRef();

    const [visible, setVisible] = useState(false);
    const [selMsg, setSelMsg] = useState(null);

    // console.log('selmsg', selMsg);

    function show(msg) {
        setVisible(true);
        setSelMsg(msg);
    }

    function hide() {
        setVisible(false);
        setSelMsg(null);
    }

    useImperativeHandle(ref, () => ({
        show,
        hide,
    }));

    function reloadMsgList() {
        msgListRef.current.reload();
    }

    function onReplyClick() {
        replyModalRef.current.showModal(selMsg.userId);
    }

    function renderFooter() {
        return (
            <Button type="primary" onClick={onReplyClick}>
                回复消息
            </Button>
        );
    }

    return (
        <Drawer
            visible={visible}
            mask={false}
            width={900}
            onClose={hide}
            destroyOnClose
            title={`${selMsg?.userName}的消息`}
            bodyStyle={{padding: 0}}
            footer={renderFooter()}
        >
            <div className={styles.container}>
                <div className={styles.preview}>
                    <PageHeader title="消息预览">
                        <MsgPreviewContainer appId={appId} msgId={selMsg?.id} />
                    </PageHeader>
                </div>
                <div className={styles.history}>
                    <PageHeader title="消息历史">
                        <UserMessageList ref={msgListRef} appId={appId} userId={selMsg?.userId} />
                    </PageHeader>
                </div>
            </div>

            <MessageReplyModal
                ref={replyModalRef}
                appId={appId}
                onMessageSendSuccess={reloadMsgList}
            />
        </Drawer>
    );
});

ReceivedMsgDrawer.displayName = 'ReceivedMsgDrawer';

ReceivedMsgDrawer.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default ReceivedMsgDrawer;
