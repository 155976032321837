import React from 'react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import useTitle from '@/hook/useTitle';
import {Space} from 'antd';
import RecentList from '@/page/app/ReportPage/UserIncreaseReport/RecentList';
import RecentReportList from '../component/RecentReportList';
import DetailsReportList from '../component/DetailsReportList';

import LineChart from './LineChart';

const alias = 'user-activity';

export default function UserActivityReport() {
    useTitle('成员活跃度 - 数据统计');
    const {appId} = useParams();

    const detailsColumns = [
        {
            title: '时间',
            dataIndex: 'reportDate',
        },
        {
            title: '进入应用（人）',
            dataIndex: 'appEnterUserCount',
            align: 'right',
        },
        {
            title: '进入应用（次）',
            dataIndex: 'appEnterCount',
            align: 'right',
        },
        {
            title: '用户消息（人）',
            dataIndex: 'userMsgUserCount',
            align: 'right',
        },
        {
            title: '用户消息（次）',
            dataIndex: 'userMsgCount',
            align: 'right',
        },
    ];

    const recentColumns = [
        {
            title: '时间',
            dataIndex: 'title',
        },
        ...detailsColumns.slice(1),
        {
            title: '进入应用频率（次/人）',
            key: 'appEnterFreq',
            align: 'right',
            render: (__, {allowUserCount, appEnterCount}) => {
                return Number(appEnterCount / allowUserCount).toFixed(2);
            },
        },
        {
            title: '用户消息频率（次/人）',
            key: 'userMsgFreq',
            align: 'right',
            render: (__, {allowUserCount, userMsgCount}) => {
                return Number(userMsgCount / allowUserCount).toFixed(2);
            },
        },
    ];

    return (
        <Space direction="vertical" size={48} style={{display: 'flex'}}>
            <RecentReportList appId={appId} alias={alias} columns={recentColumns} />
            <LineChart appId={appId} alias={alias} />
            <DetailsReportList appId={appId} alias={alias} columns={detailsColumns} />
        </Space>
    );
}
