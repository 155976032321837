import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import MediaType from '@/page/app/MediaPage/MediaType';
import PreviewModal from '@/page/component/UploadWallModal/PreviewModal';
import {getFileUrl} from '@/page/component/attachUtils';
import CommonFileContent from './comp/CommonFileContent';
import {valuePropTypes} from './propTypes';

export default function ImageContent({value, onChange}) {
    const previewModalRef = useRef();

    return (
        <>
            <CommonFileContent
                type={MediaType.IMAGE}
                value={value}
                onChange={onChange}
                buttonTitle="添加图片"
                description={
                    <>
                        <p>格式支持：{MediaType.IMAGE.accept}</p>
                        <p>文件大小不超过 {filesize(MediaType.IMAGE.maxSize)}</p>
                    </>
                }
            >
                {({title, attachId, attachExt}) => (
                    <>
                        <img
                            src={getFileUrl(attachId, attachExt)}
                            alt={title}
                            title={title}
                            onClick={() =>
                                previewModalRef.current.showModal(getFileUrl(attachId, attachExt))
                            }
                        />
                    </>
                )}
            </CommonFileContent>

            <PreviewModal ref={previewModalRef} />
        </>
    );
}

ImageContent.propTypes = {
    value: valuePropTypes,
    onChange: PropTypes.func.isRequired,
};
