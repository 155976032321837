import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {UserTag} from '@/component';
import UserViewModal from './UserViewModal';

UserClickableTags.propTypes = {
    users: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    tagProps: PropTypes.object,
};

/**
 * 可点击的用户标签列表，默认点击显示该用户详细信息 Modal。
 *
 * @param users 用户列表，其对象属性为 {id, name}
 * @param tagProps 标签属性
 * @returns {JSX.Element|null}
 * @constructor
 */
function UserClickableTags({users, tagProps}) {
    const userViewModalRef = useRef();

    if (_.isEmpty(users)) {
        return null;
    }

    function onTagClick(userId) {
        return () => {
            userViewModalRef.current.showModal(userId);
        };
    }

    const tags = users.map(({id, name}) => (
        <UserTag key={id} onClick={onTagClick(id)} {...tagProps}>
            {name}
        </UserTag>
    ));

    return (
        <>
            {tags}

            <UserViewModal ref={userViewModalRef} />
        </>
    );
}

export default UserClickableTags;
