import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useAsync} from 'react-use';

import {DepartTag} from '@/component';
import {getUserLinks} from '@/api/departs';

export default function UserDeparts({userId}) {
    const {loading, value} = useAsync(() => {
        return getUserLinks(userId);
    }, [userId]);

    // console.log('loading, value, error', loading, value, error);

    if (loading) {
        return null;
    }

    const links = value?.data;
    if (_.isEmpty(links)) {
        return null;
    }

    return links.map(({primaryDepart, depart}) => {
        return (
            <DepartTag key={depart.id}>
                {depart.name}
                {primaryDepart && ' [主部门]'}
            </DepartTag>
        );
    });
}

UserDeparts.propTypes = {
    userId: PropTypes.string.isRequired,
};
