import React, {forwardRef, useImperativeHandle, useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';

import {PageGrid} from '@/component';
import {getCommentsUrl} from '@/api/comment';
import {getColumns} from './columns';

import PreviewPanel from './PreviewPanel';

const CommentTable = forwardRef(({onNewsPreviewClick}, ref) => {
    const previewRef = useRef();
    const tableRef = useRef();
    const {appId} = useParams();

    useImperativeHandle(ref, () => ({
        hideDrawer: () => previewRef.current.hide(),
    }));

    function onContentClick(record) {
        previewRef.current.show(record, true);
    }

    function reloadTable() {
        tableRef.current.refresh();
    }

    const columns = getColumns(
        ['userName', 'commentContent', 'praiseNum', 'stick', 'shown', 'newsTitle', 'createTime'],
        {
            onNewsPreviewClick,
            onContentClick,
        }
    );

    return (
        <>
            <PageGrid ref={tableRef} columns={columns} url={getCommentsUrl(appId)} />
            <PreviewPanel ref={previewRef} reloadTable={reloadTable} />
        </>
    );
});

CommentTable.displayName = 'CommentTable';

CommentTable.propTypes = {
    onNewsPreviewClick: PropTypes.func,
};

export default CommentTable;
