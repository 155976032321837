import _ from 'lodash';

export function isEmptySelData(selData) {
    if (_.isEmpty(selData)) {
        return true;
    }

    const {departs, users, tags} = selData;
    return _.isEmpty(departs) && _.isEmpty(users) && _.isEmpty(tags);
}

/**
 * 将对象格式的信息发送范围转换为列表形式。
 *
 * @param tos
 */
export function convertTos(tos) {
    const {departs, users, tags} = tos;

    const toparty = toObj2Array('toparty', departs);
    const touser = toObj2Array('touser', users);
    const totag = toObj2Array('totag', tags);

    return [...toparty, ...touser, ...totag];
}

function toObj2Array(msgToType, tos) {
    if (_.isEmpty(tos)) {
        return [];
    }

    return tos.map(({id, name}) => {
        return {
            msgToType,
            msgToId: id,
            msgToName: name,
        };
    });
}
