import _ from 'lodash';

export function extractImageSource(html) {
    let imgReg = /<img.*?(?:>|\/>)/gi;
    let srcReg = /src=['"]?([^'"]*)['"]?/i;
    let arr = html.match(imgReg);
    let imgSrc = [];

    if (!_.isEmpty(arr)) {
        for (let i = 0; i < arr.length; i++) {
            let src = arr[i].match(srcReg)[1];
            // src && imgSrc.push(src);
            if (src && !imgSrc.includes(src)) {
                imgSrc.push(src);
            }
        }
    }

    // 抽取背景图
    const bgImgSrc = extractBgImageSource(html);

    // 使用 set 过滤重复数据
    const allSrc = new Set([...imgSrc, ...bgImgSrc]);
    console.log('orig image set', allSrc);
    // 过滤掉本地图片，防止拷贝本地图片时重复上传
    const {origin} = window.location;

    return [...allSrc].filter(value => {
        if (!value) {
            return false;
        }

        const str = value.toLowerCase();
        if (!str.startsWith('http')) {
            return false;
        }

        return !str.startsWith(origin);
    });
}

export function replaceImageSource(html, origin, target) {
    // return html.replace(/<img.*?src="(.*?)".*?\/?>/gi, function (img, src) {
    //     return src === origin ? img.replace(src, target) : img;
    // });
    return html.replaceAll(origin, target);
}

export function extractBgImageSource(html) {
    let div = document.createElement('div'); //创建一个div去装富文本标签
    div.innerHTML = html; //填充富文本标签内容
    let sections = div.getElementsByTagName('*');
    let sectionArr = Array.from(sections); //转化为数组
    let oldImgSrc = [];
    sectionArr &&
        sectionArr.forEach(item => {
            let backgroundImage = item.style.backgroundImage;
            if (backgroundImage && backgroundImage.trim().startsWith('url')) {
                //拿到背景图片将路径拿出来push到新的数组
                oldImgSrc.push(backgroundImage.split(`("`)[1].split(`")`)[0]);
            }
        });

    return oldImgSrc;
}

export const toast = (text, duration) => {
    if (!text || !text.length) return;
    duration = duration || 1000;
    let root = document.body;
    let toastDom = document.createElement('div');
    toastDom.setAttribute('id', 'toast');
    toastDom.setAttribute(
        'style',
        `display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background: rgba(0, 0, 0, 0);`
    );
    let textDom = document.createElement('div');
    textDom.setAttribute(
        'style',
        `background: rgba(0, 0, 0, 0.2);
    padding: 5px 10px;
    border-radius: 4px;
    color: #fff;
    font-size: 13px;`
    );

    textDom.innerHTML = text;
    toastDom.append(textDom);
    root.append(toastDom);

    setTimeout(function () {
        let toast = document.getElementById('toast');
        toast.remove();
    }, duration);
};
