/*
 * Created by liyi on 2021-02-01 17:04.
 */
import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Badge, Table, Tag, Input, Button, Space} from 'antd';

import {SearchOutlined} from '@/icons';

export default function SyncContactLeaveTable({users, onSelect}) {
    const searchInputRef = useRef();

    const columns = [
        {
            title: '姓名',
            dataIndex: 'name',
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            filterIcon: filtered => (
                <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}} />
            ),
            filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
                // console.log('filterDropdown', selectedKeys);

                function handleSearch() {
                    // console.log('handleSearch selectedKeys', selectedKeys);
                    confirm();
                    // setSearchText(selectedKeys[0]);
                }

                return (
                    <div style={{padding: 8}}>
                        <Input
                            ref={searchInputRef}
                            placeholder="搜索 Email"
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={handleSearch}
                            style={{marginBottom: 8, display: 'block'}}
                        />
                        <Space>
                            <Button
                                type="primary"
                                onClick={handleSearch}
                                icon={<SearchOutlined />}
                                size="small"
                                style={{width: 90}}
                            >
                                查询
                            </Button>
                            <Button
                                onClick={clearFilters}
                                size="small"
                                style={{width: 90}}
                            >
                                重置
                            </Button>
                        </Space>
                    </div>
                );
            },
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    const timeout = setTimeout(() => {
                        searchInputRef.current?.select();
                        clearTimeout(timeout);
                    }, 100);
                }
            },
            onFilter: (value, record) => {
                // console.log('onFilter value, record', value, record);
                const {email} = record;
                if (!email || !email.trim()) {
                    return false;
                }

                return email.toLowerCase().includes(value?.toLowerCase());
            },
        },
        {
            title: '状态',
            dataIndex: 'enabled',
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                const {onjob, enabled} = record;
                return (
                    <div>
                        {onjob ? (
                            <div>
                                <Badge status="success" /> 在职
                            </div>
                        ) : (
                            <div>
                                <Badge status="error" /> 离职
                            </div>
                        )}
                        {enabled ? (
                            <div>
                                <Badge status="success" /> 账号正常
                            </div>
                        ) : (
                            <div>
                                <Badge status="error" /> 账号禁用
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: '企业微信状态',
            dataIndex: 'status',
            render: text => {
                if (text === 1) {
                    return <Tag color="#2db7f5">已激活</Tag>;
                } else if (text === 2) {
                    return <Tag color="red">已禁用</Tag>;
                } else if (text === 4) {
                    return <Tag color="purple">未激活</Tag>;
                } else if (text === 5) {
                    return <Tag color="#f50">退出企业</Tag>;
                }
                return '';
            },
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    function onSelectChange(selectedRowKeys, selectedRows) {
        setSelectedRowKeys(selectedRowKeys);
        onSelect(selectedRowKeys);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return (
        <Table
            rowKey="id"
            rowSelection={rowSelection}
            columns={columns}
            dataSource={users}
            pagination={false}
        />
    );
}

SyncContactLeaveTable.propTypes = {
    users: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
};
