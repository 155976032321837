import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Empty, Spin} from 'antd';
import _ from 'lodash';

import AppTree from '@/page/component/AppTree';
import {convertDeparts2TreeFormat, TOP_PARENT_ID} from '@/util/treeUtils';
import DepartFormModal from '../DepartFormModal';
import DepartsTreeMenu from './DepartsTreeMenu';

export default function LocalTree({localTreeData, refreshTrees}) {
    const modalRef = useRef();

    const {loading, value} = localTreeData;
    const [treeData, setTreeData] = useState(null);
    const [contextMenuAttrs, setContextMenuAttrs] = useState({});
    const menuContainerRef = useRef();

    useEffect(() => {
        if (loading) {
            return;
        }

        function nameCallback(node) {
            return `[${node.extId}] ${node.name}`;
        }

        const {data} = value || {};
        setTreeData(convertDeparts2TreeFormat(data, nameCallback));
    }, [loading, value]);

    function onRightClick({event, node}) {
        // console.log(event, node);

        const {depart, extraAttrs} = node;

        setContextMenuAttrs({depart, extraAttrs});

        const {pageX, pageY} = event;
        menuContainerRef.current.showMenu({pageX, pageY});
    }

    function showModal({id, extId, name, parentId = TOP_PARENT_ID} = {}) {
        modalRef.current.showModal({id, extId, name, parentId});
    }

    function closeModal() {
        modalRef.current.closeModal();
    }

    function onSelect(ids, {node}) {
        console.log(node.depart.listOrder, ids, node);
    }

    function renderEmpty() {
        if (!_.isEmpty(treeData)) {
            return null;
        }

        return (
            <Empty
                description={
                    <>
                        <Button type="primary" style={{marginTop: 10}} onClick={() => showModal()}>
                            创建根部门
                        </Button>
                    </>
                }
            />
        );
    }

    return (
        <Card title="本地组织结构">
            {renderEmpty()}

            <Spin spinning={loading}>
                {!loading && !!treeData && (
                    <AppTree treeData={treeData} onRightClick={onRightClick} onSelect={onSelect} />
                )}
            </Spin>

            <DepartsTreeMenu
                enableAddRoot={_.isEmpty(treeData)}
                contextMenuAttrs={contextMenuAttrs}
                menuContainerRef={menuContainerRef}
                refreshTree={refreshTrees}
                showFormModal={showModal}
                closeFormModal={closeModal}
            />
            <DepartFormModal ref={modalRef} refreshTree={refreshTrees} />
        </Card>
    );
}

LocalTree.propTypes = {
    localTreeData: PropTypes.shape({
        loading: PropTypes.bool,
        value: PropTypes.object,
    }),
    refreshTrees: PropTypes.func.isRequired,
};
