import * as env from './env';

/**
 * 输出 env 所有内容，方便开发时查看
 *
 * 调用时需要使用 import './envPrint';
 */
export function printEnv() {
    !env.isProd && console.log('ENV', env);
}

printEnv();
