import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Modal, Popover, Space} from 'antd';

import {CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined} from '@/icons';
import {formatLong} from '@/util/dateUtils';
import FixedTimeModal from '@/page/app/MsgSendPage/FixedTimeModal';
import {changeMessageFixedTime, deleteFiexedTimeMessage} from '@/api/message';

export default function FixedTimeStatus({appId, messageId, status, fixedTime, refresh}) {
    const fixedTimeModalRef = useRef();

    // console.log('appId', appId);

    function onChangeTimeClick() {
        fixedTimeModalRef.current.showModal(fixedTime);
    }

    function onChange(timestamp) {
        return changeMessageFixedTime(appId, messageId, timestamp).then(data => {
            console.log(data);
            refresh();
            fixedTimeModalRef.current.closeModal();
        });
    }

    function onDeleteClick() {
        Modal.confirm({
            title: '警告',
            content: '确定删除此消息？',
            okButtonProps: {danger: true},
            onOk: () => {
                deleteFiexedTimeMessage(appId, messageId).then(data => {
                    console.log(data);
                    refresh();
                });
            },
        });
    }

    function renderFixedTimeContent(fixedTime) {
        return (
            <div>
                发送时间：{formatLong(fixedTime)}
                {status === 0 && (
                    <>
                        <Divider />
                        <Space>
                            <Button type="primary" size="small" onClick={onChangeTimeClick}>
                                修改时间
                            </Button>
                            <Button danger type="primary" size="small" onClick={onDeleteClick}>
                                删除消息
                            </Button>
                        </Space>
                    </>
                )}
            </div>
        );
    }

    let icon = null;
    if (status === 0) {
        icon = <ClockCircleOutlined style={{color: '#f5222d'}} />;
    } else if (status === 1) {
        icon = <CheckCircleOutlined style={{color: '#52c41a'}} />;
    } else if (status === 2) {
        icon = <CloseCircleOutlined style={{color: '#f5222d'}} />;
    }

    return (
        <>
            <Popover title="定时发送" content={renderFixedTimeContent(fixedTime)} placement="right">
                <Space>
                    {icon}
                    定时
                </Space>
            </Popover>
            <FixedTimeModal ref={fixedTimeModalRef} okText="修改时间" onChange={onChange} />
        </>
    );
}

FixedTimeStatus.propTypes = {
    appId: PropTypes.string.isRequired,
    messageId: PropTypes.string.isRequired,
    fixedTime: PropTypes.number.isRequired,
    status: PropTypes.number,
    refresh: PropTypes.func.isRequired,
};
