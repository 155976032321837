import React, {useContext, createContext, useCallback} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {useAsyncRetry} from 'react-use';
import {getSyncConfigs, saveSyncConfigs, deleteBySrcDepart} from '@/api/bi';

const SyncConfigContext = createContext({});
const SyncConfigProvider = SyncConfigContext.Provider;

SyncConfigContainer.propTypes = {
    children: PropTypes.node,
};

export function SyncConfigContainer({children}) {
    const {value, retry} = useAsyncRetry(async () => {
        const {data} = await getSyncConfigs();
        if (_.isEmpty(data)) {
            return {};
        }

        return _.groupBy(data, 'dataType');
    }, []);
    console.log('SyncConfigContainer', value);

    const saveConfig = useCallback((dataType, configs) => {
        return saveSyncConfigs(dataType, configs);
    }, []);

    const deleteConfigsBySrcDepart = useCallback(srcDepart => {
        return deleteBySrcDepart(srcDepart);
    }, []);

    return (
        <SyncConfigProvider value={{retry, saveConfig, deleteConfigsBySrcDepart, configs: value}}>
            {children}
        </SyncConfigProvider>
    );
}

/**
 * @typedef {Object} SyncConfigContextConfigs
 * @property {Array=} global_depart
 * @property {Array=} global_tag
 * @property {Array=} tag
 */

/**
 * @name saveConfig
 * @function
 * @param {string} dataType - 数据类型
 * @param {Array} configs - 同步配置
 * @return {Promise}
 */

/**
 * @typedef {Object} SyncConfigContextValue
 * @property {SyncConfigContextConfigs} configs - 同步配置，按 dataType 分组
 * @property {saveConfig} saveConfig - 保存同步配置方法
 * @property {function} deleteConfigsBySrcDepart - 保存同步配置方法
 * @property {function} retry - 重现读取后台同步配置方法
 */

/**
 * 返回 SyncConfigContext 的 value
 *
 * @return {SyncConfigContextValue}
 */
export function useSyncConfig() {
    return useContext(SyncConfigContext);
}
