import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Avatar, Descriptions} from 'antd';
import {DepartTag, TagTag, UserTag} from '@/component';

import styles from './WecomAppInfo.module.less';

export default function WecomAppInfo({appInfo}) {
    if (_.isEmpty(appInfo)) {
        return null;
    }

    function renderAllow(allows, AllowTag) {
        if (_.isEmpty(allows)) {
            return '未定义';
        }

        return allows.map(({allowName, allowId}) => <AllowTag key={allowId}>{allowName}</AllowTag>);
    }

    return (
        <>
            <Descriptions column={1} bordered title="应用信息" className={styles.container}>
                <Descriptions.Item label="应用名称">{appInfo.name}</Descriptions.Item>
                <Descriptions.Item label="应用说明">{appInfo.description}</Descriptions.Item>
                <Descriptions.Item label="应用 Logo">
                    <Avatar src={appInfo.squareLogoUrl} shape="square" size={64} />
                </Descriptions.Item>
                <Descriptions.Item label="AgentId">{appInfo.agentid}</Descriptions.Item>
                <Descriptions.Item label="Secret">{appInfo.secret}</Descriptions.Item>
                <Descriptions.Item label="是否停用">{'' + appInfo.close}</Descriptions.Item>
                <Descriptions.Item label="是否打开地理位置上报">
                    {'' + appInfo.reportLocationFlag}
                </Descriptions.Item>
                <Descriptions.Item label="是否上报用户进入应用事件">
                    {'' + appInfo.reportEnter}
                </Descriptions.Item>
            </Descriptions>

            <Descriptions
                column={1}
                bordered
                title="可见范围"
                style={{marginTop: 24}}
                className={styles.container}
            >
                <Descriptions.Item label="可见部门">
                    {renderAllow(appInfo.allowDeparts, DepartTag)}
                </Descriptions.Item>
                <Descriptions.Item label="可见人员">
                    {renderAllow(appInfo.allowUsers, UserTag)}
                </Descriptions.Item>
                <Descriptions.Item label="可见标签">
                    {renderAllow(appInfo.allowTags, TagTag)}
                </Descriptions.Item>
            </Descriptions>
        </>
    );
}

WecomAppInfo.propTypes = {
    appInfo: PropTypes.object,
};
