import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Modal, Tag} from 'antd';
import {PageGrid} from '@/component';
import {formatLong} from '@/util/dateUtils';
import {InfoCircleOutlined} from '@/icons';
import SyncLogDetailModal from './SyncLogDetailModal';

const SyncLogModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const SyncLogDetailModalRef = useRef();

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onDetailClick(text) {
        return () => {
            SyncLogDetailModalRef.current.showModal(text);
        };
    }

    const columns = [
        {
            title: '开始时间',
            dataIndex: 'beginTime',
            render: text => formatLong(text),
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            render: text => formatLong(text),
        },
        {
            title: '存在错误',
            dataIndex: 'hasError',
            // eslint-disable-next-line react/display-name
            render: text => (text ? <Tag color="red">是</Tag> : <Tag color="green">否</Tag>),
        },
        {
            title: '日志详情',
            dataIndex: 'log',
            // eslint-disable-next-line react/display-name
            render: text => {
                if (!text) {
                    return null;
                }
                return (
                    <InfoCircleOutlined
                        style={{color: '#1890ff', cursor: 'pointer'}}
                        onClick={onDetailClick(text)}
                    />
                );
            },
        },
    ];

    return (
        <>
            <Modal
                visible={visible}
                title="员工同步日志"
                destroyOnClose
                onCancel={closeModal}
                width={1000}
                okButtonProps={{style: {display: 'none'}}}
            >
                <PageGrid url="/bi/user-sync/logs" columns={columns} />
            </Modal>
            <SyncLogDetailModal ref={SyncLogDetailModalRef} />
        </>
    );
});

SyncLogModal.displayName = 'SyncLogModal';

export default SyncLogModal;
