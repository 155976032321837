import React, {useEffect, useState} from 'react';
import {Tabs, Typography} from 'antd';
import {
    matchPath,
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useParams,
} from 'react-router-dom';
import useTitle from '@/hook/useTitle';
import reportRoutes from './reportRoutes';
import {PieChartOutlined} from '@/icons';

import styles from './index.module.less';

export default function ReportPage() {
    useTitle('数据统计');
    const history = useHistory();
    const location = useLocation();
    const {appId} = useParams();
    const [activeKey, setActiveKey] = useState(reportRoutes[0].path);

    useEffect(() => {
        const route = reportRoutes.find(({path}) => {
            const m = matchPath(location.pathname, {path});
            return m && m.path;
        });
        if (route) {
            setActiveKey(route.path);
        }
    }, [location.pathname]);

    function onTabChange(key) {
        let toPath = key.replace(':appId', appId);
        history.push(toPath);
    }

    const tabLeft = (
        <Typography.Text type="secondary" className={styles.tabsTitle}>
            <PieChartOutlined style={{marginRight: 10}} />
            数据统计
        </Typography.Text>
    );
    return (
        <div className={styles.container}>
            <Tabs
                className={styles.tabs}
                onChange={onTabChange}
                activeKey={activeKey}
                tabBarExtraContent={{left: tabLeft}}
            >
                {reportRoutes.map(({path, label}) => (
                    <Tabs.TabPane key={path} tab={label} />
                ))}
            </Tabs>

            <div className={styles.content}>
                <Switch>
                    <Redirect exact from="/apps/:appId/report" to={reportRoutes[0].path} />
                    {reportRoutes.map(({path, Comp}) => (
                        <Route key={path} path={path} component={Comp} />
                    ))}
                </Switch>
            </div>
        </div>
    );
}
