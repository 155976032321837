import React from 'react';

import {ResponsiveView} from '@/component';
import AdminLayout from './layout/AdminLayout';

export default function AdminPage() {
    return (
        <ResponsiveView>
            <AdminLayout />
        </ResponsiveView>
    );
}
