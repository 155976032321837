import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, message} from 'antd';

import MenuForm from './MenuForm';

import {addMenu, updateMenu, getMenuInfo} from '@/api/menus';
import {menuLength, byteLength} from '@/util/stringUtils';

const MenuFormModal = forwardRef(({appId, reloadMenus}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [menu, setMenu] = useState(null);
    const [isMain, setIsMain] = useState(true);
    const [isAdd, setIsAdd] = useState(true);
    const [parentId, setParentId] = useState('-1');

    function showModal(isMain, isAdd, record) {
        setVisible(true);
        if (isAdd) {
            if (!isMain) {
                setParentId(record.id);
            }
        } else {
            getMenuInfo(record.id).then(({data}) => {
                console.log('getMenuInfo', data);
                setMenu(data);
            });
            setParentId(record.parentId);
        }
        setIsMain(isMain);
        setIsAdd(isAdd);
    }

    function closeModal() {
        setVisible(false);
        setMenu(null);
        setIsMain(true);
        setIsAdd(true);
        setParentId('-1');
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const {name} = values;
        // const menuNameLenth = menuLength(name);
        const menuNameLenth = byteLength(name);
        const nameRuleLenth = isMain ? 16 : 40;
        // const nameRuleLenth = isMain ? 8 : 16;
        if (menuNameLenth > nameRuleLenth) {
            message.warning(`${isMain ? '主' : '子'}菜单名字不多于${nameRuleLenth}个字节`);
            return;
        }
        const promise = isAdd ? addMenu : updateMenu;

        promise(values)
            .then(data => {
                console.log(data);
                reloadMenus();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    return (
        <Modal
            width={1000}
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd ? '添加菜单' : '修改菜单'}
            onCancel={closeModal}
            onOk={onOk}
        >
            <MenuForm
                ref={formRef}
                isMain={isMain}
                appId={appId}
                parentId={parentId}
                menuInfo={menu}
                onFinish={onFinish}
            />
        </Modal>
    );
});

MenuFormModal.propTypes = {
    appId: PropTypes.string.isRequired,
    reloadMenus: PropTypes.func,
};

MenuFormModal.displayName = 'MenuFormModal';

export default MenuFormModal;
