import passwordGen from 'generate-password';

/**
 * 将 html 字符串转换为文本。
 *
 * @param html
 * @return {null|*}
 */
export function html2Text(html) {
    if (!html) {
        return null;
    }

    html = html.replace(/<style([\s\S]*?)<\/style>/gi, '');
    html = html.replace(/<script([\s\S]*?)<\/script>/gi, '');
    html = html.replace(/<\/div>/gi, '\n');
    html = html.replace(/<\/li>/gi, '\n');
    html = html.replace(/<li>/gi, '* ');
    html = html.replace(/<\/ul>/gi, '\n');
    html = html.replace(/<\/p>/gi, '\n');
    html = html.replace(/<br\s*[/]?>/gi, '\n');
    html = html.replace(/<[^>]+>/gi, '');
    html = html.replace(/[ ]{2,}/gi, ''); // 合并多个空格

    return html.replace(/[\n]{2,}/gi, '\n').trim();
}

/**
 * 密码生成。
 *
 * 规则：生成的密码中包含大写、小写、数字、特殊符号。
 *
 * @return {string}
 */
export function generatePassword() {
    return passwordGen.generate({
        numbers: true,
        lowercase: true,
        uppercase: true,
        symbols: '@#$%&',
        excludeSimilarCharacters: true,
        strict: true,
        length: 20,
    });
}

/**
 * 企业微信接口中对于字符串长度限制是中英文字节数，其中 1 个英文或数字占 2 个字节，汉字占 4 个字节。
 *
 * @param str
 */
export function menuLength(str) {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
        const a = str.charAt(i);
        // eslint-disable-next-line no-control-regex
        a.match(/[^\x00-\xff]/gi) != null ? (len += 2) : (len += 1);
    }
    return len;
}

export const LINK_REGEXP = /^https?:\/\/\w+[^\s]+\.[^\s]+$/;

/**
 * 判断给定字符串是否连接形式。
 *
 * @param link 连接字符串
 * @return {boolean}
 */
export function isLink(link) {
    return LINK_REGEXP.test(link);
}

/**
 * 检测是否 Email，格式要求是 name@site.tld
 *
 * @param email 给定 Email 字符串
 * @returns {boolean}
 */
export function isEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

/**
 * 名称类正则表达式，用于判断中文、英文、数字、下划线、空格
 *
 * @type {RegExp}
 */
export const NAME_REGEXP = /^[\u4e00-\u9fa5\w\s-]+$/g;
export const NAME_MESSAGE = '必须为中文、英文、数字、下划线、空格';

/**
 * 计算字符串所占的内存字节数，默认使用UTF-8的编码方式计算，也可制定为UTF-16
 * UTF-8 是一种可变长度的 Unicode 编码格式，使用一至四个字节为每个字符编码
 *
 * 000000 - 00007F(128个代码)      0zzzzzzz(00-7F)                             一个字节
 * 000080 - 0007FF(1920个代码)     110yyyyy(C0-DF) 10zzzzzz(80-BF)             两个字节
 * 000800 - 00D7FF
 * 00E000 - 00FFFF(61440个代码)    1110xxxx(E0-EF) 10yyyyyy 10zzzzzz           三个字节
 * 010000 - 10FFFF(1048576个代码)  11110www(F0-F7) 10xxxxxx 10yyyyyy 10zzzzzz  四个字节
 *
 * 注: Unicode在范围 D800-DFFF 中不存在任何字符
 * {@link http://zh.wikipedia.org/wiki/UTF-8}
 *
 * UTF-16 大部分使用两个字节编码，编码超出 65535 的使用四个字节
 * 000000 - 00FFFF  两个字节
 * 010000 - 10FFFF  四个字节
 *
 * {@link http://zh.wikipedia.org/wiki/UTF-16}
 *
 * 代码取自： {@link https://gist.github.com/nuintun/9955353}
 *
 * @param  {String} str
 * @param  {String} charset utf-8, utf-16
 * @return {Number}
 */
export function byteLength(str, charset = 'utf-8') {
    let total = 0;
    let charCode;

    charset = charset.toLowerCase();

    if (charset === 'utf-8' || charset === 'utf8') {
        for (let i = 0, len = str.length; i < len; i++) {
            charCode = str.codePointAt(i);

            if (charCode <= 0x007f) {
                total += 1;
            } else if (charCode <= 0x07ff) {
                total += 2;
            } else if (charCode <= 0xffff) {
                total += 3;
            } else {
                total += 4;
                i++;
            }
        }
    } else if (charset === 'utf-16' || charset === 'utf16') {
        for (let i = 0, len = str.length; i < len; i++) {
            charCode = str.codePointAt(i);

            if (charCode <= 0xffff) {
                total += 2;
            } else {
                total += 4;
                i++;
            }
        }
    } else {
        total = str.replace(/[^\x00-\xff]/g, 'aa').length;
    }

    return total;
}
