import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './ArticleContainer.module.less';

export default function ArticleContainer({children, className, style}) {
    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {/*<div className={styles.headerTitle}>
                    <img
                        src={require('@/asset/bi-logo_blue.svg').default}
                        alt="logo"
                        style={{height: 30}}
                    />
                    <h1>{APP_TITLE}</h1>
                </div>*/}

                <div className={classNames(styles.main, className)} style={style}>
                    {children}
                </div>
            </div>
        </div>
    );
}

ArticleContainer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
