import _ from 'lodash';

/**
 * 对于图文类来说，只要点开图文编辑表单，并且执行了「确定」操作，则其中的 id、itemId 等信息都将被清除。
 *
 * 也就是说作为全新的图文返回。
 *
 * @param newsContents
 */
export function cleanNewsContents(newsContents) {
    if (_.isEmpty(newsContents)) {
        return newsContents;
    }

    const newNewsContents = [];
    for (const newsContent of newsContents) {
        const {id, itemId, ...rest} = newsContent;
        newNewsContents.push(rest);
    }

    return newNewsContents;
}
