import _ from 'lodash';

import {travelAndOptTree} from '@/util/treeUtils';

/**
 *
 * @param treeData
 * @param allowDepartIds 可见的部门 id
 *      如果为为 null 或 undefined, 表示所有部门可见
 *      如果是 [] ，表示所有不可见
 *      如果有值，则对应部门和子部门可见
 * @return {*}
 */
export function getTreeData(treeData, allowDepartIds) {
    if (_.isEmpty(treeData)) {
        return treeData;
    }

    if (!Array.isArray(allowDepartIds)) {
        return treeData;
    }

    function action(ids = []) {
        return node => {
            if (typeof node === 'object') {
                // console.log(node, ids.includes(node.id));

                if (ids.includes(_.get(node, 'depart.id'))) {
                    return true;
                } else {
                    node.disabled = true;
                    return false;
                }
            }

            return false;
        };
    }

    const newTree = [...treeData];
    travelAndOptTree(newTree, action(allowDepartIds));
    return newTree;
}
