import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Divider, Modal} from 'antd';
import _ from 'lodash';
import {isPromise} from '@/util/PromiseUtils';

import styles from './styles.module.less';

/**
 * 在应用列表中选择。
 */
const AppMultiSelector = forwardRef(({onSelect}, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [indeterminate, setIndeterminate] = React.useState(true);
    const [checkAll, setCheckAll] = React.useState(false);

    // 以应用 id 为 value，name 为 label 的数组
    const [appOptions, setAppOptions] = useState([]);
    const [checkedList, setCheckedList] = useState([]);

    function showModal(appIds, appMap) {
        setVisible(true);

        // setCheckedList(appIds);

        // 如果 appMap 是以 id 为 key，name 为值的对象，则将其转换为 checkbox group 支持数组格式
        let options = [];
        if (_.isPlainObject(appMap) && !_.isEmpty(appMap)) {
            options = Object.keys(appMap).map(key => {
                return {value: key, label: appMap[key]};
            });
            setAppOptions(options);
        }

        changeState(appIds, options);
    }

    function closeModal() {
        setVisible(false);
        setAppOptions([]);
        setCheckedList([]);
        setCheckAll(false);
        setIndeterminate(true);
        setLoading(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function changeState(list = [], options = []) {
        console.log('changeState', list, options);
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    }

    function onChange(list = []) {
        changeState(list, appOptions);
    }

    function onCheckAllChange(e) {
        setCheckedList(e.target.checked ? appOptions.map(({value}) => value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    }

    function onOk() {
        setLoading(true);

        const result = onSelect(checkedList);
        if (isPromise(result)) {
            result.then(data => {
                // console.log(data);
                if (data === true) {
                    closeModal();
                } else {
                    setLoading(false);
                }
            });
        } else {
            if (result) {
                closeModal();
            } else {
                setLoading(false);
            }
        }
    }

    return (
        <Modal
            destroyOnClose
            title="选择应用"
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            okButtonProps={{loading}}
        >
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                全选
            </Checkbox>
            <Divider />
            <Checkbox.Group
                className={styles.group}
                options={appOptions}
                value={checkedList}
                defaultValue={checkedList}
                onChange={onChange}
            />
        </Modal>
    );
});

AppMultiSelector.displayName = 'AppMultiSelector';

AppMultiSelector.propTypes = {
    onSelect: PropTypes.func.isRequired,
};

export default AppMultiSelector;
