import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Result} from 'antd';
import {useHistory, useParams} from 'react-router-dom';
import {initReducerContext} from '@someok/simple-reducer-store';
import _ from 'lodash';

import {getUserApp} from '@/api/apps';
import appActions from './actions';
import {useAuthStore} from '@/context/auth';

const initialState = {
    appId: null,
    userId: null,

    app: {},

    isMgr: false,
    isUser: false,

    // 扩展属性，例如是否支持批量发送到 Email 或手机号，或者是否支持审核等
    extAttr: {},
};

const [useAppStore, AppStoreProvider, AppContext] = initReducerContext(initialState, appActions);

export {useAppStore, AppStoreProvider, AppContext};

export default function AppPageWrapper({children}) {
    const [authStore] = useAuthStore();
    // console.log('authStore......', authStore);
    const currentUserId = _.get(authStore, 'user.id');

    const [loading, setLoading] = useState(true);
    const [existApp, setExistApp] = useState(false);
    const {appId} = useParams();
    const history = useHistory();
    const [, dispatch] = useAppStore();

    useEffect(() => {
        if (!currentUserId) {
            return;
        }

        getUserApp(currentUserId, appId).then(json => {
            // console.log(json);
            if (
                !json ||
                !json.data ||
                !json.data.app ||
                !json.data.roleType ||
                !['app_mgr', 'app_user'].includes(json.data.roleType)
            ) {
                setLoading(false);
                setExistApp(false);
                return;
            }

            const {data} = json;
            // console.log('data.........', data);
            dispatch(['initApp', data]);
            setLoading(false);
            setExistApp(true);
        });
    }, [appId, currentUserId, dispatch]);

    if (loading) {
        return null;
    }

    if (existApp) {
        return children;
    }

    return (
        <Result
            status="404"
            title="404"
            subTitle="应用不存在"
            extra={
                <Button type="primary" onClick={() => history.replace('/')}>
                    返回首页
                </Button>
            }
        />
    );
}

AppPageWrapper.propTypes = {
    children: PropTypes.node,
};
