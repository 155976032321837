import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';

import {
    format,
    fromNow as fromNowFun,
    FORMAT_DEFAULT,
    FORMAT_LONG,
    FORMAT_ISO,
} from '@/util/dateUtils';

/**
 * 将传入的时间戳转换为格式化的日期
 *
 * 默认：YYYY-MM-DD
 * <DateView timestamp={timestamp}/>
 *
 * 相对当前时间，例如：几分钟前
 * <DateView timestamp={timestamp} fromNow/>
 *
 * 带时间的日期：YYYY-MM-DD HH:mm
 * <DateView timestamp={timestamp} formatter={FORMAT_LONG}/>
 *
 * @param timestamp 时间戳
 * @param fromNow 是否用相对当前时间的字符串显示，例如「几分钟前」
 * @param formatter 格式化字符串
 * @return {*}
 * @constructor
 */
const DateView = ({timestamp, fromNow, formatter}) => {
    if (!timestamp) return '-';

    if (fromNow) {
        return (
            <Tooltip title={format(timestamp, FORMAT_ISO)}>
                <span>{fromNowFun(timestamp)}</span>
            </Tooltip>
        );
    }

    return format(timestamp, formatter);
};

DateView.FORMAT_LONG = FORMAT_LONG;
DateView.FORMAT_ISO = FORMAT_ISO;

DateView.propTypes = {
    formatter: PropTypes.string,
    fromNow: PropTypes.bool,
    timestamp: PropTypes.number,
};

DateView.defaultProps = {
    formatter: FORMAT_DEFAULT,
    fromNow: false,
    timestamp: null,
};

export default memo(DateView);
