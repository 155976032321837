import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Divider, Input, message, Modal, Card, Select} from 'antd';
import copy from 'copy-to-clipboard';

import {LINK_REGEXP} from '@/util/stringUtils';
import {getTicketUrl} from '@/api/clientApps';
import {CopyOutlined} from '@/icons';

const TicketUrlModal = forwardRef((props, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [clientApp, setClientApp] = useState(null);
    const [ticketUrl, setTicketUrl] = useState(null);

    function showModal(capp) {
        setVisible(true);
        setClientApp(capp);
    }

    function closeModal() {
        setVisible(false);
        setTicketUrl(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onCopyClick() {
        if (copy(ticketUrl)) {
            message.info('拷贝成功');
        }
    }

    function onInputChange(e) {
        console.log(e);
        setTicketUrl(null);
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        getTicketUrl(clientApp.id, values.destUrl).then(({data}) => {
            console.log(data);
            setTicketUrl(data);
        });
    }

    function renderTickerUrl() {
        if (!ticketUrl) {
            return null;
        }

        return (
            <>
                <Divider />
                <Card
                    title="Ticket Url"
                    extra={
                        <Button
                            type="primary"
                            size="small"
                            icon={<CopyOutlined />}
                            onClick={onCopyClick}
                        >
                            拷贝链接
                        </Button>
                    }
                >
                    {ticketUrl}
                </Card>
            </>
        );
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={`生成 [${clientApp?.name}] 下的 Ticket Url`}
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={clientApp}>
                <Form.Item
                    name="destUrl"
                    label="URL"
                    rules={[
                        {
                            required: true,
                        },
                        {
                            pattern: LINK_REGEXP,
                            message: '不是有效链接',
                        },
                    ]}
                >
                    <Input placeholder="URL" autoFocus onChange={onInputChange} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        生成链接
                    </Button>
                </Form.Item>
            </Form>

            {renderTickerUrl()}
        </Modal>
    );
});

TicketUrlModal.displayName = 'TicketUrlModal';

export default TicketUrlModal;
