import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Divider, Form, Input, Radio} from 'antd';

import NewsThumbEditor from './NewsThumbEditor';
import AttachsUpload from './AttachsUpload';
import UEditor from '@/page/component/UEditor';

import styles from './index.module.less';

const NewsForm = forwardRef(
    ({mediaType, currentTmpId, newsById, onNewsChange, onAttachChange}, ref) => {
        const formRef = useRef();
        const editorRef = useRef();
        // console.log('currentTmpId============', currentTmpId);

        const [editorReady, setEditorReady] = useState(false);

        const currentNews = newsById[currentTmpId] ?? {};

        // console.log('currentNews...', currentNews);

        function isNews() {
            return mediaType === 'news';
        }

        function isNewsLink() {
            return mediaType === 'news_link';
        }

        useImperativeHandle(ref, () => ({
            submit: () => {
                formRef.current.submit();
            },
        }));

        // 只在表单内容切换时起作用
        useEffect(() => {
            if (isNews() && !editorReady) {
                return;
            }

            // console.log('news form useeffect....');
            formRef.current && formRef.current.setFieldsValue(currentNews);

            if (isNews()) {
                // console.log('editorRef', editorRef);
                editorRef.current && editorRef.current.setContent(currentNews.content ?? '');
            }

            // 此处的依赖项只使用 currentTmpId
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [currentTmpId]);

        // console.log('editorReady', editorReady, currentTmpId);

        function onEditorReady() {
            // console.log('ready');
            // UEditor 载入完毕后初始化一下数据
            formRef.current && formRef.current.setFieldsValue(currentNews);
            if (isNews()) {
                editorRef.current && editorRef.current.setContent(currentNews.content ?? '');
            }

            setEditorReady(true);
        }

        function onFinish(values) {
            console.log('Received values of form: ', values);
        }

        function onValuesChange(changedValues, allValues) {
            // console.log('onValuesChange', changedValues, allValues);
            let extra = {};
            // 禁止拷贝
            if (changedValues.hasOwnProperty('newsContentForbidCopy')) {
                const forbidCopy = changedValues.newsContentForbidCopy;
                if (forbidCopy) {
                    extra = {
                        // newsShowReadNum: false,
                        // newsShowPraiseNum: false,
                        newsAccessType: 1,
                    };
                } else {
                    extra = {
                        newsContentMask: false,
                    };
                }
                formRef.current.setFieldsValue(extra);
            }

            // 加水印
            if (changedValues.hasOwnProperty('newsContentMask')) {
                const mask = changedValues.newsContentMask;
                if (mask) {
                    extra = {
                        newsContentForbidCopy: true,
                        // newsShowReadNum: false,
                        // newsShowPraiseNum: false,
                        newsAccessType: 1,
                    };
                    formRef.current.setFieldsValue(extra);
                }
            }
            // const content = editorRef.current.getContent();
            onNewsChange && onNewsChange(currentTmpId, {...currentNews, ...allValues, ...extra});
        }

        function onThumbChange(thumbAttachId, thumbAttachExt, thumbUrl) {
            // console.log('attachId, attachExt', thumbAttachId, thumbAttachExt);

            const currentNews = newsById[currentTmpId];
            const formData = formRef.current.getFieldsValue();
            onNewsChange &&
                onNewsChange(currentTmpId, {
                    ...currentNews,
                    ...formData,
                    thumbAttachId,
                    thumbAttachExt,
                    thumbUrl, // todo: 已废弃
                });

            formRef.current.setFields([
                {
                    name: 'thumbAttachId',
                    value: thumbAttachId,
                },
                {
                    name: 'thumbAttachExt',
                    value: thumbAttachExt,
                },
            ]);
        }

        // if (!currentNews) {
        //     console.log('!currentNews 1111222222');
        //     return null;
        // }

        const layout = {
            labelCol: {span: 3},
            // wrapperCol: {span: 20},
        };
        const tailLayout = {
            wrapperCol: {offset: 3},
        };

        return (
            <Form
                ref={formRef}
                {...layout}
                labelAlign="left"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                layout="horizontal"
            >
                {/*<Form.Item hidden name="id">
                    <Input />
                </Form.Item>*/}
                <Form.Item hidden name="mediaType" initialValue="news">
                    <Input />
                </Form.Item>
                <Form.Item name="title">
                    <Input
                        className={styles.titleInput}
                        autoFocus
                        placeholder="在此输入标题"
                        maxLength={30}
                        size="large"
                    />
                </Form.Item>

                {isNews() && (
                    <>
                        <Form.Item name="content">
                            <UEditor ref={editorRef} onReady={onEditorReady} />
                        </Form.Item>

                        <AttachsUpload
                            currentTmpId={currentTmpId}
                            attaches={currentNews['attaches']}
                            onAttachChange={onAttachChange}
                        />
                    </>
                )}

                {isNewsLink() && (
                    <Form.Item name="link" label="外链">
                        <Input size="large" placeholder="在此输入外链" maxLength={1000} />
                    </Form.Item>
                )}

                <Divider />

                <Form.Item hidden name="thumbAttachId">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="thumbAttachExt">
                    <Input />
                </Form.Item>

                <NewsThumbEditor news={currentNews} onThumbChange={onThumbChange} />

                <Divider />

                <Form.Item
                    name="description"
                    label="摘要"
                    rules={[
                        {
                            max: 50,
                        },
                    ]}
                    extra={mediaType === 'news' ? '如不填则自动抓取正文前 50 个字' : null}
                >
                    <Input.TextArea placeholder="摘要" maxLength={50} rows={2} />
                </Form.Item>

                {isNews() && (
                    <>
                        <Form.Item name="author" label="作者">
                            <Input placeholder="作者（选填）" maxLength={100} />
                        </Form.Item>

                        <Divider />

                        <Form.Item name="newsCommentType" label="留言控制">
                            <Radio.Group>
                                <Radio value={0}>开启留言，仅企业微信成员可留言</Radio>
                                <Radio value={1}>关闭留言</Radio>
                            </Radio.Group>
                            {/* <Select>
                                <Select.Option value={0}>
                                    开启留言，仅企业微信成员可留言
                                </Select.Option>
                                <Select.Option value={1}>关闭留言</Select.Option>
                            </Select>*/}
                        </Form.Item>
                        <Form.Item name="newsAccessType" label="安全控制">
                            <Radio.Group disabled={currentNews.newsContentForbidCopy}>
                                <Radio value={1}>仅企业微信成员可访问</Radio>
                                <Radio value={0}>无限制，所有人可访问</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            {...tailLayout}
                            name="newsContentForbidCopy"
                            valuePropName="checked"
                        >
                            <Checkbox>正文禁止复制</Checkbox>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{offset: 4}}
                            name="newsContentMask"
                            valuePropName="checked"
                        >
                            <Checkbox>正文加水印</Checkbox>
                        </Form.Item>
                        <Form.Item {...tailLayout} name="newsShowReadNum" valuePropName="checked">
                            <Checkbox>展示阅读数</Checkbox>
                        </Form.Item>
                        <Form.Item {...tailLayout} name="newsShowPraiseNum" valuePropName="checked">
                            <Checkbox>展示点赞数</Checkbox>
                        </Form.Item>
                    </>
                )}
            </Form>
        );
    }
);

NewsForm.displayName = 'NewsForm';

NewsForm.propTypes = {
    mediaType: PropTypes.oneOf(['news', 'news_link']),
    currentTmpId: PropTypes.string.isRequired,
    newsById: PropTypes.object,
    onNewsChange: PropTypes.func.isRequired,
    onAttachChange: PropTypes.func.isRequired,
};

export default NewsForm;
