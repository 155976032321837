/* eslint-disable react/prop-types */
// noinspection NpmUsedModulesInstalled

import React from 'react';

import Button from './Button';
import PropTypes from 'prop-types';
import {Modal as AntdModal} from 'antd';

class Modal extends React.PureComponent {
    static propTypes = {
        title: PropTypes.string,
        visible: PropTypes.bool,
        beforeClose: PropTypes.func,
        onClose: PropTypes.func,
        onConfirm: PropTypes.func,
    };

    static defaultProps = {
        title: '',
        visible: false,
        zIndex: 1050,
        alignStyle: 'top',
        extendControls: [],
        debug: false,
    };

    closeModal = () => {
        this.props.beforeClose && this.props.beforeClose();
        this.props.onClose();
    };

    onConfirm = () => {
        // onConfirm 返回 false undefined 则关闭弹窗, 否则不关闭
        this.props.onConfirm && !this.props.onConfirm() && this.closeModal();
    };

    render() {
        let {title, visible, zIndex, alignStyle} = this.props;

        return (
            <AntdModal
                title={title}
                closable
                destroyOnClose
                maskClosable
                onCancel={this.closeModal}
                visible={visible}
                width={600}
                footer={[
                    <Button key="close" onClick={this.closeModal}>
                        取消
                    </Button>,
                    <Button key="insert" onClick={this.onConfirm}>
                        确认
                    </Button>,
                ]}
                // animation="zome"
                // maskAnimation="fade"
                // zIndex={zIndex}
                // style={alignStyle === 'middle' ? {top: '50%', transform: 'translateY(-50%)'} : {}}
            >
                {this.props.component}
            </AntdModal>
        );
    }
}

export default Modal;
