/*
 * Created by liyi on 2021-02-01 17:04.
 */
import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Table, Space, PageHeader, Button, Modal, message} from 'antd';
import _ from 'lodash';

import {PlusOutlined} from '@/icons';

import MenuFormModal from './MenuFormModal';

import {deleteMenu, publishMenu, updateOrder} from '@/api/menus';

export default function MenuTable({appId, loading, menus, reload}) {
    const menuFormModalRef = useRef();

    function onDeleteClick(record, index) {
        return () => {
            Modal.confirm({
                title: '提醒',
                content: `是否删除 [${record.name}]`,
                okButtonProps: {danger: true},
                onOk: () => {
                    console.log(index, record);
                    deleteMenu(record.id).then(data => {
                        console.log(data);
                        reload();
                    });
                },
            });
        };
    }

    function getMenuIdsByParentId(parentId) {
        const isMain = !(parentId && parentId !== '-1');
        let ids;
        if (isMain) {
            ids = menus.map(menu => menu.id);
        } else {
            const menu = menus.filter(menu => menu.id === parentId);
            ids = menu[0].children.map(menu => menu.id);
        }
        return ids;
    }

    function updateMenuOrder(isUp, record) {
        return () => {
            const {parentId, id} = record;
            const ids = getMenuIdsByParentId(parentId);
            const index = ids.indexOf(id);
            if (isUp) {
                ids[index] = ids.splice(index - 1, 1, ids[index])[0];
            } else {
                ids[index] = ids.splice(index + 1, 1, ids[index])[0];
            }
            updateOrder(parentId, ids).then(data => {
                console.log(data);
                reload();
            });
        };
    }

    function getMenuMoveConfig(record) {
        const {parentId, id} = record;
        const ids = getMenuIdsByParentId(parentId);
        const index = ids.indexOf(id);
        let moveConfig = {
            upDisabled: false,
            downDisabled: false,
        };
        if (ids.length === 1 || index === 0) {
            moveConfig.upDisabled = true;
        }
        if (ids.length === 1 || index === ids.length - 1) {
            moveConfig.downDisabled = true;
        }
        return moveConfig;
    }

    const columns = [
        {
            title: '菜单名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '操作',
            width: '25%',
            key: 'action',
            // eslint-disable-next-line react/display-name
            render: (text, record, index) => {
                const {parentId} = record;
                const isMain = !(parentId && parentId !== '-1');
                const moveConfig = getMenuMoveConfig(record);
                return (
                    <div
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}
                    >
                        {isMain ? (
                            <Button type="link" onClick={addMenu(false, record)}>
                                添加下级菜单
                            </Button>
                        ) : null}
                        <>
                            <Button
                                type="link"
                                disabled={moveConfig.upDisabled}
                                onClick={updateMenuOrder(true, record)}
                            >
                                上移
                            </Button>
                            <Button
                                type="link"
                                disabled={moveConfig.downDisabled}
                                onClick={updateMenuOrder(false, record)}
                            >
                                下移
                            </Button>
                        </>
                        <Button type="link" onClick={updateMenu(isMain, record)}>
                            修改
                        </Button>
                        <Button type="link" onClick={onDeleteClick(record, index)}>
                            删除
                        </Button>
                    </div>
                );
            },
        },
    ];

    function updateMenu(isMain, record) {
        return () => {
            menuFormModalRef.current.showModal(isMain, false, record);
        };
    }

    function addMenu(isMain, record) {
        return () => {
            if (isMain) {
                if (record.length >= 3) {
                    Modal.confirm({
                        title: '提醒',
                        content: `最多只能添加3个主菜单！`,
                    });
                } else {
                    menuFormModalRef.current.showModal(isMain, true);
                }
            } else {
                if (_.isEmpty(record.children) || record.children.length < 5) {
                    menuFormModalRef.current.showModal(isMain, true, record);
                } else {
                    Modal.confirm({
                        title: '提醒',
                        content: `最多只能添加5个子菜单！`,
                    });
                }
            }
        };
    }

    function onPublishMenu() {
        Modal.confirm({
            title: '提醒',
            content: `是否发布本地菜单到企业微信，发布后会根据当前菜单覆盖企业微信上的应用菜单`,
            onOk: () => {
                if (menus.length) {
                    publishMenu(appId).then(({data}) => {
                        if (data) {
                            const {errcode, errmsg} = data;
                            if (errcode === 0) {
                                message.success('发布成功');
                            } else {
                                message.error(errmsg);
                            }
                        }
                    });
                } else {
                    message.warn('发布的菜单不能为空');
                }
            },
        });
    }

    function renderExtra() {
        return (
            <Space>
                <Button type="primary" onClick={onPublishMenu}>
                    发布
                </Button>
                <Button type="primary" icon={<PlusOutlined />} onClick={addMenu(true, menus)} />
            </Space>
        );
    }

    return (
        <>
            <PageHeader title="菜单" extra={renderExtra()} />
            <Table
                rowKey="id"
                loading={loading}
                columns={columns}
                dataSource={menus}
                pagination={false}
            />
            <MenuFormModal ref={menuFormModalRef} appId={appId} reloadMenus={reload} />
        </>
    );
}

MenuTable.propTypes = {
    appId: PropTypes.string,
    loading: PropTypes.bool,
    menus: PropTypes.array,
    reload: PropTypes.func,
};
