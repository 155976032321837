import React from 'react';
import _ from 'lodash';

import {useAuthStore} from '@/context/auth';
import UserView from '@/page/admin/DepartsPage/UserView';

import styles from './ProfilePage.module.less';

export default function UserProfile() {
    const [authStore] = useAuthStore();
    const user = _.get(authStore, 'user') || {};

    return (
        <div className={styles.container}>
            <UserView user={user} column={1} />
        </div>
    );
}
