import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Upload} from 'antd';
import filesize from 'filesize';
import _ from 'lodash';
import classNames from 'classnames';

import MediaType from '@/page/app/MediaPage/MediaType';
import AppUpload from '@/page/component/AppUpload';
import PlusButton from './PlusButton';
import {valuePropTypes} from '../propTypes';

import styles from './CommonFileContent.module.less';

/**
 * 文件上传类消息内容，包括图片、语音、文件。
 */
export default function CommonFileContent({
    type,
    value,
    onChange,
    previewClassName,
    previewStyle,
    buttonTitle,
    description,
    children,
}) {
    // 使得 Upload fileList 属性变成本组件可控，以方便清空组件删除文件列表
    // 如果使用 Upload 自己控制列表内容，在隐藏 Upload 组件的时候会报错：
    // Can't perform a React state update on an unmounted component.
    // This is a no-op, but it indicates a memory leak in your application.
    // To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    const [fileList, setFileList] = useState([]);

    function beforeUpload(file) {
        // console.log('beforeUpload', file);
        const {size} = file;
        const {maxSize} = type;

        if (size <= 5) {
            message.warn('文件必须大于 5 个字节');
            return Upload.LIST_IGNORE;
        }

        if (!maxSize || size <= maxSize) {
            return true;
        }

        message.warn('文件大小不能超过 ' + filesize(maxSize));

        // Upload.LIST_IGNORE 算是 antd 对于 beforeUpload 的 hack
        // 如果返回 false 的话，该文件缩略图仍然会显示在预览列表中，
        // 所以开发方新增了这个特定的属性用于处理这个问题
        return Upload.LIST_IGNORE;
    }

    function onUploadChange({file, fileList}) {
        // console.log('onUploadChange');
        // console.log('file, fileList', file, fileList);
        if (file.status === 'done') {
            const {
                response: {data},
            } = file;

            // console.log(data);
            let {id, fileExt, fileName, fileSize} = data[0];
            // afterUpload && afterUpload(data);
            onChange &&
                onChange({title: fileName, attachId: id, attachExt: fileExt, attachSize: fileSize});
            setFileList([]);
        } else {
            setFileList([...fileList]);
        }
    }

    function onDeleteClick() {
        onChange && onChange({});
    }

    let previewContainerStyle = {display: 'none'};
    let uploadContainerStyle = {display: 'flex'};

    if (!_.isEmpty(value)) {
        previewContainerStyle = {display: 'flex'};
        uploadContainerStyle = {display: 'none'};
    }

    // console.log('value.....', value, _.isEmpty(value));

    const accept = type.accept && type.accept !== '*' ? type.accept : null;
    return (
        <>
            <div className={styles.preview} style={previewContainerStyle}>
                <div
                    className={classNames(styles.previewBox, previewClassName)}
                    style={previewStyle}
                >
                    {!_.isEmpty(value) && children && children(value)}
                </div>
                <Button type="link" onClick={onDeleteClick}>
                    删除
                </Button>
            </div>
            <div style={uploadContainerStyle} className={styles.upload}>
                <AppUpload
                    category="message"
                    accept={accept}
                    maxCount={1}
                    listType="picture"
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                    fileList={fileList}
                >
                    <PlusButton title={buttonTitle}>{description}</PlusButton>
                </AppUpload>
            </div>
        </>
    );
}

CommonFileContent.propTypes = {
    type: PropTypes.oneOf([MediaType.IMAGE, MediaType.VOICE, MediaType.FILE]).isRequired,
    value: valuePropTypes,
    previewClassName: PropTypes.string,
    previewStyle: PropTypes.object,
    buttonTitle: PropTypes.string.isRequired,
    description: PropTypes.node,
    onChange: PropTypes.func,
    children: PropTypes.func,
};
