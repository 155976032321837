/* eslint-disable react/display-name */
import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Input, PageHeader, Popover, Space, Tooltip} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';

import {PageGrid} from '@/component';
import DateRange from '../component/DateRange';
import {getNewsStatUrl, newsStatDownloadUrl} from '@/api/report';
import {formatLong} from '@/util/dateUtils';
import {getFileUrl} from '@/page/util/appUrlUtils';
import NewsReadUserModal from './NewsReadUserModal';
import SingleNewsChartModal from './SingleNewsChartModal';
import {DownloadOutlined} from '@/icons';

NewsStatList.propTypes = {
    appId: PropTypes.string.isRequired,
};

function NewsStatList({appId}) {
    const gridRef = useRef();
    const readUserModalRef = useRef();
    const chartModalRef = useRef();

    const [search, setSearch] = useState(null);
    const [dateRange, setDateRange] = useState([
        dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD'),
    ]);

    useEffect(() => {
        if (_.isEmpty(dateRange)) {
            return;
        }

        gridRef.current.reload2FirstPage({
            start: dateRange[0],
            end: dateRange[1],
            title: search,
        });
    }, [dateRange, search]);

    function onChange(dates, dateStrings) {
        console.log(dates, dateStrings);
        setDateRange(dateStrings);
    }

    function onReadUserClick(record) {
        return () => {
            console.log(record);
            readUserModalRef.current.showModal(record.id);
        };
    }

    function onSingleNewsStatClick(record) {
        return () => {
            console.log(record);
            chartModalRef.current.showModal(record.id, record.sendTime);
        };
    }

    function onDownloadExcelClick() {
        const url = newsStatDownloadUrl(appId, dateRange[0], dateRange[1], search ?? '');
        console.log(url);
        window.open(url);
    }

    const columns = [
        {
            title: '图文',
            dataIndex: 'title',
            render: (text, record) => {
                const {thumbAttachId, thumbAttachExt} = record;
                const url = getFileUrl(thumbAttachId, thumbAttachExt);

                return (
                    <Popover
                        placement="right"
                        title={text}
                        content={<img alt="缩略图" src={url} style={{width: 240}} />}
                    >
                        <Button type="link">{text}</Button>
                    </Popover>
                );
            },
        },
        {
            title: '发送时间',
            dataIndex: 'sendTime',
            render: text => {
                return formatLong(text);
            },
        },
        {
            title: '阅读数（次）',
            dataIndex: 'readCount',
            align: 'right',
        },
        {
            title: '阅读数（人）',
            dataIndex: 'readUserCount',
            align: 'right',
        },
        {
            title: '转发分享（次）',
            dataIndex: 'shareCount',
            align: 'right',
        },
        {
            title: '转发分享（人）',
            dataIndex: 'shareUserCount',
            align: 'right',
        },
        {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (__, record) => {
                return (
                    <Space>
                        <Button type="link" onClick={onSingleNewsStatClick(record)}>
                            趋势图
                        </Button>
                        <Button type="link" onClick={onReadUserClick(record)}>
                            阅读名单
                        </Button>
                    </Space>
                );
            },
        },
    ];

    return (
        <PageHeader
            title="单篇图文统计"
            style={{padding: '16px 0'}}
            extra={
                <DateRange onChange={onChange}>
                    <Input.Search placeholder="按标题查询" allowClear onSearch={setSearch} />
                    <Tooltip title="下载 Excel">
                        <Button icon={<DownloadOutlined />} onClick={onDownloadExcelClick} />
                    </Tooltip>
                </DateRange>
            }
        >
            <PageGrid
                ref={gridRef}
                tableProps={{size: 'small'}}
                initParams={{start: dateRange[0], end: dateRange[1]}}
                columns={columns}
                url={getNewsStatUrl(appId)}
            />

            <NewsReadUserModal ref={readUserModalRef} appId={appId} />
            <SingleNewsChartModal ref={chartModalRef} appId={appId} />
        </PageHeader>
    );
}

export default NewsStatList;
