import React from 'react';
import {Card, message} from 'antd';
import copy from 'copy-to-clipboard';
import _ from 'lodash';

import useTitle from '@/hook/useTitle';
import AppLayout from '@/page/component/AppLayout';
import AppLayoutHeader from '@/page/component/AppLayout/AppLayoutHeader';

import * as icons from '@/icons';
import styles from './IconsPage.module.less';

export default function IconsPage() {
    useTitle('Icons');
    const keys = Object.keys(icons).sort();

    const Outlined = keys.filter(key => key.endsWith('Outlined'));
    const TwoTone = keys.filter(key => key.endsWith('TwoTone'));
    const Filled = keys.filter(key => key.endsWith('Filled'));
    const antdIcons = {TwoTone, Outlined, Filled};

    function onIconClick(iconName) {
        return () => {
            if (copy(iconName)) {
                message.success(iconName + ' 拷贝成功');
            }
        };
    }

    function renderIcon(typeIcons) {
        return typeIcons.map(key => {
            const Icon = icons[key];
            return (
                <Card.Grid key={key} className={styles.grid} onClick={onIconClick(key)}>
                    <Icon className={styles.icon} />
                    <div className={styles.title}>{key}</div>
                </Card.Grid>
            );
        });
    }

    return (
        <AppLayout header={<AppLayoutHeader />} paddingContent={0} style={{overflowY: 'auto'}}>
            {Object.keys(antdIcons).map(iconGroup => {
                if (_.isEmpty(antdIcons[iconGroup])) {
                    return null;
                }

                return (
                    <Card key={iconGroup} title={iconGroup} bordered={false}>
                        {renderIcon(antdIcons[iconGroup])}
                    </Card>
                );
            })}
        </AppLayout>
    );
}
