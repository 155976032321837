import React from 'react';
import {Tree} from 'antd';
import _ from 'lodash';

import {ApartmentOutlined, DownOutlined, TeamOutlined} from '@/icons';
import {TOP_PARENT_ID} from '@/util/treeUtils';

export default function AppTree({...rest}) {
    function treeIcon(node) {
        if (_.get(node, 'depart.parentId') === TOP_PARENT_ID) {
            return <ApartmentOutlined />;
        }
        return <TeamOutlined />;
    }

    return (
        <Tree
            defaultExpandAll
            showLine={{showLeafIcon: false}}
            showIcon
            switcherIcon={<DownOutlined />}
            icon={treeIcon}
            {...rest}
        />
    );
}
