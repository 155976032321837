import {useAsyncRetry} from 'react-use';

import {getUserAppMeta} from '@/api/apps';

export function useMyApps(userId) {
    return useAsyncRetry(async () => {
        if (!userId) {
            return {data: null};
        }
        return await getUserAppMeta(userId);
    }, [userId]);
}
