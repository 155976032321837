import React, {useState} from 'react';
import {Layout} from 'antd';

import {useResponsiveView} from '@/component';
import AdminRoutes from '../AdminRoutes';
import AdminHeader from './AdminHeader';
import AdminSidebar from './AdminSidebar';

import styles from './AdminLayout.module.less';

const {Content} = Layout;

export default function AdminLayout() {
    const [sidebarCollapsed, setSidebarCollapsed] = useState(true);
    const {isMobile} = useResponsiveView();

    const contentStyle = isMobile ? {padding: '16px 16px 0'} : {padding: '24px 50px 0'};

    function toggleSidebar() {
        setSidebarCollapsed(!sidebarCollapsed);
    }

    return (
        <Layout className={styles.layout}>
            <AdminHeader onToggleSidebar={toggleSidebar} />

            <Content style={contentStyle} className={styles.content}>
                <Layout className={styles.contentLayout}>
                    <AdminSidebar collapsed={sidebarCollapsed} onCollapse={toggleSidebar} />
                    <Content className={styles.innerContent}>
                        <AdminRoutes />
                    </Content>
                </Layout>
            </Content>
        </Layout>
    );
}
