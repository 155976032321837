import React, {useRef, useState} from 'react';
import {Alert, Button, Form, Input} from 'antd';
import {useHistory} from 'react-router-dom';

import useTitle from '@/hook/useTitle';
import {UserOutlined} from '@/icons';

import LoginContainer from './LoginContainer';
import CaptchaFormItem from './CaptchaFormItem';
import {passResetRequest} from '@/api/auth';

import styles from './LoginPage.module.less';

const ForgetPassPage = () => {
    useTitle('忘记密码');
    const history = useHistory();
    const formRef = useRef();

    const [captchaKey, setCaptchaKey] = useState(null);
    const [sendMail, setSendMail] = useState(false);

    function onLoadCaptchaKey(captchaKey) {
        // console.log('captchaKey', captchaKey);
        setCaptchaKey(captchaKey);
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        passResetRequest({...values, captchaKey})
            .then(() => {
                setSendMail(true);
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    return (
        <LoginContainer title="忘记密码">
            {sendMail ? (
                <Alert
                    message="邮件提醒"
                    description="请检查您的邮箱中的密码重置邮件，并点击相应链接重置密码"
                    type="success"
                    showIcon
                />
            ) : (
                <>
                    <Alert
                        message="重置说明"
                        description="将会发送密码重置链接到给定 Email，此 Email 必须已存在系统中"
                        type="info"
                        showIcon
                        style={{marginBottom: 24}}
                    />
                    <Form onFinish={onFinish} ref={formRef}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Email 不能为空',
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Email"
                                size="large"
                                autoFocus
                            />
                        </Form.Item>

                        <CaptchaFormItem onLoadCaptchaKey={onLoadCaptchaKey} />

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                className={styles.submit}
                            >
                                发送重置信息到邮箱
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="link"
                                size="large"
                                className={styles.submit}
                                onClick={() => history.push('/login')}
                            >
                                返回登录页
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            )}
        </LoginContainer>
    );
};

export default ForgetPassPage;
