import React, {useEffect, useState} from 'react';
import {Button, Result} from 'antd';
import qs from 'qs';
import {Link, useHistory} from 'react-router-dom';

import { getOidcUserinfo, loginByEmail} from '@/api/auth';
import {PageLoading} from '@/component';
import {useAuthStore} from '@/context/auth';
import {setTokens} from '@/util/TokenStore';
import useTitle from '@/hook/useTitle';

function BiSsoPage() {
    useTitle('SSO');
    const history = useHistory();
    const {user_token} = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
    });
    const [error, setError] = useState(
        user_token ? null : {status: 400, message: 'user_token 不存在'}
    );
    const [loading, setLoading] = useState(false);
    const [, dispatch] = useAuthStore();

    useEffect(() => {
        dispatch('reset');
        async function login() {
            setLoading(true);

            const {msg, data} = await getOidcUserinfo(user_token);
            console.log('{msg, data}', {msg, data});
            if (!data) {
                setError({status: 401, message: msg || '出现错误'});
                setLoading(false);
                return false;
            }
            
            try {
                const loginData = await loginByEmail(data?.email);
                if (loginData.status !== 200) {
                    setError({status: loginData.status, message: loginData.message});
                    setLoading(false);
                    return false;
                }

                const json = loginData.data;
                setTokens(json.tokens);
                let jsonCopy = JSON.parse(JSON.stringify(json));
                jsonCopy.user.passReset = false;
                jsonCopy.user.passModifyTime = new Date().getTime();
                dispatch(['loginSuccess', jsonCopy]);

                history.replace('/');
                return true;
            } catch (e) {
                const {status, message} = e;
                setLoading(false);
                setError({status, message});
            }
        }

        login();
    }, [user_token, dispatch, history]);

    if (loading) {
        return <PageLoading />;
    }

    if (error) {
        const {status, message} = error;
        return (
            <Result
                status="warning"
                title="请返回登录页重新认证"
                subTitle={`[${status}] ${message}`}
                extra={[
                    <Link to="/login" key="login">
                        <Button type="primary">重新登录</Button>
                    </Link>,
                ]}
            />
        );
    }

    return null;
}

export default BiSsoPage;
