import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Divider, Dropdown, Menu, Tooltip} from 'antd';
import DownOutlined from '@ant-design/icons/DownOutlined';

import _ from 'lodash';
import classNames from 'classnames';

import styles from 'component/PageGrid/styles.module.less';

/**
 * 表格每行最后的操作列。
 *
 * 通过传入 actions 的定义，自动展现操作按钮，支持在菜单中显示更多操作
 */
export default class RowAction extends Component {
    static propTypes = {
        text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

        // 布局支持 横向和纵向两种，默认是横向
        layout: PropTypes.oneOf(['vertical', 'horizontal']),

        showMenuIcon: PropTypes.bool,

        actions: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.string.isRequired,

                // 显示横线
                isDivider: PropTypes.bool,

                // 操作标题，可以为空
                title: PropTypes.string,
                // 鼠标放上去显示的 tooltip，一般在不用 title 的时候使用
                tooltip: PropTypes.string,
                icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
                hide: PropTypes.bool,
                onClick: PropTypes.func,
            })
        ).isRequired,

        record: PropTypes.object.isRequired,
    };

    static defaultProps = {
        text: '更多',
        layout: 'horizontal',
        showMenuIcon: true,
    };

    /**
     * 是否横向布局。
     *
     * @return {boolean}
     */
    isHorizontal = () => {
        const {layout} = this.props;
        return layout === 'horizontal';
    };

    onOutActionClick = action => {
        return e => {
            const {record} = this.props;

            e.preventDefault();
            const {onClick} = action;
            if (onClick) {
                onClick(record);
            }
        };
    };

    renderActionLink = action => {
        const {title, icon} = action;

        return (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a href="#" onClick={this.onOutActionClick(action)}>
                {icon}
                {title}
            </a>
        );
    };

    renderShowOutMenuActions = actions => {
        if (_.isEmpty(actions)) {
            return null;
        }
        const isHr = this.isHorizontal();
        const cls = classNames({
            [styles.horizontalAction]: isHr,
        });

        return actions.map((action, index) => {
            const {key, tooltip} = action;

            return (
                <div key={key} className={cls}>
                    {isHr && index !== 0 && <Divider type="vertical" />}
                    {tooltip ? (
                        <Tooltip title={tooltip}>{this.renderActionLink(action)}</Tooltip>
                    ) : (
                        this.renderActionLink(action)
                    )}
                </div>
            );
        });
    };

    renderShowInMenuActions = actions => {
        if (_.isEmpty(actions)) {
            return null;
        }

        const menu = (
            <Menu>
                {actions.map(action => {
                    const {key, isDivider} = action;

                    if (isDivider) {
                        return <Menu.Divider key={key} />;
                    }
                    return <Menu.Item key={key}>{this.renderActionLink(action)}</Menu.Item>;
                })}
            </Menu>
        );
        return (
            <Dropdown overlay={menu} placement="bottomRight">
                <span className={styles.moreAction}>
                    {this.props.text}

                    {this.props.showMenuIcon && <DownOutlined className={styles.dropdownIcon} />}
                </span>
            </Dropdown>
        );
    };

    render() {
        const {actions} = this.props;

        if (_.isEmpty(actions)) {
            return null;
        }

        const showOutMenuActions = actions.filter(action => action && !action.hide);
        const showInMenuActions = actions.filter(action => action && action.hide);
        const isHr = this.isHorizontal();

        return (
            <span className={styles.actionWrap}>
                {this.renderShowOutMenuActions(showOutMenuActions)}

                {isHr && !_.isEmpty(showOutMenuActions) && !_.isEmpty(showInMenuActions) && (
                    <Divider type="vertical" />
                )}

                {isHr ? (
                    this.renderShowInMenuActions(showInMenuActions)
                ) : (
                    <div>{this.renderShowInMenuActions(showInMenuActions)}</div>
                )}
            </span>
        );
    }
}
