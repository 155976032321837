import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Checkbox, Divider, Tabs} from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import {useBeforeUnload} from 'react-use';
import {Prompt} from 'react-router-dom';

import {CardTabsContainer} from '@/component';
import MediaSelectModal from '@/page/app/MediaPage/MediaSelectModal';
import {
    ContainerOutlined,
    FileTextOutlined,
    LinkOutlined,
    PaperClipOutlined,
    PictureOutlined,
    SoundOutlined,
    VideoCameraOutlined,
} from '@/icons';
import Ping from '@/page/component/toolkit/Ping';

import TextContent from './content/TextContent';
import NewsContent from './content/NewsContent';
import NewsLinkContent from './content/NewsLinkContent';
import ImageContent from './content/ImageContent';
import VoiceContent from './content/VoiceContent';
import VideoContent from './content/VideoContent';
import FileContent from './content/FileContent';

import styles from './index.module.less';
import Item from '@/page/app/MsgSendPage/Item';

const {TabPane} = Tabs;

// 不显示分享设置
const NOT_SHARE_TYPE = ['news', 'news_link', 'voice'];

const MessageContent = forwardRef(({className, style, autoFocus = true, onMsgTypeChange}, ref) => {
    const mediaSelectModal = useRef();

    // 是否已编辑
    const [isDirty, setDirty] = useState(false);

    const [msgType, setMsgType] = useState('text');

    // 0 表示可对外分享，1 表示不能分享且内容显示水印
    const [safe, setSafe] = useState(0);

    useBeforeUnload(isDirty, '确定关闭，已编辑内容将会丢弃');

    // 由于每个 tab 都可能有自己的内容，所以这儿放置一个统一的对象用于存储所有内容
    // 对象以 msgType 为 key，格式为：
    // {
    //      msgType: {title, description, content, newsNum, newsItemId, attachId, attachExt, attachSize, newsContents: []}
    // }
    const [contentByType, setContentByType] = useState({
        text: {},
        news: [],
        news_link: [],
        image: {},
        voice: {},
        video: {},
        file: {},
    });

    function updateContent(newContent) {
        setContentByType(newContent);
        setDirty(true);
    }

    function isNewsLikeType(type) {
        return ['news', 'news_link'].includes(type);
    }

    useImperativeHandle(ref, () => ({
        /**
         * 外部控制 dirty 状态。
         *
         * @param dirty
         */
        setEditDirty: dirty => setDirty(dirty),

        /**
         * 设置消息编辑器内容，如果在Modal 打开的时候注入数据，可以使用 setTimeout 做个延时处理。
         *
         * @param type
         * @param data
         */
        setData: (type, data) => {
            setMsgType(type);
            setDirty(true);
            setSafe(data.safe || 0);

            // 图文、链接图文需要取其中的 newsContents
            if (isNewsLikeType(type)) {
                const {newsContents} = data;
                updateContent({...contentByType, [type]: newsContents});
            } else {
                updateContent({...contentByType, [type]: data});
            }
        },

        /**
         * 方便外部通过 ref 调用，返回当前高亮的标签页的 key 值，以及所有内容。
         *
         * 返回内容包括：
         *
         * msgType: 信息类型
         * content: 对应 msgType 的信息内容
         * contentByType: 所有存在于编辑框的信息
         *
         * @return {{msgType: string, content: Object, contentByType: Object}}
         */
        getData: () => {
            let content = contentByType[msgType];
            // console.log('content......', content);

            // 对于图文、链接图文来说，其对应内容是数组形式
            // 如果是从素材中选取，则其中会包含一些 audit 信息，需要过滤掉
            if (isNewsLikeType(msgType) && !_.isEmpty(content)) {
                const newsContents = content.map(
                    ({
                        // id,
                        creatorId,
                        creatorName,
                        createTime,
                        modifierId,
                        modifierName,
                        modifyTime,
                        deleted,
                        // itemId,
                        // itemFrom,
                        thumbUrl,
                        ...rest
                    }) => {
                        return rest;
                    }
                );
                const {title, description, thumbAttachId, thumbAttachExt} = newsContents[0];
                content = {
                    title,
                    description,
                    thumbAttachId,
                    thumbAttachExt,
                    newsNum: newsContents.length,
                    newsContents,
                };
            }

            return {
                msgType,
                safe,

                // 当前 msgType 对应的内容
                content,
                contentByType,
            };
        },
    }));

    function onTextChange(value) {
        updateContent({...contentByType, text: {content: value}});
    }

    function onNewsChange(value) {
        // console.log(value);
        updateContent({...contentByType, news: value});
    }

    function onNewsLinkChange(value) {
        updateContent({...contentByType, news_link: value});
    }

    function onImageChange(value) {
        updateContent({...contentByType, image: value});
    }

    function onVoiceChange(value) {
        updateContent({...contentByType, voice: value});
    }

    function onVideoChange(value) {
        // console.log('onVideoChange', value);
        updateContent({...contentByType, video: value});
    }

    function onFileChange(value) {
        updateContent({...contentByType, file: value});
    }

    function onTabChange(activeKey) {
        setMsgType(activeKey);
        onMsgTypeChange && onMsgTypeChange(activeKey);
    }

    function onMediaSelectClick() {
        let type = msgType;
        if (msgType === 'news_link') {
            type = 'news';
        }
        mediaSelectModal.current.showModal(type);
    }

    /**
     * 从媒体库选择之后会切换到对应标签，并将内容置入
     * @param mediaType
     * @param value
     * @param newsList
     */
    function onMediaSelect(mediaType, value, newsList) {
        // console.log('onMediaSelect======', mediaType, value, newsList);
        if (_.isEmpty(value)) {
            return;
        }

        // 切换标签页
        setMsgType(mediaType);

        if (mediaType === 'text') {
            onTextChange(value);
            return;
        }

        const {title, description, content, newsNum, attachId, attachExt, attachSize} = value;

        // 图文和链接图文需要使用的是第二个参数 newsList，里面包含图文内容和附件
        if (isNewsLikeType(mediaType)) {
            updateContent({...contentByType, [mediaType]: newsList});
            return;
        }

        updateContent({
            ...contentByType,
            [mediaType]: {
                title,
                description,
                content,
                newsNum,
                attachId,
                attachExt,
                attachSize,
            },
        });
    }

    function onShareChange(e) {
        // console.log('share', e.target.checked);
        setSafe(e.target.checked ? 1 : 0);
    }

    const extra = (
        <Button type="link" onClick={onMediaSelectClick}>
            从素材库选取
        </Button>
    );

    return (
        <>
            <CardTabsContainer className={classNames(styles.container, className)} style={style}>
                <Prompt when={isDirty} message="确定离开本页面？已编辑内容将会丢弃！" />
                <Ping />

                <Tabs
                    activeKey={msgType}
                    onChange={onTabChange}
                    tabBarExtraContent={extra}
                    type="card"
                >
                    <TabPane
                        tab={
                            <span>
                                <FileTextOutlined />
                                文本
                            </span>
                        }
                        key="text"
                    >
                        <TextContent
                            autoFocus={autoFocus}
                            value={contentByType.text.content}
                            onChange={onTextChange}
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <ContainerOutlined />
                                图文
                            </span>
                        }
                        key="news"
                    >
                        <NewsContent value={contentByType.news} onChange={onNewsChange} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <LinkOutlined />
                                链接图文
                            </span>
                        }
                        key="news_link"
                    >
                        <NewsLinkContent
                            value={contentByType.news_link}
                            onChange={onNewsLinkChange}
                        />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <PictureOutlined />
                                图片
                            </span>
                        }
                        key="image"
                    >
                        <ImageContent value={contentByType.image} onChange={onImageChange} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <SoundOutlined />
                                语音
                            </span>
                        }
                        key="voice"
                    >
                        <VoiceContent value={contentByType.voice} onChange={onVoiceChange} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <VideoCameraOutlined />
                                视频
                            </span>
                        }
                        key="video"
                    >
                        <VideoContent value={contentByType.video} onChange={onVideoChange} />
                    </TabPane>
                    <TabPane
                        tab={
                            <span>
                                <PaperClipOutlined />
                                文件
                            </span>
                        }
                        key="file"
                    >
                        <FileContent value={contentByType.file} onChange={onFileChange} />
                    </TabPane>
                </Tabs>

                <MediaSelectModal ref={mediaSelectModal} onSelected={onMediaSelect} />
            </CardTabsContainer>

            {!NOT_SHARE_TYPE.includes(msgType) && (
                <>
                    <Divider />
                    <Item title="分享设置">
                        <Checkbox checked={safe === 1} onChange={onShareChange}>
                            不能分享且内容显示水印
                        </Checkbox>
                    </Item>
                </>
            )}
        </>
    );
});

MessageContent.displayName = 'MessageContent';

MessageContent.propTypes = {
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,

    /**
     * 向外传递当前 tab 的 key 值
     */
    onMsgTypeChange: PropTypes.func,
};

export default MessageContent;
