import React, {forwardRef, useImperativeHandle, useState, useEffect} from 'react';
import {Modal} from 'antd';

import RolesPanel from '../RolesPage/RolesPanel';
import {roleScopePropTypes} from '../RolesPage/propTypes';
import {getUserPermissions, updateUserRoles} from '@/api/roles';

/**
 * 角色选择 Modal
 */
const RoleSelector = forwardRef(({scope, scopeItemId}, ref) => {
    const [visible, setVisible] = useState(false);
    const [userId, setUserId] = useState(null);
    const [roleIds, setRoleIds] = useState([]);
    const [selectable, setSelectable] = useState(true);

    useEffect(() => {
        if (!userId) {
            return;
        }

        getUserPermissions(userId, scope, scopeItemId).then(({data}) => {
            // console.log('getUserPermissions', data);
            const initRoles = data.roles || [];
            const ids = initRoles.map(role => role.id);
            setRoleIds(ids);
        });
    }, [scope, scopeItemId, userId]);

    /**
     * 显示角色 Modal
     *
     * @param userId 用户 id
     * @param selectable true 的时候支持角色选择，否则只能查看已有角色
     */
    function showModal(userId, selectable = true) {
        setVisible(true);
        setUserId(userId);
        setSelectable(selectable);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        // console.log('ok...', roleIds);
        updateUserRoles(userId, roleIds).then(() => {
            // console.log('updateUserRoles', data);
            closeModal();
        });
    }

    function onRoleCheckToggle({roleId, checked}) {
        // console.log(roleId, checked);
        if (checked) {
            if (!roleIds.includes(roleId)) {
                setRoleIds([...roleIds, roleId]);
            }
        } else {
            if (roleIds.includes(roleId)) {
                setRoleIds(roleIds.filter(id => id !== roleId));
            }
        }
    }

    return (
        <Modal
            destroyOnClose
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            width={700}
            okButtonProps={selectable ? {} : {style: {display: 'none'}}}
            bodyStyle={{padding: 0}}
        >
            <RolesPanel
                scope={scope}
                scopeItemId={scopeItemId}
                fallbackOnApp
                editable={false}
                showUserList={false}
                selectable={selectable}
                initSelectRoleIds={roleIds}
                onlyShowInitRoles={!selectable}
                onRoleCheckToggle={onRoleCheckToggle}
            />
        </Modal>
    );
});

RoleSelector.displayName = 'RoleSelector';

RoleSelector.propTypes = {
    ...roleScopePropTypes,
};

export default RoleSelector;
