/* eslint-disable react/display-name */
import React from 'react';
import {Avatar, Button, Tag} from 'antd';
import _ from 'lodash';

import {formatLong} from '@/util/dateUtils';

const linkBtnStyle = {paddingLeft: 0, paddingRight: 0};

const columnDefines = ({onContentClick, onNewsPreviewClick}) => ({
    // 暂时屏蔽头像
    avatar: {
        dataIndex: 'thumbAvatar',
        width: 64,
        render: (text, record) => {
            console.log(record.userName, record);
            return (
                <Avatar src={text} alt="UN">
                    {record.userName.charAt(0)}
                </Avatar>
            );
        },
    },
    userName: {
        title: '留言者',
        dataIndex: 'userName',
        render: text => {
            return text;
        },
    },
    commentContent: {
        title: '留言内容',
        dataIndex: 'content',
        render: (text, record) => {
            return (
                <Button
                    type="link"
                    style={linkBtnStyle}
                    onClick={() => onContentClick && onContentClick(record)}
                >
                    {_.truncate(text, {length: 30})}
                </Button>
            );
        },
    },
    newestCommentContent: {
        title: '最新留言',
        dataIndex: 'content',
        render: (text, record) => {
            return (
                <Button
                    type="link"
                    style={linkBtnStyle}
                    onClick={() => onContentClick && onContentClick(record)}
                >
                    {_.truncate(text, {length: 30})}
                </Button>
            );
        },
    },
    praiseNum: {
        title: '点赞数',
        dataIndex: 'praiseNum',
        render: text => {
            return text || 0;
        },
    },
    newsCommentCount: {
        title: '评论数',
        dataIndex: 'commentCount',
        render: text => {
            return text || 0;
        },
    },
    shown: {
        title: '精选留言',
        dataIndex: 'shown',
        render: text => {
            if (text) {
                return <Tag color="cyan">精选</Tag>;
            }
            return null;
        },
    },
    stick: {
        title: '置顶',
        dataIndex: 'stick',
        render: text => {
            if (text) {
                return <Tag color="#f50">置顶</Tag>;
            }
            return null;
        },
    },
    newsTitle: {
        title: '所属图文',
        dataIndex: 'itemTitle',
        render: (text, record) => {
            const {itemId} = record;
            return (
                <Button
                    type="link"
                    style={linkBtnStyle}
                    onClick={() => onNewsPreviewClick && onNewsPreviewClick(itemId)}
                >
                    {text}
                </Button>
            );
        },
    },
    createTime: {
        title: '留言时间',
        dataIndex: 'createTime',
        render: text => {
            return formatLong(text);
        },
    },
});

export function getColumns(keys, options) {
    const defines = columnDefines(options);
    return keys.map(key => defines[key]).filter(Boolean);
}
