import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Space, Tag} from 'antd';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';

SyncConfigTagsField.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })
    ),
    onChange: PropTypes.func,
};

function SyncConfigTagsField({value = [], onChange}) {
    const DepartUserTagSelectorRef = useRef();

    function onTagSelClick() {
        DepartUserTagSelectorRef.current.showModal({initialValues: {tags: value}});
    }

    function onTagSelect(values) {
        console.log('onTagSelect', values);
        const {tags = []} = values || {};
        onChange(tags);
        return true;
    }

    return (
        <>
            <Space direction="vertical">
                <Button size="small" type="primary" onClick={onTagSelClick}>
                    选择标签
                </Button>
                <div>
                    {value?.map(({id, name}) => (
                        <Tag key={id}>{name}</Tag>
                    ))}
                </div>
            </Space>

            <DepartUserTagSelector
                ref={DepartUserTagSelectorRef}
                modalTitle="选择标签"
                showTagTab={true}
                showDepartTab={false}
                showUserTab={false}
                onSelect={onTagSelect}
            />
        </>
    );
}

export default SyncConfigTagsField;
