import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Modal, Tag, Button} from 'antd';

import {exportContact} from '@/api/contact';

const SyncContactToWecomModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jobId, setJobId] = useState(null);
    const [msg, setMsg] = useState(null);

    /**
     * 打开 Modal
     *
     */
    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
        setLoading(false);
        setJobId(null);
        setMsg(null);
    }

    function onSyncToWecom() {
        setLoading(true);
        exportContact().then(({data}) => {
            if (data) {
                const {jobid, errcode, errmsg} = data;
                if (errcode === 0) {
                    setJobId(jobid);
                } else {
                    setMsg(errmsg);
                }
                setLoading(false);
            }
        });
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title="同步到企业微信"
            width={800}
            onCancel={closeModal}
            okButtonProps={{
                style: {display: 'none'},
            }}
        >
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                {jobId && (
                    <span>
                        同步到企业微信成功，任务id：<Tag color="green">{jobId}</Tag>
                        ，请根据id前往异步任务日志查看详情
                    </span>
                )}
                {msg && (
                    <span>
                        同步到企业微信失败，失败原因：<Tag color="red">{msg}</Tag>
                    </span>
                )}
                <Button
                    loading={loading}
                    disabled={loading}
                    style={{flex: 1, marginTop: 10}}
                    onClick={onSyncToWecom}
                >
                    同步所有用户到企业微信
                </Button>
            </div>
        </Modal>
    );
});

SyncContactToWecomModal.displayName = 'SyncContactToWecomModal';

export default SyncContactToWecomModal;
