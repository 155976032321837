import React, {forwardRef, useImperativeHandle, useState} from 'react';
import ReactPlayer from 'react-player';

import {CloseCircleOutlined} from '@/icons';

import styles from './styles.module.less';

const Player = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState(null);

    /**
     * @param type video, audio
     * @param title 标题
     * @param url 网址
     */
    function showPlayer(type, title, url, description) {
        setVisible(true);
        setData({type, title, url, description});
    }

    function closePlayer() {
        setVisible(false);
        setData(null);
    }

    useImperativeHandle(ref, () => ({
        showPlayer,
        closePlayer,
    }));

    if (!data) {
        return null;
    }

    const displayStyle = visible ? {display: 'flex'} : {display: 'none'};
    const {type, title, url, description} = data;

    const playProps = type === 'video' ? {width: 600} : {width: 400, height: 50};
    return (
        <div className={styles.overview} style={displayStyle}>
            <div className={styles.close} onClick={closePlayer}>
                <CloseCircleOutlined />
            </div>

            <div className={styles.title}>{title}</div>
            <ReactPlayer controls playing={false} url={url} {...playProps} />
            {description && <div className={styles.description}>{description}</div>}
        </div>
    );
});

Player.displayName = 'Player';

export default Player;
