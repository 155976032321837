import React from 'react';
import {Divider} from 'antd';

import useTitle from '@/hook/useTitle';
import SyncConfigGlobalDepart from './SyncConfigGlobalDepart';
import SyncConfigGlobalTags from './SyncConfigGlobalTags';
import SyncConfigDepart2TagMapper from './SyncConfigDepart2TagMapper';
import {SyncConfigContainer} from './SyncConfigContext';
import SyncLog from './SyncLog';

function BiUserSync() {
    useTitle('BI 用户同步 - 系统管理');

    return (
        <div style={{overflowY: 'auto'}}>
            <SyncConfigContainer>
                <SyncConfigGlobalDepart />
                <Divider />
                <SyncConfigGlobalTags />
                <Divider />
                <SyncConfigDepart2TagMapper />
                <Divider />
                <SyncLog />
            </SyncConfigContainer>
        </div>
    );
}

export default BiUserSync;
