import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, List, message, Modal, Row, Spin, Typography} from 'antd';
import _ from 'lodash';

import {DeleteOutlined} from '@/icons';

import AppTree from '@/page/component/AppTree';
import {useDepartTree, useUserDepartLinks} from './useDepartService';
import {convertDeparts2TreeFormat} from '@/util/treeUtils';
import {batchUpdateUserLinks} from '@/api/departs';

import styles from './MultiDepartEditModal.module.less';

const MultiDepartEditModal = forwardRef(({reloadUsers}, ref) => {
    const [visible, setVisible] = useState(false);
    const [userId, setUserId] = useState(null);
    const [treeData, setTreeData] = useState([]);
    const [links, setLinks] = useState([]);

    const {loading: treeLoading, value: departs} = useDepartTree();
    const {loading: linkLoading, value: linkData} = useUserDepartLinks(userId);

    useEffect(() => {
        if (treeLoading) {
            return;
        }

        const {data} = departs;
        setTreeData(convertDeparts2TreeFormat(data));
    }, [treeLoading, departs]);

    useEffect(() => {
        if (linkLoading) {
            return;
        }

        const linkList = _.get(linkData, 'data', []);
        const list = linkList
            .map(item => {
                const {id, primaryDepart, depart} = item;
                if (!depart) {
                    return null;
                }

                return {
                    linkId: id,
                    primaryDepart,
                    departId: depart.id,
                    departName: depart.name,
                };
            })
            .filter(Boolean);
        setLinks(list);
    }, [linkData, linkLoading]);

    function showModal(userId) {
        setVisible(true);
        setUserId(userId);
    }

    function closeModal() {
        setVisible(false);
        setUserId(null);
        setLinks([]);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onSelect(idArr, {node}) {
        const {
            depart: {id, name},
        } = node;

        let link = links.find(({departId}) => departId === id);
        if (link) {
            return;
        }

        link = {
            departId: id,
            departName: name,
        };
        setLinks([...links, link]);
    }

    function onDeleteClick(departId) {
        return () => {
            const newLinks = links.filter(link => link.departId !== departId);
            setLinks(newLinks);
        };
    }

    function onPrimarySetDepart(departId) {
        return () => {
            const newLinks = links.map(link => {
                if (link.primaryDepart) {
                    return {...link, primaryDepart: false};
                }
                if (link.departId === departId) {
                    return {...link, primaryDepart: true};
                }
                return {...link};
            });
            setLinks(newLinks);
        };
    }

    function renderItem(item) {
        const {primaryDepart, departId, departName} = item;

        const actions = [
            <Button
                key="delete"
                type="text"
                icon={<DeleteOutlined />}
                onClick={onDeleteClick(departId)}
            />,
        ];

        if (primaryDepart) {
            actions.unshift(
                <Button key="is-primary-depart" type="text" disabled>
                    主部门
                </Button>
            );
        } else {
            actions.unshift(
                <Button
                    key="set-primary-depart"
                    type="link"
                    className={styles.setPrimary}
                    onClick={onPrimarySetDepart(departId)}
                >
                    设为主部门
                </Button>
            );
        }

        return (
            <List.Item className={styles.item} actions={actions}>
                {departName}
            </List.Item>
        );
    }

    function onOk() {
        if (_.isEmpty(links)) {
            message.error('人员必须属于至少一个部门');
            return;
        }

        let link = links.find(({primaryDepart}) => primaryDepart);
        if (!link) {
            message.error('必须有一个「主部门」');
            return;
        }

        batchUpdateUserLinks(userId, links).then(() => {
            closeModal();
            reloadUsers && reloadUsers();
        });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            width={1000}
            title="选择人员所在部门"
            onCancel={closeModal}
            onOk={onOk}
        >
            <Row>
                <Col span={12} className={styles.tree}>
                    <Spin spinning={treeLoading}>
                        {!treeLoading && <AppTree treeData={treeData} onSelect={onSelect} />}
                    </Spin>
                </Col>
                <Col span={12}>
                    <Spin spinning={linkLoading}>
                        <List
                            header={<Typography.Text type="secondary">已选择部门</Typography.Text>}
                            dataSource={links}
                            renderItem={renderItem}
                        />
                    </Spin>
                </Col>
            </Row>
        </Modal>
    );
});

MultiDepartEditModal.displayName = 'MultiDepartEditModal';

MultiDepartEditModal.propTypes = {
    reloadUsers: PropTypes.func,
};

export default MultiDepartEditModal;
