import React, {useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {Tag} from 'antd';

TagGroup.propTypes = {
    data: PropTypes.array,
    renderItem: PropTypes.func.isRequired,

    /**
     * 默认显示条目数量，超过次数者隐藏，使用 More 按钮展示
     */
    visibleCount: PropTypes.number,
};

function TagGroup({data, visibleCount = 10, renderItem}) {
    const [showAll, setShowAll] = useState(false);

    if (_.isEmpty(data)) {
        return null;
    }
    if (visibleCount <= 0) {
        throw new Error('[visibleCount] 参数不能小于等于 0');
    }

    function onMoreClick() {
        setShowAll(prevState => !prevState);
    }

    function renderMoreTag() {
        const showExtraTag = data.length > visibleCount;
        if (!showExtraTag) {
            return null;
        }

        const extraDesc = showAll ? '隐藏' : `展开 +${data.length - visibleCount}`;

        return (
            <Tag onClick={onMoreClick} style={{cursor: 'pointer'}}>
                {extraDesc}
            </Tag>
        );
    }

    let items = showAll ? data : data.slice(0, visibleCount);
    return (
        <>
            {items.map((item, index) => renderItem(item, index, data))}
            {renderMoreTag()}
        </>
    );
}

export default TagGroup;
