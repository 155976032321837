import {useAsync, useAsyncRetry} from 'react-use';

import {getAllApps} from '@/api/apps';
import {getAppCustomSettingList, getAppCustomSettings} from '@/api/settings';

/**
 * 返回所有应用的 id: name 生成的 Object，和 appCustom category 对应的配置。
 */
export function useAppCustomSettingsDefine() {
    return useAsyncRetry(() => {
        return Promise.all([
            getAllApps(),
            getAppCustomSettingList(),
        ]).then(([appsResult, settingsResult]) => {
            console.log(appsResult, settingsResult);
            const appIdMap = (appsResult?.data ?? []).reduce((prev, cur) => {
                const {id, name} = cur;

                return {
                    ...prev,
                    [id]: name,
                };
            }, {});
            const settingList = settingsResult?.data ?? [];
            return {appIdMap, settingList};
        });
    }, []);
}

/**
 * 返回给定应用下所有自定义配置的支持情况。
 *
 * @param appId 应用 id
 */
export function useAppCustomSettings(appId) {
    const {value} = useAsync(async () => {
        const {data} = await getAppCustomSettings();
        const {batchSendEmailOrMobile, appMsgAudit, appAllowAddUsers} = data || {};
        return {
            batchSendEmailOrMobile: batchSendEmailOrMobile?.includes(appId),
            appMsgAudit: appMsgAudit?.includes(appId),
            appAllowAddUsers: appAllowAddUsers?.includes(appId),
        };
    }, [appId]);

    return {
        batchSendEmailOrMobile: value?.batchSendEmailOrMobile ?? false,
        appMsgAudit: value?.appMsgAudit ?? false,
        appAllowAddUsers: value?.appAllowAddUsers ?? false,
    };
}
