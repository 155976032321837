import React from 'react';
import PropTypes from 'prop-types';
import {Spin} from 'antd';

import AppTree from '@/page/component/AppTree';
import {useDepartTreeData} from './tabPaneService';
import {getTreeData} from './departTreeUtils';

import styles from '../MainPanel.module.less';

export default function DepartTabPane({allowDepartIds, onAddSelected}) {
    const {loading, treeData} = useDepartTreeData();

    // console.log('allowDepartIds', allowDepartIds);
    // console.log('loading, value', loading, value, treeData);

    const filterTreeData = loading ? treeData : getTreeData(treeData, allowDepartIds);

    function onSelect(idArr, {node}) {
        // console.log(idArr, node);
        const {
            depart: {id, name},
        } = node;

        onAddSelected('departs', {id, name});
    }

    return (
        <div className={styles.tabContent1}>
            <Spin spinning={loading}>
                {!loading && !!filterTreeData && (
                    <AppTree treeData={filterTreeData} onSelect={onSelect} />
                )}
            </Spin>
        </div>
    );
}

DepartTabPane.propTypes = {
    /**
     * 可见的部门 id
     *
     * 如果为为 null 或 undefined, 表示所有部门可见
     *
     * 如果是 [] ，表示所有不可见
     * 如果有值，则对应部门和子部门可见
     */
    allowDepartIds: PropTypes.arrayOf(PropTypes.string),

    onAddSelected: PropTypes.func.isRequired,
};
