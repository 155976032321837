import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, DatePicker, Space} from 'antd';
import dayjs from 'dayjs';
import {useEffectOnce} from 'react-use';

DateRange.propTypes = {
    /**
     * 日期范围变化之后触发
     *
     * 参数为：
     * @param {Array} dayjs 对象，日期开始、结束
     * @param {Array} YYYY-MM-DD 字符串格式，日期开始、结束
     */
    onChange: PropTypes.func.isRequired,
    children: PropTypes.node,
};

function DateRange({onChange, children}) {
    const current = dayjs();
    const [dateStart, setDateStart] = useState(current.subtract(7, 'd'));
    const [dateEnd, setDateEnd] = useState(current);

    useEffectOnce(() => {
        onChange([dateStart, dateEnd], [formatDayjs(dateStart), formatDayjs(dateEnd)]);
    });

    function formatDayjs(date) {
        return date.format('YYYY-MM-DD');
    }

    function onRangeChange(range) {
        return () => {
            let end = dayjs();
            let start = end.subtract(range, 'd');
            setDateStart(start);
            setDateEnd(end);

            onChange([start, end], [formatDayjs(start), formatDayjs(end)]);
        };
    }

    function onDateChange(dates, dateStrings) {
        setDateStart(dates[0]);
        setDateEnd(dates[1]);
    }

    function onFilter() {
        onChange([dateStart, dateEnd], [formatDayjs(dateStart), formatDayjs(dateEnd)]);
    }

    function isSameRange(range) {
        const end = dayjs();
        const start = end.subtract(range, 'd');

        const isSame = start.isSame(dateStart, 'd') && end.isSame(dateEnd, 'd');
        return isSame ? {type: 'primary'} : {};
    }

    // noinspection NonAsciiCharacters
    const ranges = {
        '近 7 天': [current.subtract(7, 'd'), current],
        '近 15 天': [current.subtract(15, 'd'), current],
        '近 30 天': [current.subtract(30, 'd'), current],
    };

    return (
        <Space>
            <DatePicker.RangePicker
                value={[dateStart, dateEnd]}
                showTime={false}
                allowClear={false}
                ranges={ranges}
                onChange={onDateChange}
            />
            <Button type="primary" onClick={onFilter}>
                筛选
            </Button>
            <Button {...isSameRange(7)} onClick={onRangeChange(7)}>
                最近 7 天
            </Button>
            <Button {...isSameRange(30)} onClick={onRangeChange(30)}>
                最近 30 天
            </Button>

            {children}
        </Space>
    );
}

export default DateRange;
