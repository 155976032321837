import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import warning from 'warning';

import MasterPanel from './MasterPanel';
import SlavePanel from './SlavePanel';

import styles from './MasterSlavePanel.module.less';

/**
 * 纵向主从布局面板。
 *
 * 一般用于主面板列表，从面板展示的布局需要。
 *
 * 从面板支持 close
 *
 * @param className
 * @param style
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function MasterSlavePanel({className, style, children}) {
    if (process.env.NODE_ENV !== 'production') {
        warning(
            React.Children.count(children) <= 2,
            'MasterSlavePanel children count must less than 2'
        );

        React.Children.forEach(children, child => {
            warning(
                child.type.prototype === MasterPanel.prototype ||
                    child.type.prototype === SlavePanel.prototype,
                'MasterSlavePanel children only support MasterPanel and SlavePanel'
            );
        });
    }

    return (
        <div className={classNames(styles.container, className)} style={style}>
            {children}
        </div>
    );
}

MasterSlavePanel.MasterPanel = MasterPanel;
MasterSlavePanel.SlavePanel = SlavePanel;

MasterSlavePanel.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
