import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'antd';

import {AppstoreOutlined, BarsOutlined} from '@/icons';

export default function SwitchButtons({visible = true, initialGrid = true, onSwitch}) {
    const [isGrid, setGrid] = useState(initialGrid);

    if (!visible) {
        return null;
    }

    function onGridClick() {
        setGrid(true);
        onSwitch && onSwitch(true);
    }

    function onListClick() {
        setGrid(false);
        onSwitch && onSwitch(false);
    }

    return (
        <Button.Group>
            <Button
                icon={<AppstoreOutlined />}
                type={isGrid ? 'primary' : 'default'}
                onClick={onGridClick}
            />
            <Button
                icon={<BarsOutlined />}
                type={!isGrid ? 'primary' : 'default'}
                onClick={onListClick}
            />
        </Button.Group>
    );
}

SwitchButtons.propTypes = {
    visible: PropTypes.bool,

    // 初始是否九宫格
    initialGrid: PropTypes.bool,

    onSwitch: PropTypes.func,
};
