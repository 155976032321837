import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Button, message, Modal, Tag} from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';

import PlusButton from '@/page/app/component/MessageContent/content/comp/PlusButton';
import AppUpload from '@/page/component/AppUpload';
import SyncContactErrTable from './SyncContactErrTable';
import SyncContactLeaveTable from './SyncContactLeaveTable';

import {dealLeaveContact} from '@/api/contact';
import {API_ROOT} from '@/env';

const SyncContactToDBModal = forwardRef(({reloadUsers}, ref) => {
    const [visible, setVisible] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [errContacts, setErrContacts] = useState([]);
    const [leaveUsers, setLeaveUsers] = useState([]);
    const [selectLeaveUsers, setSelectLeaveUsers] = useState([]);

    /**
     * 打开 Modal
     *
     */
    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
        clearErrInfo();
    }

    function clearErrInfo() {
        setShowErr(false);
        setErrContacts([]);
        setLeaveUsers([]);
        setSelectLeaveUsers([]);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onUploadChange({file}) {
        if (file.status === 'done') {
            const {
                response: {data},
            } = file;
            console.log('onUploadChange', data);
            if (data) {
                const {success, errorList, leaveUsers} = data;
                if (success) {
                    message.success('同步到数据库成功');
                    closeModal();
                } else {
                    setShowErr(true);
                    setErrContacts(errorList);
                    setLeaveUsers(leaveUsers);
                }
                reloadUsers();
            }
        }
    }

    function onBackSelectSync() {
        clearErrInfo();
    }

    function onDealSelectLeaveUsers() {
        console.log('selectedRows', selectLeaveUsers);
        if (_.isEmpty(selectLeaveUsers)) {
            message.success('请选择用户进行离职处理');
            return;
        }
        dealLeaveContact(selectLeaveUsers).then(({data}) => {
            if (data.length) {
                message.success(`离职处理失败：${data.join(';')}`);
            } else {
                message.success('离职处理成功');
            }
            closeModal();
        });
    }

    function onSelectChange(selectedRows) {
        setSelectLeaveUsers(selectedRows);
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={showErr ? `同步结果` : '请上传Csv进行同步'}
            width={showErr ? 1000 : 800}
            onCancel={closeModal}
            okButtonProps={{
                style: {display: 'none'},
            }}
        >
            <>
                {showErr ? (
                    <>
                        {!_.isEmpty(errContacts) && (
                            <div style={{marginTop: 10}}>
                                <Tag color="red" style={{marginBottom: 10}}>
                                    下列csv同步到企业微信失败，请联系管理员进行处理
                                </Tag>
                                <SyncContactErrTable contacts={errContacts} />
                            </div>
                        )}
                        {!_.isEmpty(leaveUsers) && (
                            <div style={{marginTop: 20}}>
                                <Tag color="orange" style={{marginBottom: 10}}>
                                    检测到下列用户不在csv中，勾选后点击确定可以进行离职处理
                                </Tag>
                                <SyncContactLeaveTable
                                    users={leaveUsers}
                                    onSelect={onSelectChange}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                            }}
                        >
                            <Button style={{marginTop: 10}} onClick={onBackSelectSync}>
                                上一步
                            </Button>
                            {!_.isEmpty(leaveUsers) && (
                                <Button
                                    style={{marginTop: 10, marginLeft: 10}}
                                    onClick={onDealSelectLeaveUsers}
                                    danger
                                >
                                    置为离职状态
                                </Button>
                            )}
                        </div>
                    </>
                ) : (
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <AppUpload
                            action={`${API_ROOT}/contact-sync`}
                            category="media"
                            accept=".csv"
                            maxCount={1}
                            listType="picture"
                            onChange={onUploadChange}
                        >
                            <PlusButton title="添加Csv" />
                        </AppUpload>
                    </div>
                )}
            </>
        </Modal>
    );
});

SyncContactToDBModal.displayName = 'SyncContactToDBModal';

SyncContactToDBModal.propTypes = {
    reloadUsers: PropTypes.func,
};
export default SyncContactToDBModal;
