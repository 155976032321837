import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Radio, Tag} from 'antd';
import classNames from 'classnames';
import _ from 'lodash';

import {useAppStore} from '@/page/app/context';
import {CheckOutlined, CloseOutlined} from '@/icons';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';
import {DepartTag, TagGroup, TagTag, UserTag} from '@/component';
import {convertTos, isEmptySelData} from './utils';
import {getMessageViewerCount} from '@/api/message';
import CustomSendModal from './custom-send/CustomSendModal';

import Item from './Item';

import styles from './SendArea.module.less';

const SendArea = forwardRef(({appId, batchSendEmailOrMobile = false}, ref) => {
    const selectorRef = useRef();
    const customSendRef = useRef();

    const [toAll, setToAll] = useState(true);
    // 1: 发送所有人、2：范围发送、3:自定义发送
    const [radioValue, setRadioValue] = useState(1);

    // 格式为 {departs: [{id, name}], users: [{id, name}], tags: [{id, name}]}
    const [selData, setSelData] = useState({});
    const [viewerCount, setViewerCount] = useState(0);

    const [appStore] = useAppStore();
    const app = _.get(appStore, 'app', {});
    const allowDepartIds = _.get(app, 'allowDeparts', []).map(({allowId}) => allowId);
    const allowUsers = _.get(app, 'allowUsers', []);
    // console.log('SendArea', appStore, allowUsers, allowDepartIds);

    useEffect(() => {
        const tos = convertTos(selData);
        getMessageViewerCount(appId, toAll, tos).then(({data}) => {
            // console.log(data);
            setViewerCount(data ?? 0);
        });
    }, [appId, selData, toAll]);

    useImperativeHandle(ref, () => ({
        getData: () => {
            if (toAll) {
                return {toAll, tos: {}, toUserCount: viewerCount};
            } else {
                return {toAll, tos: selData, toUserCount: viewerCount};
            }
        },
    }));

    function onRadioChange(e) {
        const value = e.target.value;
        console.log('onRadioChange', value);
        setRadioValue(value);
        setToAll(value === 1);
    }

    function onSelectClick() {
        if (radioValue === 2) {
            selectorRef.current.showModal({initialValues: selData});
        } else if (radioValue === 3) {
            customSendRef.current.showModal();
        }
    }

    function onSelectClearClick() {
        setSelData({});
    }

    function onSelect(values) {
        console.log('onSelect', values);
        setSelData(values);
        return true;
    }

    function onTagClose(type, closeId) {
        return () => {
            const items = selData[type];
            const newItems = items.filter(({id}) => id !== closeId);
            setSelData({...selData, [type]: newItems});
        };
    }

    function renderSelItems(title, type, Comp, items) {
        if (_.isEmpty(items)) {
            return null;
        }

        function renderTagItem(item) {
            const {id, name} = item;

            return (
                <Comp key={id} closable onClose={onTagClose(type, id)}>
                    {name}
                </Comp>
            );
        }

        return (
            <Item title={title} className={styles.selectedItems}>
                <TagGroup renderItem={renderTagItem} data={items} />
            </Item>
        );
    }

    const {departs, users, tags} = selData || {};

    return (
        <Item title="发送范围" className={styles.container}>
            <div className={styles.selLine}>
                <div style={{flex: 1}}>
                    <Radio.Group value={radioValue} onChange={onRadioChange}>
                        <Radio value={1}>所有人</Radio>
                        <Radio value={2}>范围发送</Radio>
                        {batchSendEmailOrMobile && <Radio value={3}>自定义发送</Radio>}
                    </Radio.Group>

                    <Button
                        type="link"
                        icon={<CheckOutlined />}
                        disabled={toAll}
                        className={styles.selectedBtn}
                        onClick={onSelectClick}
                    >
                        选择发送范围
                    </Button>

                    {!toAll && !isEmptySelData(selData) && (
                        <Button
                            type="text"
                            style={{color: '#8c8c8c'}}
                            icon={<CloseOutlined />}
                            className={styles.selectedBtn}
                            onClick={onSelectClearClick}
                        >
                            清除所有已选
                        </Button>
                    )}
                </div>
                <div>
                    <Tag>
                        共 <strong>{viewerCount}</strong> 人
                    </Tag>
                </div>
            </div>
            {!isEmptySelData(selData) && (
                <div
                    className={classNames({[styles.hidden]: toAll, [styles.selectedArea]: !toAll})}
                >
                    {renderSelItems('部门', 'departs', DepartTag, departs)}
                    {renderSelItems('成员', 'users', UserTag, users)}
                    {renderSelItems('标签', 'tags', TagTag, tags)}
                </div>
            )}

            <DepartUserTagSelector
                ref={selectorRef}
                modalTitle="选择发送范围"
                allowDepartIds={allowDepartIds}
                allowUsers={allowUsers}
                onSelect={onSelect}
            />

            <CustomSendModal ref={customSendRef} appId={appId} onSelect={onSelect} />
        </Item>
    );
});

SendArea.displayName = 'SendArea';

SendArea.propTypes = {
    appId: PropTypes.string.isRequired,

    /**
     * 是否通过 email 或手机号批量发送消息
     *
     * 此值读取自 sys_setting 的 appCustom category
     */
    batchSendEmailOrMobile: PropTypes.bool,
};

export default SendArea;
