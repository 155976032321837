import _ from 'lodash';

export const TOP_PARENT_ID = '0';

export function isTopNode(parentId) {
    return parentId === TOP_PARENT_ID;
}

/**
 * 将后台的部门列表数据转换为 antd tree 支持的格式。
 *
 * 转换时将部门数据存入节点的 「depart」中，将一些扩展信息存入 extraAttrs 中，后者包括
 * hasPrev, hasNext, prevId, nextId 等属性，方便同级上下移动。
 *
 * @param departs 部门列表
 * @param nameCallback 名称转换方法
 * @return {*[]|*}
 */
export function convertDeparts2TreeFormat(departs, nameCallback) {
    if (_.isEmpty(departs)) {
        return [];
    }

    function recursiveConvert(nodes, callback) {
        return nodes.map((node, index, arr) => {
            // console.log(node, index, arr);
            const {id, name, children} = node;

            const hasPrev = arr.length > 1 && index > 0;
            const prevId = hasPrev ? arr[index - 1]['id'] : null;
            const hasNext = arr.length > 1 && index < arr.length - 1;
            const nextId = hasNext ? arr[index + 1]['id'] : null;
            const extraAttrs = {
                hasPrev,
                hasNext,
                levelPos: index,
                prevId,
                nextId,
            };

            const title = callback ? callback(node) : name;

            if (children && children.length > 0) {
                return {
                    key: id,
                    title,
                    depart: node,
                    extraAttrs,
                    children: recursiveConvert(children, callback),
                };
            }

            return {
                key: id,
                title,
                depart: node,
                extraAttrs,
            };
        });
    }

    return recursiveConvert(departs, nameCallback);
}

const loop = (data, key, callback) => {
    for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
            return callback(data[i], i, data);
        }

        if (data[i].children) {
            loop(data[i].children, key, callback);
        }
    }
};

/**
 * 返回 matchFn 条件匹配的节点的父节点。
 *
 * @param treeNodes 树结构
 * @param matchFn 匹配模式
 * @param ID_KEY
 * @param CHILDREN_KEY
 * @return {null|*} 父节点，如果空值表示顶级节点
 */
function getParentNode(treeNodes, matchFn, {ID_KEY = 'id', CHILDREN_KEY = 'children'} = {}) {
    if (!treeNodes) return null;

    if (!Array.isArray(treeNodes) || !_.isFunction(matchFn)) {
        throw new Error('arguments is not correct');
    }

    const levelIds = {};
    let hit = false;
    let level = -1;

    const parentNodes = {};

    function travelTree(nodes, lv) {
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];

            if (!hit) {
                levelIds[lv] = {
                    [ID_KEY]: node[ID_KEY],
                };
                parentNodes[node[ID_KEY]] = node;
            }

            if (!hit && matchFn(node)) {
                level = lv;
                hit = true;
            }

            if (!hit && Array.isArray(node[CHILDREN_KEY]) && node[CHILDREN_KEY].length > 0) {
                travelTree(node[CHILDREN_KEY], lv + 1);
            }
        }
    }

    travelTree(treeNodes, 0);

    // 将取得的对象转换为 id 数组
    const paths = Object.keys(levelIds)
        .filter(key => key <= level)
        .map(key => levelIds[key][ID_KEY]);

    const parentId = paths[paths.length - 2];
    return parentNodes[parentId];
}

export function onTreeDrop(info, data) {
    const dropKey = info.node.props.eventKey;
    const dragKey = info.dragNode.props.eventKey;
    const dropPos = info.node.props.pos.split('-');
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1);
        dragObj = item;
    });

    // console.log('dragObj', dragObj);

    if (!info.dropToGap) {
        // Drop on the content
        loop(data, dropKey, item => {
            item.children = item.children || [];
            // where to insert 示例添加到头部，可以是随意位置
            item.children.unshift(dragObj);
        });
    } else if (
        (info.node.props.children || []).length > 0 && // Has children
        info.node.props.expanded && // Is expanded
        dropPosition === 1 // On the bottom gap
    ) {
        loop(data, dropKey, item => {
            item.children = item.children || [];
            // where to insert 示例添加到头部，可以是随意位置
            item.children.unshift(dragObj);

            // in previous version, we use item.children.push(dragObj) to insert the
            // item to the tail of the children
        });
    } else {
        let ar;
        let i;
        loop(data, dropKey, (item, index, arr) => {
            ar = arr;
            i = index;
        });
        if (dropPosition === -1) {
            ar.splice(i, 0, dragObj);
        } else {
            ar.splice(i + 1, 0, dragObj);
        }
    }

    // console.log('result', dragObj);
    // 返回被拖动节点的同级节点，用于排序
    let items;
    loop(data, dragKey, (item, indexed, arr, par) => {
        // console.log('item, indexed, arr', item, indexed, arr);
        // console.log('parent', par);
        items = arr;
    });

    // 返回被拖动节点的父节点，如果为空表示顶级
    const parentNode = getParentNode(data, node => {
        return node.key === dragKey;
    });
    // console.log('paths', parentNode);

    return {parentNode, items};
}

/**
 * 遍历树形列表。
 *
 * @param nodes 树形列表
 * @param nodeFn 节点操作方法，参数为节点内容，返回 false 表示继续遍历，返回 true 表示此节点不再往下遍历
 * @param ID id
 * @param CHILDREN children
 */
export function travelAndOptTree(
    nodes = [],
    nodeFn = () => false,
    {ID = 'id', CHILDREN = 'children'} = {}
) {
    if (!nodes || nodes.length === 0) {
        return;
    }

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        if (nodeFn(node)) {
            continue;
        }

        const children = node[CHILDREN];
        if (Array.isArray(children) && children.length > 0) {
            travelAndOptTree(children, nodeFn, {ID, CHILDREN});
        }
    }
}
