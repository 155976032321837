import React from 'react';
import {useParams} from 'react-router-dom';
import _ from 'lodash';

import MenuTable from './MenuTable';
import MenuModal from './MenuModal';
import useTitle from '@/hook/useTitle';
import AppContent from '@/page/component/AppLayout/AppContent';
import {useMenus} from '@/page/app/AppMenusPage/useMenusService';

export default function AppMenusPage() {
    useTitle('菜单管理');

    const {appId} = useParams();

    const {loading, value, retry} = useMenus(appId);
    const menus = _.get(value, 'data');

    console.log(menus);

    return (
        <AppContent style={{display: 'flex', padding: '24px', flex: 1}}>
            <div style={{flex: 1}}>
                <MenuTable appId={appId} loading={loading} menus={menus} reload={retry} />
            </div>
            <div style={{flex: 1, display: 'flex'}}>
                <MenuModal menus={menus} />
            </div>
        </AppContent>
    );
}
