import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, message, Upload} from 'antd';
import {previewImage} from 'antd/es/upload/utils';
import _ from 'lodash';
import filesize from 'filesize';

import {UploadOutlined} from '@/icons';
import PreviewModal from '@/page/component/UploadWallModal/PreviewModal';
import AppUpload from '../../AppUpload';

// 附件最大不能超过 100M
const MAX_FILE_SIZE = 104857600;

const AttachsUpload = ({currentTmpId, attaches, onAttachChange}) => {
    const previewModalRef = useRef();

    // console.log('attaches', attaches);

    const [list, setList] = useState([]);

    useEffect(() => {
        if (_.isEmpty(attaches)) {
            setList([]);
            return;
        }

        const fileList = attaches.map(
            ({id, fileName, image, thumbUrl, contentType, urls: {file}}) => ({
                uid: id,
                name: fileName,
                status: 'done',
                url: file,
                isImage: image,
                thumbUrl: image ? thumbUrl || file : '',
                type: contentType,
            })
        );

        setList(fileList);
    }, [attaches]);

    function onPreview(file) {
        console.log(file);
        const {isImage, url} = file;
        if (isImage) {
            previewModalRef.current.showModal(url);
        }
    }

    function onRemove(file) {
        const {uid} = file;

        const newAttaches = attaches.filter(attach => attach.id !== uid);
        onAttachChange && onAttachChange(currentTmpId, newAttaches);
        return true;
        /*
        return deleteAttach(uid)
            .then(() => {
                onAttachChange && onAttachChange(currentTmpId, newAttaches);
                return true;
            })
            .catch(() => {
                return false;
            });*/
    }

    function onChange({file, fileList}) {
        // console.log(file, fileList);
        // console.log('file.status', file.status);

        setList(fileList);

        if (file.status === 'done') {
            const {
                response: {data},
            } = file;

            // console.log('attach=======', data);

            previewImage(file.originFileObj).then(thumbUrl => {
                // console.log('thumbUrl', thumbUrl);
                const attach = data[0];
                attach.thumbUrl = thumbUrl;

                onAttachChange && onAttachChange(currentTmpId, [...attaches, attach]);
            });
        }
    }

    function beforeUpload(file) {
        // console.log('beforeUpload', file);
        const {size} = file;
        if (size > MAX_FILE_SIZE) {
            message.warn(`文件大小不能超过 ${filesize(MAX_FILE_SIZE)}`);
            return Upload.LIST_IGNORE;
        }

        return true;
    }

    return (
        <>
            <AppUpload
                category="news"
                listType="picture"
                fileList={list}
                beforeUpload={beforeUpload}
                onPreview={onPreview}
                onChange={onChange}
                onRemove={onRemove}
            >
                <Button icon={<UploadOutlined />}>上传附件</Button>
            </AppUpload>
            <PreviewModal ref={previewModalRef} />
        </>
    );
};

AttachsUpload.propTypes = {
    currentTmpId: PropTypes.string.isRequired,
    attaches: PropTypes.array,
    onAttachChange: PropTypes.func.isRequired,
};

export default AttachsUpload;
