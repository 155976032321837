import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Descriptions, Divider, message, Spin, Tooltip} from 'antd';
import _ from 'lodash';

import {EditOutlined, InfoCircleOutlined} from '@/icons';
import {EditTag} from '@/component';
import DepartUserTagSelector from '@/page/admin/selector/DepartUserTagSelector';
import {updateAppRoles} from '@/api/apps';
import UserClickableTags from '@/page/admin/DepartsPage/UserClickableTags';

import styles from 'page/admin/AppsPage/AppInfo.module.less';

export default function AppRoleView({appId, roleEditable = false, appRoles, reload}) {
    const dutSelectorRef = useRef();

    const [appRoleType, setAppRoleType] = useState(null);

    function getRoles(type) {
        const roles = _.get(appRoles, `${type}`);
        if (!roles) {
            return [];
        }

        return roles.map(({id, userId, userName}) => ({id: userId, name: userName, appRoleId: id}));
    }

    const mgrRoles = getRoles('app_mgr');
    const userRoles = getRoles('app_user');

    // console.log('{loading, value}', {loading, value});

    function onAllowUserClick(roleType, selectedUsers) {
        return () => {
            console.log(roleType, selectedUsers, appId);
            setAppRoleType(roleType);

            let isAppMgr = roleType === 'app_mgr';
            const modalTitle = isAppMgr ? '选择应用管理员' : '选择消息发送人';

            const initialValues = {users: isAppMgr ? mgrRoles : userRoles};
            dutSelectorRef.current.showModal({modalTitle, initialValues});
        };
    }

    function onSelect(values) {
        // console.log('onSelect', values);
        const users = _.get(values, 'users', []);
        if (appRoleType === 'app_mgr' && _.isEmpty(users)) {
            message.warn('未选择任何用户！');
            return false;
        }

        const appRoles = users.map(({id}) => ({
            appId,
            appRoleType,
            userId: id,
        }));
        // console.log(appRoles);
        return updateAppRoles(appId, appRoleType, appRoles).then(data => {
            // console.log('updateAppRoles', data);
            if (data.status === 200) {
                reload();
                return true;
            } else {
                return false;
            }
        });
    }

    return (
        <>
            <Divider orientation="left">应用角色</Divider>
            <Descriptions column={1} className={styles.descriptions}>
                <Descriptions.Item
                    label={
                        <>
                            应用管理员
                            <Tooltip title="拥有「消息发送人」的一切权限">
                                <Button type="text" icon={<InfoCircleOutlined />} danger />
                            </Tooltip>
                        </>
                    }
                >
                    <div className={styles.tags}>
                        <EditTag
                            showTag={roleEditable}
                            icon={<EditOutlined />}
                            className={styles.tag}
                            onClick={onAllowUserClick('app_mgr', mgrRoles)}
                        >
                            编辑
                        </EditTag>
                        <UserClickableTags users={mgrRoles} tagProps={{className: styles.tag}} />
                    </div>
                </Descriptions.Item>
                <Descriptions.Item label="消息发送人">
                    <div className={styles.tags}>
                        <EditTag
                            showTag={roleEditable}
                            icon={<EditOutlined />}
                            className={styles.tag}
                            onClick={onAllowUserClick('app_user', userRoles)}
                        >
                            编辑
                        </EditTag>
                        <UserClickableTags users={userRoles} tagProps={{className: styles.tag}} />
                    </div>
                </Descriptions.Item>
            </Descriptions>

            <DepartUserTagSelector
                ref={dutSelectorRef}
                showDepartTab={false}
                showTagTab={false}
                onSelect={onSelect}
            />
        </>
    );
}

AppRoleView.propTypes = {
    appId: PropTypes.string.isRequired,
    roleEditable: PropTypes.bool,

    appRoles: PropTypes.shape({
        app_mgr: PropTypes.array,
        app_user: PropTypes.array,
    }),
    reload: PropTypes.func.isRequired,
};
