import {getFileDownloadUrl, getFileUrl} from '@/page/util/appUrlUtils';

export {getFileUrl, getFileDownloadUrl};

// export const getFileUrl = (attachId, attachExt) => {
//     return `${API_ROOT}/attach/files/${attachId}.${attachExt}`;
// };
//
// export const getFileDownloadUrl = attachId => {
//     return `${API_ROOT}/attach/download/${attachId}`;
// };
