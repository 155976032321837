import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Input, PageHeader, Space} from 'antd';

import {useMediaList} from '../mediaService';
import MediaTable from './MediaTable';
import MediaGrid from './MediaGrid';
import SwitchButtons from './SwitchButtons';
import {columnAction} from './ActionButtons';
import {mediaTypeProps} from '../propTypes';
import {useAppStore} from '@/page/app/context';

const MediaListView = forwardRef(
    (
        {
            editable = true,
            onItemChecked,
            type,
            columnKeys,
            columnOptions,
            columnActionProps,
            switchVisible = false,
            initialGrid = false,
            children,
        },
        ref
    ) => {
        const [{isMgr, userId}] = useAppStore();

        const [current, setCurrent] = useState(1);
        const [size, setSize] = useState(20);
        const [query, setQuery] = useState(null);
        const [grid, setGrid] = useState(initialGrid);

        const {appId} = useParams();
        const {mediaRecords, total, loading, retry} = useMediaList(
            appId,
            type.key,
            query,
            current,
            size
        );

        /**
         * 当处于第一页的时候，则刷新。
         */
        function reload(everyPage = false) {
            if (everyPage) {
                retry();
            } else {
                if (current === 1) {
                    retry();
                }
            }
        }

        useImperativeHandle(ref, () => ({
            reload,
        }));

        function onShowSizeChange(current, pageSize) {
            setCurrent(current);
            setSize(pageSize);
        }

        function onChange(pageNumber) {
            setCurrent(pageNumber);
        }

        function showTotal(total) {
            return `共 ${total} 条记录`;
        }

        function onSearch(value) {
            // console.log(value);
            setQuery(value);
        }

        const pagination = {
            position: ['bottomRight'],
            current,
            pageSize: size,
            total,
            simple: false,
            showSizeChanger: true,
            onShowSizeChange: onShowSizeChange,
            onChange,
            showTotal,
        };

        function onSwitch(isGrid) {
            setGrid(isGrid);
        }

        const extra = (
            <Space>
                <SwitchButtons
                    visible={switchVisible}
                    initialGrid={initialGrid}
                    onSwitch={onSwitch}
                />
                <Input.Search placeholder={type.searchPlaceholder} allowClear onSearch={onSearch} />
                {children}
            </Space>
        );

        const action = columnAction({reload, editable, isMgr, userId, ...columnActionProps});

        return (
            <PageHeader title={onItemChecked ? null : type.label} extra={extra}>
                {grid ? (
                    <MediaGrid
                        editable={editable}
                        type={type}
                        onItemChecked={onItemChecked}
                        mediaRecords={mediaRecords}
                        loading={loading}
                        reload={reload}
                        pagination={pagination}
                        columnOptions={columnOptions}
                        columnActionProps={columnActionProps}
                    />
                ) : (
                    <MediaTable
                        editable={editable}
                        onItemChecked={onItemChecked}
                        rowKey="id"
                        loading={loading}
                        mediaRecords={mediaRecords}
                        columnKeys={columnKeys}
                        columnOptions={columnOptions}
                        columnActionProps={columnActionProps}
                        columnAction={action}
                        pagination={pagination}
                    />
                )}
            </PageHeader>
        );
    }
);

MediaListView.displayName = 'MediaListView';

MediaListView.propTypes = {
    ...mediaTypeProps,
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
    query: PropTypes.string,
    columnKeys: PropTypes.array,
    columnOptions: PropTypes.object,
    columnActionProps: PropTypes.object,
    switchVisible: PropTypes.bool,
    initialGrid: PropTypes.bool,
    children: PropTypes.node,
};

export default MediaListView;
