import React from 'react';
import PropTypes from 'prop-types';

import NewsCommentList from './NewsCommentList';

export default function NewsComment({appId, app, userId, userName, news}) {
    const {newsCommentType} = news;
    // console.log('newsCommentType', newsCommentType);

    // 关闭评论
    if (newsCommentType === 1) {
        return null;
    }

    // console.log('userId', userId);
    // 允许留言
    const enableComment = newsCommentType === 0 && !!userId;

    return (
        <NewsCommentList
            enableComment={enableComment}
            appId={appId}
            app={app}
            userId={userId}
            userName={userName}
            newsId={news.id}
        />
    );
}

NewsComment.propTypes = {
    appId: PropTypes.string,
    app: PropTypes.object,
    userId: PropTypes.string,
    userName: PropTypes.string,
    news: PropTypes.object,
};
