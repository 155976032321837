/*
 * Created by liyi on 2021-02-01 17:44.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './MenuModal.module.less';

export default function MenuModal({menus}) {
    console.log('menus', menus);

    function renderMultiMenu() {
        return (
            <div className={classNames(styles.content)}>
                <div className={classNames(styles.header)} />
                <div className={classNames(styles.footer)}>
                    {menus &&
                        menus.map((menu, index) => {
                            return (
                                <div key={index} className={classNames(styles.itemWrap)}>
                                    {menu.children && (
                                        <div className={classNames(styles.subWrap)}>
                                            {menu.children.map((subMenu, subIndex) => {
                                                return (
                                                    <div
                                                        key={subIndex}
                                                        className={classNames(styles.subItem)}
                                                    >
                                                        {subMenu.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                    <div className={classNames(styles.item)}>{menu.name}</div>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }

    return <div className={classNames(styles.container)}>{renderMultiMenu()}</div>;
}

MenuModal.propTypes = {
    menus: PropTypes.array,
};
