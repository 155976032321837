import React from 'react';
import PropTypes from 'prop-types';
import {Avatar as AntdAvatar} from 'antd';

import {UserOutlined} from '@/icons';
import styles from './Avatar.module.less';

export default function Avatar({src, text, size}) {
    if (src) {
        return <AntdAvatar src={src} size={size} />;
    }

    if (text) {
        return (
            <AntdAvatar size={size} className={styles.text}>
                {text.charAt(0)}
            </AntdAvatar>
        );
    }

    return <AntdAvatar icon={<UserOutlined />} size={size} />;
}

Avatar.propTypes = {
    src: PropTypes.string,
    text: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Avatar.defaultProps = {
    size: 'default',
};
