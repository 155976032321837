import React from 'react';

import {TwoColumnLayout} from '@/component';
import LogsList from './LogsList';

import useTitle from '@/hook/useTitle';

export default function LogsPage() {
    useTitle('系统日志 - 系统管理');

    return (
        <TwoColumnLayout>
            <TwoColumnLayout.ContentPanel>
                <LogsList />
            </TwoColumnLayout.ContentPanel>
        </TwoColumnLayout>
    );
}
