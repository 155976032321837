import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Descriptions} from 'antd';
import _ from 'lodash';

import {BoolView} from '@/component';
import UserTags from './UserTags';
import UserDeparts from './UserDeparts';

const {Item} = Descriptions;

export default function UserView({user = {}, column = 2, ...rest}) {
    if (_.isEmpty(user)) {
        return null;
    }

    return (
        <Descriptions column={column} bordered {...rest}>
            <Item label="姓名">{user.name}</Item>
            <Item label="别名">{user.alias}</Item>
            <Item label="职务">{user.position}</Item>
            <Item label="性别">{user.gender === 1 ? '男' : '女'}</Item>
            <Item label="Email">{user.email}</Item>
            <Item label="手机号">{user.mobile}</Item>
            <Item label="电话" span={column}>
                {user.telephone}
            </Item>
            <Item label="地址" span={column}>
                {user.address}
            </Item>
            <Item label="在职状态">
                <BoolView value={user.onjob} trueText="在职" falseText="离职" />
            </Item>
            <Item label="账号状态">
                <BoolView value={user.enabled} trueText="启用" falseText="禁用" />
            </Item>
            <Item label="企业微信用户 ID" span={column}>
                {user.enableSync ? (
                    user.extId ? (
                        user.extId
                    ) : (
                        user.id
                    )
                ) : (
                    <div>
                        <Badge status="error" />
                        不同步到企业微信
                    </div>
                )}
            </Item>
            <Item label="所属部门" span={column}>
                <UserDeparts userId={user.id} />
            </Item>
            <Item label="所属标签" span={column} labelStyle={{width: 150}}>
                <UserTags userId={user.id} />
            </Item>
        </Descriptions>
    );
}

UserView.propTypes = {
    user: PropTypes.object,
    column: PropTypes.number,
};
