import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Empty, List} from 'antd';
import _ from 'lodash';

import AppItem from './AppItem';

const AppList = ({appList, onAppSelect}) => {
    const [selAppId, setSelAppId] = useState(null);

    function onSelect(app) {
        setSelAppId(app.id);
        onAppSelect(app);
    }

    if (_.isEmpty(appList)) {
        return <Empty style={{marginTop: 100}} />;
    }

    return (
        <List
            dataSource={appList}
            renderItem={item => <AppItem app={item} selAppId={selAppId} onSelect={onSelect} />}
        />
    );
};

AppList.propTypes = {
    appList: PropTypes.array,
    onAppSelect: PropTypes.func.isRequired,
};

export default AppList;
