import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import _ from 'lodash';
import {Spin} from 'antd';
import {useAsyncRetry} from 'react-use';

import CommentItem from './CommentItem';
import ReplyCommentForm from './ReplyCommentForm';

import {getComments} from '@/api/comment';

export default function PreviewCommentList({itemId, highlightCommentId}) {
    const replyModalRef = useRef();
    const {appId} = useParams();

    const {loading, value, retry} = useAsyncRetry(() => {
        return getComments(appId, itemId);
    }, [itemId]);

    function onReplyClick(commentId) {
        replyModalRef.current.showModal(commentId);
    }

    const comments = _.get(value, 'data', []);

    return (
        <>
            <Spin spinning={loading}>
                {comments.map(comment => {
                    return (
                        <CommentItem
                            key={comment.id}
                            comment={comment}
                            highlightCommentId={highlightCommentId}
                            reload={retry}
                            onReplyClick={onReplyClick}
                            showReply
                        >
                            {!_.isEmpty(comment.children) &&
                                comment.children.map(item => (
                                    <CommentItem
                                        key={item.id}
                                        comment={item}
                                        highlightCommentId={highlightCommentId}
                                        reload={retry}
                                        isChild
                                    />
                                ))}
                        </CommentItem>
                    );
                })}
            </Spin>
            <ReplyCommentForm ref={replyModalRef} reload={retry} />
        </>
    );
}

PreviewCommentList.propTypes = {
    itemId: PropTypes.string.isRequired,
    highlightCommentId: PropTypes.string,
};
