import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Spin} from 'antd';
import ImgCrop from 'antd-img-crop';
import 'antd/es/slider/style';

import {PlusOutlined} from '@/icons';

import PreviewModal from '../../UploadWallModal/PreviewModal';

import AppUpload from '../../AppUpload';
import {getFileUrl} from '@/page/component/attachUtils';

import styles from './NewsThumbEditor.module.less';

export default function NewsThumbEditor({news, onThumbChange}) {
    const [loading, setLoading] = useState(false);

    const {thumbAttachId, thumbAttachExt, thumbUrl} = news;

    const previewRef = useRef();

    function beforeUpload() {
        setLoading(true);
        return true;
    }

    function onUploadChange({file}) {
        // console.log('onUploadChange', file);
        if (file.status === 'done') {
            const {
                response: {data},
            } = file;
            console.log('thumb onUploadChange', file);

            const {id, fileExt} = data[0];
            onThumbChange(id, fileExt);
            setLoading(false);
            // previewImage(file.originFileObj).then(thumbUrl => {
            // });
        }
    }

    function getImgUrl(thumb = true) {
        if (thumb && thumbUrl) {
            return thumbUrl;
        }

        if (thumbAttachId) {
            return getFileUrl(thumbAttachId, thumbAttachExt);
        }

        return null;
    }

    const imgUrl = getImgUrl();

    return (
        <div className={styles.container}>
            <Spin spinning={loading}>
                <div className={styles.converPlaceholder}>
                    {imgUrl && (
                        <img
                            className={styles.thumbImg}
                            src={imgUrl}
                            alt="封面缩略图"
                            onClick={() => previewRef.current.showModal(getImgUrl(false))}
                        />
                    )}
                </div>
            </Spin>
            <ImgCrop
                modalTitle="封面图编辑"
                modalOk="确定"
                modalCancel="取消"
                aspect={1068 / 455}
                quality={0.9}
            >
                <AppUpload
                    category="news"
                    maxImgWidth={1068}
                    maxImgHeight={455}
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={onUploadChange}
                >
                    <Button type="link" icon={<PlusOutlined />}>
                        {!thumbAttachId ? '添加' : '修改'}封面图
                    </Button>
                </AppUpload>
            </ImgCrop>

            <PreviewModal ref={previewRef} />
        </div>
    );
}

NewsThumbEditor.propTypes = {
    news: PropTypes.shape({
        thumbAttachId: PropTypes.string,
        thumbAttachExt: PropTypes.string,
        thumbUrl: PropTypes.string,
    }).isRequired,
    onThumbChange: PropTypes.func.isRequired,
};
