import React, {useEffect, useRef, useState} from 'react';
import qs from 'qs';
import {useAsyncRetry} from 'react-use';

import {Alert, Button, Form, Input} from 'antd';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {useHistory, useLocation} from 'react-router-dom';
import useTitle from '@/hook/useTitle';
import LoginContainer from './LoginContainer';
import CaptchaFormItem from './CaptchaFormItem';
import {passReset, passResetCheck} from '@/api/auth';
import {PasswordFormItem} from '@/component';
import {useAuthStore} from '@/context/auth';

const PasswordResetPage = () => {
    useTitle('重置密码');
    const formRef = useRef();
    const history = useHistory();
    const {search} = useLocation();
    const [errMsg, setErrMsg] = useState(null);
    const {email, code} = qs.parse(search, {ignoreQueryPrefix: true});
    const {error} = useAsyncRetry(async () => {
        if (email && code) {
            return passResetCheck(email, code);
        }
        return {};
    }, []);

    const [captchaKey, setCaptchaKey] = useState(null);

    const [authStore] = useAuthStore();
    console.log('authStore', authStore);

    // 系统管理员密码最低位数为15，其他人员为8位
    const isAdmin = authStore?.hasAuthorities('global:admin:r', 'r')
        || authStore?.hasAuthorities('global:admin:r', 'w')
        || false;

    useEffect(() => {
        if (!code || !email) {
            setErrMsg('当前页面参数错误，请检查后重试');
        }
    }, [code, email]);

    useEffect(() => {
        if (error) {
            setErrMsg(error.message);
        }
    }, [error]);

    function onLoadCaptchaKey(captchaKey) {
        // console.log('captchaKey', captchaKey);
        setCaptchaKey(captchaKey);
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        passReset({...values, captchaKey})
            .then(() => {
                history.push('/login');
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    if (errMsg) {
        return (
            <Alert
                message="密码重置"
                description={errMsg}
                type="error"
                showIcon
                style={{margin: '100px auto', width: 500}}
            />
        );
    }

    return (
        <LoginContainer title="密码重置">
            <Form onFinish={onFinish} ref={formRef}>
                <Form.Item name="code" initialValue={code}>
                    <Input type="hidden" />
                </Form.Item>
                <Form.Item name="email" initialValue={email}>
                    <Input prefix={<UserOutlined />} placeholder="Email" size="large" disabled />
                </Form.Item>

                <PasswordFormItem
                    rules={[{required: true, message: '密码不能为空'}]}
                    label=""
                    min={isAdmin ? 15 : 8}
                    inputProps={{
                        autoFocus: true,
                        placeholder: '新密码',
                        autoComplete: 'new-password',
                        size: 'large',
                        prefix: <LockOutlined />,
                    }}
                />

                <CaptchaFormItem onLoadCaptchaKey={onLoadCaptchaKey} />

                <Form.Item>
                    <Button type="primary" htmlType="submit" size="large">
                        重置密码
                    </Button>
                </Form.Item>
            </Form>
        </LoginContainer>
    );
};

export default PasswordResetPage;
