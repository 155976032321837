const TOKEN_KEY = 'app_tokens';

const storage = localStorage;

/**
 * 返回 tokens {access_token: string, refresh_token: string}
 *
 * @return {Object}
 */
export function getTokens() {
    const data = storage.getItem(TOKEN_KEY);
    return JSON.parse(data);
}

/**
 * 保存 tokens
 *
 * @param {Object} tokens {access_token: string, refresh_token: string}
 */
export function setTokens(tokens) {
    storage.setItem(TOKEN_KEY, JSON.stringify(tokens));
}

/**
 * 清除 tokens
 */
export function cleanTokens() {
    storage.removeItem(TOKEN_KEY);
}
