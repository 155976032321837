import {useAsyncRetry} from 'react-use';

import {getSimpleNewsContents} from '@/api/media';

export function useNewsContentsService(mediaId) {
    return useAsyncRetry(async () => {
        if (!mediaId) {
            return {data: null};
        }
        return await getSimpleNewsContents(mediaId);
    }, [mediaId]);
}
