import React from 'react';
import {Tag} from 'antd';
import PropTypes from 'prop-types';

import {TagOutlined} from '@/icons';

export default function TagTag({children, ...rest}) {
    return (
        <Tag color="cyan" icon={<TagOutlined />} {...rest}>
            {children}
        </Tag>
    );
}

TagTag.propTypes = {
    children: PropTypes.node,
};
