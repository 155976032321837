import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, Modal} from 'antd';

import {addRole, updateRole} from '@/api/roles';
import {roleScopePropTypes} from './propTypes';

const RoleFormModal = forwardRef(({scope = 'global', scopeItemId, reloadRoles}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [role, setRole] = useState({});

    function showModal(role) {
        setVisible(true);
        setRole({scope, scopeItemId, ...role});
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function isAdd() {
        return !role.id;
    }

    function onOk() {
        formRef.current.submit();
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);
        const promise = isAdd() ? addRole(values) : updateRole(role.id, values);

        promise
            .then(() => {
                reloadRoles();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd() ? '添加角色' : '修改角色'}
            onCancel={closeModal}
            onOk={onOk}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical" initialValues={role}>
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>
                <Form.Item hidden name="scope">
                    <Input />
                </Form.Item>

                <Form.Item hidden name="scopeItemId">
                    <Input />
                </Form.Item>

                <Form.Item
                    name="name"
                    label="角色名称"
                    rules={[
                        {
                            required: true,
                            max: 30,
                        },
                    ]}
                >
                    <Input placeholder="角色名称" />
                </Form.Item>

                <Form.Item
                    name="memo"
                    label="角色说明"
                    rules={[
                        {
                            max: 200,
                        },
                    ]}
                >
                    <Input placeholder="角色说明" />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

RoleFormModal.displayName = 'RoleFormModal';

RoleFormModal.propTypes = {
    ...roleScopePropTypes,
    reloadRoles: PropTypes.func,
};

export default RoleFormModal;
