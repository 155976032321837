import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spin, Table} from 'antd';
import {useSumReports} from './service';

export default function RecentReportList({appId, alias, columns, onLoad}) {
    const {loading, value} = useSumReports(appId, alias);
    console.log('loading, value', loading, value);

    useEffect(() => {
        if (!loading) {
            onLoad && onLoad(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, value]);

    return (
        <Spin spinning={loading}>
            <Table
                size="small"
                dataSource={value}
                columns={columns}
                pagination={false}
                rowKey="id"
            />
        </Spin>
    );
}

RecentReportList.propTypes = {
    appId: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    onLoad: PropTypes.func,
};
