import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {message} from 'antd';

import {FullscreenModal} from '@/component';
import VideoForm from './VideoForm';
import VideoPreview from './VideoPreview';
import {isPromise} from '@/util/PromiseUtils';

import styles from '@/page/component/NewsFormModal/NewsFormModal.module.less';

/**
 * 视频上传组件，支持编辑标题、摘要等信息。
 *
 * 通过 onFormSuccess 返回视频内容。
 */
const VideoFormModal = forwardRef(({category = 'media', onFormSuccess}, ref) => {
    const [visible, setVisible] = useState(false);
    const [videoData, setVideoData] = useState(null);
    const [spinning, setSpinning] = useState(false);
    // console.log('VideoFormModal===========', videoData);

    function showModal(video) {
        setVisible(true);
        setVideoData(video);
    }

    function closeModal() {
        setVideoData(null);
        setSpinning(false);
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onVideoChange(values) {
        // console.log('onVideoChange', values);
        setVideoData(values);
    }

    function onOk() {
        // console.log('onOk..');
        // console.log(videoData);
        const {title, attachId} = videoData || {};
        if (!title || !title.trim()) {
            message.warn('标题不能为空');
            return;
        }

        if (!attachId) {
            message.warn('必须上传视频');
            return;
        }

        setSpinning(true);
        const result = onFormSuccess(videoData);
        if (isPromise(result)) {
            result.then(data => {
                if (data === true) {
                    closeModal();
                } else {
                    setSpinning(false);
                }
            });
        } else {
            if (result) {
                closeModal();
            } else {
                setSpinning(false);
            }
        }
    }

    return (
        <FullscreenModal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            onCancel={closeModal}
            onOk={onOk}
            loading={spinning}
        >
            <div className={styles.main}>
                <VideoForm category={category} videoData={videoData} onChange={onVideoChange} />
            </div>
            <div className={styles.preview}>
                <VideoPreview videoData={videoData} />
            </div>
        </FullscreenModal>
    );
});

VideoFormModal.displayName = 'VideoFormModal';

VideoFormModal.propTypes = {
    category: PropTypes.oneOf(['media', 'message']),
    onFormSuccess: PropTypes.func.isRequired,
};

export default VideoFormModal;
