import React from 'react';
import PropTypes from 'prop-types';
import {Avatar} from 'antd';
import classNames from 'classnames';

import AppPinButton from './AppPinButton';

import styles from './AppItem.module.less';

export default function AppItem({pined, imageUrl, title, big = false, className, onPinedClick}) {
    const container = classNames(styles.container, {[styles.bigContainer]: big}, className);

    return (
        <div className={container}>
            <AppPinButton
                pined={pined}
                big={big}
                className={styles.btnPin}
                onClick={onPinedClick}
            />
            <div className={styles.imageWrap}>
                <Avatar
                    shape="square"
                    size={big ? 96 : 64}
                    src={imageUrl}
                    style={{borderRadius: 8}}
                />
            </div>
            <div className={styles.title}>{title}</div>
        </div>
    );
}

AppItem.propTypes = {
    big: PropTypes.bool,
    pined: PropTypes.bool,
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
    onPinedClick: PropTypes.func,
};
