import React from 'react';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Result} from 'antd';

import useTitle from '@/hook/useTitle';
import AppLayout from '../component/AppLayout';
import AppLayoutHeader from '../component/AppLayout/AppLayoutHeader';
import AppList from './AppList';
import AppItem from './AppItem';
import {useMyApps} from './useHomeService';
import {useAuthStore} from '@/context/auth';
import {toggleUserAppPined} from '@/api/apps';

export default function HomePage() {
    useTitle();
    const [authStore] = useAuthStore();
    // console.log('authStore......', authStore);
    const currentUserId = _.get(authStore, 'user.id');
    const {loading, retry, value} = useMyApps(currentUserId);
    // console.log('myApps=======', value);

    // if (loading) {
    //     return <PageLoading />;
    // }

    const apps = _.get(value, 'data.apps', []);
    const meta = _.get(value, 'data.meta', {});
    // console.log('app, meta', apps, meta);

    const pinedAppIds = Object.values(meta)
        .filter(({pined}) => pined)
        .sort((a, b) => b.modifyTime - a.modifyTime) // 按照 pined 时间倒序
        .map(({appId}) => appId);

    const pinedApps = pinedAppIds
        .map(appId => {
            return apps.find(({id}) => id === appId);
        })
        .filter(Boolean);

    function onPinedClick(appId, pined) {
        return e => {
            e.preventDefault();

            toggleUserAppPined(currentUserId, appId, !pined).then(data => {
                console.log(data);
                retry();
            });
        };
    }

    function renderAppList() {
        return (
            <>
                {!_.isEmpty(pinedApps) && (
                    <AppList title="置顶应用" style={{marginBottom: 48}}>
                        {pinedApps.map(app => {
                            const {id, name, squareLogoUrl} = app;
                            return (
                                <Link key={id} to={`/apps/${id}`}>
                                    <AppItem
                                        appId={id}
                                        userId={currentUserId}
                                        title={name}
                                        imageUrl={squareLogoUrl}
                                        pined
                                        onPinedClick={onPinedClick(id, true)}
                                    />
                                </Link>
                            );
                        })}
                    </AppList>
                )}

                <AppList title="我的应用">
                    {apps.map(app => {
                        const pined = _.get(meta, `${app.id}.pined`, false);
                        const {id, name, squareLogoUrl} = app;

                        return (
                            <Link key={id} to={`/apps/${id}`}>
                                <AppItem
                                    appId={id}
                                    userId={currentUserId}
                                    title={name}
                                    imageUrl={squareLogoUrl}
                                    pined={pined}
                                    onPinedClick={onPinedClick(id, pined)}
                                />
                            </Link>
                        );
                    })}
                </AppList>
            </>
        );
    }

    return (
        <AppLayout header={<AppLayoutHeader enabledHomeLink={false} />} style={{overflowY: 'auto'}}>
            {loading ? null : _.isEmpty(apps) ? (
                <Result
                    status="404"
                    title="您并非任何应用的成员"
                    subTitle="请联系管理员开通应用权限"
                    style={{marginTop: 100}}
                />
            ) : (
                renderAppList()
            )}
        </AppLayout>
    );
}
