import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './CardTabsContainer.module.less';

/**
 * 对于 antd tabs 的包装，重新定义了 card 模式下 tabs 的样式。
 *
 * 需注意，Tabs type 必须定义为 card。
 *
 * 调用方式：
 *
 * <CardTabsContainer className={classNames(styles.container, className)}>
 *     <Tabs type="card">
 *     </Tabs>
 * </CardTabsContainer
 *
 * @param className
 * @param style
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function CardTabsContainer({className, style, children}) {
    return (
        <div className={classNames(styles.container, className)} style={style}>
            {children}
        </div>
    );
}

CardTabsContainer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
