import {useState, useEffect} from 'react';

import {MenuConfig} from './AdminRoutes';
import {useAuthStore} from '@/context/auth';

export function useMenuService() {
    const [authStore] = useAuthStore();
    const [menus, setMenus] = useState([]);

    useEffect(() => {
        const authMenus = MenuConfig.filter(({authorities}) =>
            authStore.hasAuthorities(authorities, 'r')
        );
        setMenus(authMenus);
    }, [authStore]);

    return menus;
}

export function useEditAuthService(auths) {
    const [authStore] = useAuthStore();

    const [editable, setEditable] = useState(false);

    useEffect(() => {
        setEditable(authStore.hasAuthorities(auths, 'w'));
    }, [authStore, auths]);

    return editable;
}

export function useHasAdminAuthService() {
    const [authStore] = useAuthStore();
    const [globalAuth, setGlobalAuth] = useState(false);

    useEffect(() => {
        setGlobalAuth(authStore.hasGlobalAuths());
    }, [authStore]);

    return globalAuth;
}
