import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Input, message, Modal} from 'antd';

import {SendOutlined} from '@/icons';
import {addComment} from '@/api/comment';

import styles from './NewsCommentForm.module.less';

export default function NewsCommentForm({appId, userId, userName, newsId}) {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [content, setContent] = useState(null);

    function showModal() {
        setVisible(true);
    }

    function closeModal(confirm = true) {
        if (confirm && content) {
            Modal.confirm({
                title: '警告',
                content: '确定放弃已编辑内容？',
                onOk: () => {
                    resetOnClose();
                },
            });
        } else {
            resetOnClose();
        }
    }

    function resetOnClose() {
        setVisible(false);
        setLoading(false);
        setContent(null);
    }

    function onContentChange(e) {
        setContent(e.target.value);
    }

    function onSendClick() {
        setLoading(true);
        addComment(appId, newsId, userId, userName, content)
            .then(data => {
                console.log(data);
                message.warn('你的留言将会在审核后显示');
                closeModal(false);
            })
            .catch(e => {
                message.error(e.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    return (
        <>
            <div className={styles.container} onClick={showModal}>
                写下你的留言
            </div>
            <Modal
                destroyOnClose
                maskClosable={false}
                title="留言"
                visible={visible}
                onCancel={closeModal}
                footer={null}
            >
                <Form.Item extra="你的留言将会由管理员审核后发布">
                    <Input.TextArea
                        allowClear
                        rows={4}
                        autoFocus
                        maxLength={3000}
                        value={content}
                        onChange={onContentChange}
                    />
                </Form.Item>
                <Button
                    type="primary"
                    icon={<SendOutlined />}
                    disabled={!content || !content.trim()}
                    loading={loading}
                    onClick={onSendClick}
                >
                    发送
                </Button>
            </Modal>
        </>
    );
}

NewsCommentForm.propTypes = {
    appId: PropTypes.string,
    userId: PropTypes.string,
    userName: PropTypes.string,
    newsId: PropTypes.string,
};
