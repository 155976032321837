import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MsgPreview from '../MsgPreview';
import {useMessage} from '../historyService';

export default function MsgPreviewContainer({appId, msgId}) {
    const {value} = useMessage(appId, msgId);
    const message = _.get(value, 'data');

    if (!msgId || _.isEmpty(message)) {
        return null;
    }

    return <MsgPreview message={message} />;
}

MsgPreviewContainer.propTypes = {
    appId: PropTypes.string.isRequired,
    msgId: PropTypes.string,
};
