import React, {forwardRef, useImperativeHandle, useState} from 'react';

import {FullscreenModal} from '@/component';
import NewsView from '@/page/article/comp/NewsView';

const NewsViewModal = forwardRef((props, ref) => {
    const [visible, setVisible] = useState(false);
    const [newsContentId, setNewsContentId] = useState(null);

    function showModal(newsContentId) {
        setVisible(true);
        setNewsContentId(newsContentId);
    }

    function closeModal() {
        setVisible(false);
        setNewsContentId(null);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <FullscreenModal
            destroyOnClose
            contentWidth={500}
            visible={visible}
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
        >
            {newsContentId && <NewsView id={newsContentId} />}
        </FullscreenModal>
    );
});

NewsViewModal.displayName = 'NewsViewModal';

export default NewsViewModal;
