import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import MediaType, {getFileMediaUrl} from '../MediaType';
import FileMediaWrapper from './FileMediaWrapper';
import Player from '@/page/component/Player';

export default function VoicePage({editable = true, onItemChecked}) {
    const playerRef = useRef();

    function onNameClick(record) {
        const {attachId, attachExt} = record;
        const url = getFileMediaUrl(attachId, attachExt);

        playerRef.current.showPlayer('audio', record.title, url);
    }

    return (
        <>
            <FileMediaWrapper
                editable={editable}
                onItemChecked={onItemChecked}
                type={MediaType.VOICE}
                onNameClick={onNameClick}
            />
            <Player ref={playerRef} />
        </>
    );
}

VoicePage.propTypes = {
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
};
