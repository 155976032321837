import React, {useRef, useState} from 'react';
import _ from 'lodash';
import {Button} from 'antd';

import {TwoColumnLayout} from '@/component';

import DepartsTree from './DepartsTree';
import UserList from './UserList';
import useTitle from '@/hook/useTitle';
import {ApartmentOutlined, EditOutlined} from '@/icons';
import DepartEditModal from './DepartEditModal';
import {useDepartTree} from '@/page/admin/DepartsPage/useDepartService';
import {useEditAuthService} from '../adminService';

export default function DepartsPage() {
    useTitle('组织结构 - 系统管理');

    const departEditModalRef = useRef();

    const editable = useEditAuthService(['global:depart:w']);

    // 可使用 treeState.retry() 重新载入所有数据
    const treeState = useDepartTree();
    const [depart, setDepart] = useState({});

    function refreshTree() {
        treeState.retry();
    }

    function onDepartEditClick() {
        departEditModalRef.current.showModal();
    }

    function onDepartSelect(selDepart) {
        setDepart(selDepart);
    }

    return (
        <TwoColumnLayout>
            <TwoColumnLayout.SidePanel>
                <TwoColumnLayout.PanelHeader title="组织结构" icon={<ApartmentOutlined />}>
                    {editable && (
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={onDepartEditClick}
                        />
                    )}

                    <DepartEditModal ref={departEditModalRef} refreshTree={refreshTree} />
                </TwoColumnLayout.PanelHeader>
                <TwoColumnLayout.PanelContent>
                    <DepartsTree
                        treeState={treeState}
                        onDepartSelect={onDepartSelect}
                        onDepartEditClick={onDepartEditClick}
                    />
                </TwoColumnLayout.PanelContent>
            </TwoColumnLayout.SidePanel>

            <TwoColumnLayout.ContentPanel>
                {!_.isEmpty(depart) && <UserList depart={depart} editable={editable} />}
            </TwoColumnLayout.ContentPanel>
        </TwoColumnLayout>
    );
}
