import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {
    AuditOutlined,
    CommentOutlined,
    MessageOutlined,
    PicLeftOutlined,
    PictureOutlined,
    PieChartOutlined,
    RollbackOutlined,
    SendOutlined,
    SettingOutlined,
    TagsOutlined,
} from '@/icons';
import {useAppStore} from '@/page/app/context';

const AppRouteConfig = [
    {
        path: '/apps/:appId',
        exact: true,
        component: require('./MsgSendPage').default,
    },
    {
        label: '发送消息',
        path: '/apps/:appId/msg-send',
        exact: true,
        icon: <SendOutlined />,
        component: require('./MsgSendPage').default,
    },
    {
        path: '/apps/:appId/msg-send/list',
        component: require('./msg-audit/MyAuditMsgList').default,
    },
    {
        label: '待审核消息',
        path: '/apps/:appId/audit-msg',
        icon: <AuditOutlined />,
        isAuditMgr: true,     // 只有审核人才能查看此条目
        component: require('./msg-audit/MgrAuditMsgList').default,
    },
    {
        label: '历史消息',
        key: 'msg-history',
        icon: <MessageOutlined />,
        children: [
            {
                label: '已发送消息',
                path: '/apps/:appId/msg-history-send',
                component: require('./msg-history/MsgHistorySendPage').default,
            },
            {
                label: '已接收消息',
                path: '/apps/:appId/msg-history-received',
                component: require('./msg-history/MsgHistoryReceivedPage').default,
            },
        ],
    },
    {
        label: '留言管理',
        key: 'comment',
        icon: <CommentOutlined />,
        path: '/apps/:appId/comments',
        component: require('./CommentPage').default,
    },
    {
        label: '素材管理',
        path: '/apps/:appId/media',
        icon: <PictureOutlined />,
        component: require('./MediaPage').default,
    },
    {
        label: '数据统计',
        path: '/apps/:appId/report',
        icon: <PieChartOutlined />,
        component: require('./ReportPage').default,
    },
    {
        label: '可用标签',
        path: '/apps/:appId/tag',
        icon: <TagsOutlined />,
        component: require('./AppTagsPage').default,
    },
    {
        label: '自动回复',
        path: '/apps/:appId/reply',
        icon: <RollbackOutlined />,
        component: require('./MsgAutoReply').default,
    },
    {
        label: '菜单管理',
        path: '/apps/:appId/menu',
        icon: <PicLeftOutlined />,
        isAppMgr: true,
        component: require('./AppMenusPage').default,
    },
    {
        label: '应用设置',
        path: '/apps/:appId/settings',
        icon: <SettingOutlined />,
        isAppMgr: true,
        component: require('./AppSettingsPage').default,
    },
    {
        path: '/apps/:appId/*',
        component: require('../NotFoundPage').default,
    },
];

export const MenuConfig = AppRouteConfig.filter(item => !!item.label);

export default function AppRoutes() {
    const [{isMgr, extAttr}] = useAppStore();
    const auditMgr = extAttr?.userAttr?.auditMgr ?? false;

    const child = [];
    AppRouteConfig.forEach(({children}) => {
        if (children && children.length > 0) {
            child.push(...children);
        }
    });

    return (
        <Switch>
            {child.map(({path, exact, isAppMgr, isAuditMgr, component}) => {
                // console.log('path, exact, component', path, exact, component);
                // 管理员方可访问
                if (isAppMgr && !isMgr) {
                    return null;
                }

                if (isAuditMgr && !auditMgr) {
                    return null;
                }

                return (
                    <Route
                        key={`subroute${path}`}
                        exact={exact}
                        path={path}
                        component={component}
                    />
                );
            })}

            {AppRouteConfig.map(({path, exact, isAppMgr, isAuditMgr, component}) => {
                if (component && path) {
                    // 管理员方可访问
                    if (isAppMgr && !isMgr) {
                        return null;
                    }

                    if (isAuditMgr && !auditMgr) {
                        return null;
                    }

                    return (
                        <Route
                            key={`route${path}`}
                            exact={exact}
                            path={path}
                            component={component}
                        />
                    );
                }

                return null;
            })}
        </Switch>
    );
}
