import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, Divider, Form, Input, Modal, Row, Select, Switch, Typography} from 'antd';

import {getUser} from '@/api/users';
import {addDepartUser, updateDepartUser} from '@/api/departs';
import {PasswordFormItem} from '@/component';
import {NAME_REGEXP, NAME_MESSAGE} from '@/util/stringUtils';

const {Option} = Select;

const UserFormModal = forwardRef(({departId, reloadUsers}, ref) => {
    const formRef = useRef();
    const [visible, setVisible] = useState(false);
    const [userId, setUserId] = useState(null);
    const [sync, setSync] = useState(true);
    const [enableSync, setEnableSync] = useState(true);

    useEffect(() => {
        if (!userId) {
            return;
        }

        getUser(userId).then(({data}) => {
            console.log('data.enableSync', data.enableSync);
            if (!data.enableSync) {
                setEnableSync(false);
                setSync(false);
            }
            formRef.current.setFieldsValue(data);
        });
    }, [userId]);

    function showModal(userId) {
        setVisible(true);
        setUserId(userId);
    }

    function closeModal() {
        setVisible(false);
        setUserId(null);
        setSync(true);
        setEnableSync(true);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function isAdd() {
        return !userId;
    }

    function onOk() {
        formRef.current.submit();
    }

    function onSyncChange(checked) {
        console.log(`switch to ${checked}`);
        setSync(checked);
    }

    function onFinish(values) {
        console.log('Received values of form: ', values);

        const promise = isAdd() ? addDepartUser : updateDepartUser;
        promise(departId, values, sync)
            .then(data => {
                console.log('<<<<user: ', data);
                reloadUsers();
                closeModal();
            })
            .catch(e => {
                e.catch422Error && e.catch422Error(formRef.current);
            });
    }

    const footer = [
        <div key="sync" style={{display: 'inline-block', marginRight: 24}}>
            <Typography.Text type="secondary" style={{marginRight: 24}}>
                同步到企业微信:
            </Typography.Text>
            <Switch
                key="sync"
                checked={sync}
                disabled={!enableSync}
                checkedChildren="开启"
                unCheckedChildren="关闭"
                onChange={onSyncChange}
            />
        </div>,
        <Button key="cancelBtn" onClick={closeModal}>
            取消
        </Button>,
        <Button key="okBtn" type="primary" onClick={onOk}>
            确定
        </Button>,
    ];

    return (
        <Modal
            destroyOnClose
            maskClosable={false}
            visible={visible}
            title={isAdd() ? '添加用户' : '修改用户'}
            onCancel={closeModal}
            footer={footer}
        >
            <Form ref={formRef} onFinish={onFinish} layout="vertical">
                <Form.Item hidden name="id">
                    <Input />
                </Form.Item>

                <Row gutter={8}>
                    <Col flex={1}>
                        <Form.Item
                            name="name"
                            label="姓名"
                            rules={[
                                {
                                    required: true,
                                    max: 60,
                                },
                                {
                                    pattern: NAME_REGEXP,
                                    message: NAME_MESSAGE,
                                },
                            ]}
                        >
                            <Input placeholder="姓名" autoFocus />
                        </Form.Item>
                    </Col>
                    <Col flex={1}>
                        <Form.Item
                            name="alias"
                            label="别名"
                            rules={[
                                {
                                    max: 30,
                                },
                            ]}
                        >
                            <Input placeholder="别名" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name="position"
                            label="职位"
                            rules={[
                                {
                                    max: 100,
                                },
                            ]}
                        >
                            <Input placeholder="职位" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="gender" label="性别" initialValue={1}>
                            <Select>
                                <Option value={1}>男</Option>
                                <Option value={2}>女</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    <Col flex={1}>
                        <Form.Item
                            name="mobile"
                            label="手机"
                            rules={[
                                {
                                    max: 30,
                                },
                            ]}
                        >
                            <Input placeholder="手机号" />
                        </Form.Item>
                    </Col>

                    <Col flex={1}>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    max: 60,
                                },
                            ]}
                            extra={!isAdd() && '企业微信限制不许修改'}
                        >
                            <Input placeholder="Email" disabled={!isAdd()} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={8}>
                    {isAdd() && (
                        <Col flex={1}>
                            <PasswordFormItem
                                tooltip="如果为提供密码，则此人员无法登录本系统"
                                inputProps={{autoComplete: 'new-password'}}
                            />
                        </Col>
                    )}
                    <Col flex={1}>
                        <Form.Item
                            name="telephone"
                            label="电话"
                            rules={[
                                {
                                    max: 30,
                                },
                            ]}
                        >
                            <Input placeholder="电话" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name="address"
                    label="地址"
                    rules={[
                        {
                            max: 100,
                        },
                    ]}
                >
                    <Input placeholder="地址" />
                </Form.Item>

                <Row gutter={8}>
                    <Col flex={1}>
                        <Form.Item
                            name="onjob"
                            label="在职"
                            valuePropName="checked"
                            initialValue={true}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col flex={1}>
                        <Form.Item
                            name="enabled"
                            label="账号启用"
                            valuePropName="checked"
                            initialValue={true}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>

                <Divider />

                <Form.Item
                    name="extId"
                    label="企业微信用户 ID"
                    extra="只在维护企业微信通讯录已存在的用户时使用，慎用！"
                    rules={[
                        {
                            max: 32,
                        },
                        {
                            pattern: /^[\w]+$/,
                            message: '只能是英文大小写或数字',
                        },
                    ]}
                >
                    <Input placeholder="企业微信用户 ID" />
                </Form.Item>

                <Form.Item style={{display: 'none'}}>
                    <Button type="primary" htmlType="submit" />
                </Form.Item>
            </Form>
        </Modal>
    );
});

UserFormModal.displayName = 'UserFormModal';

UserFormModal.propTypes = {
    departId: PropTypes.string,
    reloadUsers: PropTypes.func,
};
export default UserFormModal;
