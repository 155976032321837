import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TwoColumnLayout.module.less';

export default function PanelContent({className, style, children}) {
    return (
        <div className={classNames(styles.panelContent, className)} style={style}>
            {children}
        </div>
    );
}

PanelContent.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.node,
};
