import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './TwoColumnLayout.module.less';

export default function SidePanel({className, style, enableScroll = false, children}) {
    return (
        <div
            className={classNames(
                styles.sidePanel,
                {[styles.enableScroll]: enableScroll},
                className
            )}
            style={style}
        >
            {children}
        </div>
    );
}

SidePanel.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    // 是否允许滚动，也就是是 overflow-y: auto
    enableScroll: PropTypes.bool,
    children: PropTypes.node,
};
