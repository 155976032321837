import React from 'react';
import PropTypes from 'prop-types';
import {Button, Divider, Tooltip} from 'antd';
import MsgAuditTags from './MsgAuditTags';
import {InfoCircleOutlined} from '@/icons';

MsgAuditView.propTypes = {
    appId: PropTypes.string.isRequired,
    roleEditable: PropTypes.bool,
    supportAppMsgAudit: PropTypes.bool,
    appAudits: PropTypes.array,
    retryAudit: PropTypes.func.isRequired,
};

function MsgAuditView({appId, roleEditable = false, supportAppMsgAudit, appAudits, retryAudit}) {
    if (!supportAppMsgAudit) {
        return null;
    }

    return (
        <>
            <Divider orientation="left">
                应用消息审核
                <Tooltip title="此处选择的人员必须位于「应用管理员」或「消息发送人」列表">
                    <Button type="text" danger icon={<InfoCircleOutlined />} />
                </Tooltip>
            </Divider>
            <MsgAuditTags
                appId={appId}
                roleEditable={roleEditable}
                appAudits={appAudits}
                retryAudit={retryAudit}
            />
        </>
    );
}

export default MsgAuditView;
