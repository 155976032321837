/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import {Button, Space, Table, Tag} from 'antd';

import msgTypes from '@/page/component/msg/MsgTypes';
import {DeleteOutlined, EditOutlined} from '@/icons';

export default function KeywordsTable({editable = true, dataSource, onEditClick, onDeleteClick}) {
    const columns = [
        {
            title: '规则标题',
            dataIndex: 'replyName',
        },
        {
            title: '关键字',
            dataIndex: 'replyKeyJson',
            render: text => {
                if (!text) {
                    return null;
                }

                const json = JSON.parse(text);
                if (!Array.isArray(json)) {
                    return null;
                }

                return json.map(({type, keyword}, index) => (
                    <Tag key={index}>
                        {keyword} [{type === 1 ? '包含' : '完全匹配'}]
                    </Tag>
                ));
            },
        },
        {
            title: '回复类型',
            dataIndex: 'msgType',
            render: text => {
                const {label} = msgTypes[text];
                return label;
            },
        },
    ];

    if (editable) {
        columns.push({
            title: '操作',
            key: 'action',
            width: 120,
            render: (text, record) => {
                return (
                    <Space>
                        <Button
                            type="link"
                            icon={<EditOutlined />}
                            onClick={() => onEditClick(record)}
                        >
                            修改
                        </Button>
                        <Button
                            type="link"
                            danger
                            icon={<DeleteOutlined />}
                            onClick={() => onDeleteClick(record)}
                        >
                            删除
                        </Button>
                    </Space>
                );
            },
        });
    }

    return <Table dataSource={dataSource} columns={columns} rowKey="id" pagination={false} />;
}

KeywordsTable.propTypes = {
    editable: PropTypes.bool,
    dataSource: PropTypes.array,
    onEditClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
};
