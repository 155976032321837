const reportRoutes = [
    {
        path: '/apps/:appId/report/user_increase',
        Comp: require('./UserIncreaseReport').default,
        label: '成员增长',
    },
    {
        path: '/apps/:appId/report/user_activity',
        Comp: require('./UserActivityReport').default,
        label: '成员活跃度',
    },
    {
        path: '/apps/:appId/report/news',
        Comp: require('./NewsAnalysisReport').default,
        label: '图文分析',
    },
];

export default reportRoutes;
