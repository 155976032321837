import _ from 'lodash';
import PageGrid from './PageGrid';

export {default as RowAction} from './RowAction';

/**
 * 在已选列表中查找是否有了给定行的key，有则删除，无则加上。
 *
 * 一般用于在行上点击的时候切换已选值。
 *
 * @param rowKey 给定行的 key
 * @param selectedRowKeys 已选的所有 key
 * @returns 更新后的已选 key 列表
 */
export function getKeysByRowSelect(selectedRowKeys, rowKey) {
    let rowKeys;
    if (selectedRowKeys.includes(rowKey)) {
        rowKeys = _.without(selectedRowKeys, rowKey);
    } else {
        rowKeys = [...selectedRowKeys, rowKey];
    }

    return rowKeys;
}

/**
 * 在已选列表中查找是否有了给定内容，有则删除，无则加上。
 *
 * @param selectedRows 给定行内容数组
 * @param row 待删除或添加的新内容
 * @param match 匹配规则
 * @return 更新后的已选内容列表
 */
export function getRowsByRowSelect(selectedRows, row, match) {
    let rows;
    if (_.find(selectedRows, match)) {
        rows = _.reject(selectedRows, match);
    } else {
        rows = [...selectedRows, row];
    }
    return rows;
}

export default PageGrid;
