import {useAsyncRetry} from 'react-use';

import {getAllApps} from '@/api/apps';
import {getAllTags} from '@/api/tags';

export function useApps() {
    return useAsyncRetry(() => {
        return getAllApps();
    }, []);
}

export function useTags(searchValue) {
    return useAsyncRetry(() => {
        return getAllTags(searchValue);
    }, [searchValue]);
}
