export function sendFromText(sendFrom) {
    let from = '';
    if (sendFrom === 'manual') {
        from = 'Web';
    } else if (sendFrom === 'menu') {
        from = '菜单';
    } else if (sendFrom === 'reply') {
        from = '自动回复';
    } else if (sendFrom === 'received') {
        from = '客户端';
    } else if (sendFrom === 'api') {
        from = '应用';
    }
    return from;
}
