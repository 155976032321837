import React, {forwardRef, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Tabs} from 'antd';

import TextPage from './pages/TextPage';
import ImagePage from './pages/ImagePage';
import NewsPage from './pages/NewsPage';
import VoicePage from './pages/VoicePage';
import VideoPage from './pages/VideoPage';
import FilePage from './pages/FilePage';
import {getNewsContents} from '@/api/media';

const {TabPane} = Tabs;

const MediaSelectModal = forwardRef(({onSelected}, ref) => {
    const [visible, setVisible] = useState(false);
    const [activeTab, setActiveTab] = useState('text');

    function showModal(tab) {
        setVisible(true);

        if (tab) {
            setActiveTab(tab);
        }
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onTabChange(activeKey) {
        setActiveTab(activeKey);
    }

    function onTextChecked(record) {
        // console.log(record);
        onSelected && onSelected('text', record.content);
        closeModal();
    }

    function onItemChecked(record) {
        // console.log(record);
        const {mediaType} = record;
        onSelected && onSelected(mediaType, record);
        closeModal();
    }

    function onNewsItemChecked(record) {
        const {id, mediaType} = record;

        // 返回内容列表
        getNewsContents(id).then(({data}) => {
            // console.log(data);
            onSelected && onSelected(mediaType, record, data);
            closeModal();
        });
    }

    return (
        <Modal destroyOnClose width={1500} visible={visible} onCancel={closeModal} footer={null}>
            <Tabs centered activeKey={activeTab} onChange={onTabChange}>
                <TabPane tab="文本" key="text">
                    <TextPage editable={false} onItemChecked={onTextChecked} />
                </TabPane>
                <TabPane tab="图文" key="news">
                    <NewsPage editable={false} onItemChecked={onNewsItemChecked} />
                </TabPane>
                <TabPane tab="图片" key="image">
                    <ImagePage
                        editable={false}
                        enableDownload={false}
                        onItemChecked={onItemChecked}
                    />
                </TabPane>
                <TabPane tab="语音" key="voice">
                    <VoicePage editable={false} onItemChecked={onItemChecked} />
                </TabPane>
                <TabPane tab="视频" key="video">
                    <VideoPage editable={false} onItemChecked={onItemChecked} />
                </TabPane>
                <TabPane tab="文件" key="file">
                    <FilePage editable={false} onItemChecked={onItemChecked} />
                </TabPane>
            </Tabs>
        </Modal>
    );
});

MediaSelectModal.displayName = 'MediaSelectModal';

MediaSelectModal.propTypes = {
    onSelected: PropTypes.func,
};

export default MediaSelectModal;
