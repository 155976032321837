/**
 * 获得给定视频地址的第一帧缩略图，格式为 dataURL 字符串
 *
 * 对于 aws s3 来说，需要配置其 cors 策略为：
 [
 {
        "AllowedHeaders": [
            "*"
        ],
        "AllowedMethods": [
            "GET",
            "PUT",
            "POST",
            "DELETE"
        ],
        "AllowedOrigins": [
            "*"
        ],
        "ExposeHeaders": []
    }
 ]
 *
 * @param url 视频地址
 * @return {Promise<unknown>}
 */
export function getVideoThumb(url) {
    return new Promise((resolve, reject) => {
        const video = document.createElement('video');
        video.crossOrigin = 'anonymous';
        // video.width = 500;
        // video.height = 375;

        // video.addEventListener('error', e => {
        //     console.error(`Error loading:`, e);
        //     console.log(e.message);
        // });

        video.addEventListener('loadeddata', function () {
            const canvas = document.createElement('canvas');
            const width = video.videoWidth;
            const height = video.videoHeight;
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, width, height);

            try {
                const dataURL = canvas.toDataURL('image/png');
                resolve({dataURL, width, height});
            } catch (e) {
                reject(e);
            }
        });

        video.src = url;
        console.log(video);
        video.muted = true;
        const playPromise = video.play();
        // console.log(playPromise);
        if (playPromise !== undefined) {
            playPromise.catch(e => {
                // Auto-play was prevented
                // Show paused UI.
                console.log('playback prevented');
                reject(e);
            });
        }
    });
}
