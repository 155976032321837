import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {message, Modal} from 'antd';
import _ from 'lodash';

import MessageContent from '@/page/app/component/MessageContent';
import {sendMessage} from '@/api/message';

const MessageReplyModal = forwardRef(({appId, onMessageSendSuccess}, ref) => {
    const contentRef = useRef();

    const [visible, setVisible] = useState(false);
    const [submiting, setSubmiting] = useState(false);
    const [userId, setUserId] = useState(null);

    function showModal(userId) {
        setVisible(true);
        setUserId(userId);
    }

    function closeModal() {
        setVisible(false);
        setUserId(null);
        setSubmiting(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function sendMessage2Wecom(msgType, safe, content) {
        setSubmiting(true);
        const values = {
            toAll: false,
            tos: [{msgToType: 'touser', msgToId: userId}],
            msgType,
            safe,
            message: content,
            newsContents: content.newsContents,
        };
        console.table(values);

        sendMessage(appId, values)
            .then(data => {
                console.log(data);
                message.success('发送成功');

                onMessageSendSuccess && onMessageSendSuccess();

                closeModal();
            })
            .catch(() => {
                setSubmiting(false);
            });
    }

    function onOk() {
        const {msgType, safe, content} = contentRef.current.getData();

        if (_.isEmpty(content)) {
            message.warn('「消息内容」不能为空');
            return;
        }

        Modal.confirm({
            title: '提示',
            content: '确定要发送消息？',
            okText: '确定发送',
            onOk: () => {
                sendMessage2Wecom(msgType, safe, content);
            },
        });
    }

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title="回复消息"
            width={1000}
            onCancel={closeModal}
            okText="回复消息"
            onOk={onOk}
            okButtonProps={{loading: submiting}}
        >
            <MessageContent ref={contentRef} style={{marginBottom: 24, maxWidth: '100%'}} />
        </Modal>
    );
});

MessageReplyModal.displayName = 'MessageReplyModal';

MessageReplyModal.propTypes = {
    appId: PropTypes.string.isRequired,
    onMessageSendSuccess: PropTypes.func,
};

export default MessageReplyModal;
