import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Button, Space, DatePicker} from 'antd';
import Line from '@ant-design/charts/es/line';
import dayjs from 'dayjs';

import {useSingleNewsStat} from './newsReportService';
import ChartDownloader from '../component/ChartDownloader';

SingleNewsChart.propTypes = {
    appId: PropTypes.string.isRequired,
    newsId: PropTypes.string,
    sendTime: PropTypes.number,
};

function SingleNewsChart({appId, newsId, sendTime}) {
    const chartRef = useRef();
    const [dateStart, setDateStart] = useState(dayjs(sendTime));
    const [dateEnd, setDateEnd] = useState(dayjs());

    const {loading, value} = useSingleNewsStat(
        appId,
        newsId,
        dateStart.format('YYYY-MM-DD'),
        dateEnd.format('YYYY-MM-DD')
    );
    console.log('loading, value', loading, value);

    // function disabledDate(current) {
    //     // return current.isBefore(sendTime);
    //     return false;
    // }

    function onDateChange(dates) {
        setDateStart(dates[0]);
        setDateEnd(dates[1]);
    }

    if (!value) {
        return null;
    }

    return (
        <>
            <div style={{textAlign: 'center', marginBottom: 24}}>
                <Space>
                    <DatePicker.RangePicker
                        value={[dateStart, dateEnd]}
                        showTime={false}
                        allowClear={false}
                        // disabledDate={disabledDate}
                        onChange={onDateChange}
                    />
                    <ChartDownloader chartRef={chartRef} name="单篇图文趋势图.png" />
                </Space>
            </div>

            <Line
                chartRef={chartRef}
                data={value}
                padding="auto"
                xField="accessDate"
                yField="value"
                seriesField="category"
                smooth
                point={{shape: 'circle'}}
            />
        </>
    );
}

export default SingleNewsChart;
