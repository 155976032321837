import React from 'react';
import CorpSetting from './CorpSetting';
import useTitle from '@/hook/useTitle';

const SettingsPage = () => {
    useTitle('系统设置 - 系统管理');

    return <CorpSetting />;
};

export default SettingsPage;
