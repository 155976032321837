import React from 'react';
import PropTypes from 'prop-types';

import {useNewsSumReports} from './newsReportService';
import {Spin, Table} from 'antd';

RecentNewsList.propTypes = {
    appId: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
};

function RecentNewsList({appId, alias}) {
    const {loading, value} = useNewsSumReports(appId, alias);
    // console.log('loading, value', loading, value);

    const columns = [
        {
            title: '时间',
            dataIndex: 'title',
        },
        {
            title: '群发图文（次）',
            dataIndex: 'sendCount',
            align: 'right',
        },
        {
            title: '文章（篇）',
            dataIndex: 'newsCount',
            align: 'right',
        },
        {
            title: '群发人数',
            dataIndex: 'toUserCount',
            align: 'right',
        },
        {
            title: '阅读数（次）',
            dataIndex: 'readCount',
            align: 'right',
        },
        {
            title: '平均打开率',
            key: 'openNews',
            align: 'right',
            render: (__, {readUserCount, toUserCount}) => {
                if (toUserCount === 0) {
                    return '0.00%';
                }
                return Number((readUserCount / toUserCount) * 100).toFixed(2) + '%';
            },
        },
    ];
    return (
        <Spin spinning={loading}>
            <Table
                size="small"
                dataSource={value}
                columns={columns}
                pagination={false}
                rowKey="id"
            />
        </Spin>
    );
}

export default RecentNewsList;
