import React from 'react';
import PropTypes from 'prop-types';
import {Avatar, Typography} from 'antd';
import filesize from 'filesize';

import styles from './CommonFileView.module.less';

export default function CommonFileView({icon, title, onClick, attachSize}) {
    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.icon}>
                <Avatar icon={icon} />
            </div>
            <div className={styles.content}>
                <Typography.Title level={5}>{title}</Typography.Title>
                <Typography.Paragraph type="secondary">{filesize(attachSize)}</Typography.Paragraph>
            </div>
        </div>
    );
}

CommonFileView.propTypes = {
    icon: PropTypes.node,
    title: PropTypes.string,
    attachSize: PropTypes.number,
    onClick: PropTypes.func,
};
