import React from 'react';
import PropTypes from 'prop-types';
import {Empty, Tag} from 'antd';
import _ from 'lodash';
import classNames from 'classnames';

import styles from './RoleAuthorityList.module.less';

export default function RoleAuthorityList({authorities}) {
    if (_.isEmpty(authorities)) {
        return <Empty style={{marginTop: 50}} />;
    }

    // console.log('authorities', authorities);
    const auths = combineAuths(authorities);
    // console.log(auths);

    return (
        <ul className={styles.list}>
            {auths.map(({memo, READ, WRITE}, index) => (
                <li key={index} className={styles.line}>
                    <div className={styles.memo}>{memo}</div>
                    <div className={styles.actions}>
                        <div className={classNames(styles.action, styles.read)}>
                            {READ && <Tag color="green">可读</Tag>}
                        </div>
                        <div className={classNames(styles.action, styles.write)}>
                            {WRITE && <Tag color="magenta">可写</Tag>}
                        </div>
                    </div>
                </li>
            ))}
        </ul>
    );
}

RoleAuthorityList.propTypes = {
    authorities: PropTypes.array,
};

function combineAuths(authorities) {
    if (_.isEmpty(authorities)) {
        return [];
    }

    const memes = [];
    const authMap = {};

    authorities.forEach(({memo, authority, action}) => {
        if (!memes.includes(memo)) {
            memes.push(memo);
        }

        let auth = authMap[memo] || {};
        if (_.isEmpty(auth)) {
            auth = {
                memo,
                [action]: authority,
            };
        } else {
            auth = {...auth, [action]: authority};
        }
        authMap[memo] = auth;
    });
    memes.sort((item1, item2) => {
        return item1.localeCompare(item2);
    });

    return memes.map(memo => {
        return authMap[memo];
    });
}
