import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Button, Modal} from 'antd';

import {getSingleNewsStat} from '@/api/report';
import SingleNewsChart from './SingleNewsChart';

const SingleNewsChartModal = forwardRef(({appId}, ref) => {
    const [visible, setVisible] = useState(false);
    const [newsId, setNewsId] = useState(null);
    const [sendTime, setSendTime] = useState(null);

    function showModal(newsId, sendTime) {
        setVisible(true);
        setNewsId(newsId);
        setSendTime(sendTime);
    }

    function closeModal() {
        setVisible(false);
    }
    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    return (
        <Modal
            destroyOnClose
            visible={visible}
            width={1000}
            title="趋势图"
            onCancel={closeModal}
            okButtonProps={{style: {display: 'none'}}}
            cancelText="关闭"
        >
            {newsId && sendTime && (
                <SingleNewsChart appId={appId} newsId={newsId} sendTime={sendTime} />
            )}
        </Modal>
    );
});

SingleNewsChartModal.displayName = 'SingleNewsChartModal';

SingleNewsChartModal.propTypes = {
    appId: PropTypes.string.isRequired,
};

export default SingleNewsChartModal;
