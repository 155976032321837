import {deleteJson, getJson, patchJson, postJson} from '@/util/ajaxUtils';
import {API_ROOT} from '@/env';

export function sendMessage(appId, data) {
    return postJson(`/message/${appId}`, {data});
}

export function saveFiexedTimeMessage(appId, data) {
    return postJson(`/message/${appId}/fixedtime`, {data});
}

export function getMessageViewerCount(appId, toAll, tos) {
    return postJson(`/message/${appId}/viewer-count`, {data: {toAll, tos}});
}

export function changeMessageFixedTime(appId, messageId, timestamp) {
    return patchJson(`/message/${appId}/fixedtime/${messageId}`, {params: {timestamp}});
}

export function deleteFiexedTimeMessage(appId, messageId) {
    return deleteJson(`/message/${appId}/fixedtime/${messageId}`);
}

export function getSendMsgHistoryUrl(appId) {
    return `${API_ROOT}/message/${appId}?msgDirection=send`;
}

export function getReceivedMsgHistoryUrl(appId) {
    return `${API_ROOT}/message/${appId}?msgDirection=received`;
}

export function getMessage(appId, msgId) {
    return getJson(`/message/${appId}/${msgId}`);
}

export function getUserMessages(appId, userId, current, size) {
    return getJson(`/message/${appId}/users/${userId}`, {params: {current, size}});
}

/**
 * @deprecated 废弃
 * @param appId
 * @returns {Promise<{data: null, success: boolean, message: string, status: number} | * | undefined | boolean>}
 */
export function isSupportCustomSend(appId) {
    return getJson(`/message/${appId}/custom-send/status`)
        .then(({data}) => data)
        .catch(() => false);
}

export function validateCustomSendData(appId, type, data) {
    return postJson(`/message/${appId}/custom-send/validate`, {params: {type}, data});
}
