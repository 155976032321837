import React from 'react';

import AppLayout from '../component/AppLayout';
import AppPageWrapper, {AppStoreProvider} from './context';

import AppRoutes from './AppRoutes';
import AppSiderMenu from './AppSiderMenu';
import AppHeader from './AppHeader';

export default function AppPage() {
    return (
        <AppStoreProvider>
            <AppPageWrapper>
                <AppLayout header={<AppHeader />} sider={<AppSiderMenu />} paddingContent={0}>
                    <AppRoutes />
                </AppLayout>
            </AppPageWrapper>
        </AppStoreProvider>
    );
}
