import React from 'react';
import PropTypes from 'prop-types';
import filesize from 'filesize';

import {PaperClipOutlined} from '@/icons';
import MediaType, {getFileMediaUrl} from '@/page/app/MediaPage/MediaType';
import CommonFileView from './comp/CommonFileView';
import CommonFileContent from './comp/CommonFileContent';
import {valuePropTypes} from './propTypes';

export default function FileContent({value, onChange}) {
    return (
        <CommonFileContent
            type={MediaType.FILE}
            value={value}
            onChange={onChange}
            buttonTitle="添加文件"
            previewStyle={{maxWidth: 500, width: 500}}
            description={<p>文件大小不超过 {filesize(MediaType.FILE.maxSize)}</p>}
        >
            {({title, attachId, attachExt, attachSize}) => (
                <>
                    <CommonFileView
                        icon={<PaperClipOutlined />}
                        title={title}
                        attachSize={attachSize}
                        onClick={() => {
                            const url = getFileMediaUrl(attachId, attachExt);
                            window.open(url, '_blank', 'noopener');
                        }}
                    />
                </>
            )}
        </CommonFileContent>
    );
}

FileContent.propTypes = {
    value: valuePropTypes,
    onChange: PropTypes.func.isRequired,
};
