import React from 'react';
import PropTypes from 'prop-types';
import {Tag} from 'antd';

export default function MediaTag({children, ...rest}) {
    return (
        <Tag color="#108ee9" {...rest}>
            {children}
        </Tag>
    );
}

MediaTag.propTypes = {
    children: PropTypes.node,
};
