import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {PageHeader} from 'antd';
import Line from '@ant-design/charts/es/line';

import DateRange from '../component/DateRange';
import {getRangeReports} from '@/api/report';
import ChartDownloader from '@/page/app/ReportPage/component/ChartDownloader';

LineChart.propTypes = {
    appId: PropTypes.string.isRequired,
    alias: PropTypes.string.isRequired,
};

function LineChart({appId, alias}) {
    const chartRef = useRef();
    const [lineData, setLineData] = useState([]);

    async function onChange(dates, dateStrings) {
        // console.log(dates, dateStrings);
        const {data = []} = await getRangeReports(alias, appId, dateStrings[0], dateStrings[1]);
        // console.log(data);

        const newData = data.reduce((accumulator, currentValue) => {
            const {reportDate, subscribeCount, unsubscribeCount, allowUserCount} = currentValue;

            accumulator.push({
                category: '开通应用可见（人）',
                date: reportDate,
                value: subscribeCount,
            });
            accumulator.push({
                category: '关闭应用可见（人）',
                date: reportDate,
                value: unsubscribeCount,
            });
            accumulator.push({
                category: '已关注的可见成员',
                date: reportDate,
                value: allowUserCount,
            });

            return accumulator;
        }, []);
        setLineData(newData);
    }

    return (
        <>
            <PageHeader
                title="趋势图"
                style={{padding: '16px 0'}}
                extra={
                    <DateRange onChange={onChange}>
                        <ChartDownloader chartRef={chartRef} name="成员增长趋势图.png" />
                    </DateRange>
                }
            />
            <Line
                data={lineData}
                chartRef={chartRef}
                padding="auto"
                xField="date"
                yField="value"
                seriesField="category"
                smooth
                color={['#29cc97', '#fb104a', '#3571ff']}
                point={{shape: 'circle'}}
            />
        </>
    );
}

export default LineChart;
