import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';

import PortalOverlay from '../portal/PortalOverlay';

import './ContextMenuContainer.less';
import styles from './ContextMenuContainer.module.less';

/**
 * 右键菜单的容器，放置在 {@link PortalOverlay} 中，每次显示的时候需要传入鼠标位置：{pageX, pageY}
 */
export default class ContextMenuContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
    };

    constructor(props) {
        super(props);

        this.overlayRef = React.createRef();

        this.state = {
            pageX: -1,
            pageY: -1,
        };
    }

    /**
     * 用于外部调用显示菜单。
     *
     * @public
     * @param pageX
     * @param pageY
     */
    showMenu = ({pageX, pageY}) => {
        if (pageY && pageX) {
            this.setState(
                {
                    pageX,
                    pageY,
                },
                () => {
                    this.overlayRef.current.show();
                }
            );
        }
    };

    render() {
        const {pageX, pageY} = this.state;
        return (
            <PortalOverlay ref={this.overlayRef}>
                <div style={{left: pageX, top: pageY}} className={styles.menu}>
                    {this.props.children}
                </div>
            </PortalOverlay>
        );
    }
}
