import PropTypes from 'prop-types';

const mediaTypeShape = {
    key: PropTypes.oneOf(['text', 'news', 'image', 'voice', 'video', 'file']).isRequired,
    label: PropTypes.string.isRequired,
    icon: PropTypes.node,
    searchPlaceholder: PropTypes.string,
    accept: PropTypes.string,
};

export const mediaTypeProps = {
    type: PropTypes.shape(mediaTypeShape).isRequired,
};

export const fileMediaTypeProps = {
    type: PropTypes.shape({
        ...mediaTypeShape,
        key: PropTypes.oneOf(['image', 'voice', 'video', 'file']).isRequired,
    }).isRequired,
};
