import React from 'react';
import PropTypes from 'prop-types';

import MediaType, {getFileMediaUrl} from '../MediaType';
import FileMediaWrapper from './FileMediaWrapper';

export default function FilePage({editable = true, onItemChecked}) {
    function onNameClick(record) {
        const {attachId, attachExt} = record;
        const url = getFileMediaUrl(attachId, attachExt);
        window.open(url, '_blank', 'noopener');
    }

    return (
        <FileMediaWrapper
            editable={editable}
            onItemChecked={onItemChecked}
            type={MediaType.FILE}
            onNameClick={onNameClick}
        />
    );
}

FilePage.propTypes = {
    // 是否可编辑
    editable: PropTypes.bool,

    // 选中之后的操作
    onItemChecked: PropTypes.func,
};
