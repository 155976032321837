import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Result, Spin} from 'antd';

import {updateRoleAuthorities} from '@/api/roles';
import {roleTypes} from '../propTypes';
import {usePermissions} from '../useRoleService';
import RoleAuthItem from './RoleAuthItem';

import styles from './RoleAuthoritiesModal.module.less';

const RoleAuthoritiesModal = forwardRef(({role, authorities = [], reload}, ref) => {
    const [visible, setVisible] = useState(false);
    const [selAuthorities, setSelAuthorities] = useState(authorities);
    const {loading, value} = usePermissions(role.scope);
    // console.log({loading, value});

    // console.log('modal authorities', authorities);

    useEffect(() => {
        setSelAuthorities(authorities);
    }, [authorities]);

    function showModal() {
        setVisible(true);
    }

    function closeModal() {
        setVisible(false);
    }

    useImperativeHandle(ref, () => ({
        showModal,
        closeModal,
    }));

    function onOk() {
        // console.log('ok...', role, selAuthorities);
        updateRoleAuthorities(role.id, selAuthorities).then(() => {
            reload();
            closeModal();
        });
    }

    function toggleReadWrite(initAuths, rwType, checked) {
        if (checked) {
            if (!initAuths.includes(rwType)) {
                return [...initAuths, rwType];
            }
        } else {
            if (initAuths.includes(rwType)) {
                return initAuths.filter(auth => auth !== rwType);
            }
        }
        return initAuths;
    }

    function onToggle({read, write, readChecked, writeChecked}) {
        // console.log(read, write, readChecked, writeChecked);
        let auths = toggleReadWrite(selAuthorities, read, readChecked);
        auths = toggleReadWrite(auths, write, writeChecked);
        setSelAuthorities(auths);
    }

    function renderAuths() {
        if (loading) {
            return null;
        }

        const {status, message, data} = value;
        if (status !== 200) {
            return <Result status="warning" title={`权限信息读取出错：${message}`} />;
        }

        return (
            <ul className={styles.groupList}>
                {data.map(({name, auths}, index) => {
                    return (
                        <li key={index}>
                            <div className={styles.groupName}>{name}</div>
                            <ul>
                                {auths.map(({memo, read, write}, idx) => {
                                    return (
                                        <li key={idx}>
                                            <RoleAuthItem
                                                memo={memo}
                                                read={read}
                                                write={write}
                                                initValues={authorities}
                                                onToggle={onToggle}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        </li>
                    );
                })}
            </ul>
        );
    }

    return (
        <Modal
            destroyOnClose
            visible={visible}
            title={`[${role.name}] 权限选择`}
            onCancel={closeModal}
            onOk={onOk}
        >
            <Spin spinning={loading}>{renderAuths()}</Spin>
        </Modal>
    );
});

RoleAuthoritiesModal.displayName = 'RoleAuthoritiesModal';

RoleAuthoritiesModal.propTypes = {
    role: roleTypes,
    authorities: PropTypes.arrayOf(PropTypes.string),
    reload: PropTypes.func,
};

export default RoleAuthoritiesModal;
