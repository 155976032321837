//
// 为保证 ant design icon 打包时候 tree-shaking 可用，故将所有用到的 @ant-design 统一放置在此。
//
// 注意：不要直接使用 import {RollbackOutlined} from '@ant-design/icons';
//      这种方式 tree-shaking 不起作用，会把所有未用到的图标也打包进去
//

export {default as ControlTwoTone} from '@ant-design/icons/ControlTwoTone';
export {default as FileTextTwoTone} from '@ant-design/icons/FileTextTwoTone';
export {default as LikeOutlined} from '@ant-design/icons/LikeOutlined';
export {default as LikeFilled} from '@ant-design/icons/LikeFilled';
export {default as CommentOutlined} from '@ant-design/icons/CommentOutlined';
export {default as ClockCircleOutlined} from '@ant-design/icons/ClockCircleOutlined';
export {default as PieChartOutlined} from '@ant-design/icons/PieChartOutlined';

export {default as VerticalAlignTopOutlined} from '@ant-design/icons/VerticalAlignTopOutlined';
export {default as VerticalAlignBottomOutlined} from '@ant-design/icons/VerticalAlignBottomOutlined';

export {default as RollbackOutlined} from '@ant-design/icons/RollbackOutlined';
export {default as SearchOutlined} from '@ant-design/icons/SearchOutlined';
export {default as SendOutlined} from '@ant-design/icons/SendOutlined';
export {default as ExportOutlined} from '@ant-design/icons/ExportOutlined';
export {default as HistoryOutlined} from '@ant-design/icons/HistoryOutlined';
export {default as MessageOutlined} from '@ant-design/icons/MessageOutlined';
export {default as PictureOutlined} from '@ant-design/icons/PictureOutlined';
export {default as PushpinTwoTone} from '@ant-design/icons/PushpinTwoTone';
export {default as CloseOutlined} from '@ant-design/icons/CloseOutlined';
export {default as CloseCircleOutlined} from '@ant-design/icons/CloseCircleOutlined';

export {default as AuditOutlined} from '@ant-design/icons/AuditOutlined';
export {default as ApartmentOutlined} from '@ant-design/icons/ApartmentOutlined';
export {default as AppstoreAddOutlined} from '@ant-design/icons/AppstoreAddOutlined';
export {default as AppstoreOutlined} from '@ant-design/icons/AppstoreOutlined';
export {default as BarsOutlined} from '@ant-design/icons/BarsOutlined';
export {default as FileOutlined} from '@ant-design/icons/FileOutlined';
export {default as TagsOutlined} from '@ant-design/icons/TagsOutlined';
export {default as TagOutlined} from '@ant-design/icons/TagOutlined';
export {default as TeamOutlined} from '@ant-design/icons/TeamOutlined';
export {default as LinkOutlined} from '@ant-design/icons/LinkOutlined';

export {default as HomeOutlined} from '@ant-design/icons/HomeOutlined';
export {default as LogoutOutlined} from '@ant-design/icons/LogoutOutlined';
export {default as ProfileOutlined} from '@ant-design/icons/ProfileOutlined';
export {default as LockOutlined} from '@ant-design/icons/LockOutlined';
export {default as UserOutlined} from '@ant-design/icons/UserOutlined';
export {default as UserDeleteOutlined} from '@ant-design/icons/UserDeleteOutlined';
export {default as UploadOutlined} from '@ant-design/icons/UploadOutlined';
export {default as StopOutlined} from '@ant-design/icons/StopOutlined';
export {default as EditOutlined} from '@ant-design/icons/EditOutlined';
export {default as DeleteOutlined} from '@ant-design/icons/DeleteOutlined';
export {default as MoreOutlined} from '@ant-design/icons/MoreOutlined';
export {default as SettingOutlined} from '@ant-design/icons/SettingOutlined';
export {default as PoweroffOutlined} from '@ant-design/icons/PoweroffOutlined';

export {default as DoubleRightOutlined} from '@ant-design/icons/DoubleRightOutlined';
export {default as DoubleLeftOutlined} from '@ant-design/icons/DoubleLeftOutlined';
export {default as MenuFoldOutlined} from '@ant-design/icons/MenuFoldOutlined';
export {default as MenuUnfoldOutlined} from '@ant-design/icons/MenuUnfoldOutlined';

export {default as UpOutlined} from '@ant-design/icons/UpOutlined';
export {default as DownOutlined} from '@ant-design/icons/DownOutlined';

export {default as ExclamationCircleOutlined} from '@ant-design/icons/ExclamationCircleOutlined';
export {default as InfoCircleOutlined} from '@ant-design/icons/InfoCircleOutlined';

export {default as MenuOutlined} from '@ant-design/icons/MenuOutlined';
export {default as PlusOutlined} from '@ant-design/icons/PlusOutlined';

export {default as PicCenterOutlined} from '@ant-design/icons/PicCenterOutlined';
export {default as PicLeftOutlined} from '@ant-design/icons/PicLeftOutlined';
export {default as PicRightOutlined} from '@ant-design/icons/PicRightOutlined';

export {default as PhoneOutlined} from '@ant-design/icons/PhoneOutlined';
export {default as MailOutlined} from '@ant-design/icons/MailOutlined';
export {default as MobileOutlined} from '@ant-design/icons/MobileOutlined';
export {default as SolutionOutlined} from '@ant-design/icons/SolutionOutlined';

export {default as CheckSquareOutlined} from '@ant-design/icons/CheckSquareOutlined';
export {default as CheckOutlined} from '@ant-design/icons/CheckOutlined';
export {default as CheckCircleOutlined} from '@ant-design/icons/CheckCircleOutlined';
export {default as FileProtectOutlined} from '@ant-design/icons/FileProtectOutlined';
export {default as SafetyOutlined} from '@ant-design/icons/SafetyOutlined';
export {default as CopyOutlined} from '@ant-design/icons/CopyOutlined';
export {default as KeyOutlined} from '@ant-design/icons/KeyOutlined';

export {default as MinusCircleOutlined} from '@ant-design/icons/MinusCircleOutlined';

export {default as CloudDownloadOutlined} from '@ant-design/icons/CloudDownloadOutlined';
export {default as CloudUploadOutlined} from '@ant-design/icons/CloudUploadOutlined';
export {default as CloudSyncOutlined} from '@ant-design/icons/CloudSyncOutlined';

export {default as VideoCameraOutlined} from '@ant-design/icons/VideoCameraOutlined';
export {default as SoundOutlined} from '@ant-design/icons/SoundOutlined';
export {default as PaperClipOutlined} from '@ant-design/icons/PaperClipOutlined';
export {default as FileTextOutlined} from '@ant-design/icons/FileTextOutlined';
export {default as ContainerOutlined} from '@ant-design/icons/ContainerOutlined';

export {default as DownloadOutlined} from '@ant-design/icons/DownloadOutlined';
export {default as SyncOutlined} from '@ant-design/icons/SyncOutlined';
