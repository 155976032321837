import React from 'react';
import PropTypes from 'prop-types';
import {Button, Table, Space} from 'antd';

import {getColumns} from './mediaColumns';
import {CheckOutlined, CopyOutlined} from '@/icons';

export default function MediaTable({
    editable = true,
    onItemChecked,
    loading,
    mediaRecords,
    columnKeys,
    columnOptions,
    columnActionProps,
    columnAction,
    pagination,
}) {
    const columns = getColumns(columnKeys, columnOptions);

    if (editable && columnAction) {
        columns.push(columnAction);
    }

    if (onItemChecked) {
        const {onCopyLinkClick} = columnActionProps;
        const width = onCopyLinkClick ? 250 : 100;

        const checkedAction = {
            title: '选择',
            key: 'checkedAction',
            width,
            // eslint-disable-next-line react/display-name
            render: (text, record) => {
                return (
                    <Space>
                        {onCopyLinkClick && (
                            <Button icon={<CopyOutlined />} onClick={() => onCopyLinkClick(record)}>
                                拷贝链接
                            </Button>
                        )}

                        <Button
                            type="primary"
                            icon={<CheckOutlined />}
                            onClick={() => onItemChecked(record)}
                        >
                            选择
                        </Button>
                    </Space>
                );
            },
        };

        columns.push(checkedAction);
    }

    return (
        <Table
            rowKey="id"
            loading={loading}
            dataSource={mediaRecords}
            columns={columns}
            pagination={pagination}
        />
    );
}

MediaTable.propTypes = {
    editable: PropTypes.bool,
    onItemChecked: PropTypes.func,
    loading: PropTypes.bool,
    mediaRecords: PropTypes.array,
    columnKeys: PropTypes.array,
    columnOptions: PropTypes.object,
    columnActionProps: PropTypes.object,
    columnAction: PropTypes.object,
    pagination: PropTypes.object,
};
