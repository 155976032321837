import React from 'react';
import {Redirect} from 'react-router-dom';
import _ from 'lodash';

import Forbidden from '@/page/component/AuthRoute/Forbidden';
import {useMenuService} from './adminService';

/**
 * 用于 /admin 的跳转。
 *
 * @constructor
 */
export default function AdminRedirect() {
    const menus = useMenuService();

    if (_.isEmpty(menus)) {
        return <Forbidden />;
    }

    // 存在菜单项则跳转
    return <Redirect to={menus[0].path} />;
}
