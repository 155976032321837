import React from 'react';
import PropTypes from 'prop-types';

import styles from './ArticleTitle.module.less';

export default function ArticleTitle({title, subTitle, description}) {
    return (
        <div className={styles.articleTitle}>
            <h1 className={styles.title}>{title}</h1>
            {subTitle && <h2 className={styles.subTitle}>{subTitle}</h2>}
            {description && <h2 className={styles.description}>{description}</h2>}
        </div>
    );
}

ArticleTitle.propTypes = {
    title: PropTypes.node.isRequired,
    subTitle: PropTypes.node,
    description: PropTypes.node,
};
