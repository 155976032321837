import React, {useEffect, useState} from 'react';
import {Link, matchPath, useLocation, useParams} from 'react-router-dom';
import {Menu} from 'antd';

import {MenuConfig} from './MediaRoutes';
import styles from './MediaSider.module.less';

export default function MediaSider() {
    const [selMenuKey, setSelMenuKey] = useState(MenuConfig[0].path);
    const location = useLocation();
    const {appId} = useParams();
    // console.log(location, appId);

    useEffect(() => {
        MenuConfig.forEach(config => {
            const m = matchPath(location.pathname, config);
            if (m && m.path) {
                setSelMenuKey(m.path);
            }
        });
    }, [location.pathname]);

    return (
        <Menu className={styles.menu} selectedKeys={[selMenuKey]}>
            {MenuConfig.map(({label, path, icon}) => {
                const to = path.replace(':appId', appId);

                return (
                    <Menu.Item key={path} icon={icon} className={styles.menuItem}>
                        <Link to={to}>{label}</Link>
                    </Menu.Item>
                );
            })}
        </Menu>
    );
}
