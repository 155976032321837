import React from 'react';
import PropTypes from 'prop-types';
import {Button, Space, Typography} from 'antd';
import {Link} from 'react-router-dom';
import {ClockCircleOutlined, SendOutlined} from '@/icons';
import FixedTimeModal from '@/page/app/MsgSendPage/FixedTimeModal';

SendButtons.propTypes = {
    appId: PropTypes.string.isRequired,
    isAuditSender: PropTypes.bool,
    submiting: PropTypes.bool,
    onSendClick: PropTypes.func.isRequired,
    onFixedTimeClick: PropTypes.func.isRequired,
    onFixedTimeChange: PropTypes.func.isRequired,
    fixedTimeModalRef: PropTypes.object.isRequired,
};

function SendButtons({
    appId,
    isAuditSender = false,
    submiting = false,
    onSendClick,
    onFixedTimeClick,
    onFixedTimeChange,
    fixedTimeModalRef,
}) {
    return (
        <>
            <Space size="large">
                <Button
                    type="primary"
                    danger={isAuditSender}
                    icon={<SendOutlined />}
                    loading={submiting}
                    disabled={submiting}
                    onClick={onSendClick(isAuditSender)}
                >
                    发送{isAuditSender && '审核'}
                </Button>
                <Button
                    icon={<ClockCircleOutlined />}
                    danger={isAuditSender}
                    onClick={onFixedTimeClick(isAuditSender)}
                >
                    定时发送
                </Button>

                {isAuditSender && (
                    <Link to={`/apps/${appId}/msg-send/list`}>
                        <Typography.Text type="secondary">历史消息</Typography.Text>
                    </Link>
                )}
            </Space>
            <FixedTimeModal ref={fixedTimeModalRef} onChange={onFixedTimeChange(isAuditSender)} />
        </>
    );
}

export default SendButtons;
