import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Space, Tooltip} from 'antd';

import PlusButton from './PlusButton';
import styles from './NewsLikeContent.module.less';

/**
 * 类似图文的消息内容，例如图文、链接图文、视频。
 */
export default function NewsLikeContent({
    hasValue,
    type,
    buttonTitle,
    description,
    onAddClick,
    onEditClick,
    onChange,
    children,
}) {
    function onDeleteClick() {
        Modal.confirm({
            title: '警告',
            content: '确定删除当前内容',
            okButtonProps: {danger: true},
            onOk: () => {
                if (type === 'video') {
                    onChange && onChange({});
                } else {
                    onChange && onChange([]);
                }
            },
        });
    }

    return (
        <>
            {hasValue && (
                <div className={styles.preview}>
                    <div className={styles.previewBox}>{children}</div>
                    <Space direction="vertical">
                        <Tooltip title="如编辑，则作为全新的图文发送，而不再与原有图文或素材关联">
                            <Button type="link" onClick={onEditClick}>
                                编辑
                            </Button>
                        </Tooltip>
                        <Button type="link" onClick={onDeleteClick}>
                            删除
                        </Button>
                    </Space>
                </div>
            )}

            {!hasValue && (
                <PlusButton title={buttonTitle} onClick={onAddClick}>
                    {description}
                </PlusButton>
            )}
        </>
    );
}

NewsLikeContent.propTypes = {
    type: PropTypes.oneOf(['video', 'news', 'news_link']).isRequired,
    hasValue: PropTypes.bool.isRequired,
    buttonTitle: PropTypes.string,
    description: PropTypes.node,
    onChange: PropTypes.func,
    onAddClick: PropTypes.func,
    onEditClick: PropTypes.func,
    children: PropTypes.node,
};
