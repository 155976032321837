import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {useAsyncRetry} from 'react-use';
import {Avatar, Comment, Tag} from 'antd';

import {getOpenComments} from '@/api/comment';
import NewsCommentForm from './NewsCommentForm';
import {LikeOutlined, LikeFilled} from '@/icons';
import {TextView} from '@/component';
import {toggleCommentPraise} from '@/api/praise';

import styles from './NewsCommentList.module.less';

export default function NewsCommentList({enableComment, appId, app, userId, userName, newsId}) {
    const [comments, setComments] = useState([]);
    const [praiseItemIds, setPraiseItemIds] = useState([]);

    const {loading, value, retry} = useAsyncRetry(() => {
        return getOpenComments(appId, newsId, userId);
    }, [appId, newsId]);

    // console.log('loading, value', loading, value);

    useEffect(() => {
        if (loading) {
            return;
        }

        const stickComments = _.get(value, 'data.comments', []).filter(({stick}) => stick);
        const commonComments = _.get(value, 'data.comments', []).filter(({stick}) => !stick);
        setComments([...stickComments, ...commonComments]);

        const pItemIds = _.get(value, 'data.praiseItemIds', []);
        setPraiseItemIds(pItemIds);
    }, [loading, value]);

    // console.log(comments);
    // console.log('app', app);

    function onPraiseClick(commentId) {
        if (!userId) {
            return;
        }

        toggleCommentPraise(appId, userId, userName, commentId).then(data => {
            console.log(data);
            retry();
        });
    }

    function renderList() {
        if (_.isEmpty(comments)) {
            return null;
        }

        const praiseStyle = userId ? {cursor: 'pointer'} : {};

        return (
            <div style={{marginTop: 24}}>
                <h4>精选评论</h4>
                {comments.map(
                    ({id, content, praiseNum, thumbAvatar, stick, userName, children}) => (
                        <Comment
                            key={id}
                            content={<TextView>{content}</TextView>}
                            author={
                                <>
                                    {userName} {stick && <Tag>置顶</Tag>}
                                </>
                            }
                            avatar={
                                <Avatar shape="square" src={thumbAvatar}>
                                    {userName.charAt(0)}
                                </Avatar>
                            }
                        >
                            <div
                                className={styles.praise}
                                style={praiseStyle}
                                onClick={() => onPraiseClick(id)}
                            >
                                {praiseItemIds.includes(id) ? (
                                    <LikeFilled style={{marginRight: 10, color: '#1890ff'}} />
                                ) : (
                                    <LikeOutlined style={{marginRight: 10}} />
                                )}
                                {praiseNum > 0 && praiseNum}
                            </div>

                            {/* 子评论不显示人员，而是现实应用名 */}
                            {!_.isEmpty(children) &&
                                children.map(child => (
                                    <Comment
                                        key={child.id}
                                        content={<TextView>{child.content}</TextView>}
                                        author={app?.name}
                                        avatar={
                                            <Avatar shape="square" src={app?.squareLogoUrl}>
                                                {app?.name?.charAt(0)}
                                            </Avatar>
                                        }
                                    >
                                        <div
                                            className={styles.praise}
                                            style={praiseStyle}
                                            onClick={() => onPraiseClick(child.id)}
                                        >
                                            {praiseItemIds.includes(child.id) ? (
                                                <LikeFilled
                                                    style={{marginRight: 10, color: '#1890ff'}}
                                                />
                                            ) : (
                                                <LikeOutlined style={{marginRight: 10}} />
                                            )}
                                            {child.praiseNum > 0 && child.praiseNum}
                                        </div>
                                    </Comment>
                                ))}
                        </Comment>
                    )
                )}
            </div>
        );
    }

    return (
        <div>
            {enableComment && (
                <NewsCommentForm
                    appId={appId}
                    newsId={newsId}
                    userId={userId}
                    userName={userName}
                />
            )}
            {renderList()}
        </div>
    );
}

NewsCommentList.propTypes = {
    enableComment: PropTypes.bool,
    appId: PropTypes.string,
    app: PropTypes.object,
    userId: PropTypes.string,
    userName: PropTypes.string,
    newsId: PropTypes.string,
};
