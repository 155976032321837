/**
 * 判断给定对象是否是 Promise。
 *
 * @param obj
 * @return {boolean}
 */
export const isPromise = obj => {
    return (
        !!obj &&
        (typeof obj === 'object' || typeof obj === 'function') &&
        typeof obj.then === 'function'
    );
};

/**
 * 给 Promise 增加 timeout 处理。
 *
 * @param ms 毫秒
 * @param promise Promise 对象
 * @return {Promise<any>}
 */
export const promiseTimeout = (ms, promise) => {
    // Create a promise that rejects in <ms> milliseconds
    const timeout = new Promise((resolve, reject) => {
        const id = setTimeout(() => {
            clearTimeout(id);
            reject(new Error('Timed out in ' + ms + 'ms.'));
        }, ms);
    });

    // Returns a race between our timeout and the passed in promise
    return Promise.race([promise, timeout]);
};
