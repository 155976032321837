import React from 'react';
import {Switch} from 'react-router-dom';

import {
    ApartmentOutlined,
    AppstoreAddOutlined,
    AppstoreOutlined,
    FileOutlined,
    FileProtectOutlined,
    SettingOutlined,
    TagsOutlined,
    CloudSyncOutlined,
} from '@/icons';
import AuthRoute from '@/page/component/AuthRoute';

export const AdminRouteConfig = [
    {
        path: '/admin',
        exact: true,
        component: require('./AdminRedirect').default,
    },
    {
        label: '应用管理',
        path: '/admin/apps',
        icon: <AppstoreAddOutlined />,
        authorities: ['global:app:r', 'global:app:w'],
        component: require('./AppsPage').default,
    },
    {
        path: '/admin/appcustom',
        authorities: ['global:app:r', 'global:app:w'],
        component: require('./AppCustomPage').default,
    },
    {
        label: '组织结构',
        path: '/admin/departs',
        icon: <ApartmentOutlined />,
        authorities: ['global:depart:r', 'global:depart:w'],
        component: require('./DepartsPage').default,
    },
    {
        label: '标签管理',
        path: '/admin/tags',
        icon: <TagsOutlined />,
        authorities: ['global:tag:r', 'global:tag:w'],
        component: require('./TagsPage').default,
    },
    {
        label: '角色管理',
        path: '/admin/roles',
        icon: <FileProtectOutlined />,
        authorities: ['global:role:r', 'global:role:w'],
        component: require('./RolesPage').default,
    },
    {
        label: '外部应用',
        path: '/admin/clientapps',
        icon: <AppstoreOutlined />,
        authorities: ['global:clientApp:r', 'global:clientApp:w'],
        component: require('./ClientAppsPage').default,
    },
    {
        label: '企业设置',
        path: '/admin/settings',
        icon: <SettingOutlined />,
        authorities: ['global:corp:r', 'global:corp:w'],
        component: require('./SettingsPage').default,
    },
    {
        label: '员工同步设置',
        path: '/admin/bi-sync',
        icon: <CloudSyncOutlined />,
        authorities: ['global:depart:r', 'global:depart:w'],
        component: require('./BiUserSync').default,
    },
    {
        label: '系统日志',
        path: '/admin/logs',
        icon: <FileOutlined />,
        authorities: ['global:log:r'],
        component: require('./LogsPage').default,
    },
    /*{
        label: '异步任务日志',
        path: '/admin/synclogs',
        icon: <RollbackOutlined />,
        authorities: ['global:asyncLog:r'],
        component: require('./SyncLogsPage').default,
    },*/
    {
        path: '/admin/*',
        component: require('../NotFoundPage').default,
    },
];

export const MenuConfig = AdminRouteConfig.filter(item => !!item.label);

export default function AppRoutes() {
    return (
        <Switch>
            {AdminRouteConfig.map(({path, exact, authorities, component}) => {
                return (
                    <AuthRoute
                        key={`route${path}`}
                        onAuthorities={authorities}
                        exact={exact}
                        path={path}
                        component={component}
                    />
                );
            })}
        </Switch>
    );
}
