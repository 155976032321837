import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';

import MediaListView from '../../MediaListView';
import {fileMediaTypeProps} from '../../propTypes';
import AddButton from '../AddButton';
import FileTitleFormModal from './FileTitleFormModal';
import {getFileUrl} from '@/page/util/appUrlUtils';
import {message} from 'antd';

export default function FileMediaWrapper({
    editable = true,
    enableDownload = true,
    onItemChecked,
    type,
    switchVisible = false,
    initialGrid = false,
    onNameClick,
}) {
    const listRef = useRef();
    const titleFormModalRef = useRef();

    function reload() {
        listRef.current.reload();
    }

    function onEditClick(record) {
        titleFormModalRef.current.showModal(record.id, record.title);
    }

    function onCopyLinkClick(record) {
        const url = getFileUrl(record.attachId, record.attachExt);

        const result = copy(url);
        if (result) {
            message.success(`链接拷贝到剪贴板`);
        } else {
            message.warn('链接拷贝失败');
        }
    }

    return (
        <>
            <MediaListView
                ref={listRef}
                editable={editable}
                onItemChecked={onItemChecked}
                type={type}
                columnKeys={['avatar', 'title', 'size', 'creatorName', 'createTime']}
                columnOptions={{onNameClick}}
                columnActionProps={{onEditClick, onCopyLinkClick, enableDownload}}
                switchVisible={switchVisible}
                initialGrid={initialGrid}
            >
                {editable && <AddButton type={type} reload={reload} />}
            </MediaListView>
            <FileTitleFormModal ref={titleFormModalRef} reload={reload} />
        </>
    );
}

FileMediaWrapper.propTypes = {
    ...fileMediaTypeProps,
    editable: PropTypes.bool,
    enableDownload: PropTypes.bool,
    onItemChecked: PropTypes.func,
    onNameClick: PropTypes.func,
    switchVisible: PropTypes.bool,
    initialGrid: PropTypes.bool,
};
