import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Badge, Button, Tag, Card, message, Space, Spin} from 'antd';
import _ from 'lodash';

import {validateCustomSendData} from '@/api/message';
import {getTypeAlias} from './utils';

CustomSendValidateView.propTypes = {
    appId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
    onBackClick: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

function CustomSendValidateView({appId, type, data, onBackClick, closeModal, onSelect}) {
    console.log('appId, type, data', appId, type, data);
    const [failureList, setFailureList] = useState([]);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        validateCustomSendData(appId, type, data)
            .then(json => {
                console.log(json);
                setUsers(json?.data?.users ?? []);
                setFailureList(json?.data?.failureList ?? []);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [appId, data, type]);

    function onSubmitClick() {
        console.log(users);
        if (_.isEmpty(users)) {
            message.warn(`有效用户列表不能为空`);
            return;
        }

        const selUsers = users.map(({id, name}) => ({id, name}));
        onSelect({users: selUsers});
        closeModal();
    }

    return (
        <Spin spinning={loading}>
            <Space direction="vertical" size="large" style={{display: 'flex'}}>
                <Alert
                    message={
                        <span>
                            <Tag>{getTypeAlias(type)}</Tag>
                            对应有效用户数：
                            <Badge
                                count={users.length}
                                showZero
                                overflowCount={1000}
                                style={{backgroundColor: '#52c41a'}}
                            />
                        </span>
                    }
                    type="success"
                    showIcon
                />

                {!_.isEmpty(failureList) && (
                    <>
                        <Alert
                            message="无效用户列表"
                            description="下属人员可能不在本应用可发送范围内，发送时将会忽略"
                            type="warning"
                            showIcon
                        />
                        <Card bodyStyle={{padding: 16}}>
                            <ul style={{marginBottom: 0}}>
                                {failureList.map(item => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </Card>
                    </>
                )}

                <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={onBackClick}>返回上一页</Button>
                    <Button type="primary" onClick={onSubmitClick}>
                        确定
                    </Button>
                </Space>
            </Space>
        </Spin>
    );
}

export default CustomSendValidateView;
