import PropTypes from 'prop-types';

export const valuePropTypes = PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    newsItemId: PropTypes.string,
    newsNum: PropTypes.number,
    attachId: PropTypes.string,
    attachExt: PropTypes.string,
    attachSize: PropTypes.number,
    newsContents: PropTypes.array,
});
